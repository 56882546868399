import React from 'react';

import { FormattedMessage } from 'react-intl';

import messages from './messages';

const IntegrityRating = () => {
  return (
    <div className="w-[269px] h-[163px] whitespace-normal py-4 px-2 text-xs rounded-md">
      <div className="mb-6">
        <span className="font-bold">
          <FormattedMessage {...messages.high} />:
        </span>
        <span className="font-normal ml-1">
          <FormattedMessage {...messages.high_description} />
        </span>
      </div>
      <div className="mb-6">
        <span className="font-bold">
          <FormattedMessage {...messages.medium} />:
        </span>
        <span className="font-normal ml-1">
          <FormattedMessage {...messages.medium_description} />
        </span>
      </div>
      <div>
        <span className="font-bold">
          <FormattedMessage {...messages.low} />:
        </span>
        <span className="font-normal ml-1">
          <FormattedMessage {...messages.low_description} />
        </span>
      </div>
    </div>
  );
};

export default IntegrityRating;
