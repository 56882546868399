import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import ToggleButton from '@components/Base/ToggleButton';
import messages from '@components/Flows/ConfigPanels/messages';
import PanelHeader from '@components/Flows/ConfigPanels/PanelHeader';
import configCardMessages from '@components/Flows/ConfigureCard/messages';
import { InitialStateType } from '@containers/flowDetails/slice';

interface OffboardingConfigProps {
  config: {
    feedback: boolean;
  };
  isUpdated: boolean;
  setDefaultPanelType: () => void;
  saveConfig: () => void;
  updateFeedbackChange: (obj: { [key: string]: boolean | null }) => void;
  updateFlowConfigStatus: InitialStateType['updateFlowConfigStatus'];
  createFlowConfigStatus: InitialStateType['createFlowConfigStatus'];
}

const OffboardingPanel = (props: OffboardingConfigProps) => {
  const {
    config: { feedback },
    isUpdated,
    setDefaultPanelType,
    saveConfig,
    updateFlowConfigStatus,
    createFlowConfigStatus,
    updateFeedbackChange,
  } = props;

  const handleFeedbackChange = (isFeedback: boolean) => updateFeedbackChange({ feedback: isFeedback });

  return (
    <>
      <PanelHeader
        label={<FormattedMessage {...configCardMessages.offboarding_label} />}
        description={<FormattedMessage {...configCardMessages.offboarding_description} />}
        setDefaultPanelType={setDefaultPanelType}
      />
      <div className="flex flex-col h-full p-5">
        <div className="flex items-center gap-3 px-4 py-3 bg-white rounded-md shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)]">
          <div className="flex flex-col">
            <span className="text-base font-semibold text-gray-900">
              <FormattedMessage {...configCardMessages.feedback} />
            </span>
            <span className="text-xs text-gray-600">
              <FormattedMessage {...configCardMessages.feedback_description} />
            </span>
          </div>
          <ToggleButton
            data-testid="feedback_switch"
            checked={feedback}
            onClick={handleFeedbackChange}
            disabled={false}
          />
        </div>
      </div>
      <div className="w-full px-5 py-4 mt-auto border-t border-gray-200 ">
        <Button
          isLoading={updateFlowConfigStatus.isConfigUpdateLoading || createFlowConfigStatus.isLoading}
          data-testid="save_button"
          className="w-full text-sm"
          disabled={!isUpdated}
          onClick={saveConfig}
          tabIndex={0}
        >
          <FormattedMessage {...messages.save_changes} />
        </Button>
      </div>
    </>
  );
};
export default OffboardingPanel;
