import React, { RefObject, useMemo, useRef, useState } from 'react';

import { ChevronDownIcon } from '@heroicons/react/24/solid';

import { classNames } from '@utils/classNames';

export interface AccordionProps {
  title: string | React.ReactNode;
  text: string | React.ReactNode;
  icon?: React.ReactNode;
  children?: React.ReactNode;
  isOpened: boolean;
  parentRef?: RefObject<HTMLDivElement> | null;
  contentWrapperHeight?: number | null;
}

const Accordion = ({ title, text, icon, children, isOpened, parentRef, contentWrapperHeight }: AccordionProps) => {
  const [isOpen, setIsOpen] = useState(isOpened);
  const childRef = useRef<HTMLDivElement>(null);

  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  const getContentWrapperHeight = () => {
    if (parentRef?.current) {
      return parentRef.current.clientHeight;
    } else if (childRef.current) {
      return childRef.current.clientHeight;
    }
  };

  const contentHeight = useMemo(() => getContentWrapperHeight(), [contentWrapperHeight, isOpen]);

  return (
    <div className="shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)] bg-white rounded py-3">
      <div className="relative isolate flex items-center justify-between px-4">
        <div className="flex items-center">
          {icon && <div className="bg-gray-100 px-[5px] py-4 flex justify-center items-center rounded">{icon}</div>}
          <div className="pl-2">
            <div className="text-base font-semibold">{title}</div>
            <div className="text-xs text-gray-600 font-normal">{text}</div>
          </div>
        </div>
        <button
          aria-expanded={isOpen}
          aria-label="Toggle accordion button"
          data-testid="switch-button"
          onClick={handleOnClick}
        >
          <ChevronDownIcon
            data-testid="chevron-down-icon"
            className={classNames(
              'h-6 w-6 ml-1 text-gray-500 transition-transform duration-500',
              isOpen ? '-rotate-180 ' : '-rotate-0',
            )}
          />
        </button>
      </div>
      <div
        data-testid="accordion-content"
        aria-hidden={!isOpen}
        className={classNames('overflow-hidden duration-500 transition-all', isOpen && 'mt-3')}
        style={{
          maxHeight: isOpen ? `${contentHeight}px` : 0,
        }}
      >
        <div
          ref={parentRef ?? childRef}
          className={classNames('transition-all duration-500', isOpen ? 'opacity-100' : 'opacity-0')}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export { Accordion };
