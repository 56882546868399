import { defineMessages } from 'react-intl';

export const scope = 'app.components.side_navigation.side_nav_profile_content';

export default defineMessages({
  account_settings: {
    id: `${scope}.account_settings`,
    defaultMessage: 'Account Settings',
  },
  sign_out: {
    id: `${scope}.sign_out`,
    defaultMessage: 'Sign Out',
  },
  version: {
    id: `${scope}.version`,
    defaultMessage: 'Version',
  },
});
