import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import { Checkbox } from '@components/Base/Checkbox';
import { Dropdown } from '@components/Base/Dropdown';
import SidePanel from '@components/SidePanel';
import { AppliedFilters, flowStatusList } from '@containers/flowList/types';

import { FilterButton } from './FilterButton';
import messages from './messages';

export interface ListFiltersProps {
  appliedFilters: AppliedFilters;
  isLoading: boolean;
  selectedUserDetails:
    | {
        label: string;
        id: number | string;
        value: number | string;
      }
    | string
    | undefined;
  updateAppliedFilters: React.Dispatch<React.SetStateAction<object>>;
  formattedUserList: {
    label: string;
    id: number | string;
    value: number | string;
  }[];
}

const ListFilters = ({
  appliedFilters,
  updateAppliedFilters,
  isLoading,
  selectedUserDetails,
  formattedUserList,
}: ListFiltersProps) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState<string[]>(appliedFilters.status ?? []);
  const [selectedUser, setSelectedUser] = useState<
    | {
        label: string;
        id: number | string;
        value: number | string;
      }
    | string
  >(selectedUserDetails ?? '');

  useEffect(() => {
    if (selectedUserDetails && selectedUserDetails !== selectedUser) {
      setSelectedUser(selectedUserDetails);
    }
  }, [selectedUserDetails]);

  const resetStates = () => {
    setSelectedStatus([]);
    setSelectedUser('');
    updateAppliedFilters({});
    handleOnClose();
  };

  const handleOnClose = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedStatus((prevState) => [
      ...new Set(e.target.checked ? [...prevState, value] : (prevState ?? []).filter((status) => status !== value)),
    ]);
  };

  const handleOnApply = () => {
    const filterObj = {
      status: selectedStatus,
      updated_by: get(selectedUser, 'id'),
    };
    updateAppliedFilters(filterObj);
    setIsFilterOpen(false);
  };

  const hasNonEmptyFilters = (filters: AppliedFilters): boolean => {
    return Object.values(filters).some((value) => {
      if (value && typeof value === 'object') {
        return Object.values(value).some(Boolean);
      }
      return Boolean(value);
    });
  };

  const hasAppliedFilters = useMemo(() => hasNonEmptyFilters(appliedFilters as AppliedFilters), [appliedFilters]);

  return (
    <>
      <FilterButton hasAppliedFilters={hasAppliedFilters} onClick={handleOnClose} />
      <SidePanel show={isFilterOpen} allowCustomHeader disabledAutoClose onClose={handleOnClose} containerPadding="p-0">
        <div className="flex flex-col flex-1">
          <div className="sticky top-0">
            <div className="flex p-5 bg-white">
              <div className="w-[20.375rem]">
                <div className="text-base font-semibold text-gray-900">
                  <FormattedMessage {...messages.filter} />
                </div>
                <div className="text-sm font-normal text-gray-600">
                  <FormattedMessage {...messages.customize_view} />
                </div>
              </div>
              <div
                data-testid="close_btn"
                role="button"
                tabIndex={-1}
                className="px-2 text-gray-600 cursor-pointer"
                onClick={handleOnClose}
                onKeyPress={handleOnClose}
              >
                <XMarkIcon className="w-6 h-6" aria-hidden="true" />
              </div>
            </div>
          </div>

          <div className="border-b"></div>

          <div className="flex flex-col flex-1 p-6 bg-white">
            <div className="text-base font-semibold text-gray-900">
              <FormattedMessage {...messages.status} />
            </div>
            <div className="flex pt-3 pb-6">
              {flowStatusList.map((list) => (
                <div key={list.value} className="pr-4">
                  <Checkbox
                    id={list.value}
                    onChange={(e) => handleStatusChange(e, list.value)}
                    checked={!!selectedStatus.includes(list.value)}
                    disabled={false}
                    name={list.label}
                    value={list.label}
                    label={list.label}
                    size="sm"
                  />
                </div>
              ))}
            </div>
            <div className="pb-2 text-base font-semibold text-gray-900">
              <FormattedMessage {...messages.last_updated_by} />
            </div>
            <Dropdown
              id="selectLastUpdatedBy"
              value={selectedUser}
              isSearchable={false}
              placeholder={<FormattedMessage {...messages.select} />}
              onChange={(user) =>
                setSelectedUser(
                  user as {
                    label: string;
                    id: string | number;
                    value: string | number;
                  },
                )
              }
              options={formattedUserList}
              isLoading={isLoading}
            />
          </div>

          <div className="sticky bottom-0">
            <div className="h-20 flex items-center justify-start px-6 bg-white shadow-[0px_3px_45px_0px_#00000024]">
              <Button variant="outline" onClick={resetStates}>
                <FormattedMessage {...messages.clear} />
              </Button>
              <div className="px-3">
                <Button onClick={handleOnApply}>
                  <FormattedMessage {...messages.apply} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SidePanel>
    </>
  );
};

export default ListFilters;
