import React, { useEffect, useRef, useState } from 'react';

import CalendarView from '@components/Shared/DateRangePicker/CalenderView/Calender';

import DateInputBox from './DateInputBox';
import Header from './Header';
import './styles.css';

interface DatePickerProps {
  onChange: (date: Date) => void;
  defaultDate?: Date;
  disablePastDate?: boolean;
  isReadOnly?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disabledWeekend?: boolean;
  isDateRangePicker?: boolean;
  dateClassName?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  onChange,
  defaultDate,
  disablePastDate = false,
  isReadOnly = false,
  minDate,
  maxDate,
  disabledWeekend = false,
  dateClassName,
}) => {
  const [selectedDate, setSelectedDate] = useState<Date | null>(defaultDate || null);
  const [isHeaderVisible, setIsHeaderVisible] = useState<boolean>(false);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const calendarRef = useRef<HTMLDivElement>(null);
  const [isDisplayDateView, setIsDisplayDateView] = useState<boolean>(true);

  const handleDateChange = (newDate: Date) => {
    if (!isReadOnly) {
      setSelectedDate(newDate);
      onChange(newDate);
    }
  };

  const handleMonthSelect = (newDate: Date) => {
    handleDateChange(newDate);
  };

  const handleYearSelect = (year: number) => {
    if (selectedDate) {
      const newDate = new Date(year, selectedDate.getMonth(), selectedDate.getDate());
      handleDateChange(newDate);
    }
  };

  const handleCalendarIconClick = () => {
    handleDateView(true);
    setIsHeaderVisible((prev) => !prev);
  };

  const handleInputFocus = () => {
    setIsHeaderVisible(false);
  };

  const handleDateView = (isDisplay: boolean) => {
    setIsDisplayDateView(isDisplay);
  };

  useEffect(() => {
    const handleClickEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsHeaderVisible(false);
      }
    };
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node) &&
        calendarRef.current &&
        !calendarRef.current.contains(event.target as Node)
      ) {
        setIsHeaderVisible(false);
      }
    };

    document.addEventListener('keydown', handleClickEscapeKey);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleClickEscapeKey);
    };
  }, []);

  return (
    <div ref={datePickerRef} className="date-picker relative">
      <DateInputBox
        date={selectedDate}
        onDateChange={handleDateChange}
        onIconClick={handleCalendarIconClick}
        onInputFocus={handleInputFocus}
        inputClassName={dateClassName}
      />
      {isHeaderVisible && (
        <div ref={calendarRef} className={'calenderview-container h-fit bg-white border shadow-lg rounded-lg p-4 mt-1'}>
          <Header
            onMonthSelect={handleMonthSelect}
            onYearSelect={handleYearSelect}
            defaultDate={selectedDate || new Date()}
            onDateChange={handleDateChange}
            handleDateView={handleDateView}
          />
          <div className="mt-1">
            {isDisplayDateView && (
              <CalendarView
                startDate={null}
                endDate={null}
                date={selectedDate || new Date()}
                selectedDate={selectedDate}
                onDateSelect={handleDateChange}
                disablePastDate={disablePastDate}
                isReadOnly={isReadOnly}
                minDate={minDate}
                maxDate={maxDate}
                disabledWeekend={disabledWeekend}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DatePicker;
