import mixpanel from 'mixpanel-browser';

import { mixpanelDefaultProperties, mixpanelEvents } from '@utils/mixpanelEvents';

export type MixpanelProperties = {
  orgId: number | null;
  orgName: string;
  userId: number | null;
  userRole: string;
  projectName?: string;
  flowType?: string;
  platformName?: string;
  version?: string;
};
export interface TrackProperties {
  [key: string]: string | number | boolean | object | null;
}

export const trackEvent = (event: string, properties?: TrackProperties) => {
  const mixpanelProperties = {
    ...mixpanelDefaultProperties,
    ...properties,
  };
  mixpanel.track(event, mixpanelProperties);
};

export const userLoggedInEvent = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_USER_LOGGED_IN, {
    ...options,
  });
};

export const proviewUserNewProjectCreationStartedEvent = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_USER_NEW_PROJECT_CREATION_STARTED, {
    ...options,
  });
};

export const proviewUserNewProjectCreatedEvent = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_USER_NEW_PROJECT_CREATED, {
    ...options,
  });
};

export const proviewUserProjectPageOpenedEvent = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_USER_PROJECT_PAGE_OPENED, {
    ...options,
  });
};

export const proviewUserFlowPageOpenedEvent = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_USER_FLOW_PAGE_OPENED, {
    ...options,
  });
};

export const proviewUserNewFlowCreationStartedEvent = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_USER_NEW_FLOW_CREATION_STARTED, {
    ...options,
  });
};

export const provieUserNewFlowCreatedEvent = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_USER_NEW_FLOW_CREATED, {
    ...options,
  });
};

export const proviewUserFlowDetailPageViewedEvent = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_USER_FLOW_DETAIL_PAGE_VIEWED, {
    ...options,
  });
};

export const proviewConsoleVoucherPageViewedEvent = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_CONSOLE_VOUCHERS_PAGE_VIEWED, {
    ...options,
  });
};

export const proviewConsoleVoucherFilterApplied = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_CONSOLE_VOUCHERS_FILTER_APPLIED, {
    ...options,
  });
};

export const proviewConsoleVoucherSearched = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_CONSOLE_VOUCHERS_SEARCHED, {
    ...options,
  });
};

export const proviewConsoleVoucherCreated = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_CONSOLE_VOUCHER_CREATED, {
    ...options,
  });
};

export const proviewConsoleVoucherDownloadListClicked = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_CONSOLE_VOUCHER_DOWNLOAD_LIST_CLICKED, {
    ...options,
  });
};

export const proviewConsoleVoucherDownloaded = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_CONSOLE_VOUCHER_DOWNLOADED, {
    ...options,
  });
};

export const proviewConsoleStackSet = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_CONSOLE_STAKES_SET, {
    ...options,
  });
};

export const proviewConsoleExamWorkflowPageViewedEvent = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_CONSOLE_WORKFLOWS_PAGE_VIEW, {
    ...options,
  });
};

export const proviewConsoleExamWorkflowFilterApplied = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_CONSOLE_WORKFLOWS_FILTER_APPLIED, {
    ...options,
  });
};

export const proviewConsoleExamWorkflowSearched = (options?: TrackProperties) => {
  trackEvent(mixpanelEvents.PROVIEW_CONSOLE_WORKFLOWS_SEARCHED, {
    ...options,
  });
};
