import isObject from 'lodash/isObject';
import some from 'lodash/some';
import uniqBy from 'lodash/uniqBy';

import { triggerToast } from '@components/Base/Notification';
import { User } from '@containers/app/types';
import messages from '@containers/workflows/messages';
import { TOAST_VARIANT } from '@utils/data/enums';
import { getMixpanelProperties } from '@utils/helpers';

import { AppliedFilters } from '.';
import { Workflow } from './types';

type initialFilters = {
  search: string;
  filters: AppliedFilters;
};

export const getFormattedSearchConditions = (searchTerm: string) => {
  return [{ title: { _ilike: `%${searchTerm}%` } }];
};

export const formatInitialFilters = (searchParams: URLSearchParams) => {
  return (): initialFilters => {
    const queryParams: {
      [key: string]: string | string[] | object | object[];
    } = {};
    for (const [key, value] of searchParams) {
      try {
        queryParams[key] = JSON.parse(decodeURIComponent(value));
      } catch {
        try {
          queryParams[key] = decodeURIComponent(value);
        } catch (e) {
          console.error('Failed to decode URI: ', e);
        }
      }
    }
    return {
      filters: (queryParams.filters as AppliedFilters) || {},
      search:
        queryParams.search != null && queryParams.search !== '' ? String(queryParams.search).replace(/%/g, '') : '',
    };
  };
};

export const getFormattedFilters = (appliedFilters: AppliedFilters) => {
  const { selectedStatus, selectedUser, selectedWorkflow } = appliedFilters;
  return {
    ...(selectedUser?.length && {
      updated_by: { _in: selectedUser.map(({ value }) => value) },
    }),
    ...(selectedWorkflow?.length && {
      title: { _in: selectedWorkflow.map(({ value }) => value) },
    }),
    ...(selectedStatus?.length && {
      status: { _in: selectedStatus.map(({ value }) => value) },
    }),
  };
};

export const showToast = (variant: string, title: string, summary: string) => {
  triggerToast({
    variant: variant,
    message: {
      title: title,
      summary: summary,
    },
  });
};

export const showWorkflowErrorToast = () => {
  showToast(
    TOAST_VARIANT.DANGER,
    messages.error_workflow_fetch_header.defaultMessage,
    messages.error_workflow_fetch_body.defaultMessage,
  );
};

export const formatProperties = (user: User, searchParam: string) => {
  const mixpanelProperties = getMixpanelProperties(user);
  const additionalProperties = {
    module: 'Exams',
  };
  const combinedProperties = {
    ...additionalProperties,
    ...mixpanelProperties,
    searchTerm: searchParam,
  };
  return combinedProperties;
};

export const getModifiedUserList = (wftWorkflow: Workflow[]) => {
  const filteredworkflows = wftWorkflow.filter(
    (workflow: Workflow) =>
      workflow.updated_by_details &&
      (workflow.updated_by_details.name || workflow.updated_by_details.email || workflow.updated_by_details.id),
  );

  const uniqueworkflows = uniqBy(filteredworkflows, (workflow: Workflow) => workflow.updated_by_details?.id);

  return uniqueworkflows
    .map((workflow) => {
      const { email, name, id } = workflow.updated_by_details || {};
      return {
        label: email || name || '',
        value: id,
      };
    })
    .filter((workflow) => workflow.label && workflow.value);
};

export const hasNonEmptyFilters = (filters: AppliedFilters): boolean => {
  return some(filters, (value) => {
    if (isObject(value)) {
      return some(value, Boolean);
    }
    return Boolean(value);
  });
};
