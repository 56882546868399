import React, { useRef } from 'react';

import { v4 as uuidv4 } from 'uuid';

import StripLoader from '@components/Base/InfiniteLoaders/StripLoader';

interface ColumnType {
  id: number | string;
}

interface TableLoaderProps {
  columns: Array<ColumnType>;
}
const TableLoader: React.FC<TableLoaderProps> = ({ columns }) => {
  const uuids = useRef(Array.from({ length: 8 }, () => uuidv4()));
  return (
    <>
      {uuids.current.map((id) => (
        <tr key={id} role="row" className="odd:bg-white even:bg-slate-50" data-testid="loading">
          {columns.map((column, index) => (
            <td
              key={`${column.id}-${index}`}
              className="px-6 py-5 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
            >
              <StripLoader />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
};

export default TableLoader;
