import React, { useEffect, useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import Header from '@components/Base/Header';
import WorkFlowList from '@components/WorkFlow';
import messages from '@containers/workflows/messages';
import { getWtfWorkflowAction } from '@containers/workflows/slice';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import { proviewConsoleExamWorkflowPageViewedEvent } from '@utils/mixpanelActions';
import { RootState } from '@utils/store';

import {
  formatInitialFilters,
  getFormattedFilters,
  getFormattedSearchConditions,
  getModifiedUserList,
} from './helpers';

export type AppliedFilters = {
  selectedStatus?: { label: string; value: string }[];
  selectedUser?: { label: string; value: string }[];
  selectedWorkflow?: { label: string; value: string }[];
};

const WorkFlows: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const parseInitialFilters = formatInitialFilters(searchParams);
  const filterDetails = parseInitialFilters();
  const { filters, search } = filterDetails;
  const [appliedFilters, updateAppliedFilters] = useState<AppliedFilters>(filters);
  const { data: wftWorkflow, isLoading, totalCount } = useAppSelector((state: RootState) => state.workFlowList);
  const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);

  const [searchTerm, setSearchTerm] = useState<string>(search);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const userList = useMemo(() => {
    return getModifiedUserList(wftWorkflow);
  }, [wftWorkflow]);

  const updateURLParams = () => {
    setSearchParams(
      (params) => {
        const filters = Object.entries(appliedFilters).reduce((acc: Record<string, string | object>, [key, value]) => {
          if (value) {
            if (
              Array.isArray(value) ? value.length > 0 : typeof value === 'object' ? Object.keys(value).length > 0 : true
            ) {
              acc[key] = value;
            }
          }
          return acc;
        }, {});

        if (Object.keys(filters).length > 0) {
          params.set('filters', JSON.stringify(filters));
        } else {
          params.delete('filters');
        }

        if (searchTerm) {
          params.set('search', searchTerm.replace(/%/g, ''));
        } else {
          params.delete('search');
        }
        return params;
      },
      { replace: true },
    );
  };

  useEffect(() => {
    updateURLParams();
    dispatch(
      getWtfWorkflowAction({
        searchConditions: searchTerm ? getFormattedSearchConditions(searchTerm) : {},
        conditions: getFormattedFilters(appliedFilters as AppliedFilters),
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      }),
    );
  }, [appliedFilters]);

  useEffect(() => {
    updateURLParams();
    dispatch(
      getWtfWorkflowAction({
        searchConditions: searchTerm ? getFormattedSearchConditions(searchTerm) : {},
        conditions: getFormattedFilters(appliedFilters as AppliedFilters),
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
      }),
    );
  }, [pagination]);

  useEffect(() => {
    updateURLParams();
    setPagination({ pageIndex: 0, pageSize: 10 });
    dispatch(
      getWtfWorkflowAction({
        searchConditions: searchTerm ? getFormattedSearchConditions(searchTerm) : {},
        conditions: getFormattedFilters(appliedFilters as AppliedFilters),
        limit: pagination.pageSize,
        offset: 0,
      }),
    );
  }, [searchTerm]);

  useEffect(() => {
    if (isMixpanelInitialized && user) {
      const mixpanelProperties = getMixpanelProperties(user);
      const additionalProperties = {
        module: 'Exams',
      };
      const properties = { ...mixpanelProperties, ...additionalProperties };
      proviewConsoleExamWorkflowPageViewedEvent(properties);
    }
  }, [isMixpanelInitialized, user.tenant]);

  return (
    <>
      <Header details={{ label: <FormattedMessage {...messages.workflow} /> }} />
      <WorkFlowList
        wftWorkflow={wftWorkflow}
        appliedFilters={appliedFilters}
        updateAppliedFilters={updateAppliedFilters}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        isLoading={isLoading}
        isMixpanelInitialized={isMixpanelInitialized}
        user={user}
        totalCount={totalCount}
        pagination={pagination}
        setPagination={setPagination}
        userList={userList}
      />
    </>
  );
};

export default WorkFlows;
