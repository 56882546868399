import React from 'react';

import { FormattedMessage } from 'react-intl';

import withUserActions from '@/hoc/withUserActions';
import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';

import messages from './messages';

type SignoutModalProps = {
  isModalOpen: boolean;
  closeModal: () => void;
  onSignout?: () => void;
};

export const SignoutModal = (props: SignoutModalProps) => {
  const { isModalOpen, closeModal, onSignout } = props;

  return (
    <Modal size="md" show={isModalOpen} onCancel={closeModal}>
      <div className="text-xl font-semibold text-gray-900">
        <FormattedMessage {...messages.sign_out} />
      </div>
      <div className="mt-3 mb-2 text-sm text-gray-900">
        <FormattedMessage {...messages.sign_out_warning} />
      </div>

      <div className="flex items-center justify-end gap-3 w-full mt-6">
        <Button variant="outline" onClick={closeModal}>
          <FormattedMessage {...messages.go_back} />
        </Button>

        <Button onClick={onSignout}>
          <FormattedMessage {...messages.confirm} />
        </Button>
      </div>
    </Modal>
  );
};

export default withUserActions(SignoutModal);
