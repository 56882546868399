import { defineMessages } from 'react-intl';

export const scope = 'app.components.SideNavigation.SideNavTab.settings';

export default defineMessages({
  settings: {
    id: `${scope}.settings`,
    defaultMessage: 'Settings',
  },
  integrations: {
    id: `${scope}.integrations`,
    defaultMessage: 'Integrations',
  },
  home: {
    id: `${scope}.home`,
    defaultMessage: 'Home',
  },
  projects: {
    id: `${scope}.projects`,
    defaultMessage: 'Projects',
  },
  events: {
    id: `${scope}.events`,
    defaultMessage: 'Events',
  },
  flows: {
    id: `${scope}.flows`,
    defaultMessage: 'Flows',
  },
  sessions: {
    id: `${scope}.sessions`,
    defaultMessage: 'Sessions',
  },
  exams: {
    id: `${scope}.exams`,
    defaultMessage: 'Exams',
  },
  payments: {
    id: `${scope}.payments`,
    defaultMessage: 'Payments',
  },
  attendees: {
    id: `${scope}.attendees`,
    defaultMessage: 'Attendees',
  },
  reports: {
    id: `${scope}.reports`,
    defaultMessage: 'Reports',
  },
  more_apps: {
    id: `${scope}.more_apps`,
    defaultMessage: 'More Apps',
  },
  proctors: {
    id: `${scope}.proctors`,
    defaultMessage: 'Proctors',
  },
  schedule: {
    id: `${scope}.schedule`,
    defaultMessage: 'Schedule',
  },
  rooms: {
    id: `${scope}.rooms`,
    defaultMessage: 'Rooms',
  },
  voucher: {
    id: `${scope}.voucher`,
    defaultMessage: 'Vouchers',
  },
  workFlows: {
    id: `${scope}.workFlows`,
    defaultMessage: 'Work Flows',
  },
});
