import React from 'react';

import { useFeatureValue } from '@growthbook/growthbook-react';
import { get, includes } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { Outlet, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import { triggerToast } from '@components/Base/Notification';
import ProjectForm from '@components/Projects/ProjectForm';
import SideNavigation from '@components/SideNavigation';
import { navSection } from '@components/SideNavigation/SideNavTab/navItems';
import { setTimezoneAction } from '@containers/app/slice';
import {
  createNewProject,
  createProjectComplete,
  editProject,
  handleProjectFormClose,
} from '@containers/projects/helpers';
import { selectProjectById } from '@containers/projects/slice';
import { DEFAULT_TIMEZONE, FormattedTimeZone } from '@containers/user/types';
import HeaderProvider from '@contexts/HeaderContext';
import { LanguageContextType, useLanguage } from '@contexts/LanguageContext';
import { TOAST_VARIANT } from '@utils/data/enums';
import { GROWTHBOOK_FLAGS } from '@utils/growthbook';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import useFetchFileUrl from 'app/hooks/useFetchFileUrl';

import messages from './messages';

const Layout = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const hideNavigation = includes(['/logout'], location.pathname);
  const logoUrl = useFeatureValue(GROWTHBOOK_FLAGS.LOGO_URL, '');

  const { projectForm, data: projects, isLoading } = useAppSelector((state) => state.projects);

  const selectedProject = useAppSelector((state) => selectProjectById(state, projectForm.projectId));
  const user = useAppSelector((state) => state.app.user);
  const timezones = useAppSelector((state) => state.app.timezones);
  const title = <FormattedMessage {...messages.changes_saved} />;
  const summary = <FormattedMessage {...messages.changes_summary} />;

  const { locale, switchLanguage } = useLanguage() as LanguageContextType;

  const handleSetLocale = (language: string) => {
    switchLanguage(language);
    triggerToast({
      variant: TOAST_VARIANT.SUCCESS,
      message: { title, summary },
    });
  };

  const handleSetTimezone = (timezone: FormattedTimeZone) => {
    dispatch(setTimezoneAction(timezone));
    triggerToast({
      variant: TOAST_VARIANT.SUCCESS,
      message: { title, summary },
    });
  };

  const { url } = useFetchFileUrl(user.profile_pic_file_id);

  return (
    <HeaderProvider>
      <div className="flex">
        {!hideNavigation && !isLoading && user.isLoggedIn && (
          <SideNavigation
            selectedTimezone={get(timezones, 'selectedTimezone', DEFAULT_TIMEZONE)}
            handleSetTimezone={handleSetTimezone}
            timeZone={get(timezones, 'data', [])}
            selectedLanguage={locale}
            handleSetLocale={handleSetLocale}
            isNewUser={!projects.length}
            navItems={navSection.slice(0, 13)}
            logo={logoUrl}
            user={{ ...user, url }}
          />
        )}
        {projectForm.isOpenModal && (
          <ProjectForm
            isOpen={projectForm.isOpenModal}
            isLoading={projectForm.isLoading}
            isSuccess={projectForm.isSuccess}
            newProject={projectForm.newProject}
            projects={projects}
            selectedProject={selectedProject}
            onClose={handleProjectFormClose}
            onComplete={createProjectComplete}
            onCreateProject={createNewProject}
            onEditProject={editProject}
          />
        )}
        <div className="relative w-full">
          <Outlet />
        </div>
      </div>
      <ToastContainer
        toastClassName="Toastify__toast rounded-none p-0 m-0 mt-4"
        bodyClassName="Toastify__toast-body p-0 m-0"
        className={'toast-container absolute right-7 bottom-7 w-[420px] z-50'}
        position="bottom-right"
      />
    </HeaderProvider>
  );
};

export default Layout;
