export const GET_PROJECTS_QUERY = `
    query getProjects {
        pc_project {
            id
            name
            region
            description
            token
            flows {
              name
              id
              uuid
            }
            tags {
              id
              key
              value
            }
        }
    }`;

export const CREATE_PROJECT_MUTATION = `
    mutation createProject($payload: pc_create_project_input!) {
      pc_create_project(data: $payload) {
        id,
        token,
        name,
        description,
        region,
        tags {
          id
          key
          value
        }
      }
    }
    `;

export const EDIT_PROJECT_MUTATION = `
    mutation editProject($payload: pc_update_project_input!) {
      pc_update_project(data: $payload) {
        id
        name
        region
        token
        description
        tags {
          id
          key
          value
        }
      }
    }`;
