import React from 'react';

import { FormattedMessage, MessageDescriptor } from 'react-intl';

import { Button } from '@components/Base/Button';

type EmptyMessagePageProps = {
  icon: string;
  titleMessage: MessageDescriptor;
  descriptionMessage: MessageDescriptor;
  buttonMessage?: MessageDescriptor;
  handleButtonClick?: () => void;
  descriptionWidth?: string;
};

const EmptyMessagePage: React.FC<EmptyMessagePageProps> = ({
  icon,
  titleMessage,
  descriptionMessage,
  buttonMessage,
  handleButtonClick,
  descriptionWidth,
}) => {
  return (
    <div className="flex justify-center w-full h-full">
      <div className="text-center text-gray-900 mt-52">
        <div className="flex justify-center mb-6">
          <img src={icon} alt="icon" className="w-20 h-20" />
        </div>
        <div className="text-lg font-semibold">
          <FormattedMessage {...titleMessage} />
        </div>
        <div className={`text-sm font-normal ${descriptionWidth}`}>
          <FormattedMessage {...descriptionMessage} />
        </div>
        {buttonMessage && handleButtonClick && (
          <div className="flex justify-center mt-5">
            <Button onClick={handleButtonClick}>
              <FormattedMessage {...buttonMessage} />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmptyMessagePage;
