export type UserType = {
  id: string;
  name: string;
  lastname: string;
  email: string;
  active: boolean;
  createdAt: Date;
  updatedAt: Date;
};

export type HASURA_HEADERS = {
  'X-Hasura-Default-Role': ROLE;
  'X-Hasura-Allowed-Roles': ROLE[];
  'X-Hasura-User-Id': string;
  'X-Hasura-Tenant-Id': string;
};

export type FIREBASE_USER = {
  'https://hasura.io/jwt/claims': HASURA_HEADERS;
  iss: string;
  aud: string;
  auth_time: number;
  user_id: string;
  sub: string;
  iat: number;
  exp: number;
  'rest-token'?: string;
  firebase: {
    identities: object;
    sign_in_provider: string;
  };
};

export const projectUser: UserType = {
  id: '1',
  name: 'John',
  lastname: 'Doe',
  email: 'john.doe@example.com',
  active: true,
  createdAt: new Date(),
  updatedAt: new Date(),
};

export type User = {
  currentRole: ROLE | string;
  accountName: string;
  isLoggedIn: boolean;
  email: string;
  uid: string;
  name: string;
  username: string;
  tenant: {
    id: number | null;
    name: string;
    subdomain: string;
  };
  metadata: {
    createdAt: string | null;
    creationTime: string;
    lastLoginAt: string;
    lastSignInTime: string;
  };
  roles?: ROLE[];
  displayName?: string | null;
  organization?: number;
  id: number | null;
  old_id?: number | null;
  external_id?: number;
  url?: string;
  profile_pic_file_id?: number;
  phone_number?: string;
  hasClient?: boolean;
  meta?: object;
  country?: object;
  timezone?: string;
};

export enum ROLE {
  ADMIN = 'ADMIN',
  RECRUITER = 'RECRUITER',
  MASTER_RECRUITER = 'MASTER_RECRUITER',
  CONTENT_MANAGER = 'CONTENT_MANAGER',
  EVALUATOR = 'EVALUATOR',
  COLLABORATOR = 'COLLABORATOR',
  COORDINATOR = 'COORDINATOR',
  HIRING_MANAGER = 'HIRING_MANAGER',
  MASTER_CONTENT_MANAGER = 'MASTER_CONTENT_MANAGER',
  TENANT_ADMIN = 'TENANT_ADMIN',
  PROCTOR_ADMIN = 'PROCTOR_ADMIN',
  PROCTOR = 'PROCTOR',
}

/**
 *  action and response types
 */

export type GetFileUrlActionPayload = {
  queryVariables: {
    id: number;
  };
  callback: {
    onSuccess: (response: FetchFileUrlResponse) => void;
  };
};

export type FetchFileUrlResponse = {
  resource_url: string;
  original_filename: string;
  id: number;
};

export type UploadFileActionPayload = {
  body: {
    file: File;
    additionalParameter: {
      file_type_id: number;
      owner_id: number;
    };
  };
  callback: {
    onSuccess: (response: number) => void;
    onError: (error: { errorMessage: string }) => void;
  };
};

export type UploadFileResponse = {
  data: {
    file_upload_file: {
      id: number;
      data: {
        Policy: string;
        'X-Amz-Algorithm': string;
        'X-Amz-Credential': string;
        'X-Amz-Date': string;
        'X-Amz-Signature': string;
        bucket: string;
        key: string;
        url: string;
      };
      expires_in: number;
      key: string;
    };
  };
};

export type UpdateUserActionPayload = {
  body: {
    profile_pic_file_id?: number;
    phone_number?: string;
    status?: string;
    timezone?: string;
  };
  callback: {
    onSuccess: () => void;
    onError: (error: unknown) => void;
  };
};

export type UpdateUserResponse = {
  data: {
    update_user: {
      success: boolean;
    };
  };
};
