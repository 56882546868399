import React, { ReactNode, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import messages from './messages';

interface Option {
  id: string;
  title: ReactNode;
  description?: ReactNode;
}

interface StackListProps {
  onSelect: (selectedId: string) => void;
}

const StackList: React.FC<StackListProps> = ({ onSelect }) => {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const options: Option[] = [
    {
      id: 'high',
      title: <FormattedMessage {...messages.high_stack_title} />,
      description: <FormattedMessage {...messages.high_stack_description} />,
    },
    {
      id: 'medium',
      title: <FormattedMessage {...messages.medium_stack_title} />,
      description: <FormattedMessage {...messages.medium_stack_description} />,
    },
    {
      id: 'low',
      title: <FormattedMessage {...messages.low_stack_title} />,
      description: <FormattedMessage {...messages.low_stack_description} />,
    },
    {
      id: 'noStack',
      title: <FormattedMessage {...messages.no_stack_title} />,
      description: <FormattedMessage {...messages.no_stack_description} />,
    },
  ];

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    onSelect(value);
  };

  return (
    <div className="p-4">
      <div className="flex flex-col h-full">
        {options.map((option) => (
          <div key={option.id} className="flex flex-col mb-4">
            <div className="flex flex-col gap-1 px-4 py-3 bg-white rounded-md shadow-md border-b">
              <div className="flex items-center gap-3">
                <input
                  type="radio"
                  id={option.id}
                  name="options"
                  value={option.id}
                  checked={selectedOption === option.id}
                  onChange={handleChange}
                  className="mr-2 leading-tight cursor-pointer"
                />
                <label htmlFor={option.id} className="font-semibold text-base cursor-pointer leading-tight">
                  {option.title}
                </label>
              </div>
              <div className="ml-9 text-gray-700 pt-1 text-xs font-normal" style={{ whiteSpace: 'pre-line' }}>
                {option.description}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StackList;
