import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Workflow } from './types';

export const initialState: {
  isLoading: boolean;
  isSuccess: boolean;
  error: string | Error | null;
  data: Workflow[];
  totalCount: number;
} = {
  isLoading: false,
  isSuccess: false,
  error: null,
  data: [],
  totalCount: -1,
};

export const workFlowList = createSlice({
  name: 'workFlowList',
  initialState: initialState,
  reducers: {
    getWtfWorkflowAction: (
      state,
      _action: PayloadAction<{
        searchConditions: object[] | object;
        conditions: object;
        limit: number;
        offset: number;
      }>,
    ) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error = null;
    },

    getWtfWorkflowSuccessAction: (state, { payload }: PayloadAction<{ data: Workflow[]; count: number }>) => {
      state.data = payload.data;
      state.totalCount = payload.count;
      state.isSuccess = true;
      state.isLoading = false;
      state.error = null;
    },

    getWtfWorkflowErrorAction: (state, { payload: error }) => {
      state.error = error;
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export const { getWtfWorkflowAction, getWtfWorkflowSuccessAction, getWtfWorkflowErrorAction } = workFlowList.actions;

export default workFlowList.reducer;
