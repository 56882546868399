import React from 'react';

export type TooltipProps = {
  children: React.ReactNode | string;
  text: React.ReactNode | string;
  isVisible?: boolean;
  position?: 'top-middle' | 'top-left' | 'top-right' | 'bottom-middle' | 'bottom-left' | 'bottom-right';
  id: string;
};

const positionClassesMap = {
  'top-middle': {
    container: 'bottom-full mb-2',
    arrow: 'top-6 left-1/2 transform -translate-x-1/2 -mb-2',
  },
  'top-left': {
    container: 'bottom-full mb-2 left-0',
    arrow: 'top-6 left-5 transform -translate-x-1/2 -mb-1',
  },
  'top-right': {
    container: 'bottom-full mb-2 right-0',
    arrow: 'top-6 right-5 transform translate-x-1/2 -mb-1',
  },
  'bottom-middle': {
    container: 'top-full mt-2',
    arrow: 'bottom-6 left-1/2 transform -translate-x-1/2 -mt-1',
  },
  'bottom-left': {
    container: 'top-full mt-2 left-0',
    arrow: 'bottom-6 left-5 transform -translate-x-1/2 -mt-1',
  },
  'bottom-right': {
    container: 'top-full mt-2 right-0',
    arrow: 'bottom-6 right-5 transform translate-x-1/2 -mt-2',
  },
};

const Tooltip = ({ children, text, isVisible, position = 'top-middle', id }: TooltipProps) => {
  const positionClasses = positionClassesMap[position];

  return (
    <div className="relative flex flex-col items-center group" data-testid={`${id}-tooltip`}>
      {children}
      <div
        className={`absolute flex flex-col items-center ${positionClasses.container} ${isVisible ? 'flex' : 'hidden'} group-hover:flex`}
      >
        <span className="relative p-2.5 text-xs z-10 leading-none text-white whitespace-nowrap bg-black rounded shadow-lg">
          {text}
        </span>
        <div className={`absolute w-3 h-3 rotate-45 bg-black ${positionClasses.arrow}`}></div>
      </div>
    </div>
  );
};

export default Tooltip;
