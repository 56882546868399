import React, { useState } from 'react';

import CalendarView from '@components/Shared/DateRangePicker/CalenderView/Calender';
import Header from '@components/Shared/DateRangePicker/Header';

import { DateRange } from '../../index';

interface CalendarViewProps {
  inputDateRange: DateRange;
  defaultDateToRenderCalender: null | Date;
  handleUpdateDefaultDate: (date: DateRange) => void;
  handleToUpdateCustomRangeOfDates: (date: Date) => void;
  handleCustomSelect: () => void;
  disablePastDate: boolean;
  isReadOnly: boolean;
  disabledWeekend: boolean;
  minDate?: Date;
  maxDate?: Date;
  isDateRangePicker?: boolean;
}

const CalenderView = (props: CalendarViewProps) => {
  const {
    inputDateRange,
    defaultDateToRenderCalender,
    handleUpdateDefaultDate,
    handleCustomSelect,
    handleToUpdateCustomRangeOfDates,
    disablePastDate,
    isReadOnly,
    disabledWeekend,
    minDate,
    maxDate,
    isDateRangePicker,
  } = props;
  const { startDate, endDate } = inputDateRange;
  const [showDateView, setShowDateView] = useState<boolean>(true);

  return (
    <div className="w-[224px]">
      <Header
        selectedMonth={defaultDateToRenderCalender ? defaultDateToRenderCalender.getMonth() : new Date().getMonth()}
        selectedYear={
          defaultDateToRenderCalender ? defaultDateToRenderCalender.getFullYear() : new Date().getFullYear()
        }
        onMonthSelect={(date) => {
          handleUpdateDefaultDate({
            startDate: date,
            endDate: null,
          });
        }}
        onYearSelect={(date) => {
          handleUpdateDefaultDate({
            startDate: date,
            endDate: null,
          });
        }}
        defaultDate={defaultDateToRenderCalender}
        toggleMonth={(date) => {
          handleUpdateDefaultDate({
            startDate: date,
            endDate: null,
          });
        }}
        handleDateView={(isDisplay) => {
          setShowDateView(isDisplay);
        }}
      />
      {showDateView && (
        <CalendarView
          date={defaultDateToRenderCalender || new Date()}
          selectedDate={null}
          startDate={startDate}
          endDate={endDate}
          onDateSelect={(date) => {
            handleToUpdateCustomRangeOfDates(date);
            handleCustomSelect();
          }}
          disablePastDate={disablePastDate}
          isReadOnly={isReadOnly}
          disabledWeekend={disabledWeekend}
          minDate={minDate}
          maxDate={maxDate}
          isDateRangePicker={isDateRangePicker}
        />
      )}
    </div>
  );
};

export default CalenderView;
