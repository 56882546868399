import React, { FC, ReactNode, createContext, useMemo } from 'react';

import type { Option } from '@components/Base/GroupDropdown';
import { handleProjectFormOpen } from '@containers/projects/helpers';
import { setCurrentProject } from '@containers/projects/slice';
import { AddNewProject, Project, type SelectedOption } from '@containers/projects/types';
import { transformDataToOptions } from '@utils/helpers';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import { proviewUserNewProjectCreationStartedEvent } from '@utils/mixpanelActions';
import type { RootState } from '@utils/store';

export type HeaderContextType = {
  handleProjectChange: (project: SelectedOption) => void;
  options: Option[];
};

export const initialHeaderContext = {
  handleProjectChange: (selectedOption: SelectedOption) => selectedOption,
  options: [],
};

export const HeaderContext = createContext<HeaderContextType>(initialHeaderContext);

const HeaderProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state: RootState) => state.projects);
  const { user } = useAppSelector((state) => state.app);

  const handleProjectChange = (selectedOption: SelectedOption) => {
    if (selectedOption.value !== AddNewProject) {
      const currentProject = data.find((option: Project) => option.id === selectedOption.details.id);
      currentProject && dispatch(setCurrentProject(currentProject));
    } else {
      handleProjectFormOpen();
      const mixpanelProperties = {
        orgId: user.tenant.id,
        orgName: user.tenant.name,
        userId: user.id,
        userRole: user.currentRole,
      };
      proviewUserNewProjectCreationStartedEvent(mixpanelProperties);
    }
    return selectedOption;
  };

  const options = useMemo(() => transformDataToOptions(data), [data]);

  return <HeaderContext.Provider value={{ handleProjectChange, options }}>{children}</HeaderContext.Provider>;
};

export default HeaderProvider;
