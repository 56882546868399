import { get } from 'lodash';
import { type CallEffect, type PutEffect, all, call, put, takeLatest } from 'redux-saga/effects';

import { Flow, type StatusPayload } from '@components/Flows/FlowListTable/columns';
import { setGlobalErrorAction } from '@containers/app/slice';
import { UPDATE_FLOW_MUTATION } from '@containers/flowDetails/queries';
import { UpdateFlowOutputType } from '@containers/flowDetails/types';
import fetchData from '@utils/fetchData';
import postData from '@utils/postData';

import { CREATE_FLOW_MUTATION, DELETE_FLOW_MUTATION, GET_FLOW_LIST_DATA, TEMPLATE_LIST } from './queries';
import {
  createFlowAction,
  createFlowErrorAction,
  createFlowSuccessAction,
  deleteFlowAction,
  deleteFlowErrorAction,
  deleteFlowSuccessAction,
  getFlowListAction,
  getFlowListErrorAction,
  getFlowListSuccessAction,
  getTemplateListAction,
  getTemplateListErrorAction,
  getTemplateListSuccessAction,
  updateFlowAction,
} from './slice';
import {
  CreateFlowAPIResponse,
  CreateFlowActionPayload,
  DeleteFlowAPIResponse,
  DeleteFlowActionPayload,
  Template,
} from './types';

interface GetFlowListAction {
  type: string;
  payload: {
    limit?: number;
    offset?: number;
    searchExpression?: object;
    projectId?: string | null;
    conditions?: object;
  };
}

export function* createFlowSaga(action: {
  type: string;
  payload: CreateFlowActionPayload;
}): Generator<CallEffect<unknown> | PutEffect, void, CreateFlowAPIResponse> {
  try {
    const response: CreateFlowAPIResponse = yield call(postData, {
      queryString: CREATE_FLOW_MUTATION,
      payload: action.payload,
    });
    if (response && 'data' in response) {
      yield put(createFlowSuccessAction(response.data.pc_create_flow));
    }
  } catch (error) {
    const errorMessage = error instanceof Error ? error.message : String(error);
    yield put(createFlowErrorAction(errorMessage));
    yield put(setGlobalErrorAction(error));
  }
}

export function* deleteFlowSaga(action: {
  type: string;
  payload: DeleteFlowActionPayload;
}): Generator<CallEffect<unknown> | PutEffect, void, DeleteFlowAPIResponse> {
  try {
    const response: DeleteFlowAPIResponse = yield call(postData, {
      queryString: DELETE_FLOW_MUTATION,
      payload: action.payload,
    });
    if (response && 'data' in response) {
      yield put(deleteFlowSuccessAction(response.data.pc_delete_flow));
    }
  } catch (error) {
    yield put(deleteFlowErrorAction());
    yield put(setGlobalErrorAction(error));
  }
}

export function* getFlowList(action: GetFlowListAction) {
  const { limit, offset, searchExpression, projectId, conditions } = action.payload;
  try {
    const response: Array<Flow> = yield call(fetchData, {
      queryString: GET_FLOW_LIST_DATA,
      queryKey: 'pc_flow',
      queryVariables: {
        limit,
        offset,
        searchExpression,
        projectId,
        conditions,
      },
      forceRefresh: true,
    });

    const aggregateResponse: {
      aggregate: { count: number };
    } = yield call(fetchData, {
      queryString: GET_FLOW_LIST_DATA,
      queryKey: 'pc_flow_aggregate',
      queryVariables: {
        limit,
        offset,
        searchExpression,
        projectId,
        conditions,
      },
      forceRefresh: true,
    });

    const count = get(aggregateResponse, 'aggregate.count', 0);

    if (response) {
      yield put(getFlowListSuccessAction({ data: response, totalCount: count }));
    }
  } catch (error) {
    yield put(getFlowListErrorAction(error));
    yield put(setGlobalErrorAction(error));
  }
}

export function* getTemplateList() {
  try {
    const response: Array<Template> = yield call(fetchData, {
      queryString: TEMPLATE_LIST,
      queryKey: 'pc_flow',
      queryVariables: {},
      forceRefresh: true,
    });
    if (response) {
      yield put(getTemplateListSuccessAction(response));
    }
  } catch (error) {
    yield put(getTemplateListErrorAction(error));
    yield put(setGlobalErrorAction(error));
  }
}

export function* updateFlowActiveStatus(payloadData: { type: string; payload: StatusPayload }) {
  const { payload, callback } = payloadData.payload as StatusPayload;
  try {
    const response: UpdateFlowOutputType = yield call(postData, {
      queryString: UPDATE_FLOW_MUTATION,
      payload: payload,
    });
    if (response && callback.onSuccess) {
      yield call(callback.onSuccess, response);
    }
  } catch (error) {
    callback.onError();
    yield put(setGlobalErrorAction(error));
  }
}

export function* watchGetFlowList() {
  yield takeLatest(getFlowListAction.type, getFlowList);
}

export function* watchCreateFlow() {
  yield takeLatest(createFlowAction.type, createFlowSaga);
}

export function* watchDeleteFlow() {
  yield takeLatest(deleteFlowAction.type, deleteFlowSaga);
}

export function* watchGetTemplateList() {
  yield takeLatest(getTemplateListAction.type, getTemplateList);
}

export function* watchFlowStatusSagas() {
  yield takeLatest(updateFlowAction.type, updateFlowActiveStatus);
}

export function* flowListSagas() {
  yield all([watchGetFlowList(), watchCreateFlow(), watchGetTemplateList(), watchFlowStatusSagas(), watchDeleteFlow()]);
}
