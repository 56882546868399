import React from 'react';

import { ChevronRightIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/outline';

import { classNames } from '@utils/classNames';

interface Props {
  isExpanded: boolean;
}

const SideNavHelp = (props: Props) => {
  const { isExpanded } = props;
  return (
    <div
      className={classNames(
        'flex items-center cursor-pointer overflow-hidden transition-all duration-500 ',
        isExpanded ? 'w-full pl-3.5 pr-2' : ' px-3.5',
      )}
    >
      <div className="w-6">
        <QuestionMarkCircleIcon width={24} height={24} />
      </div>
      <div
        className={classNames('mr-auto ml-3 transition-all duration-1000', isExpanded ? 'opacity-100' : 'opacity-0')}
      >
        <div className="text-sm text-gray-900 text-nowrap">Get Help</div>
      </div>

      <ChevronRightIcon height={20} width={20} className="ml-auto text-gray-400 " />
    </div>
  );
};

export default SideNavHelp;
