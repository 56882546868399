import React, { Fragment } from 'react';

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { FormattedMessage } from 'react-intl';

import Channels from '@images/svgs/channels.svg';
import { classNames } from '@utils/classNames';

import messages from './messages';

const SettingsNavItem = () => {
  return (
    <Popover className="relative">
      <PopoverButton className="w-full outline-none">
        <FormattedMessage {...messages.settings} />
      </PopoverButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <PopoverPanel className={classNames('absolute bg-white bottom-[-16px] z-50 left-36')}>
          <div className="border border-gray-200 w-52 rounded-md shadow-[0px_4px_24px_rgba(17,24,39,0.08)]">
            <div className="pt-4 pb-3 px-4">
              <div className="text-sm gap-2 flex align-middle">
                <img src={Channels} alt="Flow" className="min-w-6 min-h-6" />
                <FormattedMessage {...messages.integrations} />
              </div>
            </div>
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default SettingsNavItem;
