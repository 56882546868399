import { defineMessages } from 'react-intl';

export const scope = 'app.components.proview_assessment.exam_list';

export default defineMessages({
  no_assessments: {
    id: `${scope}.no_session`,
    defaultMessage: 'No Assessments Yet',
  },
  no_assessments_description: {
    id: `${scope}.no_session_description`,
    defaultMessage: 'There are no assessments to view at the moment.',
  },
  empty_record_title: {
    id: `${scope}.empty_search_list`,
    defaultMessage: 'No Results Found',
  },
  empty_record_description: {
    id: `${scope}.empty_record_description`,
    defaultMessage: "Sorry, we couldn't find any matches. Please try again.",
  },
  search_students: {
    id: `${scope}.search_students`,
    defaultMessage: 'Search students...',
  },
  view_recording: {
    id: `${scope}.view_recording`,
    defaultMessage: 'View Recording',
  },
  session_rating: {
    id: `${scope}.session_rating`,
    defaultMessage: 'Session Rating',
  },
  proview_assessment: {
    id: `${scope}.proview_assessment`,
    defaultMessage: 'Proview Assessment',
  },
  customize_view: {
    id: `${scope}.customize_view`,
    defaultMessage: 'Customize your view by selectively including or excluding table elements.',
  },
  filter: {
    id: `${scope}.filter`,
    defaultMessage: 'Filter',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Clear',
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Apply',
  },
  attendee: {
    id: `${scope}.attendee`,
    defaultMessage: 'Attendee',
  },
  select_attendee: {
    id: `${scope}.select_attendee`,
    defaultMessage: 'Select Attendee',
  },
  select_accoummodations: {
    id: `${scope}.select_accoummodations`,
    defaultMessage: 'Select accommodations',
  },
  accomodation: {
    id: `${scope}.accomodation`,
    defaultMessage: 'Accomodation',
  },
  select_status: {
    id: `${scope}.select_status`,
    defaultMessage: 'Select status',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  completed_on_date: {
    id: `${scope}.completed_on_date`,
    defaultMessage: 'Completed on date',
  },
});
