import { all, call, put, takeLatest } from 'redux-saga/effects';

import fetchData from '@utils/fetchData';

import { GET_PRO_SESSION_QUERY } from './queries';
import { SessionData, getProSessionsAction, getProSessionsErrorAction, getProSessionsSuccessAction } from './slice';

interface GetSessionListAction {
  type: string;
  payload: {
    conditions?: {
      title: string[];
      session_type: string[];
      session_status: string[];
      system_rating: string[];
    };
  };
}

export function* getSessionDetails(action: GetSessionListAction) {
  const { conditions } = action.payload;

  const queryVariables = {
    limit: 10,
    offset: 0,
    conditions: {
      ...conditions,
    },
  };

  try {
    const response: SessionData[] = yield call(fetchData, {
      queryString: GET_PRO_SESSION_QUERY,
      queryKey: 'pro_session',
      queryVariables: queryVariables,
      forceRefresh: true,
    });

    if (response) {
      yield put(getProSessionsSuccessAction(response));
    }
  } catch (error) {
    yield put(getProSessionsErrorAction(error as { message: string }));
    console.error('Failed to fetch session details.', error);
  }
}

export function* watchGetSessions() {
  yield takeLatest(getProSessionsAction.type, getSessionDetails);
}

export function* consoleSessionsSaga() {
  yield all([watchGetSessions()]);
}
