import React, { ChangeEvent, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import { Checkbox } from '@components/Base/Checkbox';
import { Dropdown } from '@components/Base/Dropdown';
import messages from '@components/Exams/messages';
import DateRangePicker from '@components/Shared/DateRangePicker';
import SidePanel from '@components/SidePanel';
import { accomodationList, sessionRating } from '@containers/sessions/types';
import { wftJobStatusList } from '@containers/workflows/types';
import { attendeeList } from '@mocks/data';

export interface ExamFiltersProps {
  toogleFilterView: () => void;
  showFilter: boolean;
  updateAppliedFilters: (filters: object) => void;
}

const Filters = (props: ExamFiltersProps) => {
  const { toogleFilterView, showFilter, updateAppliedFilters } = props;

  const status = wftJobStatusList.map((status) => ({
    label: status.label,
    value: status.key,
  }));

  const [selectedSessionRating, setSelectedSessionRating] = useState<string[]>([]);
  const [selectedAccomodations, setSelectedAccomodations] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(null);

  const [selectedStatus, setSelectedStatus] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(null);

  const [selectedDateRange, setSelectedDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  } | null>(null);

  const [selectedAttendee, setSelectedAttendee] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(null);

  const clearFilters = () => {
    setSelectedAttendee(null);
    setSelectedStatus(null);
    setSelectedSessionRating([]);
    setSelectedAccomodations(null);
    setSelectedDateRange(null);
    updateAppliedFilters({});
  };

  const resetFilter = () => {
    clearFilters();
    toogleFilterView();
  };

  const handleSessionRatingChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedSessionRating((prevState) => [
      ...new Set(e.target.checked ? [...prevState, value] : (prevState ?? []).filter((status) => status !== value)),
    ]);
  };

  const handleApplyFilter = () => {
    const filters = {
      selectedDateRange,
      selectedAttendee,
      selectedStatus,
      selectedSessionRating,
      selectedAccomodations,
    };
    updateAppliedFilters(filters);
    toogleFilterView();
  };

  return (
    <>
      <SidePanel show={showFilter} containerPadding="p-0" onClose={toogleFilterView} allowCustomHeader={true}>
        <div className="flex flex-col flex-1 justify-between max-w-filter-panel">
          <div>
            <div className="sticky top-0 border-b z-[1]">
              <div className="flex p-5 bg-white">
                <div className="w-filter-header">
                  <div className="text-base font-semibold text-gray-900">
                    <FormattedMessage {...messages.filter} />
                  </div>
                  <div className="text-sm font-normal text-gray-600">
                    <FormattedMessage {...messages.customize_view} />
                  </div>
                </div>
                <div
                  data-testid="close_btn"
                  role="button"
                  tabIndex={-1}
                  className="px-2 text-gray-600 cursor-pointer"
                  onClick={toogleFilterView}
                  onKeyPress={toogleFilterView}
                >
                  <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                </div>
              </div>
            </div>
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.attendee} />
              </div>
              <div className="pt-3">
                <Dropdown
                  id="attendee-list"
                  isMulti={true}
                  value={selectedAttendee}
                  isSearchable={true}
                  placeholder={<FormattedMessage {...messages.select_attendee} />}
                  onChange={(attendee) => setSelectedAttendee(attendee as { label: string; value: string }[])}
                  options={attendeeList.length ? attendeeList : []}
                />
              </div>
            </div>
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.session_rating} />
              </div>
              <div className="flex pt-3">
                {sessionRating.map((list) => (
                  <div key={list.value} className="pr-4">
                    <Checkbox
                      id={list.value}
                      onChange={(e) => handleSessionRatingChange(e, list.value)}
                      checked={!!selectedSessionRating.includes(list.value)}
                      disabled={false}
                      name={list.label}
                      value={list.label}
                      label={list.value}
                      size="sm"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.accomodation} />
              </div>
              <div className="pt-3">
                <Dropdown
                  id="accomodation-list"
                  isMulti={true}
                  value={selectedAccomodations}
                  isSearchable={true}
                  placeholder={<FormattedMessage {...messages.select_accoummodations} />}
                  onChange={(accomodation) =>
                    setSelectedAccomodations(accomodation as { label: string; value: string }[])
                  }
                  options={accomodationList}
                />
              </div>
            </div>
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.status} />
              </div>
              <div className="pt-3">
                <Dropdown
                  id="status-list"
                  isMulti={true}
                  value={selectedStatus}
                  isSearchable={true}
                  placeholder={<FormattedMessage {...messages.select_status} />}
                  onChange={(status) => {
                    setSelectedStatus(status as { label: string; value: string }[]);
                  }}
                  options={status}
                />
              </div>
            </div>
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.completed_on_date} />
              </div>
              <div className="flex pt-3">
                <DateRangePicker onClickOnDonebtn={(range) => setSelectedDateRange(range)} />
              </div>
            </div>
          </div>
          <div className="sticky bottom-0">
            <div className="h-20 flex items-center justify-start px-6 bg-white shadow-[0px_3px_45px_0px_#00000024]">
              <Button variant="outline" onClick={resetFilter}>
                <FormattedMessage {...messages.clear} />
              </Button>
              <div className="px-3">
                <Button onClick={handleApplyFilter}>
                  <FormattedMessage {...messages.apply} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SidePanel>
    </>
  );
};

export default Filters;
