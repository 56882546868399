import React, { useState } from 'react';

import { TrashIcon } from '@heroicons/react/24/outline';
import { Row } from '@tanstack/react-table';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

import Badge from '@components/Base/Badge';
import { CustomColumnDef } from '@components/Base/Table/types';
import ToggleButton from '@components/Base/ToggleButton';
import User from '@components/Base/UserDetails';
import DisableFlowModal from '@components/Flows/DisableFlowModal';
import { FlowConfigPayloadType } from '@containers/flowDetails/types';
import { STATUS } from '@utils/data/enums';
import { formatDate } from '@utils/dateFormat';
import { handleEnterKeyPress } from '@utils/helpers';

import messages from './messages';

export interface Flow {
  id?: number | string;
  is_default?: boolean;
  is_active: boolean;
  uuid: string;
  name: string;
  flow_configs: Array<FlowConfigPayloadType>;
  updated_at?: string;
  user_by_updated_by?: Partial<User> & {
    updated_at: string | null;
  };
}

export type StatusPayload = {
  payload: {
    id: number;
    is_active: boolean;
  };
  callback: {
    onSuccess: (res: unknown) => void;
    onError: () => void;
  };
};

const StatusBadge = (status: string) => {
  switch (status) {
    case STATUS.PUBLISHED:
      return <Badge type="primary">{status}</Badge>;
    case STATUS.DRAFT:
      return <Badge type="draft">{status}</Badge>;
    default:
      return <Badge type="primary">{status}</Badge>;
  }
};

const getLatestPublishConfig = (row: Row<Flow>) => {
  return row.original.flow_configs
    .filter((config: FlowConfigPayloadType) => config.status === STATUS.PUBLISHED)
    .reverse()[0];
};

export const createColumns = (
  updatedAtSortingFn: (rowA: Row<Flow>, rowB: Row<Flow>) => number,
  handleClick: (uuid: string) => void,
  handleDeleteClick: (id: string | number | undefined) => void,
  handleStatusChange: (payload: {
    payload: {
      id: number;
      is_active: boolean;
    };
    callback: {
      onSuccess: (res: unknown) => void;
      onError: () => void;
    };
  }) => void,
): CustomColumnDef<Flow>[] => [
  {
    header: () => <FormattedMessage {...messages.flow_name} />,
    accessorKey: 'flowName',
    enableSorting: false,
    cell: ({ row }) => (
      <div
        onClick={() => handleClick(row.original.uuid)}
        onKeyDown={(e) => handleEnterKeyPress(e, () => handleClick(row.original.uuid))}
        role="button"
        tabIndex={0}
        className="px-2 font-normal w-[488px] truncate"
      >
        <div title={row.original.name} className="text-sm truncate text-primary">
          {row.original.name}
        </div>
        <div title={row.original.uuid} className="text-xs text-gray-500 truncate">
          {row.original.uuid}
        </div>
      </div>
    ),
    size: 488,
  },
  {
    header: '',
    size: 153,
    enableSorting: false,
    minSize: 153,
    maxSize: 153,
    accessorKey: 'blank',
    cell: ({ row }) => {
      const latestPublishedFlowConfig = getLatestPublishConfig(row);
      return row.original.is_default ? (
        <Badge type="primary" rounded="md">
          <FormattedMessage {...messages.default} />
        </Badge>
      ) : (
        StatusBadge(latestPublishedFlowConfig?.status || row.original.flow_configs[0].status || '')
      );
    },
  },
  {
    header: () => <FormattedMessage {...messages.status} />,
    size: 153,
    enableSorting: false,
    minSize: 153,
    maxSize: 153,
    accessorKey: 'status',
    cell: ({ row }) => {
      const [isActive, setIsActive] = useState(row.original.is_active);
      const [showModal, setShowModal] = useState(false);
      const [togglePayload, setTogglePayload] = useState({
        id: null,
        active: false,
      } as { id: number | null; active: boolean });

      const handleUpdateStatus = (id: number, active: boolean) => {
        if (!active) {
          handleMakeRequest(id, active);
        } else {
          setShowModal(true);
          setTogglePayload({ id, active });
        }
      };

      const getConsent = (isSaveChanges: boolean) => {
        if (isSaveChanges) {
          handleMakeRequest(togglePayload.id as number, togglePayload.active);
        }
        setShowModal(false);
      };

      const handleMakeRequest = (id: number, active: boolean) => {
        const payloadData = {
          payload: {
            id,
            is_active: !active,
          },
          callback: {
            onSuccess: (res: {
              data: {
                pc_update_flow: {
                  is_active: boolean;
                };
              };
            }) => {
              const { is_active } = res.data.pc_update_flow;
              setIsActive(is_active);
            },
            onError: () => {
              setIsActive(active);
            },
          },
        };
        handleStatusChange(payloadData as StatusPayload);
      };

      const latestPublishedFlowConfig = getLatestPublishConfig(row);

      return (
        <div className="flex items-center px-2 text-sm font-normal" key={row.original.id}>
          <ToggleButton
            checked={isActive}
            disabled={row.original.is_default || !(latestPublishedFlowConfig?.status === STATUS.PUBLISHED)}
            onClick={() => row.original.id && handleUpdateStatus(row.original.id as number, isActive)}
            name="status"
            focusVisible={false}
          />
          <div className="ml-2">
            <FormattedMessage {...(isActive ? messages.active : messages.inactive)} />
          </div>
          {showModal && <DisableFlowModal showModal={showModal} getConsent={getConsent} />}
        </div>
      );
    },
  },
  {
    header: () => <FormattedMessage {...messages.version} />,
    size: 142,
    enableSorting: false,
    minSize: 142,
    maxSize: 142,
    accessorKey: 'version',
    cell: ({ row }) => {
      const latestPublishedFlowConfig = getLatestPublishConfig(row);
      return (
        <div className="px-2 text-sm font-normal">
          {latestPublishedFlowConfig ? latestPublishedFlowConfig.version : row.original.flow_configs[0].version}
        </div>
      );
    },
  },
  {
    header: () => <FormattedMessage {...messages.last_updated} />,
    enableSorting: true,
    enableHiding: false,
    sortUndefined: false,
    accessorKey: 'updated_at',
    size: 287,
    minSize: 287,
    maxSize: 287,
    sortingFn: updatedAtSortingFn,
    cell: ({ row }) => {
      const updatedAt = get(row, 'original.updated_at');
      const formattedDate = updatedAt ? formatDate(new Date(updatedAt), 'withTime') : '';
      return (
        <div className="px-2">
          <User showEmail data={row.original.user_by_updated_by} additionalContent={formattedDate} />
        </div>
      );
    },
  },
  {
    header: '',
    id: 'delete',
    enableSorting: false,
    size: 73,
    minSize: 73,
    maxSize: 73,
    accessorFn: () => {},
    cell: ({ row }) => (
      <div className="px-2">
        {row.original.flow_configs[0].status === STATUS.DRAFT ? (
          <TrashIcon
            className="w-5 h-5 text-[#B91C1C] cursor-pointer"
            onClick={() => handleDeleteClick(row.original.id)}
            data-testid="delete-flow-icon"
          />
        ) : (
          <></>
        )}
      </div>
    ),
  },
];
