import React, { useContext, useEffect, useState } from 'react';

import { faLoader } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { get, isEqual } from 'lodash';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useParams } from 'react-router-dom';

import { triggerToast } from '@components/Base/Notification';
import EditFlowNameModal from '@components/Flows/EditFlowNameModal';
import FlowDetailsComponent from '@components/Flows/FlowDetails';
import FlowHeader from '@components/Flows/FlowHeader';
import {
  createFlowConfigAction,
  getFlowConfigByIDAction,
  resetTriggerToast,
  updateFlowAction,
  updateFlowConfigAction,
} from '@containers/flowDetails/slice';
import {
  type BlackListApps,
  type CreateFlowConfigActionPayload,
  FlowConfigPayloadType,
  type UpdateFlowConfigActionPayload,
} from '@containers/flowDetails/types';
import { HeaderContext, HeaderContextType } from '@contexts/HeaderContext';
import { STATUS, TOAST_STATE, TOAST_VARIANT } from '@utils/data/enums';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import { proviewUserFlowDetailPageViewedEvent } from '@utils/mixpanelActions';

import messages from './messages';

interface Payload {
  [key: string]: string | number | boolean | null | BlackListApps;
}

const FlowDetails = () => {
  const dispatch = useAppDispatch();
  const { flowId } = useParams();
  const {
    data,
    isLoading,
    updateFlowStatus,
    updateFlowConfigStatus,
    createFlowConfigStatus,
    versionLog,
    triggerToast: triggerFlowConfigToast,
  } = useAppSelector((state) => state.flowConfig);

  const currentProject = useAppSelector((state) => state.projects.currentProject);
  const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);
  const [editFlowNameState, setEditFlowNameState] = useState(false);
  const [flowConfigChanges, setFlowConfigChanges] = useState<FlowConfigPayloadType | null>(null);
  const { handleProjectChange, options } = useContext<HeaderContextType>(HeaderContext);

  useEffect(() => {
    if (data) {
      if (!isEqual(data.flow_configs[0], flowConfigChanges)) {
        setFlowConfigChanges(data.flow_configs[0] as FlowConfigPayloadType);
      }
    }
  }, [data && data.flow_configs[0]]);

  useEffect(() => {
    if (
      triggerFlowConfigToast &&
      triggerFlowConfigToast !== TOAST_STATE.UPDATE_FLOW_NAME_INITIATED &&
      triggerFlowConfigToast !== TOAST_STATE.DEFAULT_FLOW_CHANGE_INITIATED
    ) {
      handleTriggerToast(triggerFlowConfigToast);
      dispatch(resetTriggerToast(null));
    }
  }, [triggerFlowConfigToast]);

  useEffect(() => {
    if (flowId) dispatch(getFlowConfigByIDAction({ uuid: flowId }));
  }, [currentProject.id, flowId]);

  useEffect(() => {
    if (isMixpanelInitialized && user) {
      const mixpanelProperties = getMixpanelProperties(user);
      proviewUserFlowDetailPageViewedEvent(mixpanelProperties);
    }
  }, [isMixpanelInitialized, user.tenant]);

  const showToast = (variant: string, summary: MessageDescriptor, title?: MessageDescriptor) => {
    triggerToast({
      variant,
      message: {
        title: title && <FormattedMessage {...title} />,
        summary: <FormattedMessage {...summary} />,
      },
    });
  };

  const handleTriggerToast = (triggerFlowConfigToast: string) => {
    switch (triggerFlowConfigToast) {
      case TOAST_STATE.FLOW_CONFIG_CHANGES_SAVED:
        showToast(TOAST_VARIANT.SUCCESS, messages.changes_saved_description, messages.changes_saved);
        break;

      case TOAST_STATE.FLOW_CONFIG_CHANGES_FAILED:
        showToast(TOAST_VARIANT.DANGER, messages.changes_failed_description, messages.changes_failed);
        break;

      case TOAST_STATE.UPDATE_FLOW_NAME_SUCCESS:
        showToast(TOAST_VARIANT.SUCCESS, messages.flow_name_updated_description, messages.flow_name_updated);
        break;

      case TOAST_STATE.UPDATE_FLOW_NAME_FAILED:
        showToast(TOAST_VARIANT.DANGER, messages.flow_name_update_failed_description, messages.flow_name_update_failed);
        break;

      case TOAST_STATE.PUBLISH_FLOW:
        showToast(TOAST_VARIANT.SUCCESS, messages.flow_publish_description, messages.flow_publish);
        break;

      case TOAST_STATE.DEFAULT_FLOW_CHANGED:
        showToast(TOAST_VARIANT.SUCCESS, messages.default_flow_changed_description, messages.default_flow_changed);
        break;

      case TOAST_STATE.DEFAULT_FLOW_UPDATE_FAILED:
        showToast(
          TOAST_VARIANT.DANGER,
          messages.default_flow_update_failed_description,
          messages.default_flow_update_failed,
        );
        break;
      default:
        showToast(TOAST_VARIANT.DANGER, messages.something_went_wrong);
    }
  };

  const updateFlowConfig = (payload: Payload) => {
    const blacklistedAppsKey = 'sb_blacklisted_apps';
    const { status } = versionLog[0];
    const updatedPayload = { ...payload };
    delete updatedPayload.user_by_created_by;

    const getActionPayload = () => {
      switch (status) {
        case STATUS.DRAFT:
          return {
            payload: {
              ...updatedPayload,
              id: data?.flow_configs[data.flow_configs.length - 1].id,
            },
            flowUuid: flowId,
          };

        case STATUS.PUBLISHED:
          delete updatedPayload.id;
          return {
            payload: {
              ...updatedPayload,
              flow_id: data?.id,
            },
            flowUuid: flowId,
          };

        default:
      }
    };
    const actionPayload = getActionPayload();

    if (actionPayload && blacklistedAppsKey in actionPayload.payload) {
      const blacklistedApps = actionPayload.payload[blacklistedAppsKey];
      if (typeof blacklistedApps === 'string') {
        try {
          actionPayload.payload[blacklistedAppsKey] = JSON.parse(blacklistedApps);
        } catch (e) {
          actionPayload.payload[blacklistedAppsKey] = {};
        }
      }

      if (blacklistedApps === null) {
        actionPayload.payload[blacklistedAppsKey] = {} as BlackListApps;
      }
    }

    if (status === STATUS.DRAFT) {
      dispatch(updateFlowConfigAction(actionPayload as UpdateFlowConfigActionPayload));
    } else {
      dispatch(createFlowConfigAction(actionPayload as CreateFlowConfigActionPayload));
    }
  };

  const updateFlow = (payload: { [key: string]: unknown }) => {
    if (data) {
      dispatch(updateFlowAction({ ...payload, id: data.id }));
      'name' in payload && dispatch(resetTriggerToast(TOAST_STATE.UPDATE_FLOW_NAME_INITIATED));
      'is_default' in payload && dispatch(resetTriggerToast(TOAST_STATE.DEFAULT_FLOW_CHANGE_INITIATED));
    }
  };

  const handleUpdateConfigChanges = (payload: { [key: string]: unknown }) => {
    setFlowConfigChanges({
      ...flowConfigChanges,
      ...(payload as FlowConfigPayloadType),
    });
  };

  const handlePublishFlow = () => {
    updateFlowConfig({ status: STATUS.PUBLISHED });
  };

  const handleSetDefault = () => {
    updateFlow({ is_default: true });
  };

  return (
    <div>
      <FlowHeader
        options={options}
        selectedFlow={{
          name: get(data, 'name', ''),
          external_id: get(data, 'uuid'),
          status: versionLog[0]?.status as string,
          is_default: get(data, 'is_default', false),
        }}
        selectedProject={{
          label: currentProject.name,
        }}
        currentProject={currentProject}
        handleProjectChange={handleProjectChange}
        openUpdateNameModal={() => setEditFlowNameState(true)}
        setAsDefault={handleSetDefault}
        updateFlowState={updateFlowStatus}
        isPublishedLoading={updateFlowConfigStatus.isPublishedLoading}
        publishFlow={handlePublishFlow}
        isFlowChanged={!!data && !isEqual(flowConfigChanges, data.flow_configs[0])}
      />
      {isLoading ? (
        <div className="w-full h-[calc(100vh-81px)] flex justify-center items-center" data-testid="loading-spinner">
          <FontAwesomeIcon spin icon={faLoader} className="w-8 h-8" />
        </div>
      ) : (
        <FlowDetailsComponent
          versionLog={versionLog}
          updatedConfig={flowConfigChanges}
          config={(data && data.flow_configs[0]) ?? null}
          updateConfig={handleUpdateConfigChanges}
          updateFlowConfigStatus={updateFlowConfigStatus}
          createFlowConfigStatus={createFlowConfigStatus}
          saveConfig={() => updateFlowConfig(flowConfigChanges as FlowConfigPayloadType)}
        />
      )}
      <EditFlowNameModal
        name={get(data, 'name', '')}
        isModalOpen={editFlowNameState}
        closeModal={() => setEditFlowNameState(false)}
        updateFlowName={updateFlow}
        updateFlowStatus={updateFlowStatus}
      />
    </div>
  );
};

export default FlowDetails;
