export interface User {
  email: string;
  name: string;
  id: number;
}

export type TimeZone = {
  description: string;
  value: string;
};

export interface FormattedTimeZone {
  label: string;
  value: string;
}

export const DEFAULT_TIMEZONE = {
  label: 'Asia/Kolkata',
  value: 'Asia/Kolkata',
};
