import React from 'react';

import CalendarView from '@components/Shared/DateRangePicker/CalenderView/Calender';
import Header from '@components/Shared/DateRangePicker/Header';

import { DateRange } from '../../index';

interface CalendarViewProps {
  inputDateRange: DateRange;
  toCalendarDefaultDate: null | Date;
  handleUpdateToCalendarDefaultDate: (date: DateRange) => void;
  handleToUpdateCustomRangeOfDates: (date: Date) => void;
  handleCustomSelect: () => void;
  getNextMonthDate: () => Date;
  disabledDateFrom?: Date | null;
  disablePastDate: boolean;
  isReadOnly: boolean;
  disabledWeekend: boolean;
  minDate?: Date;
  maxDate?: Date;
  isDateRangePicker?: boolean;
}

const CalenderView = (props: CalendarViewProps) => {
  const {
    inputDateRange,
    toCalendarDefaultDate,
    handleUpdateToCalendarDefaultDate,
    handleCustomSelect,
    handleToUpdateCustomRangeOfDates,
    getNextMonthDate,
    disabledDateFrom,
    disablePastDate,
    isReadOnly,
    disabledWeekend,
    minDate,
    maxDate,
    isDateRangePicker,
  } = props;
  const { startDate, endDate } = inputDateRange;
  const [showDateView, setShowDateView] = React.useState<boolean>(true);

  return (
    <div className="w-[224px]">
      <Header
        selectedMonth={toCalendarDefaultDate ? toCalendarDefaultDate.getMonth() : getNextMonthDate().getMonth()}
        selectedYear={toCalendarDefaultDate ? toCalendarDefaultDate.getFullYear() : new Date().getFullYear()}
        onMonthSelect={(date) => {
          handleUpdateToCalendarDefaultDate({
            startDate: startDate,
            endDate: date,
          });
        }}
        onYearSelect={(date) => {
          handleUpdateToCalendarDefaultDate({
            startDate: startDate,
            endDate: date,
          });
        }}
        defaultDate={toCalendarDefaultDate || getNextMonthDate()}
        toggleMonth={(date) => {
          handleUpdateToCalendarDefaultDate({
            startDate: startDate,
            endDate: date,
          });
        }}
        handleDateView={(isDisplay) => {
          setShowDateView(isDisplay);
        }}
      />
      {showDateView && (
        <CalendarView
          date={toCalendarDefaultDate || getNextMonthDate()}
          selectedDate={null}
          startDate={startDate}
          endDate={endDate}
          disabledDateFrom={disabledDateFrom}
          onDateSelect={(date) => {
            handleToUpdateCustomRangeOfDates(date);
            handleCustomSelect();
          }}
          disablePastDate={disablePastDate}
          isReadOnly={isReadOnly}
          disabledWeekend={disabledWeekend}
          minDate={minDate}
          maxDate={maxDate}
          isDateRangePicker={isDateRangePicker}
        />
      )}
    </div>
  );
};

export default CalenderView;
