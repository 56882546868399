import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import messages from './messages';
import calendarIcon from '../../../../images/Calendar.svg';

export interface DateInputBoxProps {
  onDateChange: (date: Date) => void;
  date?: Date | null;
  onIconClick: () => void;
  onInputFocus: () => void;
  inputClassName?: string;
}

const DateInputBox: React.FC<DateInputBoxProps> = ({
  onDateChange,
  date,
  onIconClick,
  onInputFocus,
  inputClassName,
}) => {
  const intl = useIntl();
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [isValidDate, setIsValidDate] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const dayInputRef = useRef<HTMLInputElement>(null);
  const monthInputRef = useRef<HTMLInputElement>(null);
  const yearInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (date) {
      updateDateValues(date);
    }
  }, [date]);

  const updateDateValues = (date: Date) => {
    setDay(String(date.getDate()).padStart(2, '0'));
    setMonth(String(date.getMonth() + 1).padStart(2, '0'));
    setYear(String(date.getFullYear()));
    setIsValidDate(true);
  };

  const validateDate = useCallback(() => {
    if (day && month && year) {
      const dayNum = parseInt(day, 10);
      const monthNum = parseInt(month, 10);
      const yearNum = parseInt(year, 10);

      if (!isNaN(dayNum) && !isNaN(monthNum) && !isNaN(yearNum)) {
        const daysInMonth = new Date(yearNum, monthNum, 0).getDate();
        if (dayNum > 0 && dayNum <= daysInMonth && monthNum > 0 && monthNum <= 12 && yearNum > 0) {
          setIsValidDate(true);
          return new Date(yearNum, monthNum - 1, dayNum);
        }
      }
      setIsValidDate(false);
      return null;
    } else {
      setIsValidDate(true); // No error if fields are incomplete
      return null;
    }
  }, [day, month, year]);

  useEffect(() => {
    validateDate(); // Validate date each time day, month, or year changes
  }, [day, month, year, validateDate]);

  const handleDayChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setIsValidDate(true);
    if (/^\d*$/.test(value) && value.length <= 2) {
      setDay(value);
      if (value.length === 2) {
        monthInputRef.current?.focus();
      }
    }
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setIsValidDate(true);
    if (/^\d*$/.test(value) && value.length <= 2) {
      setMonth(value);
      if (value.length === 2) {
        yearInputRef.current?.focus();
      }
    }
  };

  const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setIsValidDate(true);
    if (/^\d*$/.test(value) && value.length <= 4) {
      setYear(value);
    }
  };

  const handleContainerBlur = useCallback(
    (e: React.FocusEvent<HTMLDivElement>) => {
      if (!containerRef.current?.contains(e.relatedTarget)) {
        const validDate = validateDate();
        if (validDate) {
          onDateChange(validDate);
        }
      }
    },
    [validateDate, onDateChange],
  );

  const handleCalendarIconClick = () => {
    const validDate = validateDate();
    if (validDate) {
      onDateChange(validDate);
    }
    onIconClick();
  };

  const handleFocus = () => {
    onInputFocus(); // Notify parent to hide header
  };

  return (
    <>
      <div
        className={`flex flex-col relative w-full ${inputClassName ?? ''}`.trim()}
        ref={containerRef}
        onBlur={handleContainerBlur}
        tabIndex={-1}
        data-testid="date-input-box"
      >
        <div
          className={`border rounded flex items-center px-2 py-1.5 ${
            !isValidDate ? 'border-red-600' : 'focus-within:border-blue-700 border-gray-200'
          }`}
        >
          <div className="flex items-center w-fit">
            <input
              type="text"
              placeholder="DD"
              value={day}
              onChange={handleDayChange}
              onFocus={handleFocus}
              ref={dayInputRef}
              maxLength={2}
              className="text-center p-0 w-8 text-black placeholder-gray-500 border-none focus:outline-none focus:ring-0 focus:border-none"
            />
            <span className="text-base text-gray-900">/</span>
            <input
              type="text"
              placeholder="MM"
              value={month}
              onChange={handleMonthChange}
              onFocus={handleFocus}
              ref={monthInputRef}
              maxLength={2}
              className="text-center p-0 w-9 text-black placeholder-gray-500 border-none focus:outline-none focus:ring-0 focus:border-none"
            />
            <span className="text-base text-gray-900">/</span>
            <input
              type="text"
              placeholder="YYYY"
              value={year}
              onChange={handleYearChange}
              onFocus={handleFocus}
              ref={yearInputRef}
              maxLength={4}
              className="text-center p-0 w-12 text-black placeholder-gray-500 border-none focus:outline-none focus:ring-0 focus:border-none"
            />
          </div>
          <div className="cursor-pointer w-full h-6" onClick={handleCalendarIconClick} role="presentation"></div>
          <button
            className="absolute top-1/2 transform -translate-y-1/2 right-2 w-5 h-5 text-gray-500 cursor-pointer"
            aria-label={intl.formatMessage(messages.calendar_icon)}
            onClick={handleCalendarIconClick}
            tabIndex={0}
            data-testid="calendar-icon"
          >
            <img src={calendarIcon} alt={intl.formatMessage(messages.calendar_icon)} className="w-full h-full" />
          </button>
        </div>
      </div>
      {day && month && year && !isValidDate && (
        <div className="text-red-600 text-sm mt-1">
          <FormattedMessage {...messages.invalid_date} />
        </div>
      )}
    </>
  );
};

export default DateInputBox;
