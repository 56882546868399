import React from 'react';

type LinkProps = {
  title?: string;
  description?: string | number;
  onTitleClick?: () => void;
  textSize?: 'sm' | 'base' | 'xs';
};

const Link: React.FC<LinkProps> = ({ title, onTitleClick, description, textSize }) => {
  let textClasses = '';
  switch (textSize) {
    case 'base':
      textClasses = 'text-blue-700 font-bold text-base';
      break;
    case 'sm':
      textClasses = 'text-blue-700 font-semibold text-sm';
      break;
    case 'xs':
    default:
      textClasses = 'text-blue-700 font-medium text-xs';
      break;
  }

  return (
    <div className="w-full">
      {title && (
        <button
          className={`${textClasses} cursor-pointer focus:outline-none`}
          onClick={onTitleClick}
          onKeyDown={(event: React.KeyboardEvent<HTMLButtonElement>) => {
            if (event.key === 'Enter' || event.key === ' ') {
              onTitleClick?.();
            }
          }}
        >
          {title}
        </button>
      )}
      {description && <p className="text-gray-700 font-normal text-xs">{description}</p>}
    </div>
  );
};

export default Link;
