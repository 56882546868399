import React from 'react';

import { FormattedMessage } from 'react-intl';

import ToggleButton from '@components/Base/ToggleButton';
import messages from '@components/Flows/ConfigPanels/messages';
import configCardMessages from '@components/Flows/ConfigureCard/messages';
import { VERIFICATION_TYPE } from '@utils/data/enums';

type IdentityVerificationProps = {
  config: {
    face: string;
    photo_id: string;
  };
  updateConfig: (obj: { [key: string]: string }) => void;
};

const IdentityVerification = (props: IdentityVerificationProps) => {
  const { config, updateConfig } = props;

  const handleUpdateConfig = (updatedConfig: { [key: string]: string }) => {
    updateConfig({ ...updatedConfig });
  };

  return (
    <div className="mx-4">
      <div className="flex flex-col gap-4 mt-1 pb-1">
        <div className="px-4 py-3 bg-white rounded-md shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)]">
          <div className="flex items-center gap-3">
            <div className="flex flex-col">
              <span className="text-base font-semibold text-gray-900">
                <FormattedMessage {...configCardMessages.face_capture} />
              </span>
              <span className="text-xs text-gray-600 font-roboto">
                <FormattedMessage {...messages.face_capture_description} />
              </span>
            </div>
            <ToggleButton
              data-testid="face_switch"
              checked={config.face !== VERIFICATION_TYPE.DISABLE}
              onClick={(checked: boolean) => {
                handleUpdateConfig({
                  face: checked ? VERIFICATION_TYPE.CAPTURE : VERIFICATION_TYPE.DISABLE,
                });
              }}
              disabled={false}
            />
          </div>
          {config.face !== VERIFICATION_TYPE.DISABLE && (
            <div className="flex items-center gap-3 mt-2.5">
              <div className="flex flex-col">
                <span className="text-base font-semibold text-gray-900">
                  <FormattedMessage {...messages.face_capture_verify} />
                </span>
                <span className="text-xs text-gray-600 font-roboto">
                  <FormattedMessage {...messages.face_capture_verify_description} />
                </span>
              </div>
              <ToggleButton
                data-testid="face_verification_switch"
                checked={config.face === VERIFICATION_TYPE.VERIFY}
                onClick={(checked: boolean) => {
                  handleUpdateConfig({
                    face: checked ? VERIFICATION_TYPE.VERIFY : VERIFICATION_TYPE.CAPTURE,
                  });
                }}
                disabled={false}
              />
            </div>
          )}
        </div>
        <div className="px-4 py-3 bg-white rounded-md shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)]">
          <div className="flex items-center gap-3">
            <div className="flex flex-col">
              <span className="text-base font-semibold text-gray-900">
                <FormattedMessage {...configCardMessages.photo_id_capture} />
              </span>
              <span className="text-xs text-gray-600 font-roboto">
                <FormattedMessage {...messages.photo_id_description} />
              </span>
            </div>
            <ToggleButton
              data-testid="photo_id_switch"
              checked={config.photo_id !== VERIFICATION_TYPE.DISABLE}
              onClick={(checked: boolean) => {
                handleUpdateConfig({
                  photo_id: checked ? VERIFICATION_TYPE.CAPTURE : VERIFICATION_TYPE.DISABLE,
                });
              }}
              disabled={false}
            />
          </div>
          {config.photo_id !== VERIFICATION_TYPE.DISABLE && (
            <div className="flex items-center gap-3 mt-2.5">
              <div className="flex flex-col">
                <span className="text-base font-semibold text-gray-900">
                  <FormattedMessage {...messages.face_capture_verify} />
                </span>
                <span className="text-xs text-gray-600 font-roboto">
                  <FormattedMessage {...messages.photo_id_capture_verify_description} />
                </span>
              </div>
              <ToggleButton
                data-testid="photo_id_verification_switch"
                checked={config.photo_id === VERIFICATION_TYPE.VERIFY}
                onClick={(checked: boolean) => {
                  handleUpdateConfig({
                    photo_id: checked ? VERIFICATION_TYPE.VERIFY : VERIFICATION_TYPE.CAPTURE,
                  });
                }}
                disabled={false}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IdentityVerification;
