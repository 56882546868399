import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { debounce, isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';

import withUserActions from '@/hoc/withUserActions';
import { Button } from '@components/Base/Button';
import { Textbox } from '@components/Base/Textbox';
import messages from '@components/Flows/ConfigPanels/messages';
import PanelHeader from '@components/Flows/ConfigPanels/PanelHeader';
import configCardMessages from '@components/Flows/ConfigureCard/messages';
import { UploadFileActionPayload } from '@containers/app/types';
import { validateHexColorCode } from '@containers/flowDetails/helper';
import { InitialStateType } from '@containers/flowDetails/slice';
import { catchError, showNotification, simulateUploadProgress } from '@utils/helpers';
import { handleImageUpload } from '@utils/imageUploadHelpers';

import UploadLogoStatus from '../UploadLogoStatus';
import './styles.css';

interface CustomBrandingConfigProps {
  config: {
    logo?: { id: string; name?: string; url?: string };
    branding_primary_color: string | null;
  };
  updatedConfig: {
    logo?: { id: string; name?: string; url?: string };
    branding_primary_color: string | null;
  };
  setDefaultPanelType: () => void;
  saveConfig: () => void;
  updateFeedbackChange: (obj: { [key: string]: string | number | null }) => void;
  updateFlowConfigStatus: InitialStateType['updateFlowConfigStatus'];
  createFlowConfigStatus: InitialStateType['createFlowConfigStatus'];
  onUpdateProfilePhoto?: (payload: UploadFileActionPayload) => void;
  updateDeleteLogo: (value: boolean) => void;
  deleteLogo: boolean;
  uploadProgress: number;
  updateUploadProgress: (progress: number) => void;
}

const CustomBrandingPanel = (props: CustomBrandingConfigProps) => {
  const {
    config,
    updatedConfig,
    setDefaultPanelType,
    updateFeedbackChange,
    updateFlowConfigStatus,
    createFlowConfigStatus,
    saveConfig,
    onUpdateProfilePhoto,
    updateDeleteLogo,
    deleteLogo,
    uploadProgress,
    updateUploadProgress,
  } = props;

  const { branding_primary_color } = config;
  const [primaryColor, setPrimaryColor] = useState(branding_primary_color?.slice(1) || '');
  const [selectedFile, setSelectedFile] = useState<string | File | null>(null);
  const [isValidHex, setIsValidHex] = useState(true);
  const fileInputRef = useRef(null);
  const [logoId, setLogoId] = useState<number | null>(
    config.logo?.id && config.logo?.id !== 'null'
      ? parseInt(config.logo.id)
      : updatedConfig.logo?.id
        ? parseInt(updatedConfig.logo.id)
        : null,
  );

  const debouncedUpdateFeedback = useCallback(
    debounce((formatedConfig) => {
      updateFeedbackChange(formatedConfig);
    }, 500),
    [],
  );

  const storeSelectedFile = (file: string | File) => {
    setSelectedFile(file);
  };

  const fileUploadError = (error: Error) => {
    catchError(error, false);
  };

  const handleOnPrimaryColorChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    setPrimaryColor(newColor);

    if (validateHexColorCode(newColor)) {
      setIsValidHex(true);
      const formatedConfig = {
        branding_primary_color: `#${newColor}`,
        branding_logo_file_id: logoId,
      };
      debouncedUpdateFeedback(formatedConfig);
    } else {
      setIsValidHex(false);
    }
  };

  const handleDeleteLogo = () => {
    setLogoId(null);
    updateFeedbackChange({
      branding_logo_file_id: null,
    });
    setSelectedFile(null);
    updateUploadProgress(0);
    updateDeleteLogo(true);
  };

  const handleLogoPick = () => {
    fileInputRef.current && (fileInputRef.current as HTMLDivElement).click();
  };

  useEffect(() => {
    if (selectedFile) {
      uploadLogo(selectedFile, onUpdateProfilePhoto, setLogoId, updateUploadProgress, updateFeedbackChange);
    }
  }, [selectedFile]);

  return (
    <div data-testid="custom_branding_panel">
      <PanelHeader
        label={<FormattedMessage {...configCardMessages.custom_branding_label} />}
        description={<FormattedMessage {...configCardMessages.custom_branding_description} />}
        setDefaultPanelType={setDefaultPanelType}
      />
      <div className="flex flex-col gap-8 p-5">
        <div>
          <div className="flex flex-col">
            <span className="text-base font-semibold text-gray-900">
              <FormattedMessage {...messages.logo} />
            </span>
            <span className="text-sm text-gray-600">
              <FormattedMessage {...messages.logo_description} />
              <ul className="ml-5 leading-5 list-disc">
                <li>
                  <FormattedMessage {...messages.logo_description_l1} />
                </li>
                <li>
                  <FormattedMessage {...messages.logo_description_l2} />
                </li>
                <li>
                  <FormattedMessage {...messages.logo_description_l3} />
                </li>
              </ul>
            </span>
            <input
              type="file"
              ref={fileInputRef}
              onChange={(event) => {
                const file = event.target.files?.[0];
                handleLogoUpload(
                  file,
                  event,
                  storeSelectedFile,
                  updateDeleteLogo,
                  updateUploadProgress,
                  fileUploadError,
                );
              }}
              style={{ display: 'none' }}
              accept="image/*"
              data-testid="input-file"
            />
            <UploadLogoStatus
              logoId={deleteLogo ? null : logoId}
              selectedFile={selectedFile}
              handleLogoPick={handleLogoPick}
              uploadProgress={uploadProgress}
              deleteLogo={handleDeleteLogo}
            />
          </div>
        </div>
        <div>
          <div className="flex flex-col gap-2">
            <span className="text-base font-semibold text-gray-900">
              <FormattedMessage {...messages.primary_color} />
            </span>
            <div className="relative">
              <span
                className={`absolute left-2 ${isValidHex ? 'top-1/2 -translate-y-1/2' : 'custombranding-panel-top -translate-y-1/2'}`}
              >
                #
              </span>
              <Textbox
                name="primary_color"
                data-testid="primary_color"
                value={primaryColor}
                className="custombranding-textbox-padding"
                onChange={handleOnPrimaryColorChange}
                placeholder="Enter hex code of your color"
                tabIndex={0}
                maxLength={6}
                error={isValidHex ? '' : 'Invalid hex code'}
              />
            </div>
            <Button
              isLoading={updateFlowConfigStatus.isConfigUpdateLoading || createFlowConfigStatus.isLoading}
              data-testid="save_button"
              className="w-fit mt-3 text-sm"
              disabled={updateFlowConfigStatus.isConfigUpdateLoading || isEqual(config, updatedConfig) || !isValidHex}
              onClick={saveConfig}
              tabIndex={0}
            >
              <FormattedMessage {...messages.save_changes} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withUserActions(CustomBrandingPanel);

export const handleLogoUpload = (
  file: File | undefined,
  event: React.ChangeEvent<HTMLInputElement>,
  storeSelectedFile: (file: string | File) => void,
  updateDeleteLogo: (value: boolean) => void,
  updateUploadProgress: (progress: number) => void,
  fileUploadError: (error: Error) => void,
) => {
  if (file) {
    handleImageUpload({
      event,
      callback: {
        onSuccess: (file) => {
          storeSelectedFile(file);
          updateDeleteLogo(false);
          simulateUploadProgress(
            (progress) => {
              updateUploadProgress(progress);
            },
            300,
            90,
          );
        },
        onError: fileUploadError,
      },
      supportedFileTypes: ['image/png', 'image/jpeg'],
      ignoreConversion: true,
    });
  }
};

export const uploadLogo = (
  selectedFile: string | File | null,
  onUpdateProfilePhoto: ((payload: UploadFileActionPayload) => void) | undefined,
  setLogoId: React.Dispatch<React.SetStateAction<number | null>>,
  updateUploadProgress: (progress: number) => void,
  updateFeedbackChange: (obj: { [key: string]: string | number | null }) => void,
) => {
  if (!(selectedFile && onUpdateProfilePhoto)) return;
  onUpdateProfilePhoto({
    body: {
      file: selectedFile as File,
      additionalParameter: {
        file_type_id: 6,
        owner_id: 1,
      },
    },
    callback: {
      onSuccess: (res) => {
        setLogoId(res);
        updateUploadProgress(100);
        updateFeedbackChange({ branding_logo_file_id: res });
      },
      onError: (error) => {
        showNotification({
          variant: 'danger',
          title: <FormattedMessage {...messages.error_message_title} />,
          summary: (
            <>
              <FormattedMessage {...messages.error_message_summary} />
              <span>{error.errorMessage}</span>
            </>
          ),
        });
      },
    },
  });
};
