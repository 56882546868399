import { defineMessages } from 'react-intl';

export const scope = 'app.components.flows.configPanels.stackSetting.stackList';

export default defineMessages({
  high_stack_title: {
    id: `${scope}.high_stack_title`,
    defaultMessage: 'High Stakes',
  },
  medium_stack_title: {
    id: `${scope}.medium_stack_title`,
    defaultMessage: 'Medium Stakes',
  },
  low_stack_title: {
    id: `${scope}.low_stack_title`,
    defaultMessage: 'Low Stakes',
  },
  no_stack_title: {
    id: `${scope}.no_stack_title`,
    defaultMessage: 'No Stakes',
  },
  high_stack_description: {
    id: `${scope}.high_stack_description`,
    defaultMessage:
      'These are exams where the outcome is extremely important. The consequences of cheating or rule violations are very serious, and the system`s sensitivity to any violations is at its highest.',
  },
  medium_stack_description: {
    id: `${scope}.medium_stack_description`,
    defaultMessage:
      'These assessments are important but might not have the same level of consequence as high-stakes exams.',
  },
  low_stack_description: {
    id: `${scope}.low_stack_description`,
    defaultMessage:
      'These exams are less crucial and have milder consequences. The system may allow for more leniency in these cases, and the threshold for action in response to certain events might be higher.',
  },
  no_stack_description: {
    id: `${scope}.no_stack_description`,
    defaultMessage: 'No rules of leniency will be set.',
  },
});
