import React, { ButtonHTMLAttributes, forwardRef } from 'react';

import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type VariantProps, cva } from 'class-variance-authority';

import { classNames } from '@utils/classNames';

const buttonVariants = cva(
  'relative flex items-center justify-center px-3 py-[11px] h-[38px] text-sm rounded-md font-medium cursor-pointer outline-offset-2 disabled:cursor-not-allowed disabled:opacity-50 transition-all',
  {
    variants: {
      variant: {
        base: 'bg-primary text-white outline-primary enabled:hover:bg-primary-hover enabled:active:bg-primary-active',
        outline:
          'bg-white text-primary border border-primary outline-primary outline-offset-[3px] enabled:hover:text-primary-hover enabled:hover:border-primary-hover enabled:active:text-primary-active enabled:active:border-primary-active',
        ghost:
          'bg-white text-primary-ghost enabled:hover:bg-primary-ghost-hover outline-primary-ghost enabled:active:bg-primary-ghost-active',
        destructive: 'bg-red-700 text-white outline-red-700 enabled:hover:bg-red-800 enabled:active:bg-red-900',
        destructive_outline:
          'bg-white text-red-700 border border-red-700 outline-red-700 outline-offset-[3px] enabled:hover:text-red-800 enabled:hover:border-red-800 enabled:active:text-red-900 enabled:active:border-red-900',
        destructive_ghost: 'bg-white text-red-700 outline-red-700 enabled:hover:bg-red-50 enabled:active:bg-red-100',
      },
    },
    defaultVariants: {
      variant: 'base',
    },
  },
);

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  isLoading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(({ className, variant, ...props }, ref) => {
  const { children, isLoading, ...rest } = props;
  return (
    <button
      ref={ref}
      aria-label="Button"
      className={classNames(buttonVariants({ variant, className }), {
        'pointer-events-none': isLoading,
      })}
      {...rest}
    >
      {isLoading && <FontAwesomeIcon data-testid="spinner" className="absolute animate-spin" icon={faSpinnerThird} />}
      <span className={classNames('flex items-center justify-center gap-2', isLoading && 'invisible')}>{children}</span>
    </button>
  );
});

Button.displayName = 'Button';

export { Button, buttonVariants };
