import React, { useContext, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import GroupDropdown, { Option, ProjectDropdownProps } from '@components/Base/GroupDropdown';
import Header from '@components/Base/Header';
import { ROLE } from '@containers/app/types';
import messages from '@containers/sessions/messages';
import { HeaderContext, HeaderContextType } from '@contexts/HeaderContext';
import { useAppSelector } from '@utils/hooks';
import type { RootState } from '@utils/store';

import ProctorNgSessions from './proctor_ng';
import ProviewConsoleSessions from './proview_console';

const Sessions = () => {
  const navigate = useNavigate();
  const { currentProject } = useAppSelector((state: RootState) => state.projects);

  const { user } = useAppSelector((state: RootState) => state.app);

  const { handleProjectChange, options } = useContext<HeaderContextType>(HeaderContext);

  const handleCancelProctor = () => {};

  const renderContentByRole = () => {
    switch (user.currentRole) {
      case ROLE.TENANT_ADMIN:
      case ROLE.COORDINATOR:
        return <ProviewConsoleSessions role={user.currentRole} />;
      case ROLE.PROCTOR:
      case ROLE.PROCTOR_ADMIN:
        return <ProctorNgSessions role={user.currentRole} handleCancelProctor={handleCancelProctor} />;
      default:
        return null;
    }
  };

  const isCoordinator = user.currentRole === ROLE.COORDINATOR || user.currentRole === ROLE.TENANT_ADMIN;

  useEffect(() => {
    if (currentProject.token && isCoordinator) {
      navigate(`/sessions?project_uuid=${currentProject.token}`);
    }
  }, [currentProject.token, isCoordinator]);

  return (
    <>
      <Header details={{ label: <FormattedMessage {...messages.sessions} /> }}>
        {isCoordinator ? (
          <div className="w-[308px]">
            <GroupDropdown
              selectedValue={{ label: currentProject.name }}
              options={options as Option[]}
              onChange={handleProjectChange as ProjectDropdownProps['onChange']}
            />
          </div>
        ) : (
          <></>
        )}
      </Header>
      {renderContentByRole()}
    </>
  );
};

export default Sessions;
