export const mixpanelEvents = {
  PROVIEW_USER_LOGGED_IN: 'proview_user_logged_in',
  PROVIEW_USER_NEW_PROJECT_CREATION_STARTED: 'proview_user_new_project_creation_started',
  PROVIEW_USER_NEW_PROJECT_CREATED: 'proview_user_new_project_created',
  PROVIEW_USER_PROJECT_PAGE_OPENED: 'proview_user_project_page_opened',
  PROVIEW_USER_FLOW_PAGE_OPENED: 'proview_user_flow_page_opened',
  PROVIEW_USER_NEW_FLOW_CREATION_STARTED: 'proview_user_new_flow_creation_started',
  PROVIEW_USER_NEW_FLOW_CREATED: 'proview_user_new_flow_created',
  PROVIEW_USER_FLOW_DETAIL_PAGE_VIEWED: 'proview_user_flow_detail_page_viewed',
  PROVIEW_CONSOLE_VOUCHERS_PAGE_VIEWED: 'proview_console_vouchers_page_viewed',
  PROVIEW_CONSOLE_VOUCHERS_FILTER_APPLIED: 'proview_console_vouchers_filter_applied',
  PROVIEW_CONSOLE_VOUCHERS_SEARCHED: 'proview_console_vouchers_searched',
  PROVIEW_CONSOLE_VOUCHER_CREATED: 'proview_console_voucher_created',
  PROVIEW_CONSOLE_VOUCHER_DOWNLOAD_LIST_CLICKED: 'proview_console_voucher_download_list_clicked',
  PROVIEW_CONSOLE_VOUCHER_DOWNLOADED: 'proview_console_voucher_downloaded',
  PROVIEW_CONSOLE_WORKFLOWS_PAGE_VIEW: 'proview_console_exam_workflow_list_page_view',
  PROVIEW_CONSOLE_STAKES_SET: 'proview_console_stakes_set',
  PROVIEW_CONSOLE_WORKFLOWS_FILTER_APPLIED: 'proview_console_exam_workflow_list_filter_applied',
  PROVIEW_CONSOLE_WORKFLOWS_SEARCHED: 'proview_console_exam_workflow_list_searched',
};

export const mixpanelDefaultProperties = {
  platformName: 'Proview Console',
  module: 'Console',
  version: 'V8',
};
