export const GET_WTF_WORKFLOW_QUERY = `query getWorkflowList($limit: Int, $offset: Int, $searchExpression: [wft_workflow_bool_exp!], $conditions: wft_workflow_bool_exp = {}) {
  wft_workflow(limit: $limit, offset: $offset, where: { _and: [{_or: $searchExpression}, $conditions]}) {
    status
    title
    steps {
      name
      id
    }
    updated_at
    updated_by
    updated_by_details {
      email
      name
      id
    }
    drive_schedule {
      sku {
        price
        currency_code
      }
    }
  }
  wft_workflow_aggregate (where: { _and: [{_or: $searchExpression}, $conditions]}) {
    aggregate {
      count
    }
  }
}`;
