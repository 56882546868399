import { KeyboardEvent } from 'react';

import * as Sentry from '@sentry/react';
import { get, includes } from 'lodash';

import { type Option } from '@components/Base/GroupDropdown';
import { triggerToast } from '@components/Base/Notification';
import { FormattedUser } from '@components/Flows/AddFlowModal/types';
import { type FIREBASE_USER, type HASURA_HEADERS, ROLE, type User as UserDetails } from '@containers/app/types';
import { Project } from '@containers/projects/types';
import { DEFAULT_TIMEZONE, type TimeZone, User } from '@containers/user/types';
import { type MixpanelProperties } from '@utils/mixpanelActions';

import { LOCAL_STORAGE_KEYS } from './data/enums';
import { getCurrentRole } from './data/roles';
import { AppLocale } from './messages';

export const parseJwt = (token: string): FIREBASE_USER | object => {
  if (token) {
    const base64Url: string = token.split('.')[1];
    const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload: string = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join(''),
    );
    return JSON.parse(jsonPayload);
  }
  return {};
};

export const getHasuraHeaders = (graphToken: string): HASURA_HEADERS | object => {
  if (!graphToken) {
    return {};
  }
  return (parseJwt(graphToken) as FIREBASE_USER)['https://hasura.io/jwt/claims'];
};

export const getHasuraHeaderByName = (graphToken: string, name: string) => {
  const parsedHeaders = getHasuraHeaders(graphToken);
  return get(parsedHeaders, name) || get(parsedHeaders, (name || '').toLowerCase());
};

export const getRequiredPathRoleAccess = (): ROLE.TENANT_ADMIN => {
  return ROLE.TENANT_ADMIN;
};

export const validateRoleAccess = (roles: ROLE[], requiredRole: ROLE) => includes(roles, requiredRole);

export const getDefaultContext = (graphToken: string) => {
  const roles = getHasuraHeaderByName(graphToken, 'X-Hasura-Allowed-Roles');
  const requiredRole = getRequiredPathRoleAccess();
  const haveAccess = validateRoleAccess(roles, requiredRole);
  return {
    headers: {
      'x-hasura-role': haveAccess ? requiredRole : getCurrentRole(roles),
    },
  };
};

export const handleEnterKeyPress = (event: KeyboardEvent, callback: (e: KeyboardEvent) => void) => {
  if (event.key === 'Enter') {
    if (typeof callback === 'function') {
      callback(event);
    }
  }
};

export const transformDataToOptions = (projects: Project[]) => {
  const projectOptions = projects.map((project) => ({
    value: project.name,
    label: project.name,
    details: { id: project.id, location: project.region },
    description: project.description,
  }));

  return [
    {
      value: 'addNewProject',
      label: 'Add a new project',
      clickOnly: true,
    },
    {
      label: 'Switch to project',
      options: projectOptions,
    },
  ] as Option[];
};

export const isTextTruncated = (element: HTMLElement): boolean => {
  return element.scrollWidth > element.clientWidth;
};

export const getPageCount = (totalCount: number, pagination: { pageIndex: number; pageSize: number }) => {
  if (totalCount > 0) {
    return Math.ceil(totalCount / pagination.pageSize);
  }
  return totalCount || -1;
};

export const formatTimeZone = (timezone: Array<TimeZone>) => {
  return timezone.map((tz: TimeZone) => ({
    label: tz.description,
    value: tz.value,
  }));
};

export const formatUserList = (users: User[]): FormattedUser[] => {
  return users.map((user: User) => ({
    label: user.name,
    value: user.id,
    id: user.id,
  }));
};

export function formatRoles(role: ROLE) {
  switch (role) {
    case ROLE.TENANT_ADMIN:
      return 'Tenant Admin';
    default:
      return role;
  }
}

export function formatRolesArray(roles?: ROLE[]) {
  if (!roles) return;
  function formatRole(role: ROLE) {
    const words = role.split('_').map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
    return words.join(' ');
  }

  return roles.map(formatRole).join(', ');
}

export const catchError = (error: Error, skipToast: boolean) => {
  if (!skipToast) {
    triggerToast({
      variant: 'danger',
      setting: { position: 'top-right' },
      message: {
        title: 'Something went wrong',
        summary: error.message,
      },
    });
  }
  Sentry.captureException(error);
};

export const isBrowser = typeof window !== 'undefined';

export const getItemFromLocalStorage = (key: string) => {
  return isBrowser ? localStorage.getItem(key) : null;
};

export const setItemInLocalStorage = (key: string, value: string) => {
  if (isBrowser) {
    localStorage.setItem(key, value);
  }
};

export const getDefaultLocale = () => {
  const storedLanguage = getItemFromLocalStorage(LOCAL_STORAGE_KEYS.LANGUAGE);
  if (storedLanguage) {
    return JSON.parse(storedLanguage)?.value;
  }
  return AppLocale.English;
};

export const getDefaultTimezone = () => {
  const timeZone = getItemFromLocalStorage(LOCAL_STORAGE_KEYS.TIMEZONE);
  if (timeZone) {
    return JSON.parse(timeZone);
  }
  const zoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if ((zoneName || '').toLowerCase().includes('calcutta')) {
    return DEFAULT_TIMEZONE;
  }
  return {
    label: zoneName,
    value: zoneName,
  };
};

export const showNotification = (message: {
  variant: string;
  title: string | JSX.Element;
  summary: string | JSX.Element;
}) => {
  triggerToast({
    variant: message.variant,
    setting: { position: 'top-right' },
    message,
  });
};

export const getMixpanelProperties = (
  user: UserDetails,
  projectName?: string,
  flowType?: string,
): MixpanelProperties => {
  const { id: orgId = null, name: orgName } = user.tenant;
  const { id: userId = null, currentRole: userRole } = user;

  const mixpanelProperties: MixpanelProperties = {
    orgId,
    orgName,
    userId,
    userRole,
    platformName: 'Proview Console',
    version: 'V8',
    ...(projectName && { projectName }),
    ...(flowType && { flowType }),
  };

  return mixpanelProperties;
};

export const simulateUploadProgress = (callback: (progress: number) => void, interval: number, maxProgress: number) => {
  let progress = 0;
  const intervalId = setInterval(() => {
    if (progress < maxProgress) {
      progress += Math.floor(Math.random() * 10) + 5;
      if (progress > maxProgress) progress = maxProgress;
      if (callback) {
        callback(progress);
      }
    } else {
      clearInterval(intervalId);
    }
  }, interval);
};

export const getCurrencySymbol = (code: string) => {
  const symbols: Record<string, string> = {
    USD: '$',
    INR: '₹',
    EUR: '€',
  };
  return symbols[code] || code;
};
