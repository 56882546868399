import React, { useState } from 'react';

import { FilterButton } from '@components/Flows/Filters/FilterButton';
import Filters from '@components/ProctorNgSessions/Filters';
import { SESSION_TABS } from '@components/ProctorNgSessions/types';
import type { Proctor } from '@containers/sessions/proctor_ng/types';
import { USER_ROLE } from '@utils/data/enums';

export interface SessionFiltersProps {
  updateAppliedFilters: (filters: object) => void;
  userRole: string;
  selectedTab: string;
  proctors: Proctor[];
}

const SessionFilters = (props: SessionFiltersProps) => {
  const { updateAppliedFilters, userRole, selectedTab, proctors } = props;
  const [showFilter, setShowFilter] = useState<boolean>(false);

  const toogleFilterView = () => {
    setShowFilter((prev) => !prev);
  };

  const isShowTenant =
    (userRole === USER_ROLE.PROCTOR_ADMIN && selectedTab === SESSION_TABS.ALL_SESSIONS) ||
    (userRole === USER_ROLE.PROCTOR && selectedTab === SESSION_TABS.REVIEW_PENDING);

  const isShowSessionStatus =
    (userRole === USER_ROLE.PROCTOR_ADMIN && selectedTab === SESSION_TABS.ALL_SESSIONS) ||
    (userRole === USER_ROLE.PROCTOR && selectedTab === SESSION_TABS.REVIEW_PENDING);

  const isShowReviewStatus = userRole === USER_ROLE.PROCTOR_ADMIN && selectedTab === SESSION_TABS.ALL_SESSIONS;

  const isShowCompletedOnDate =
    (userRole === USER_ROLE.PROCTOR_ADMIN && selectedTab === SESSION_TABS.ALL_SESSIONS) ||
    (userRole === USER_ROLE.PROCTOR && selectedTab === SESSION_TABS.REVIEW_PENDING);

  const isShowRequestedOnDate =
    (userRole === USER_ROLE.PROCTOR_ADMIN && selectedTab === SESSION_TABS.REVIEW_PENDING) ||
    (userRole === USER_ROLE.PROCTOR && selectedTab === SESSION_TABS.REVIEWED);

  const showProctor = userRole === USER_ROLE.PROCTOR_ADMIN && selectedTab === SESSION_TABS.PENDING;

  return (
    <>
      <FilterButton hasAppliedFilters={false} onClick={toogleFilterView} />
      <Filters
        updateAppliedFilters={updateAppliedFilters}
        showFilter={showFilter}
        toogleFilterView={toogleFilterView}
        isShowTenant={isShowTenant}
        isShowSessionStatus={isShowSessionStatus}
        isShowReviewStatus={isShowReviewStatus}
        isShowCompletedOnDate={isShowCompletedOnDate}
        isShowRequestedOnDate={isShowRequestedOnDate}
        showProctor={showProctor}
        proctors={proctors}
      />
    </>
  );
};

export default SessionFilters;
