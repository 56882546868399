// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custombranding-panel-top {
  top : 37%;
}

.custombranding-textbox-padding {
  padding-left: 21px !important;
}`, "",{"version":3,"sources":["webpack://./app/components/Flows/ConfigPanels/CustomBranding/CustomBrandingPanel/styles.css"],"names":[],"mappings":"AAAA;EACE,SAAS;AACX;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".custombranding-panel-top {\n  top : 37%;\n}\n\n.custombranding-textbox-padding {\n  padding-left: 21px !important;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
