import React, { useState } from 'react';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import ToggleButton from '@components/Base/ToggleButton';
import BlackListModal from '@components/Flows/BlackListModal';
import SupportedBrowsers from '@components/Flows/ConfigPanels/AttendeeOnboardingPanel/SupportedBrowsers';
import messages from '@components/Flows/ConfigPanels/messages';
import { BlackListApps } from '@containers/flowDetails/types';

import { mockBlackListApps } from '../../../../__mocks__/data';

type BrowserConfigProps = {
  config: {
    browser_chrome_desktop: boolean;
    browser_chrome_mobile: boolean;
    browser_edge_desktop: boolean;
    browser_edge_mobile: boolean;
    browser_firefox_desktop: boolean;
    browser_firefox_mobile: boolean;
    browser_safari_mobile: boolean;
    browser_sb_desktop: boolean;
    sb_blacklisted_apps: string | null | BlackListApps;
    sb_content_protection: boolean;
    sb_kiosk_mode: boolean;
  };
  updateConfig: (obj: { [key: string]: string | boolean | null | BlackListApps }) => void;
};

const BrowserConfig = (props: BrowserConfigProps) => {
  const { config, updateConfig } = props;
  const [openBlackListModal, setOpenBlackListModal] = useState<boolean>(false);

  const handleUpdateConfig = (updatedConfig: { [key: string]: boolean | BlackListApps }) => {
    updateConfig({ ...config, ...updatedConfig });
    openBlackListModal && handleToggleBacklistModal();
  };

  const handleToggleBacklistModal = () => {
    setOpenBlackListModal(!openBlackListModal);
  };

  return (
    <>
      <div className="mx-4">
        <div className="flex flex-col gap-4 px-4 py-3 mt-1 mb-4 bg-white rounded-md shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)]">
          <div className="flex items-center gap-3">
            <div className="flex flex-col">
              <span className="text-base font-semibold text-gray-900">Secure Browser (SB)</span>
              <span className="text-xs text-gray-600">
                <FormattedMessage {...messages.sb_description} />
              </span>
            </div>
            <ToggleButton
              data-testid="browser_sb_desktop_switch"
              checked={config.browser_sb_desktop}
              onClick={(checked: boolean) => handleUpdateConfig({ browser_sb_desktop: checked })}
              disabled={false}
            />
          </div>
          {config.browser_sb_desktop && (
            <>
              <div className="flex items-center gap-3">
                <div className="flex flex-col">
                  <span className="text-base font-semibold text-gray-900">Kiosk Mode</span>
                  <span className="text-xs text-gray-600">
                    <FormattedMessage {...messages.kiosk_description} />
                  </span>
                </div>
                <ToggleButton
                  data-testid="sb_kiosk_mode_switch"
                  checked={config.sb_kiosk_mode}
                  onClick={(checked: boolean) => handleUpdateConfig({ sb_kiosk_mode: checked })}
                  disabled={false}
                />
              </div>
              <div className="flex items-center gap-3">
                <div className="flex flex-col">
                  <span className="text-base font-semibold text-gray-900">
                    <FormattedMessage {...messages.blacklist_apps} />
                  </span>
                  <span className="text-xs text-gray-600">
                    <FormattedMessage {...messages.blacklist_apps_description} />
                  </span>
                </div>
                <BlackListModal
                  blackListApps={mockBlackListApps}
                  isOpen={openBlackListModal}
                  onClose={handleToggleBacklistModal}
                  onSubmit={(finalList) => handleUpdateConfig({ sb_blacklisted_apps: finalList })}
                />
                <FontAwesomeIcon
                  icon={faPlus}
                  onClick={handleToggleBacklistModal}
                  className="w-4 h-4 bg-primary rounded p-1 text-white box-border"
                  data-testid="plus-icon"
                />
              </div>
              <div className="text-xs text-gray-600 p-1 bg-gray-100 ">
                <span className="font-semibold">Windows</span>: {mockBlackListApps.windows.join(', ')}
              </div>
              <div className="text-xs text-gray-600 p-1 bg-gray-100 ">
                <span className="font-semibold">Mac</span>: {mockBlackListApps.mac.join(', ')}
              </div>
              <div className="flex items-center gap-3">
                <div className="flex flex-col">
                  <span className="text-base font-semibold text-gray-900">
                    <FormattedMessage {...messages.screen_recording} />
                  </span>
                  <span className="text-xs text-gray-600">
                    <FormattedMessage {...messages.screen_recording_description} />
                  </span>
                </div>
                <ToggleButton
                  data-testid="sb_content_protection_switch"
                  checked={config.sb_content_protection}
                  onClick={(checked: boolean) => handleUpdateConfig({ sb_content_protection: checked })}
                  disabled={false}
                />
              </div>
            </>
          )}
        </div>
      </div>
      <SupportedBrowsers config={config} updateBrowserConfig={handleUpdateConfig} />
    </>
  );
};

export default BrowserConfig;
