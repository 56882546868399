import { DocumentNode } from '@apollo/client';

import Auth from '@utils/auth';

import { getDefaultContext } from './helpers';
import { processQuery } from './processQuery';

export const makeRequest = async ({
  payload,
  queryString,
  context = {},
  spreadPayload,
}: {
  payload: object;
  queryString: DocumentNode;
  context?: object;
  spreadPayload?: boolean;
}) => {
  const apolloClient = await Auth.client();
  const defaultContext = getDefaultContext(Auth.graphToken || '');
  return apolloClient.mutate({
    mutation: queryString,
    variables: spreadPayload ? { ...payload } : { payload },
    context: {
      ...defaultContext,
      ...context,
    },
  });
};

const postData = async ({
  queryString,
  payload,
  spreadPayload = false,
  context = {},
}: {
  queryString: string;
  payload: object;
  spreadPayload?: boolean;
  context?: object;
}) => {
  if (!queryString) {
    throw Error('queryString not provided');
  }
  const graphQuery = processQuery(queryString);
  if ('errorMessage' in graphQuery && graphQuery?.errorMessage === 'Invalid Query') {
    throw graphQuery;
  }
  const response = await makeRequest({
    payload,
    queryString: graphQuery as DocumentNode,
    spreadPayload,
    context,
  });

  return response;
};

export default postData;
