import React, { useMemo, useState } from 'react';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, getCoreRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { get, isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Button } from '@components/Base/Button';
import EmptyMessagePage from '@components/Base/EmptyMessagePage';
import SearchInput from '@components/Base/Input';
import Pagination from '@components/Base/Pagination';
import AddFlowModal from '@components/Flows/AddFlowModal';
import flowListMessage from '@components/Flows/AddFlowModal/messages';
import DeleteFlowModal from '@components/Flows/DeleteFlowModal';
import FlowListTable from '@components/Flows/FlowListTable';
import { Flow, type StatusPayload, createColumns } from '@components/Flows/FlowListTable/columns';
import { deleteFlowAction } from '@containers/flowList/slice';
import type { FlowState, Template } from '@containers/flowList/types';
import type { Project } from '@containers/projects/types';
import type { User } from '@containers/user/types';
import SettingIcon from '@images/setting-icon.svg';
import { formatUserList, getPageCount } from '@utils/helpers';
import { useAppDispatch } from '@utils/hooks';

import messages from './messages';
import ListFilters from '../Filters';

interface FlowListProps {
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  setPagination: React.Dispatch<React.SetStateAction<{ pageIndex: number; pageSize: number }>>;
  handleToggleAddFlowModal: (isOpen: boolean) => void;
  handleResetFlowState: () => void;
  handleCreateFlow: (flowDetails: {
    name: string;
    project_id: string;
    is_template: boolean;
    reference_flow_id: string;
  }) => void;
  templates: Template[];
  currentProject: Project;
  flow: FlowState;
  isLoading: boolean;
  data: Array<Flow>;
  totalCount: number;
  openAddFlowModal: boolean;
  searchValue: string;
  updateSearchValue: React.Dispatch<React.SetStateAction<string>>;
  userList: {
    isLoading: boolean;
    data: User[];
  };
  appliedFilters: object;
  updateAppliedFilters: React.Dispatch<React.SetStateAction<object>>;
  updateFlowActiveStatus: (payload: StatusPayload) => void;
}

export const updatedAtSortingFn = (rowA: Row<Flow>, rowB: Row<Flow>): number => {
  const dateA = new Date(rowA.original.updated_at as string);
  const dateB = new Date(rowB.original.updated_at as string);
  return dateA > dateB ? 1 : dateA < dateB ? -1 : 0;
};

const FlowList = (props: FlowListProps) => {
  const {
    searchValue,
    updateSearchValue,
    pagination,
    setPagination,
    flow,
    handleCreateFlow,
    isLoading,
    data,
    totalCount,
    templates,
    handleToggleAddFlowModal,
    handleResetFlowState,
    currentProject,
    openAddFlowModal,
    appliedFilters,
    updateAppliedFilters,
    userList,
    updateFlowActiveStatus,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedFlow, setSelectedFlow] = useState<string | number | undefined>('');
  const showEmptyPage = isEmpty(appliedFilters);

  const handleClick = (uuid: string) => {
    navigate(`/flows/${uuid}`);
  };

  const handleStatusChange = (payload: StatusPayload) => {
    updateFlowActiveStatus(payload);
  };

  const handleDeleteSelectedFlow = () => {
    const deleteFlowPayload = { id: selectedFlow };
    dispatch(deleteFlowAction(deleteFlowPayload));
    setOpenDeleteModal(false);
  };

  const handleDeleteClick = (id: string | number | undefined) => {
    setOpenDeleteModal(true);
    setSelectedFlow(id);
  };

  const columns = useMemo(
    () => createColumns(updatedAtSortingFn, handleClick, handleDeleteClick, handleStatusChange),
    [handleClick],
  );

  const pageCount = getPageCount(totalCount, pagination);

  const table = useReactTable({
    data,
    columns,
    pageCount,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
    manualPagination: true,
  });

  const { previousPage, nextPage, getCanPreviousPage, getCanNextPage, getState } = table;

  const addFlow = (
    <>
      <FontAwesomeIcon icon={faPlus} />
      <FormattedMessage {...messages.add_flow} />
    </>
  );

  const handleToggleModalOpen = () => {
    handleToggleAddFlowModal(!openAddFlowModal);
  };

  const handleToggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const isRenderNoFlowsPage = isEmpty(data) && !isLoading && !searchValue && showEmptyPage;

  const formattedUserList = formatUserList(userList.data);

  const selectedUserDetails = useMemo(() => {
    return formattedUserList.find((user) => get(user, 'id') === get(appliedFilters, 'updated_by'));
  }, [formattedUserList, get(appliedFilters, 'updated_by')]);

  return (
    <>
      {isRenderNoFlowsPage ? (
        <EmptyMessagePage
          icon={SettingIcon}
          titleMessage={messages.no_flow_created}
          descriptionMessage={messages.no_flow_created_description}
          buttonMessage={flowListMessage.add_flow}
          handleButtonClick={handleToggleModalOpen}
          descriptionWidth="w-72"
        />
      ) : (
        <div className="px-8 py-5">
          <div className="flex justify-between">
            <Button onClick={handleToggleModalOpen}>{addFlow}</Button>
            <div className="flex items-center justify-end gap-2">
              <SearchInput
                initialValue={searchValue}
                handleChange={updateSearchValue}
                placeholderMessageId="app.components.flows.flow_list.search_flows"
              />
              <div className="pt-1">
                <ListFilters
                  isLoading={userList.isLoading}
                  selectedUserDetails={selectedUserDetails}
                  formattedUserList={formattedUserList}
                  appliedFilters={appliedFilters}
                  updateAppliedFilters={updateAppliedFilters}
                />
              </div>
              <div className="w-0 h-6 border border-gray-300" />
              <Pagination
                total={totalCount}
                pageSize={pagination.pageSize}
                currentPage={getState().pagination.pageIndex}
                summary
                previousPage={previousPage}
                nextPage={nextPage}
                getCanPreviousPage={getCanPreviousPage}
                getCanNextPage={getCanNextPage}
              />
            </div>
          </div>
          <FlowListTable
            data={data}
            columns={columns}
            pagination={pagination}
            setPagination={setPagination}
            searchText={searchValue}
            isLoading={isLoading}
          />
        </div>
      )}
      <AddFlowModal
        openAddFlowModal={openAddFlowModal}
        handleToggleAddFlowModal={handleToggleModalOpen}
        handleResetFlowState={handleResetFlowState}
        templates={templates}
        flow={flow}
        currentProject={currentProject}
        handleCreateFlow={handleCreateFlow}
      />
      <DeleteFlowModal
        openDeleteFlowModal={openDeleteModal}
        handleToggleDeleteFlowModal={handleToggleDeleteModal}
        deleteSelectedFlow={handleDeleteSelectedFlow}
      />
    </>
  );
};

export default FlowList;
