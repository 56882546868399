import React, { useRef, useState } from 'react';

import { faCamera, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import withUserActions from '@/hoc/withUserActions';
import { Button } from '@components/Base/Button';
import { UpdateUserActionPayload, UploadFileActionPayload, User } from '@containers/app/types';
import { catchError, handleEnterKeyPress, showNotification } from '@utils/helpers';
import { fileTypes, handleImageUpload } from '@utils/imageUploadHelpers';

import messages from './messages';

type ProfilePicProps = {
  onUpdateProfilePhoto?: (payload: UploadFileActionPayload) => void;
  onUpdateUserAction?: (payload: UpdateUserActionPayload) => void;
  setEditProfilePic: React.Dispatch<React.SetStateAction<boolean>>;
  supportedFileTypes?: string[];
  data: User;
};

export function ProfilePic(props: ProfilePicProps) {
  const { setEditProfilePic, supportedFileTypes = fileTypes, data, onUpdateProfilePhoto, onUpdateUserAction } = props;
  const [selectedFile, setSelectedFile] = useState<string | File | null>(null);
  const fileInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDivClick = () => {
    fileInputRef.current && (fileInputRef.current as HTMLDivElement).click();
  };

  const storeSelectedFile = (file: string | File) => {
    setSelectedFile(file);
  };

  const fileUploadError = (error: Error) => {
    catchError(error, false);
  };

  const updateProfilePic = (newProfilePicId: number) => {
    if (!onUpdateUserAction) return;
    setIsLoading(true);
    onUpdateUserAction({
      body: {
        profile_pic_file_id: newProfilePicId,
      },
      callback: {
        onSuccess: () => {
          setIsLoading(false);
          showNotification({
            variant: 'success',
            title: <FormattedMessage {...messages.success_message_title} />,
            summary: <FormattedMessage {...messages.success_message_summary} />,
          });
          setEditProfilePic(false);
        },
        onError: (error) => {
          setIsLoading(false);
          showNotification({
            variant: 'danger',
            title: <FormattedMessage {...messages.error_message_title} />,
            summary: (
              <>
                <FormattedMessage {...messages.error_message_summary} />
                <span>{(error as { errorMessage: string }).errorMessage}</span>
              </>
            ),
          });
        },
      },
    });
  };

  const handleSubmit = () => {
    if (!(selectedFile && onUpdateProfilePhoto && data.id)) return;

    setIsLoading(true);
    onUpdateProfilePhoto({
      body: {
        file: selectedFile as File,
        additionalParameter: {
          file_type_id: 6,
          owner_id: data.id,
        },
      },
      callback: {
        onSuccess: (res) => {
          updateProfilePic(res);
        },
        onError: (error) => {
          setIsLoading(false);
          showNotification({
            variant: 'danger',
            title: <FormattedMessage {...messages.error_message_title} />,
            summary: (
              <>
                <FormattedMessage {...messages.error_message_summary} />
                <span>{error.errorMessage}</span>
              </>
            ),
          });
        },
      },
    });
  };

  return (
    <div className="p-2">
      <div className="flex flex-col items-center justify-center">
        {data.url || selectedFile ? (
          <div className="relative w-[256px] h-[256px] rounded-full overflow-hidden">
            <img
              src={selectedFile ? URL.createObjectURL(selectedFile as Blob | MediaSource) : data.url}
              alt="Selected"
              className="w-full h-full object-cover object-center rounded-full"
              loading="lazy"
            />
            <div
              className={classNames(
                'absolute top-0 left-0 w-full h-full flex flex-col items-center justify-center ',
                'bg-black bg-opacity-50 text-white text-lg rounded-full opacity-0',
                'transition-opacity duration-300 hover:opacity-100',
              )}
              onClick={handleDivClick}
              onKeyDown={(e) => handleEnterKeyPress(e, handleDivClick)}
              role="button"
              tabIndex={-1}
            >
              <FontAwesomeIcon icon={faCamera} className="text-5xl" />
              <div className="text-base mt-2">
                <FormattedMessage {...messages.change_profile_photo} />
              </div>
            </div>
          </div>
        ) : (
          <div
            className={classNames(
              'border w-64 h-64 rounded-full flex flex-col items-center justify-center bg-gray-200 ',
              'cursor-pointer',
            )}
            onClick={handleDivClick}
            onKeyDown={(e) => handleEnterKeyPress(e, handleDivClick)}
            role="button"
            tabIndex={-1}
            data-testid="uploadProfilePicture"
          >
            <FontAwesomeIcon icon={faPlus} className="text-gray-500 font-thin text-5xl mb-4" />
            <div className="text-gray-500 text-base">
              <FormattedMessage {...messages.add_profile_photo} />
            </div>
          </div>
        )}
        <input
          type="file"
          ref={fileInputRef}
          onChange={(event) =>
            handleImageUpload({
              event,
              callback: {
                onSuccess: storeSelectedFile,
                onError: fileUploadError,
              },
              supportedFileTypes,
              ignoreConversion: true,
            })
          }
          style={{ display: 'none' }}
          accept="image/*"
          data-testid="input-file"
        />
        <div className="flex items-center mt-8">
          <div className="w-24">
            <Button className="w-full" variant="outline" onClick={() => setEditProfilePic(false)}>
              <FormattedMessage {...messages.cancel} />
            </Button>
          </div>
          <div className="ml-4 w-28">
            <Button
              data-testid="save_profile_btn"
              className="w-full"
              onClick={handleSubmit}
              disabled={!selectedFile}
              isLoading={isLoading}
            >
              <FormattedMessage {...messages.save} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withUserActions(ProfilePic);
