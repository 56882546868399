import React from 'react';

import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { CheckBadgeIcon, InformationCircleIcon, NoSymbolIcon } from '@heroicons/react/24/solid';
import { FormattedMessage } from 'react-intl';

import AssignProvider from '@components/AssignProvider';
import AccommodationBadges from '@components/Base/Accomodations';
import { Checkbox } from '@components/Base/Checkbox';
import IntegrityRating from '@components/Base/IntegrityRating';
import Link from '@components/Base/Link';
import Tooltip from '@components/Base/NewToolTip';
import Rating from '@components/Base/Rating';
import { CustomColumnDef } from '@components/Base/Table/types';
import TextDescription from '@components/Base/TextDescription';
import User from '@components/Base/UserDetails';
import { convertToTitleCase } from '@components/ProctorNgSessions/SessionTable/helper';
import { SessionData } from '@containers/sessions/proview_console/slice';
import { providerList } from '@containers/sessions/types';
import { classNames } from '@utils/classNames';
import { formatDate, formatTimeFromMinutes, getDaysAgo } from '@utils/dateFormat';

import messages from './messages';
import editIcon from '../../../images/edit-icon.svg';
import gripHorizontalIcon from '../../../images/grip-horizontal.svg';

interface ClassificationCounts {
  critical: number;
  high: number;
  medium: number;
  low: number;
}

export const getCustomColumns: {
  [key: string]: {
    [key: string]: string[];
  };
} = {
  COORDINATOR: {
    sessions: [
      'reviews',
      'Attendee',
      'exam',
      'session_rating',
      'proctoring_type',
      'accomodations',
      'attempt',
      'status',
      'reason',
      'summary',
      'session_duration',
      'external_id',
      'completed_on',
      'menu',
    ],
    requests: [
      'request_attendee',
      'exam',
      'session_rating',
      'accomodations',
      'provider',
      'aging',
      'requested_on',
      'reason_for_request',
      'external_id',
      'proctoring_type',
      'edit',
      'delete',
    ],
  },
};

interface CreateColumnsProps {
  toggleCancelProviderModal: () => void;
}

export const createColumns = ({ toggleCancelProviderModal }: CreateColumnsProps): CustomColumnDef<SessionData>[] => [
  {
    id: 'reviews',
    header: () => '',
    accessorKey: 'reviews',
    enableSorting: false,
    cell: ({ row }) => {
      return row.original.session_reviews &&
        row.original.session_reviews.length > 0 &&
        row.original.session_reviews[0].review_status === 'REVIEWED' ? (
        <Tooltip refDiv={<CheckBadgeIcon className="w-5 h-5 text-blue-700" />}>
          <div>{<FormattedMessage {...messages.reviewed} />}</div>
        </Tooltip>
      ) : row.original.session_reviews &&
        row.original.session_reviews.length > 0 &&
        row.original.session_reviews[0].review_status === 'PENDING' ? (
        <Tooltip refDiv={<FontAwesomeIcon icon={faClockRotateLeft} className="w-4 h-4 text-blue-700" />}>
          <div>Review Pending</div>
        </Tooltip>
      ) : (
        <></>
      );
    },
    size: 40,
    className: 'text-center left-12 sticky',
    cellClassName: 'text-center left-12 sticky z-[1]',
    hidden: false,
  },
  {
    id: 'Attendee',
    header: () => 'Attendee',
    accessorKey: 'Attendee',
    enableSorting: false,
    hidden: false,
    cell: ({ row }) => (
      <User
        data={{
          name: '',
          email: '',
        }}
        size="sm"
        hideImage={true}
        additionalContent={row.original.candidate_id}
      />
    ),
    size: 227,
    className:
      'text-left text-sm font-medium font-roboto text-gray-900 left-[88px] sticky attendee-column shadow-[inset_-1px_0px_0px_0px] shadow-gray-400/20',
    cellClassName:
      'text-left sticky left-[88px] attendee-column shadow-[inset_-1px_0px_0px_0px] z-[1] shadow-gray-400/20',
  },
  {
    id: 'request_attendee',
    header: 'Attendee',
    accessorKey: 'request_attendee',
    enableSorting: false,
    cell: ({ row }) => {
      return (
        <User
          data={{
            name: '',
            email: '',
          }}
          size="sm"
          hideImage={true}
          additionalContent={row.original.candidate_id}
        />
      );
    },
    size: 208,
    className:
      'text-left text-sm font-medium font-roboto text-gray-900 left-12 sticky attendee-column shadow-[inset_-1px_0px_0px_0px] shadow-gray-400/20',
    cellClassName: 'text-left sticky left-12 attendee-column shadow-[inset_-1px_0px_0px_0px] z-[1] shadow-gray-400/20',
  },
  {
    id: 'exam',
    header: () => 'Exam',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => (
      <div>{row.original.title ? <Link title={row.original.title} description={row.original.uuid} /> : <>-</>}</div>
    ),
    size: 173,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'session_rating',
    header: () => (
      <div className="flex align-middle">
        <span className="mr-1">{<FormattedMessage {...messages.session_rating} />}</span>
        <Tooltip refDiv={<InformationCircleIcon className="w-5 h-5 text-gray-400" />} position="bottom-right">
          <IntegrityRating />
        </Tooltip>
      </div>
    ),
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => {
      const flags = row.original.flags && row.original.flags.length > 0 ? row.original.flags : [];

      const initialCounts: ClassificationCounts = {
        critical: 0,
        high: 0,
        medium: 0,
        low: 0,
      };

      const classificationCounts = flags.reduce<ClassificationCounts>((acc, flag) => {
        const classificationText =
          flag.flag_type.flag_type_classification.text.toLowerCase() as keyof ClassificationCounts;

        if (classificationText in acc) {
          acc[classificationText] += 1;
        }

        return acc;
      }, initialCounts);

      return (
        <div className="flex align-left">
          <Rating
            rating={row.original.system_rating ? row.original.system_rating : undefined}
            {...(row.original.flags &&
              row.original.flags.length > 0 && {
                flags: classificationCounts,
              })}
            {...(row.original.session_reviews && {
              statusHistory: [],
            })}
          />
        </div>
      );
    },
    size: 148,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'proctoring_type',
    header: () => 'Proctoring Type',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => <div>{row.original.session_type}</div>,
    size: 140,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'accomodations',
    header: () => 'Accomodations',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => <AccommodationBadges accommodations={row.original.accommodation} />,
    size: 262,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left whitespace-nowrap',
    hidden: false,
  },
  {
    id: 'provider',
    header: () => 'Provider',
    accessorKey: 'provider',
    enableSorting: false,
    cell: () => <div>-</div>,
    size: 133,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'attempt',
    header: () => 'Attempt',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => <div>{row.original.attempt_count ? row.original.attempt_count : '-'}</div>,
    size: 95,
    className: 'text-center text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-center',
    hidden: false,
  },
  {
    id: 'status',
    header: () => 'Status',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => <div>{row.original.session_status}</div>,
    size: 133,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'reason',
    header: () => 'Reason',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: () => <div>-</div>,
    size: 194,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left whitespace-nowrap',
    hidden: false,
  },
  {
    id: 'summary',
    header: () => 'Summary',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => (
      <TextDescription
        text={row.original.summary ?? '-'}
        showToolTip={true}
        maxNumberOfLines={2}
        textClass="w-[200px]"
      />
    ),
    size: 237,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'session_duration',
    header: () => 'Session Duration',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => (row.original.duration ? formatTimeFromMinutes(row.original.duration) : '-'),
    size: 140,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'external_id',
    header: () => 'External ID',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => <div>{row.original.external_id}</div>,
    size: 124,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-center text-sm font-normal',
    hidden: false,
  },
  {
    id: 'completed_on',
    header: () => 'Completed On',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => (row.original.completed_at ? formatDate(new Date(row.original.completed_at), 'withTime') : '-'),
    size: 203,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left text-sm/[19px] font-normal',
    hidden: false,
  },
  {
    id: 'aging',
    header: 'Aging',
    accessorKey: 'aging',
    enableSorting: false,
    size: 124,
    cell: ({ row }) => {
      return row.original.session_reviews &&
        row.original.session_reviews.length > 0 &&
        row.original.session_reviews[0]?.assigned_at
        ? getDaysAgo(new Date(row.original.session_reviews[0]?.assigned_at))
        : '-';
    },
    className: 'text-center text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-center text-sm/[19px] font-normal pl-6',
  },
  {
    id: 'requested_on',
    header: 'Requested On',
    accessorKey: 'requested_on',
    enableSorting: false,
    cell: ({ row }) => {
      return row.original.session_reviews &&
        row.original.session_reviews.length > 0 &&
        row.original.session_reviews[0]?.assigned_at
        ? formatDate(new Date(row.original.session_reviews[0].assigned_at), 'withTime')
        : '-';
    },
    size: 203,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left text-sm/[19px] font-normal',
  },
  {
    id: 'edit',
    header: '',
    accessorKey: 'edit',
    enableSorting: false,
    size: 47,
    cell: () => {
      const [isModalOpen, setIsModalOpen] = React.useState(false);
      const [hasInProgressSession] = React.useState(false);
      const handleCloseModal = () => {
        setIsModalOpen(false);
      };
      const handleRequestReview = () => {};

      const toggleOpenModal = () => {
        setIsModalOpen(!isModalOpen);
      };

      return (
        <>
          <div
            className="w-5 h-5 cursor-pointer"
            data-testid="edit-request"
            onClick={toggleOpenModal}
            onKeyDown={(e) => {
              if (e.key === 'Enter') toggleOpenModal();
            }}
            role="button"
            tabIndex={0}
          >
            <img src={editIcon} alt="edit" className="w-full h-full" />
          </div>
          <AssignProvider
            data={providerList}
            headerText="Edit Request"
            isEdit={true}
            openAddFlowModal={isModalOpen}
            handleCloseModal={handleCloseModal}
            onRequestReview={handleRequestReview}
            hasInProgressSession={hasInProgressSession}
          />
        </>
      );
    },
  },
  {
    id: 'delete',
    header: '',
    accessorKey: 'delete',
    enableSorting: false,
    size: 47,
    cell: () => (
      <div>
        <NoSymbolIcon className="w-5 h-5 text-[#B91C1C] cursor-pointer" onClick={toggleCancelProviderModal} />
      </div>
    ),
  },
  {
    id: 'menu',
    enableSorting: false,
    accessorKey: 'menu',
    size: 48,
    className: 'text-left right-0 sticky shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
    cellClassName: 'text-center right-0 sticky z-[1] shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
    header: ({ table }) => {
      return (
        <div>
          <Popover className="flex justify-end items-center pr-3">
            <PopoverButton className={classNames('focus:outline-none')}>
              <PlusCircleIcon
                className={classNames('text-black h-5 w-5 focus:outline-none')}
                aria-hidden="true"
                data-testid="hide-column"
              />
            </PopoverButton>
            <Transition>
              <PopoverPanel>
                <div
                  className="bg-white border-solid border-[1px] w-[214px] font-normal shadow-sm max-h-[480px] overflow-y-auto rounded-md px-4 absolute right-0 top-12"
                  data-testid="column-model"
                >
                  {table.getAllLeafColumns().map((column) => {
                    if (
                      column.id === 'menu' ||
                      column.id === 'reviews' ||
                      column.id === 'admin_reviews' ||
                      column.id === 'selection'
                    ) {
                      return null;
                    }
                    return (
                      <div key={column.id} className="h-[40px] w-full flex items-center">
                        <span className="pr-2 w-6 h-6">
                          <img src={gripHorizontalIcon} alt="edit" className="w-full h-full" />
                        </span>
                        <Checkbox
                          label={
                            typeof column.columnDef.header === 'string'
                              ? column.columnDef.header
                              : convertToTitleCase(column.id)
                          }
                          id={column.id}
                          size="md"
                          checked={column.getIsVisible()}
                          onChange={column.getToggleVisibilityHandler()}
                          disabled={false}
                        />
                      </div>
                    );
                  })}
                </div>
              </PopoverPanel>
            </Transition>
          </Popover>
        </div>
      );
    },
  },
  {
    id: 'reason_for_request',
    header: 'Reason For Request',
    accessorKey: 'reason_for_request',
    enableSorting: false,
    cell: ({ row }) => (
      <Tooltip
        refDiv={
          <div
            className={`overflow-hidden text-sm font-normal`}
            style={{
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 1,
            }}
          >
            {row.original.session_reviews && row.original.session_reviews.length > 0
              ? row.original.session_reviews[0].review_request_reason
              : '-'}
          </div>
        }
        position="top-left"
      >
        <div className="w-[269px] whitespace-normal leading-tight">
          {row.original.session_reviews && row.original.session_reviews.length > 0
            ? row.original.session_reviews[0].review_request_reason
            : '-'}
        </div>
      </Tooltip>
    ),
    size: 194,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
  },
];
