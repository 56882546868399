import { AppLocale } from '@utils/messages';

import EnglishUK from '../../../images/Languages/EnglishUK.png';
import French from '../../../images/Languages/French.png';
import Japanese from '../../../images/Languages/Japanese.svg';
import Spanish from '../../../images/Languages/Spanish.png';

export interface LanguageOption {
  value: string;
  label: string;
  flag: string;
}

export const languages: LanguageOption[] = [
  {
    value: AppLocale.English,
    label: 'English',
    flag: EnglishUK,
  },
  {
    value: AppLocale.Spanish,
    label: 'Spanish',
    flag: Spanish,
  },
  {
    value: AppLocale.French,
    label: 'French',
    flag: French,
  },
  {
    value: AppLocale.Japanese,
    label: 'Japanese',
    flag: Japanese,
  },
];
