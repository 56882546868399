import React, { useEffect, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import { Dropdown } from '@components/Base/Dropdown';
import DateRangePicker from '@components/Shared/DateRangePicker';
import SidePanel from '@components/SidePanel';
import { AppliedFilters } from '@containers/vouchers';
import { SELECTED_TAB } from '@containers/vouchers/types';

import messages from './messages';
import './styles.css';

export interface VoucherFiltersProps {
  updateAppliedFilters: (filters: object) => void;
  toogleFilterView: () => void;
  showFilter: boolean;
  vouchers: { label: string; value: string }[];
  appliedFilters: AppliedFilters;
  skuList: { label: string; value: string }[];
  selectedTab: string;
  redeemedList: { label: string; value: string }[];
  resetFilters: string;
}

const Filters = (props: VoucherFiltersProps) => {
  const {
    updateAppliedFilters,
    showFilter,
    toogleFilterView,
    vouchers,
    appliedFilters,
    skuList,
    selectedTab,
    redeemedList,
    resetFilters,
  } = props;

  const [selectedSku, setSelectedSku] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(appliedFilters.selectedSku ?? null);

  const [selectedAttendee, setSelectedAttendee] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(appliedFilters.selectedAttendee ?? null);

  const [selectedVouchers, setSelectedVouchers] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(appliedFilters.selectedVouchers ?? null);

  const [selectedExpiresDateRange, setSelectedExpiresDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  } | null>(appliedFilters.selectedExpiresDateRange ?? null);

  const [selectedCreatedRange, setSelectedCreatedRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  } | null>(appliedFilters.selectedCreatedRange ?? null);

  const clearFilters = () => {
    setSelectedVouchers(null);
    updateAppliedFilters({});
    setSelectedExpiresDateRange(null);
    setSelectedCreatedRange(null);
    setSelectedSku(null);
    setSelectedAttendee(null);
  };

  const resetFilter = () => {
    clearFilters();
    toogleFilterView();
  };

  const handleApplyFilter = () => {
    const filters = {
      selectedSku: selectedSku,
      selectedVouchers: selectedVouchers,
      selectedExpiresDateRange: selectedExpiresDateRange,
      selectedCreatedRange: selectedCreatedRange,
      selectedAttendee: selectedAttendee,
    };
    toogleFilterView();
    updateAppliedFilters(filters);
  };

  useEffect(() => {
    if (resetFilters) {
      clearFilters();
    }
  }, [resetFilters]);

  return (
    <>
      <SidePanel show={showFilter} containerPadding="p-0" onClose={toogleFilterView} allowCustomHeader={true}>
        <div className="flex flex-col flex-1 justify-between max-w-filter-panel">
          <div>
            <div className="sticky top-0 border-b z-[1]">
              <div className="flex p-5 bg-white">
                <div className="w-filter-header">
                  <div className="text-base font-semibold text-gray-900">
                    <FormattedMessage {...messages.filter} />
                  </div>
                  <div className="text-sm font-normal text-gray-600">
                    <FormattedMessage {...messages.customize_view} />
                  </div>
                </div>
                <div
                  data-testid="close_btn"
                  role="button"
                  tabIndex={-1}
                  className="px-2 text-gray-600 cursor-pointer"
                  onClick={toogleFilterView}
                  onKeyPress={toogleFilterView}
                >
                  <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                </div>
              </div>
            </div>
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.code} />
              </div>
              <div className="pt-3">
                <Dropdown
                  id="voucher"
                  isMulti={true}
                  value={selectedVouchers}
                  isSearchable={true}
                  placeholder={<FormattedMessage {...messages.select_code} />}
                  onChange={(voucher) => setSelectedVouchers(voucher as { label: string; value: string }[])}
                  options={vouchers.length ? vouchers : []}
                />
              </div>
            </div>
            {selectedTab === SELECTED_TAB.REDEEMED ? (
              <div className="flex flex-col px-6 py-3 bg-white">
                <div className="text-base font-semibold text-gray-900">
                  <FormattedMessage {...messages.redeemed_by} />
                </div>
                <div className="pt-3">
                  <Dropdown
                    id="redeemed-list"
                    isMulti={true}
                    value={selectedAttendee}
                    isSearchable={true}
                    placeholder="Select emails"
                    onChange={(attendee) => {
                      setSelectedAttendee(attendee as { label: string; value: string }[]);
                    }}
                    options={redeemedList.length ? redeemedList : []}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col px-6 py-3 bg-white">
                <div className="text-base font-semibold text-gray-900">
                  <FormattedMessage {...messages.sku} />
                </div>
                <div className="pt-3">
                  <Dropdown
                    id="sku-list"
                    isMulti={true}
                    value={selectedSku}
                    isSearchable={true}
                    placeholder={<FormattedMessage {...messages.select_sku} />}
                    onChange={(sku) => {
                      setSelectedSku(sku as { label: string; value: string }[]);
                    }}
                    options={skuList.length ? skuList : []}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.expires_on_date} />
              </div>
              <div className="flex pt-3">
                <DateRangePicker onClickOnDonebtn={(range) => setSelectedExpiresDateRange(range)} />
              </div>
            </div>
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.created_on_date} />
              </div>
              <div className="flex pt-3">
                <DateRangePicker onClickOnDonebtn={(range) => setSelectedCreatedRange(range)} />
              </div>
            </div>
          </div>
          <div className="sticky bottom-0">
            <div className="h-20 flex items-center justify-start px-6 bg-white shadow-[0px_3px_45px_0px_#00000024]">
              <Button variant="outline" onClick={resetFilter}>
                <FormattedMessage {...messages.clear} />
              </Button>
              <div className="px-3">
                <Button onClick={handleApplyFilter}>
                  <FormattedMessage {...messages.apply} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SidePanel>
    </>
  );
};

export default Filters;
