import React, { forwardRef } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import { classNames } from '@utils/classNames';

import messages from './messages';

export interface ModalProps {
  show: boolean;
  children: React.ReactNode;
  size: string;
  className?: string;
  onCancel: () => void;
  closeOnOverlayClick?: boolean;
}

interface SizeMap {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  [key: string]: string;
}

const Modal = forwardRef<HTMLDivElement, ModalProps>((props, ref) => {
  const { show, children, size, className, onCancel, closeOnOverlayClick } = props;

  const cancelFn = () => {
    if (onCancel) onCancel();
  };

  const sizeMap: SizeMap = {
    sm: 'w-[480px]',
    md: 'w-[525px]',
    lg: 'w-[640px]',
    xl: 'w-[880px]',
  };

  return (
    show && (
      <div
        ref={ref}
        className="fixed inset-0 z-10 flex items-center justify-center bg-gray-900 bg-opacity-50"
        role="dialog"
        aria-labelledby="modal-title"
        aria-modal="true"
      >
        {/* Overlay click handling based on the new prop */}
        <div
          className="fixed inset-0 transition-opacity bg-gray-900 bg-opacity-50"
          role="presentation"
          onClick={closeOnOverlayClick ? cancelFn : undefined}
          aria-hidden="true"
        />
        <div
          className={classNames(
            'fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg bg-white p-6 text-left shadow-xl transition-all',
            `${get(sizeMap, size)}`,
            className,
          )}
          data-testid="modal-content"
        >
          <div className="absolute top-0 right-0 pt-4 pr-3">
            <Button
              type="button"
              variant="base"
              className="z-10 text-gray-900 bg-white rounded-md enabled:hover:bg-white-hover enabled:active:bg-white-active"
              onClick={cancelFn}
              aria-label={messages.close.defaultMessage}
              data-testid="close-btn"
            >
              <span className="sr-only">
                <FormattedMessage {...messages.close} />
              </span>
              <XMarkIcon className="w-6 h-6 text-gray-900" />
            </Button>
          </div>
          {children}
        </div>
      </div>
    )
  );
});

Modal.displayName = 'Modal';

export default Modal;
