import React, { ReactElement, useContext, useEffect, useState } from 'react';

import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useSearchParams } from 'react-router-dom';

import GroupDropdown, { Option, ProjectDropdownProps } from '@components/Base/GroupDropdown';
import Header from '@components/Base/Header';
import { triggerToast } from '@components/Base/Notification';
import FlowList from '@components/Flows/FlowList';
import { StatusPayload } from '@components/Flows/FlowListTable/columns';
import messages from '@containers/flowList/messages';
import type { AppliedFilters, createFlowPayload } from '@containers/flowList/types';
import { getUserListAction } from '@containers/user/slice';
import { HeaderContext, HeaderContextType } from '@contexts/HeaderContext';
import { DUPLICATE_FLOW_ERROR, FLOW_DELETION_ERROR, TOAST_VARIANT } from '@utils/data/enums';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import {
  provieUserNewFlowCreatedEvent,
  proviewUserFlowPageOpenedEvent,
  proviewUserNewFlowCreationStartedEvent,
} from '@utils/mixpanelActions';
import type { RootState } from '@utils/store';

import {
  createFlowAction,
  getFlowListAction,
  getTemplateListAction,
  resetFlowState,
  resetTriggerFlowCreationToast,
  resetTriggerFlowDeletionToast,
  setListTableState,
  setUpdateAddFlowModal,
  updateFlowAction,
} from './slice';

export const getCurrentFlowType = (createFlowPayload: createFlowPayload) => {
  return createFlowPayload.is_template ? 'template' : createFlowPayload.reference_flow_id ? 'duplicate' : 'new';
};

const Flows = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    isLoading,
    data,
    totalCount,
    flow,
    templateList: { data: templates },
    openAddFlowModal,
    tableState,
    triggerFlowCreationToast,
    triggerFlowDeletionToast,
  } = useAppSelector((state) => state.flows);

  const userList = useAppSelector((state) => state.userList);
  const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [searchValue, updateSearchValue] = useState(tableState.searchText ?? searchParams.get('search') ?? '');
  const [currentFlowType, setCurrentFlowType] = useState<string>('');
  const [appliedFilters, updateAppliedFilters] = useState<AppliedFilters>(
    tableState.filter ?? JSON.parse(searchParams.get('filter') ?? '{}') ?? {},
  );

  const { currentProject } = useAppSelector((state: RootState) => state.projects);

  const { handleProjectChange, options } = useContext<HeaderContextType>(HeaderContext);

  useEffect(() => {
    dispatch(getUserListAction());
  }, [dispatch]);

  const updateURLParams = () => {
    setSearchParams((params) => {
      const setOrDeleteParam = (key: string, value: string) => {
        if (value) {
          params.set(key, value);
        } else {
          params.delete(key);
        }
      };
      setOrDeleteParam('search', searchValue);
      setOrDeleteParam('filter', Object.keys(appliedFilters).length > 0 ? JSON.stringify(appliedFilters) : '');
      return params;
    });
  };

  useEffect(() => {
    updateURLParams();
  }, [searchValue, appliedFilters]);

  useEffect(() => {
    if (get(currentProject, 'id')) {
      const searchFilter = {
        searchExpression: searchValue
          ? [{ name: { _ilike: `%${searchValue}%` } }, ...(!isNaN(+searchValue) ? [{ id: { _eq: searchValue } }] : [])]
          : {},
      };
      const statusFilter: string[] = get(appliedFilters, 'status') || [];

      const filterConditions = {
        conditions: {
          ...(statusFilter.length > 0
            ? {
                status: {
                  _in: statusFilter,
                },
              }
            : {}),
          ...(Number.isInteger(get(appliedFilters, 'updated_by'))
            ? { updated_by: { _in: get(appliedFilters, 'updated_by') } }
            : {}),
        },
      };
      dispatch(
        getFlowListAction({
          ...searchFilter,
          ...filterConditions,
          projectId: get(currentProject, 'id'),
          limit: tableState.pageSize,
          offset: tableState.pageIndex * tableState.pageSize,
        }),
      );
    }
  }, [tableState, searchValue, appliedFilters, currentProject, navigate, dispatch]);

  useEffect(() => {
    if (get(currentProject, 'id')) {
      dispatch(
        setListTableState({
          tableState: {
            pageIndex: pagination.pageIndex,
            pageSize: pagination.pageSize,
            searchText: searchValue,
            filter: appliedFilters,
          },
          projectId: get(currentProject, 'id'),
        }),
      );
    }
  }, [dispatch, pagination.pageIndex, pagination.pageSize, currentProject]);

  useEffect(() => {
    setPagination({
      pageIndex: 0,
      pageSize: 10,
    });
    dispatch(
      setListTableState({
        tableState: {
          pageIndex: 0,
          pageSize: 10,
          searchText: searchValue,
          filter: appliedFilters,
        },
        projectId: get(currentProject, 'id'),
      }),
    );
  }, [searchValue, appliedFilters]);

  useEffect(() => {
    if (openAddFlowModal) {
      !templates.length && dispatch(getTemplateListAction());
      const mixpanelProperties = getMixpanelProperties(user, currentProject.name);
      isMixpanelInitialized && proviewUserNewFlowCreationStartedEvent(mixpanelProperties);
    }
  }, [openAddFlowModal, isMixpanelInitialized]);

  useEffect(() => {
    if (flow.error) {
      const showToast = (title: string | ReactElement, summary: string | ReactElement) => {
        triggerToast({
          variant: TOAST_VARIANT.DANGER,
          message: { title, summary },
        });
      };

      switch (flow.error) {
        case FLOW_DELETION_ERROR:
          showToast(
            <FormattedMessage {...messages.flow_deletion_failed} />,
            <FormattedMessage {...messages.flow_deletion_failed_description} />,
          );
          break;

        case DUPLICATE_FLOW_ERROR:
          showToast(
            <FormattedMessage {...messages.flow_duplicate_name} />,
            <FormattedMessage {...messages.flow_duplicate_name_description} />,
          );
          break;

        default:
          if (flow.error) {
            showToast(
              <FormattedMessage {...messages.flow_creation_failed} />,
              <FormattedMessage {...messages.flow_could_not_be_created} />,
            );
          }
          break;
      }
      handleResetFlowState();
    }
  }, [flow.error]);

  useEffect(() => {
    if (triggerFlowCreationToast && flow.uuid) {
      const mixpanelProperties = getMixpanelProperties(user, currentProject.name, currentFlowType);
      isMixpanelInitialized && provieUserNewFlowCreatedEvent(mixpanelProperties);
      triggerToast({
        variant: TOAST_VARIANT.SUCCESS,
        message: {
          title: <FormattedMessage {...messages.flow_created} />,
          summary: <FormattedMessage {...messages.flow_created_description} />,
        },
      });
      navigate(`${flow.uuid}`);
      dispatch(resetTriggerFlowCreationToast());
      dispatch(setUpdateAddFlowModal(!openAddFlowModal));
    }
  }, [flow.uuid, triggerFlowCreationToast, isMixpanelInitialized]);

  useEffect(() => {
    if (triggerFlowDeletionToast) {
      triggerToast({
        variant: TOAST_VARIANT.SUCCESS,
        message: {
          title: <FormattedMessage {...messages.flow_deleted} />,
          summary: <FormattedMessage {...messages.flow_deleted_description} />,
        },
      });
      dispatch(resetTriggerFlowDeletionToast());
    }
  }, [triggerFlowDeletionToast]);

  useEffect(() => {
    if (isMixpanelInitialized) {
      const mixpanelProperties = getMixpanelProperties(user, currentProject.name);
      proviewUserFlowPageOpenedEvent(mixpanelProperties);
    }
  }, [isMixpanelInitialized]);

  useEffect(() => {
    if (currentProject.token) {
      navigate(`/flows?project_uuid=${currentProject.token}`);
    }
  }, [currentProject.token]);

  const handleResetFlowState = () => {
    dispatch(resetFlowState());
  };

  const handleToggleAddFlowModal = () => {
    if (openAddFlowModal && flow.error) {
      handleResetFlowState();
    }
    dispatch(setUpdateAddFlowModal(!openAddFlowModal));
  };

  const updateFlowActiveStatus = (payload: StatusPayload) => {
    dispatch(updateFlowAction(payload));
  };

  const handleCreateFlow = (createFlowPayload: createFlowPayload) => {
    const flowType = getCurrentFlowType(createFlowPayload);
    dispatch(createFlowAction(createFlowPayload));
    setCurrentFlowType(flowType);
  };

  return (
    <>
      <Header details={{ label: <FormattedMessage {...messages.flows} /> }}>
        <div className="w-[308px]">
          <GroupDropdown
            selectedValue={{ label: currentProject.name }}
            options={options as Option[]}
            onChange={handleProjectChange as ProjectDropdownProps['onChange']}
          />
        </div>
      </Header>
      <FlowList
        userList={userList}
        appliedFilters={appliedFilters}
        updateAppliedFilters={updateAppliedFilters}
        searchValue={searchValue}
        updateSearchValue={updateSearchValue}
        setPagination={setPagination}
        pagination={pagination}
        handleToggleAddFlowModal={handleToggleAddFlowModal}
        templates={templates}
        flow={flow}
        currentProject={currentProject}
        handleCreateFlow={handleCreateFlow}
        isLoading={isLoading}
        data={data}
        totalCount={totalCount}
        openAddFlowModal={openAddFlowModal}
        handleResetFlowState={handleResetFlowState}
        updateFlowActiveStatus={updateFlowActiveStatus}
      />
    </>
  );
};

export default Flows;
