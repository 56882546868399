export enum SESSION_TABS {
  ALL_SESSIONS = 'sessions',
  REVIEWED = 'reviewed',
  PENDING = 'pending',
  REVIEW_PENDING = 'review_pending',
}

export const getCustomColumns: {
  [key: string]: {
    [key: string]: string[];
  };
} = {
  PROCTOR: {
    reviewed: [
      'reviews',
      'session_candidate_proctor',
      'exam_details',
      'reason_for_request',
      'session_rating',
      'accomodations',
      'requested_on',
      'external_id',
      'proctoring_type',
    ],
    pending: [
      'reviews',
      'session_candidate_proctor',
      'due_date',
      'exam_details',
      'reason_for_request',
      'session_rating',
      'session_duration',
      'proctoring_type',
      'accomodations',
      'tenant',
      'status',
      'summary',
      'attempt',
      'external_id',
      'completed_on',
      'menu',
    ],
  },
  PROCTOR_ADMIN: {
    pending: [
      'admin_reviews',
      'session_candidate',
      'exam_details',
      'reason_for_request',
      'session_rating',
      'proctor',
      'accomodations',
      'aging',
      'requested_on',
      'external_id',
      'proctoring_type',
      'edit',
      'delete',
    ],
    sessions: [
      'admin_reviews',
      'session_candidate',
      'due_date',
      'exam_details',
      'reason_for_request',
      'session_rating',
      'session_duration',
      'proctoring_type',
      'accomodations',
      'tenant',
      'status',
      'reason',
      'summary',
      'attempt',
      'external_id',
      'completed_on',
      'menu',
    ],
  },
};
