import React from 'react';

import { get } from 'lodash';

import { classNames } from '@utils/classNames';

export interface CheckboxProps {
  name?: string;
  disabled: boolean;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  label?: string;
  id: string | number;
  size?: 'sm' | 'md';
}

const sizeMap = {
  sm: 'h-3 w-3',
  md: 'h-4 w-4',
};

const textMap = {
  sm: 'text-sm leading-1',
  md: 'text-base leading-2',
};

const Checkbox = (props: CheckboxProps) => {
  const { id, name, onChange, disabled, checked, value, label, size = 'md' } = props;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  return (
    <div className="flex items-center h-6">
      <input
        data-testid={`checkbox-${id}`}
        id={`checkbox-${id}`}
        name={`checkbox-${name}`}
        checked={checked}
        disabled={disabled}
        onChange={handleChange}
        aria-checked={checked ? 'true' : 'false'}
        type="checkbox"
        value={value}
        className={classNames(
          get(sizeMap, size, 'h-4 w-4'),
          disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:border-primary',
          'rounded text-primary border-gray-300 focus:ring-transparent focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary',
        )}
      />
      <span className="sr-only">{checked ? 'Checkbox checked' : 'Checkbox unchecked'}</span>
      {label && (
        <label
          htmlFor={`checkbox-${id}`}
          className={classNames(
            'ml-1 font-normal text-gray-900',
            get(textMap, size, 'text-base'),
            disabled && 'opacity-50',
            !disabled && 'cursor-pointer',
          )}
        >
          {label}
        </label>
      )}
    </div>
  );
};

export { Checkbox };
