import _ from 'lodash';
import isObject from 'lodash/isObject';
import some from 'lodash/some';
import * as XLSX from 'xlsx';

import { triggerToast } from '@components/Base/Notification';
import { User } from '@containers/app/types';
import {
  CreateVoucherPadload,
  CreateVoucherResponse,
  PayCouponObject,
  PayCreateCouponResponse,
} from '@containers/vouchers/saga';
import { TOAST_VARIANT } from '@utils/data/enums';
import { ValidVoucher, formatDate } from '@utils/dateFormat';
import { getMixpanelProperties } from '@utils/helpers';

import { AppliedFilters } from '.';
import messages from './messages';
import { OrderItem, RedeemedVoucherList, SELECTED_TAB, Voucher } from './types';

type initialFilters = {
  search: string;
  filters: AppliedFilters;
  tab: string;
};

export const formatInitialFilters = (searchParams: URLSearchParams) => {
  return (): initialFilters => {
    const queryParams: {
      [key: string]: string | string[] | object | object[];
    } = {};
    for (const [key, value] of searchParams) {
      try {
        queryParams[key] = JSON.parse(decodeURIComponent(value));
      } catch {
        try {
          queryParams[key] = decodeURIComponent(value);
        } catch (e) {
          console.error('Failed to decode URI: ', e);
        }
      }
    }
    return {
      filters: (queryParams.filters as AppliedFilters) || {},
      search:
        queryParams.search != null && queryParams.search !== '' ? String(queryParams.search).replace(/%/g, '') : '',
      tab: (queryParams.tab as string) || SELECTED_TAB.ISSUED,
    };
  };
};

export const hasNonEmptyFilters = (filters: AppliedFilters): boolean => {
  return some(filters, (value) => {
    if (isObject(value)) {
      return some(value, Boolean);
    }
    return Boolean(value);
  });
};

export const getFormattedFilters = (appliedFilters: AppliedFilters) => {
  const { selectedSku, selectedVouchers, selectedExpiresDateRange, selectedCreatedRange } = appliedFilters;
  return {
    ...(selectedSku?.length && {
      sku_coupons: {
        sku_id: { _in: selectedSku.map(({ value }) => value) },
      },
    }),
    ...(selectedVouchers?.length && {
      code: { _in: selectedVouchers.map(({ value }) => value) },
    }),
    ...(selectedExpiresDateRange &&
      selectedExpiresDateRange.startDate &&
      selectedExpiresDateRange.endDate && {
        valid_till: {
          _gte: selectedExpiresDateRange.startDate,
          _lte: selectedExpiresDateRange.endDate,
        },
      }),
    ...(selectedCreatedRange &&
      selectedCreatedRange.startDate &&
      selectedCreatedRange.endDate && {
        created_at: {
          _gte: selectedCreatedRange.startDate,
          _lte: selectedCreatedRange.endDate,
        },
      }),
  };
};

export const getFormattedRedeemedFilters = (appliedFilters: AppliedFilters) => {
  const { selectedVouchers, selectedExpiresDateRange, selectedCreatedRange, selectedAttendee } = appliedFilters;

  return {
    ...(((selectedExpiresDateRange?.startDate && selectedExpiresDateRange?.endDate) || selectedVouchers?.length) && {
      order_items: {
        coupon: {
          ...(selectedVouchers?.length && {
            code: { _in: selectedVouchers.map(({ value }) => value) },
          }),
          ...(selectedExpiresDateRange?.startDate &&
            selectedExpiresDateRange?.endDate && {
              valid_till: {
                _gte: selectedExpiresDateRange.startDate,
                _lte: selectedExpiresDateRange.endDate,
              },
            }),
        },
      },
    }),
    ...(selectedCreatedRange?.startDate &&
      selectedCreatedRange?.endDate && {
        created_at: {
          _gte: selectedCreatedRange.startDate,
          _lte: selectedCreatedRange.endDate,
        },
      }),
    ...(selectedAttendee &&
      selectedAttendee.length && {
        ordered_by: { _in: selectedAttendee.map(({ value }) => value) },
      }),
  };
};

export const getFormattedSearchConditions = (searchTerm: string, selectedTab: string) => {
  if (selectedTab === SELECTED_TAB.ISSUED) {
    return [{ code: { _ilike: `%${searchTerm}%` } }, { description: { _ilike: `%${searchTerm}%` } }];
  } else {
    return [
      {
        order_items: {
          coupon: {
            code: {
              _ilike: `%${searchTerm}%`,
            },
          },
        },
      },
      {
        order_items: {
          coupon: {
            description: {
              _ilike: `%${searchTerm}%`,
            },
          },
        },
      },
    ];
  }
};

export const getCombinedNames = (skuCoupons: OrderItem[] | null | []) => {
  if (skuCoupons?.length) {
    return skuCoupons.map((coupon) => coupon?.item?.sku?.name).join(', ');
  } else {
    return '-';
  }
};

export const getCreatedByName = (orderItems: OrderItem[] | null) => {
  return orderItems?.[0]?.coupon?.created_by_details?.name
    ? orderItems[0].coupon.created_by_details.name
    : orderItems?.[0]?.coupon?.created_by_details?.email
      ? orderItems[0].coupon.created_by_details?.email
      : '';
};

export const getExpiresOn = (orderItems: OrderItem[] | null) => {
  return orderItems?.[0]?.coupon?.valid_till ? ValidVoucher(new Date(orderItems[0].coupon.valid_till)) : '-';
};

export const validateNumericInput = (value: string) => {
  return /^\d*$/.test(value) || value === '';
};

export const validateVoucherCode = (voucherCode: string) => {
  return /^[a-zA-Z0-9]{5}$/.test(voucherCode);
};

export const formateVoucherPayload = (
  maxUse: string,
  voucherDiscount: string,
  voucherDescription: string,
  selectedSku: { label: string; value: string } | null,
  expiryDate: Date | null,
  selectedTags: { label: string; value: number }[] | null,
  voucherName?: string,
) => {
  const tags = selectedTags?.map((tag) => tag.label);
  const voucherData: {
    max_count: number;
    discount_percentage: number;
    description: string | null;
    sku_id: object[] | null;
    valid_till?: Date;
    code?: string;
    tag_coupons?: string[];
  } = {
    max_count: maxUse ? parseInt(maxUse) : 1,
    discount_percentage: voucherDiscount ? parseInt(voucherDiscount) : 0,
    description: voucherDescription ? voucherDescription : null,
    sku_id: selectedSku ? [{ sku_id: parseInt(selectedSku.value) }] : [],
    ...(voucherName && { code: voucherName }),
    tag_coupons: tags ? [...tags] : [],
  };
  if (expiryDate) {
    voucherData['valid_till'] = expiryDate;
  }
  return voucherData;
};

export const formatProperties = (user: User, selectedTab: string, searchParam: string) => {
  const mixpanelProperties = getMixpanelProperties(user);
  const additionalProperties = {
    module: 'Vouchers',
    tab: selectedTab,
  };
  const combinedProperties = {
    ...additionalProperties,
    ...mixpanelProperties,
    searchTerm: searchParam,
  };
  return combinedProperties;
};

export const createVoucher = async (voucherQuantity: string, createVoucherPayload: CreateVoucherPadload) => {
  const quantity = parseInt(voucherQuantity);
  const voucherCreationPayloads = [];
  for (let i = 0; i < quantity; i++) {
    voucherCreationPayloads.push(createVoucherPayload);
  }
  return voucherCreationPayloads;
};

export const formatBulkVoucherResponse = (response: PayCreateCouponResponse[]) => {
  return response.map((voucher) => {
    return voucher.data.pay_create_coupon;
  });
};

// Download Voucher Excel
export const showToast = (variant: string, title: string, summary: string) => {
  triggerToast({
    variant: variant,
    message: {
      title: title,
      summary: summary,
    },
  });
};

const showDownloadToast = (isSuccess: boolean) => {
  if (isSuccess) {
    showToast(
      TOAST_VARIANT.SUCCESS,
      messages.voucher_download_success_status_header.defaultMessage,
      messages.voucher_download_success_status_summary.defaultMessage,
    );
  } else {
    showToast(
      TOAST_VARIANT.DANGER,
      messages.voucher_download_failed_status_header.defaultMessage,
      messages.voucher_download_failed_status_summary.defaultMessage,
    );
  }
};

const createWorksheetFromData = (data: object[], fileName: string) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Coupons');

  XLSX.writeFile(workbook, fileName);
};

const formatVoucherData = (voucher: Voucher) => {
  const formattedVoucher = {
    'Voucher Code': voucher.code,
    Discount: voucher.discount_percentage,
    Description: voucher?.description,
    SKU: voucher.sku_coupons.map((sku) => sku.sku.name).join(', '),
    Tags: '---',
    'Max Use': voucher.max_count,
    'Expires On': voucher.valid_till,
    'Created by': voucher.created_by_details?.name || voucher.created_by_details?.email,
    'Created On': formatDate(new Date(voucher.created_at), 'withTime'),
  };

  return formattedVoucher;
};

const formatCreatedVoucherData = (voucher: PayCouponObject) => {
  const formattedVoucher = {
    'Voucher Code': voucher.code,
    Discount: voucher.discount_percentage,
    Description: voucher?.description,
    sku_id: voucher.sku_coupons.map((sku) => sku.sku_id).join(', '),
    Tags: '---',
    'Max Use': voucher.max_count,
    'Expires On': voucher?.valid_till ? formatDate(new Date(voucher?.valid_till), 'withTime') : '-',
  };

  return formattedVoucher;
};

export const formatRedeemedVoucherData = (voucher: RedeemedVoucherList) => {
  const firstOrderItem = voucher.order_items?.[0] ?? null;
  const coupon = firstOrderItem?.coupon ?? null;
  const orderedByDetails = voucher.ordered_by_details ?? null;
  const transactionHistories = voucher.transactions?.[0]?.transaction_status_histories ?? [];

  const formattedVoucher = {
    'Voucher Code': coupon?.code ?? '-',
    Discount: coupon?.discount_percentage ?? '-',
    Description: coupon?.description ?? '-',
    SKU: voucher.order_items?.map((item) => item.item?.sku?.name).join(', ') ?? '-',
    Tags: '---',
    Status: voucher?.status ?? '-',
    'External Id': voucher?.external_id ?? '-',
    'Created At': formatDate(new Date(voucher.created_at), 'withTime') ?? '-',
    'Max Use': coupon?.max_count ?? '-',
    'Expires On': coupon?.valid_till ? formatDate(new Date(coupon?.valid_till), 'withTime') : '-',
    'Redeemed By': orderedByDetails?.name ?? orderedByDetails?.username ?? '-',
    'Redeemed On':
      transactionHistories.length > 0
        ? (formatDate(new Date(transactionHistories[0]?.updated_at), 'withTime') ?? '-')
        : '-',
  };

  return formattedVoucher;
};

export const downloadVoucherExcel = async (data: Voucher[], fileName: string) => {
  try {
    const formattedData = data.map((coupon) => formatVoucherData(coupon));
    await createWorksheetFromData(formattedData, fileName);
    showDownloadToast(true);
  } catch (error) {
    showDownloadToast(false);
  }
};

export const downloadBulkCreatedVoucher = async (data: CreateVoucherResponse, fileName: string) => {
  try {
    const formattedData = data.map((coupon) => formatCreatedVoucherData(coupon));
    await createWorksheetFromData(formattedData, fileName);
    showDownloadToast(true);
  } catch (error) {
    showDownloadToast(false);
  }
};

export const downloadRedeemedVoucherExcel = async (data: RedeemedVoucherList[], fileName: string) => {
  try {
    const formattedData = data.map((coupon) => formatRedeemedVoucherData(coupon));
    await createWorksheetFromData(formattedData, fileName);
    showDownloadToast(true);
  } catch (error) {
    showDownloadToast(false);
  }
};

export const getFileName = (name: string) => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0];
  return `${name}_${formattedDate}.xlsx`;
};
