import { defineMessages } from 'react-intl';

import { commonScope } from '@utils/messages';

export const scope = 'app.component.flow_details.edit_flow_modal';

export default defineMessages({
  edit_name: {
    id: `${scope}.edit_name`,
    defaultMessage: 'Edit Flow Name',
  },
  name_label: {
    id: `${scope}.name_label`,
    defaultMessage: 'Flow Name *',
  },
  go_back: {
    id: `${commonScope}.go_back`,
    defaultMessage: 'Go back',
  },
  placeholder: {
    id: `${scope}.name_placeholder`,
    defaultMessage: 'Write a unique title for your flow',
  },
});
