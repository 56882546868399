import { defineMessages } from 'react-intl';

export const scope = 'app.components.workflow.filters';

export default defineMessages({
  filters_button: {
    id: `${scope}.filters_button`,
    defaultMessage: 'Filters',
  },
  filter: {
    id: `${scope}.filter`,
    defaultMessage: 'Filter',
  },
  customize_view: {
    id: `${scope}.customize_view`,
    defaultMessage: 'Customize your view by selectively including or excluding table elements.',
  },
  workflow: {
    id: `${scope}.workflow`,
    defaultMessage: 'Workflow',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  select_workflow: {
    id: `${scope}.select_workflow`,
    defaultMessage: 'Select workflows',
  },
  created_on_date: {
    id: `${scope}.created_on_date`,
    defaultMessage: 'Closed time',
  },
  expires_on_date: {
    id: `${scope}.expires_on_date`,
    defaultMessage: 'Open time',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Clear Filters',
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Apply',
  },
  select_status: {
    id: `${scope}.select_status`,
    defaultMessage: 'Select status',
  },
  sku: {
    id: `${scope}.sku`,
    defaultMessage: 'Status',
  },
  redeemed_by: {
    id: `${scope}.redeemed_by`,
    defaultMessage: 'Redeemed by',
  },
  proctoring: {
    id: `${scope}.proctoring`,
    defaultMessage: 'Proctoring',
  },
  modified_by: {
    id: `${scope}.modified_by`,
    defaultMessage: 'Last modified by',
  },
  select_user: {
    id: `${scope}.select_user`,
    defaultMessage: 'Select user',
  },
});
