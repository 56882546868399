import { defineMessages } from 'react-intl';

export const scope = 'app.components.workflow';

export default defineMessages({
  search_for_workflow: {
    id: `${scope}.search_for_workflow`,
    defaultMessage: 'Search for workflows',
  },
  empty_record_title: {
    id: `${scope}.empty_search_list`,
    defaultMessage: 'No Results Found',
  },
  empty_record_description: {
    id: `${scope}.empty_record_description`,
    defaultMessage: "Sorry, we couldn't find any matches. Please try again.",
  },
  empty_workflow: {
    id: `${scope}.empty_workflow`,
    defaultMessage: 'No workflows yet',
  },
  empty_workflow_description: {
    id: `${scope}.empty_workflow_description`,
    defaultMessage: 'No workflows have been created so far.',
  },
});
