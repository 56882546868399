import { defineMessages } from 'react-intl';

export const scope = 'app.components.projects.project_form';

export default defineMessages({
  new_project: {
    id: `${scope}.new_project`,
    defaultMessage: 'New Project',
  },
  edit_project: {
    id: `${scope}.edit_project`,
    defaultMessage: 'Edit Project',
  },
  save_changes: {
    id: `${scope}.save_changes`,
    defaultMessage: 'Save Changes',
  },
  project_name: {
    id: `${scope}.project_name`,
    defaultMessage: 'Project Name',
  },
  project_name_placeholder: {
    id: `${scope}.project_name_placeholder`,
    defaultMessage: 'Enter a unique name',
  },
  project_name_error: {
    id: `${scope}.project_name_error`,
    defaultMessage: 'Please provide a unique project name',
  },
  geography: {
    id: `${scope}.geography`,
    defaultMessage: 'Geography',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Project Description',
  },
  description_placeholder: {
    id: `${scope}.description_placeholder`,
    defaultMessage: 'Describe your project',
  },
  tags: {
    id: `${scope}.tags`,
    defaultMessage: 'Tags',
  },
  tag_name: {
    id: `${scope}.tag_name`,
    defaultMessage: 'Name',
  },
  tag_value: {
    id: `${scope}.tag_value`,
    defaultMessage: 'Value',
  },
  project_tag_error: {
    id: `${scope}.project_tag_error`,
    defaultMessage: 'Please enter a unique key and value',
  },
  create_new_project: {
    id: `${scope}.create_new_project`,
    defaultMessage: 'Create New Project',
  },
  mandatory_field_error: {
    id: `${scope}.mandatory_field_error`,
    defaultMessage: 'This field is mandatory',
  },
});
