import { PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { matchPath } from 'react-router-dom';
import {
  type CallEffect,
  type PutEffect,
  type SelectEffect,
  all,
  call,
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { setGlobalErrorAction } from '@containers/app/slice';
import { CREATE_PROJECT_MUTATION, EDIT_PROJECT_MUTATION, GET_PROJECTS_QUERY } from '@containers/projects/queries';
import {
  createProjectAction,
  editProjectAction,
  getProjectErrorAction,
  getProjectSuccessAction,
  getProjectsAction,
  projectFormErrorAction,
  projectFormSuccessAction,
  setCurrentProject,
} from '@containers/projects/slice';
import type { CreateProjectActionPayload, EditProjectActionPayload, Project } from '@containers/projects/types';
import fetchData from '@utils/fetchData';
import postData from '@utils/postData';

type CreateProjectResponse = {
  data: {
    pc_create_project: Project;
  };
};

type EditProjectResponse = {
  data: {
    pc_update_project: Project;
  };
};

export function* getProjectsSaga(): Generator<CallEffect<Project[]> | PutEffect | SelectEffect, void, Project[]> {
  try {
    const path = matchPath('projects/:project_uuid', window.location.pathname) || {
      params: { project_uuid: '' },
    };
    const data: Project[] = yield call(fetchData, {
      queryString: GET_PROJECTS_QUERY,
      queryKey: 'pc_project',
      queryVariables: {},
      forceRefresh: true,
    });
    yield put(getProjectSuccessAction(data));
    if (data.length) {
      const urlParams = new URLSearchParams(window.location.search);

      if (path.params.project_uuid || urlParams.get('project_uuid')) {
        const currentProject = data.find(
          (project) => project.token === (path.params.project_uuid || urlParams.get('project_uuid')),
        );
        if (currentProject) yield put(setCurrentProject(currentProject));
      }
    }
  } catch (error) {
    if (get(error, 'graphQLErrors.0.extensions.code')) {
      yield put(
        getProjectErrorAction({
          message: get(error, 'graphQLErrors.0.extensions.code'),
        }),
      );
    }
    yield put(setGlobalErrorAction(error));
  }
}

export function* createProjectSaga({ payload }: PayloadAction<CreateProjectActionPayload>) {
  try {
    const response: CreateProjectResponse = yield call(postData, {
      queryString: CREATE_PROJECT_MUTATION,
      payload,
    });
    if (response && 'data' in response) {
      yield put(getProjectsAction());
      yield take(getProjectSuccessAction.type);
      yield put(projectFormSuccessAction(response.data.pc_create_project));
    }
  } catch (error) {
    yield put(projectFormErrorAction(error));
    yield put(setGlobalErrorAction(error));
  }
}

export function* editProjectSaga({ payload }: PayloadAction<EditProjectActionPayload>) {
  try {
    const response: EditProjectResponse = yield call(postData, {
      queryString: EDIT_PROJECT_MUTATION,
      payload,
    });
    if (response && 'data' in response) {
      yield put(projectFormSuccessAction(response.data.pc_update_project));
    }
  } catch (error) {
    yield put(projectFormErrorAction(error));
    yield put(setGlobalErrorAction(error));
  }
}

export function* watchGetProjects() {
  yield takeLatest(getProjectsAction.type, getProjectsSaga);
}

export function* watchCreateProject() {
  yield takeLatest(createProjectAction.type, createProjectSaga);
}

export function* watchEditProject() {
  yield takeLatest(editProjectAction.type, editProjectSaga);
}

export function* projectsSaga() {
  yield all([watchGetProjects(), watchCreateProject(), watchEditProject()]);
}
