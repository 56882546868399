import React, { useMemo, useState } from 'react';

import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import Dropdown from '@components/Base/Dropdown';
import User from '@components/Base/UserDetails';
import { FormattedTimeZone, TimeZone } from '@containers/user/types';
import { LOCAL_STORAGE_KEYS } from '@utils/data/enums';
import { formatTimeZone, setItemInLocalStorage } from '@utils/helpers';

import messages from './messages';

export type TimeZoneProps = {
  timeZone: TimeZone[];
  user?: User;
  selectedTimezone: FormattedTimeZone;
  handleSetTimezone: (timezone: FormattedTimeZone) => void;
};

const TimeZones = (props: TimeZoneProps) => {
  const { timeZone, selectedTimezone, handleSetTimezone } = props;

  const formattedTimeZoneOptions = formatTimeZone(timeZone);

  const [newTimezone, setNewTimezone] = useState<FormattedTimeZone | string>(selectedTimezone);

  const handleClick = () => {
    setItemInLocalStorage(LOCAL_STORAGE_KEYS.TIMEZONE, JSON.stringify(newTimezone));
    handleSetTimezone(newTimezone as FormattedTimeZone);
  };

  const disabled = useMemo(() => {
    return get(newTimezone, 'value') === get(selectedTimezone, 'value');
  }, [newTimezone, selectedTimezone]);

  return (
    <div className="w-full p-6">
      <div className="pb-6 text-base font-medium leading-5 text-gray-900">
        <FormattedMessage {...messages.timezone} />
      </div>
      <div className="mb-4 text-sm font-normal leading-4 text-gray-900">
        <FormattedMessage {...messages.select_timezone} />
      </div>
      <div className="w-[470px]">
        <Dropdown
          value={newTimezone as FormattedTimeZone}
          name="timezone-select"
          options={formattedTimeZoneOptions}
          onChange={(value) => setNewTimezone(value as FormattedTimeZone)}
          placeholder="Change timezone"
          id="timezones"
          isSearchable
          isSearchIcon
        />
      </div>

      <div className="flex justify-end gap-3 pr-2 mt-10">
        <Button disabled={disabled} onClick={handleClick}>
          <FormattedMessage {...messages.submit} />
        </Button>
      </div>
    </div>
  );
};

export default TimeZones;
