import React, { type KeyboardEvent, type ReactElement } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';

import { handleEnterKeyPress } from '@utils/helpers';

type PanelHeaderProps = {
  label: ReactElement;
  description: ReactElement;
  setDefaultPanelType?: () => void;
  isCloseIcon?: boolean;
};

const PanelHeader = ({ label, description, setDefaultPanelType, isCloseIcon = true }: PanelHeaderProps) => {
  const onCloseKeyPress = (e: KeyboardEvent<SVGSVGElement>) =>
    handleEnterKeyPress(e, () => setDefaultPanelType && setDefaultPanelType());

  return (
    <div className="flex justify-between gap-2.5 py-4 pl-5 pr-7 border-b border-gray-200">
      <div className="flex flex-col flex-1">
        <span className="text-base font-semibold text-gray-900">{label}</span>
        <span className="text-sm text-gray-600">{description}</span>
      </div>
      {isCloseIcon && (
        <XMarkIcon
          role="button"
          data-testid="panel_close"
          className="w-6 h-6 cursor-pointer "
          onClick={setDefaultPanelType}
          onKeyDown={onCloseKeyPress}
          tabIndex={0}
        />
      )}
    </div>
  );
};
export default PanelHeader;
