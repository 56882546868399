import { defineMessages } from 'react-intl';

export const scope = 'app.components.card';

export default defineMessages({
  view_all: {
    id: `${scope}.view_all`,
    defaultMessage: 'View all',
  },
});
