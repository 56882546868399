import React from 'react';

import { FunnelIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import { classNames } from '@utils/classNames';

import messages from '../messages';

interface filterButtonProps {
  hasAppliedFilters: boolean;
  onClick: () => void;
}

export const FilterButton = ({ hasAppliedFilters, onClick }: filterButtonProps) => {
  return (
    <Button
      type="button"
      role="button"
      onClick={onClick}
      variant="outline"
      data-testid="filter-button"
      className={classNames('text-gray-900 border-gray-200', {
        'relative border-blue-700': hasAppliedFilters,
      })}
    >
      <div className="flex gap-[0.25rem] items-center">
        <FunnelIcon className="h-[0.85rem] stroke-[2.5px]" />
        <FormattedMessage {...messages.filters_button} />
      </div>
      {hasAppliedFilters && <div className="h-2 w-2 bg-blue-700 rounded absolute top-[-2px] right-[-2px]" />}
    </Button>
  );
};
