import React from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import messages from './messages';
import notFound from '../../../images/svgs/notfound.svg';

const NotFound = () => {
  const intl = useIntl();
  return (
    <div className="fixed inset-0 z-10 flex flex-col items-center justify-center">
      <div className="w-[36rem] text-center text-gray-900 text-[32px] font-semibold">
        <FormattedMessage {...messages.page_not_found} />
      </div>
      <div className="w-96 text-center text-gray-700 text-base font-normal leading-[27px]">
        <FormattedMessage {...messages.page_not_found_sub_text} />
      </div>
      <div className="w-[23rem] h-[340px] my-4">
        <img
          src={notFound}
          alt={intl.formatMessage({
            id: messages.page_not_found.defaultMessage,
          })}
          className="w-full h-full"
        />
      </div>
    </div>
  );
};

export default NotFound;
