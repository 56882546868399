import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';

import messages from './messages';

interface DisableFlowModalProps {
  showModal: boolean;
  getConsent: (isSaveChanges: boolean) => void;
}

const DisableFlowModal: React.FC<DisableFlowModalProps> = ({ showModal, getConsent }) => {
  return (
    <Modal size="sm" show={showModal} onCancel={() => getConsent(false)}>
      <p className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">
        <FormattedMessage {...messages.disable_flow} />
      </p>
      <div className="my-6 text-gray-900 whitespace-normal">
        <div className="text-sm font-normal">
          <FormattedMessage {...messages.disable_flow_description} />
        </div>
        <div className="text-sm font-normal">
          <FormattedMessage {...messages.disable_flow_note} />
        </div>
      </div>
      <div className="flex flex-row-reverse">
        <Button data-testid="create_flow_button" onClick={() => getConsent(true)}>
          <FormattedMessage {...messages.save_changes} />
        </Button>
        <div className="mr-3">
          <Button variant="outline" onClick={() => getConsent(false)}>
            <FormattedMessage {...messages.go_back} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DisableFlowModal;
