import React from 'react';

import Table from '@components/Base/Table';
import { CustomColumnDef } from '@components/Base/Table/types';
import { Voucher } from '@containers/vouchers/types';
interface VouchersTableProps {
  data: Voucher[];
  columns: CustomColumnDef<Voucher>[];
  isLoading: boolean;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  pageCount: number;
  setPagination: React.Dispatch<React.SetStateAction<{ pageIndex: number; pageSize: number }>>;
}

const IssuedVoucherTable = (props: VouchersTableProps) => {
  const { data, columns, isLoading, pagination, pageCount, setPagination } = props;
  return (
    <div className="h-full mt-5">
      <Table<Voucher>
        data={data}
        columns={columns}
        isMultiselect={false}
        isLoading={isLoading}
        pagination={pagination}
        pageCount={pageCount}
        setPagination={setPagination}
      />
    </div>
  );
};

export default IssuedVoucherTable;
