import React, { useState } from 'react';

import { faArrowRightFromBracket, faGear } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import AccountSettings from '@components/AccountSettings';
import SignoutModal from '@components/SignoutModal';
import { APP_VERSION } from '@containers/app/constants';
import { ROLE, User } from '@containers/app/types';
import { FormattedTimeZone, TimeZone } from '@containers/user/types';
import { formatRoles } from '@utils/helpers';

import messages from './messages';

type SideNavProfileContentProps = {
  user: User;
  allowProfileEdit?: boolean;
  selectedLanguage: string;
  handleSetLocale: (language: string) => void;
  selectedTimezone: FormattedTimeZone;
  handleSetTimezone: (timezone: FormattedTimeZone) => void;
  timeZone: TimeZone[];
};

const SideNavProfileContent = (props: SideNavProfileContentProps) => {
  const { user, selectedLanguage, handleSetLocale, timeZone, handleSetTimezone, selectedTimezone } = props;
  const Intl = useIntl();

  const [showAccountSettings, setShowAccountSettings] = useState(false);
  const [showSignOut, setShowSignOut] = useState(false);

  return (
    <>
      <div className="border border-gray-200 pb-3 px-4 w-52 rounded-md shadow-[0px_4px_24px_rgba(17,24,39,0.08)]">
        <div className="pt-4 pb-3 ">
          <div className="mb-1 text-sm font-semibold text-gray-900">{user.name}</div>
          <div className="text-sm text-gray-600">{formatRoles(user.currentRole as ROLE)}</div>
        </div>
        <div
          className={classNames(
            'flex items-center py-2.5 cursor-pointer hover:bg-gray-100 px-2 w-full',
            showAccountSettings ? 'text-primary bg-blue-50' : 'text-gray-700',
          )}
          title={Intl.formatMessage(messages.account_settings)}
          onClick={() => setShowAccountSettings(true)}
          onKeyDown={() => setShowAccountSettings(true)}
          role="presentation"
        >
          <FontAwesomeIcon icon={faGear} className="text-lg" />

          <span className="ml-2 text-sm truncate max-w-[135px]">
            <FormattedMessage {...messages.account_settings} />
          </span>
        </div>
        <div
          className={classNames(
            'flex items-center py-2.5 cursor-pointer hover:bg-gray-100 px-2 w-full',
            showSignOut ? 'text-primary bg-blue-50' : 'text-gray-700',
          )}
          onClick={() => setShowSignOut(true)}
          onKeyDown={() => setShowSignOut(true)}
          role="presentation"
          title={Intl.formatMessage(messages.sign_out)}
        >
          <FontAwesomeIcon icon={faArrowRightFromBracket} className="text-lg" />

          <span className="ml-2 text-sm truncate max-w-[135px]" data-testid="signOut">
            <FormattedMessage {...messages.sign_out} />
          </span>
        </div>
        <div className="mt-5 text-sm text-gray-500">
          <FormattedMessage {...messages.version} />
          <span className="ml-1">{APP_VERSION}</span>
        </div>
      </div>
      {showAccountSettings && (
        <AccountSettings
          user={user}
          timeZone={timeZone}
          handleSetTimezone={handleSetTimezone}
          selectedTimezone={selectedTimezone}
          isModalOpen={showAccountSettings}
          closeModal={() => setShowAccountSettings(false)}
          selectedLanguage={selectedLanguage}
          handleSetLocale={handleSetLocale}
        />
      )}
      <SignoutModal isModalOpen={showSignOut} closeModal={() => setShowSignOut(false)} />
    </>
  );
};

export default SideNavProfileContent;
