import React, { useEffect, useState } from 'react';

import { faArrowDown, faArrowUp, faSort } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CellContext,
  RowData,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';

import EmptyRecord from '@components/Base/Table/TableHelpers/EmptyRecord';

import TableLoader from './loader/TableLoader';
import { CustomColumnDef } from './types';
import { Checkbox } from '../Checkbox';
import './styles.css';

export interface TableProps<TData extends RowData> {
  data: TData[];
  columns: CustomColumnDef<TData>[];
  setPagination?: React.Dispatch<React.SetStateAction<{ pageIndex: number; pageSize: number }>>;
  pagination?: { pageIndex: number; pageSize: number };
  searchText?: string;
  isLoading?: boolean;
  isMultiselect?: boolean;
  handleSelection?: (rowSelection: { [key: number]: boolean } | null) => void;
  resetSelection?: boolean;
  pageCount?: number;
}

const Table = <TData extends RowData>({
  data,
  columns,
  setPagination,
  pagination,
  isLoading,
  isMultiselect,
  handleSelection = () => {},
  resetSelection,
  pageCount,
}: TableProps<TData>) => {
  const [rowSelection, setRowSelection] = useState({});

  useEffect(() => {
    handleSelection(rowSelection);
  }, [rowSelection]);

  useEffect(() => {
    if (resetSelection) {
      setRowSelection({});
    }
  }, [resetSelection]);

  const columnsWithSelection: CustomColumnDef<TData>[] = isMultiselect
    ? [
        {
          id: 'selection',
          enableSorting: false,
          header: ({ table }) => (
            <Checkbox
              {...{
                checked: table.getIsAllRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
                disabled: false,
                id: 0,
              }}
            />
          ),
          cell: (props: CellContext<TData, unknown>) => (
            <Checkbox
              {...{
                checked: props.row.getIsSelected(),
                onChange: props.row.getToggleSelectedHandler(),
                disabled: false,
                id: props.row.id,
              }}
            />
          ),
          size: 48,
          className: 'text-center left-0 sticky',
          cellClassName: 'text-center ignoreRowClick left-0 sticky z-[1]',
          enableGlobalFilter: false,
        },
        ...columns.filter((item) => !item.hidden),
      ]
    : columns.filter((item) => !item.hidden);

  const table = useReactTable({
    data,
    columns: columnsWithSelection,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    pageCount,
    state: {
      pagination,
      rowSelection,
    },
    manualPagination: true,
    enableMultiRowSelection: isMultiselect,
    enableRowSelection: isMultiselect,
    onRowSelectionChange: setRowSelection,
    enableFilters: true,
  });

  const { getAllLeafColumns } = table;

  const getSizeClass = (size?: number) => `w-[${size}px]`;

  return (
    <div className="h-[calc(100vh-13rem)] mt-5 overflow-auto max-w-[calc(100vw-9rem)] no-scrollbar">
      <table className={classNames('w-full table-fixed')}>
        <thead className="sticky top-0 z-[2]">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={classNames(
                    'pl-4 py-2 text-sm font-medium text-left text-primary-text bg-gray-100',
                    getSizeClass(header.column.columnDef.size),
                    (header.column.columnDef as CustomColumnDef<TData>).className,
                  )}
                  style={{ width: `${header.getSize()}px` }}
                >
                  {header.column.getCanSort() ? (
                    <div className="flex items-center">
                      {flexRender(header.column.columnDef.header, header.getContext())}
                      <button
                        type="button"
                        onClick={header.column.getToggleSortingHandler()}
                        className={header.column.getIsSorted() ? 'ml-2' : 'ml-2 text-gray-400'}
                      >
                        {header.column.getIsSorted() === 'desc' ? (
                          <FontAwesomeIcon icon={faArrowDown} />
                        ) : header.column.getIsSorted() === 'asc' ? (
                          <FontAwesomeIcon icon={faArrowUp} />
                        ) : (
                          <FontAwesomeIcon icon={faSort} />
                        )}
                      </button>
                    </div>
                  ) : (
                    flexRender(header.column.columnDef.header, header.getContext())
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {isLoading ? (
            <TableLoader columns={getAllLeafColumns()} />
          ) : data.length === 0 ? (
            <tr data-testid="empty_record">
              <EmptyRecord colSpan={getAllLeafColumns().length} />
            </tr>
          ) : (
            <>
              {table.getRowModel().rows.map((row) => (
                <tr key={row.id} id={row.id} className={classNames('odd:bg-white even:bg-slate-50')}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      className={classNames(
                        (cell.column.columnDef as CustomColumnDef<TData>).cellClassName,
                        'p-4 bg-inherit',
                      )}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
