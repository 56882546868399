// import { getHasuraHeaders } from '@utils/helpers';

const authUrl = process.env.REACT_APP_AUTH_URL || 'https://admin.talview.org';
const baseURL = process.env.REACT_APP_BASE_URL || 'https://proview-console.talview.org';

export const redirect = (path: string) => {
  window.location.href = path;
};

type LoginTypes = {
  location: {
    state: { from: string; search: string };
  };
};

export const redirectToAdminLogout = async (
  tenantId: number,
  username: string,
  location: LoginTypes['location'],
  skip = false,
) => {
  const hostNameValue = window.location.host;
  const subdomain = hostNameValue.split(`.${baseURL}`)[0];
  const from = location?.state?.from || '/';
  const search = window.location.search || location?.state?.search || '';
  const searchParams = new URLSearchParams(search);

  const redirectUrl = encodeURIComponent(`${window.location.origin}${from}${searchParams.toString()}`);

  const path = `${authUrl}/logout`;
  searchParams.set('redirect_url', redirectUrl);
  if (subdomain !== baseURL && !subdomain.includes('localhost')) {
    searchParams.set('subdomain', subdomain);
  }

  if (!skip) {
    if (tenantId) {
      searchParams.set('tid', tenantId.toString());
    }
    if (username) {
      searchParams.set('username', username);
    }
  }
  redirect(`${path}?${searchParams.toString()}`);
};

export const loginRedirect = ({ location }: LoginTypes) => {
  const hostNameValue = window.location.host;
  const subdomain = hostNameValue.split(`.${baseURL}`)[0];
  const from = location?.state?.from || '/';
  const search = location?.state?.search || '';
  const redirectUrl = encodeURIComponent(`${window.location.origin}${from}${search}`);
  const existingSearchParams = new URLSearchParams(search);
  const tid = existingSearchParams.get('tid');
  const userId = existingSearchParams.get('userId');

  const path = `${authUrl}/login`;
  const searchParams = new URLSearchParams();
  searchParams.set('redirect_url', redirectUrl);
  if (tid) {
    searchParams.set('tid', tid);
  }
  if (userId) {
    searchParams.set('userId', userId);
  }
  if (subdomain !== baseURL && !subdomain.includes('localhost')) {
    searchParams.set('subdomain', subdomain);
  }
  redirect(`${path}?${searchParams.toString()}`);
};
