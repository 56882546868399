import React, { useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';
import AddFlowModalTabs from '@components/Flows/AddFlowModal/AddFlowModalTabs';
import messages from '@components/Flows/AddFlowModal/messages';
import type { AddFlowProps, SelectedOptions, SelectedTemplateOptions } from '@components/Flows/AddFlowModal/types';
import type { createFlowPayload } from '@containers/flowList/types';
import { ADD_FLOW_MODAL_TAB } from '@utils/data/enums';
import { handleEnterKeyPress } from '@utils/helpers';

const AddFlowModal = ({
  handleToggleAddFlowModal,
  openAddFlowModal,
  templates,
  currentProject,
  flow: { isLoading: isCreatingFlow, error: createFlowError },
  handleResetFlowState,
  handleCreateFlow,
}: AddFlowProps) => {
  const [flowName, setFlowName] = useState<string>('');
  const [selectedTemplate, setSelectedTemplate] = useState<SelectedTemplateOptions | string>('');

  const [selectedDuplicateFlow, setSelectedDuplicateFlow] = useState<string | SelectedOptions>('');
  const [isFlowNameExceedsMaxChar, setIsFlowNameExceedsMaxChar] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleCloseModal = () => {
    resetFlowNameState();
    handleToggleAddFlowModal();
  };

  const resetFlowNameState = () => {
    setSelectedTemplate('');
    setSelectedDuplicateFlow('');
    setFlowName('');
    setIsFlowNameExceedsMaxChar(false);
    createFlowError && handleResetFlowState();
  };

  const handleTabChange = (tabIndex: number) => {
    setSelectedTab(tabIndex);
    resetFlowNameState();
  };

  const onClickCreateFlowBtn = () => {
    const createFlowPayload: createFlowPayload = {
      name: flowName,
      project_id: currentProject.id,
      is_template: selectedTab === ADD_FLOW_MODAL_TAB.TEMPLATE,
    };

    if (selectedTab !== ADD_FLOW_MODAL_TAB.NEW_FLOW) {
      createFlowPayload.reference_flow_id =
        selectedTab === ADD_FLOW_MODAL_TAB.DUPLICATE_FLOW
          ? (selectedDuplicateFlow as SelectedOptions).id
          : (selectedTemplate as SelectedTemplateOptions).id;
    }
    handleCreateFlow(
      createFlowPayload as {
        name: string;
        project_id: string;
        is_template: boolean;
        reference_flow_id: string;
      },
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    handleEnterKeyPress(e, () => handleToggleAddFlowModal());
  };

  const isDisabled = useMemo(() => {
    switch (selectedTab) {
      case ADD_FLOW_MODAL_TAB.NEW_FLOW:
        return !flowName;
      case ADD_FLOW_MODAL_TAB.DUPLICATE_FLOW:
        return !(selectedDuplicateFlow && flowName);
      default:
        return !(selectedTemplate && flowName);
    }
  }, [selectedTab, flowName, selectedDuplicateFlow, selectedTemplate]);

  const handleDuplicateChange = (e: SelectedOptions) => {
    const selectedFlow = { ...e };
    selectedFlow.value = `${selectedFlow.value} (Copy)`;
    setSelectedDuplicateFlow(e);
    setFlowName(selectedFlow.value);
  };

  const isDisableCreateFlowBtn = isDisabled || isCreatingFlow || !!isFlowNameExceedsMaxChar || !!createFlowError;

  const handleflowNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFlowName(value);
    if (value.length >= 45) {
      setIsFlowNameExceedsMaxChar(true);
    } else {
      setIsFlowNameExceedsMaxChar(false);
      createFlowError && handleResetFlowState();
    }
  };

  return (
    <Modal size="lg" show={openAddFlowModal} onCancel={handleCloseModal}>
      <p className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">
        <FormattedMessage {...messages.add_flow} />
      </p>
      <div className="relative h-[410px]">
        <AddFlowModalTabs
          handleflowNameChange={handleflowNameChange}
          flowName={flowName}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          selectedDuplicateFlow={selectedDuplicateFlow}
          handleTabChange={handleTabChange}
          handleDuplicateChange={handleDuplicateChange}
          flows={currentProject.flows}
          templates={templates}
          isFlowNameExceedsMaxChar={isFlowNameExceedsMaxChar}
          createFlowError={createFlowError}
        />
      </div>
      <div className="flex flex-row-reverse">
        <Button
          data-testid="create_flow_button"
          onClick={onClickCreateFlowBtn}
          isLoading={isCreatingFlow}
          disabled={isDisableCreateFlowBtn}
        >
          <FormattedMessage {...messages.create_flow} />
        </Button>
        <div className="mr-3">
          <Button variant="outline" onClick={handleCloseModal} onKeyDown={handleKeyDown} disabled={isCreatingFlow}>
            <FormattedMessage {...messages.cancel} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default AddFlowModal;
