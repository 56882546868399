import React from 'react';

import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import CheckAnimation from '@images/CheckAnimation.json';
import FolderAnimation from '@images/FolderAnimation.json';

import messages from './messages';

interface Props {
  isSuccess: boolean;
  onSuccess: () => void;
  isEditProject: boolean;
}

const ConfirmMessage = ({ isSuccess, onSuccess, isEditProject }: Props) => {
  return (
    <div
      className="fixed inset-0 z-10 flex items-center justify-center bg-gray-900 bg-opacity-50"
      aria-labelledby="modal-title"
      aria-modal="true"
    >
      <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2  w-full max-w-[525px] max-h-[621px] h-full overflow-hidden rounded-lg bg-white p-6 m-6 text-left shadow-xl transition-all z-10">
        <div className="text-center">
          <LottiePlayer
            className="relative max-w-[150px] w-full pt-24"
            autoplay
            loop
            src={isSuccess ? CheckAnimation : FolderAnimation}
          />
          <p className="text-lg font-medium">
            {isSuccess ? (
              <FormattedMessage {...messages.project_ready} />
            ) : (
              <FormattedMessage {...(isEditProject ? messages.updating_project : messages.creating_project)} />
            )}
          </p>
          <p className="max-w-56 mx-auto text-gray-500 text-sm mt-1.5">
            <FormattedMessage {...messages.project_ready_description} />
          </p>
          {isSuccess && (
            <Button onClick={onSuccess} className="mx-auto mt-3">
              <FormattedMessage {...messages.go_to_project} />
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmMessage;
