import React from 'react';

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { FormattedMessage } from 'react-intl';

import AccommodationBadges from '@components/Base/Accomodations';
import { Checkbox } from '@components/Base/Checkbox';
import IntegrityRating from '@components/Base/IntegrityRating';
import Tooltip from '@components/Base/NewToolTip';
import Rating from '@components/Base/Rating';
import { CustomColumnDef } from '@components/Base/Table/types';
import User from '@components/Base/UserDetails';
import { convertToTitleCase } from '@components/ProctorNgSessions/SessionTable/helper';
import { SessionData } from '@containers/exams/types';
import { classNames } from '@utils/classNames';
import { RATING } from '@utils/data/enums';
import { formatDate, formatTimeFromMinutes } from '@utils/dateFormat';
import gripHorizontalIcon from 'app/images/grip-horizontal.svg';

import './styles.css';
import messages from '../messages';

export const createColumns = (): CustomColumnDef<SessionData>[] => [
  {
    id: 'Attendee',
    header: () => 'Attendee',
    accessorKey: 'Attendee',
    enableSorting: false,
    hidden: false,
    cell: ({ row }) => {
      const email = row.original.candidate_details?.email || '';
      return (
        <User
          data={{
            name: email,
          }}
          size="sm"
          hideImage={false}
          additionalContent={row.original.candidate_id}
        />
      );
    },
    size: 280,
    className:
      'text-left text-sm font-medium font-roboto text-gray-900 attendee-position-left sticky attendee-column shadow-[inset_-1px_0px_0px_0px] shadow-gray-400/20',
    cellClassName:
      'text-left sticky attendee-position-left attendee-column shadow-[inset_-1px_0px_0px_0px] z-index-one shadow-gray-400/20',
  },
  {
    id: 'Session Recording',
    header: () => 'Session Recording',
    accessorKey: 'Session Recording',
    enableSorting: false,
    cell: () => (
      <div>
        <FormattedMessage {...messages.view_recording} />
      </div>
    ),
    size: 180,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'Session Rating',
    header: () => (
      <div className="flex align-middle">
        <span className="mr-1">
          <FormattedMessage {...messages.session_rating} />
        </span>
        <Tooltip refDiv={<InformationCircleIcon className="w-5 h-5 text-gray-400" />} position="bottom-right">
          <IntegrityRating />
        </Tooltip>
      </div>
    ),
    accessorKey: 'session_rating',
    enableSorting: false,
    cell: ({ row }) => {
      return (
        <div className="flex align-left">
          <Rating
            {...(row.original.system_rating && {
              rating: row.original.system_rating as RATING,
            })}
          />
        </div>
      );
    },
    size: 148,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'Disposition',
    header: () => 'Disposition',
    accessorKey: 'Disposition',
    enableSorting: false,
    cell: ({ row }) => <div>{row.original.summary}</div>,
    size: 280,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'Accommodations',
    header: () => 'Accommodations',
    accessorKey: 'Accommodations',
    enableSorting: false,
    cell: ({ row }) => <AccommodationBadges accommodations={row.original.accommodation} />,
    size: 262,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left whitespace-nowrap',
    hidden: false,
  },
  {
    id: 'attempt',
    header: () => 'Attempt',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => <div>{row.original.attempt_count ? row.original.attempt_count : '-'}</div>,
    size: 95,
    className: 'text-center text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-center',
    hidden: false,
  },
  {
    id: 'session_duration',
    header: () => 'Session Duration',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => (row.original.duration ? formatTimeFromMinutes(row.original.duration) : '-'),
    size: 140,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'external_id',
    header: () => 'External ID',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => <div>#{row.original.external_id}</div>,
    size: 124,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-center text-sm font-normal',
    hidden: false,
  },
  {
    id: 'completed_on',
    header: () => 'Completed On',
    accessorKey: 'session_candidate',
    enableSorting: false,
    cell: ({ row }) => (row.original.completed_at ? formatDate(new Date(row.original.completed_at), 'withTime') : '-'),
    size: 230,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left text-sm/[19px] font-normal',
    hidden: false,
  },
  {
    id: 'menu',
    enableSorting: false,
    accessorKey: 'menu',
    size: 48,
    className: 'text-left right-0 sticky shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
    cellClassName: 'text-center right-0 sticky z-index-one shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
    header: ({ table }) => {
      return (
        <div>
          <Popover className="flex justify-end items-center pr-3">
            <PopoverButton className={classNames('focus:outline-none')}>
              <PlusCircleIcon
                className={classNames('text-black h-5 w-5 focus:outline-none')}
                aria-hidden="true"
                data-testid="hide-column"
              />
            </PopoverButton>
            <Transition>
              <PopoverPanel>
                <div
                  className="bg-white border-solid border-[1px] menu-list-width font-normal shadow-sm menu-list-max-height overflow-y-auto rounded-md px-4 absolute right-0 top-12"
                  data-testid="column-model"
                >
                  {table.getAllLeafColumns().map((column) => {
                    if (column.id === 'menu') {
                      return null;
                    }
                    return (
                      <div key={column.id} className="menu-list-height w-full flex items-center">
                        <span className="pr-2 w-6 h-6">
                          <img src={gripHorizontalIcon} alt="edit" className="w-full h-full" />
                        </span>
                        <Checkbox
                          label={
                            typeof column.columnDef.header === 'string'
                              ? column.columnDef.header
                              : convertToTitleCase(column.id)
                          }
                          id={column.id}
                          size="md"
                          checked={column.getIsVisible()}
                          onChange={column.getToggleVisibilityHandler()}
                          disabled={false}
                        />
                      </div>
                    );
                  })}
                </div>
              </PopoverPanel>
            </Transition>
          </Popover>
        </div>
      );
    },
  },
];
