import React from 'react';

import { FormattedMessage } from 'react-intl';

import messages, { generateChangeLogMessages } from '@components/Flows/ConfigPanels/messages';
import { VersionLogChange } from '@containers/flowDetails/types';
import { VERSION_LOG_KEY_VALUE_MAPTING } from '@utils/data/enums';

interface ChangeLogListProps {
  detail: VersionLogChange;
}

const ChangeLogList: React.FC<ChangeLogListProps> = ({ detail }) => {
  const renderBlacklistApps = (value: string, changeIndex: number, key: string) => {
    const parsedObject = JSON.parse(value) as { [key: string]: string[] };

    return Object.entries(parsedObject).map(([subKey, subValue]) => {
      const displayValue = Array.isArray(subValue) && subValue.length > 0 ? subValue.join(', ') : 'N/A';

      return (
        <li key={`${changeIndex}-${key}-${subKey}`}>
          <FormattedMessage {...messages.blacklisted_apps_for} /> {subKey} : {displayValue}
        </li>
      );
    });
  };

  const renderChangeLogItem = (key: string, value: string | boolean | string[], changeIndex: number) => {
    if (
      key === 'sb_blacklisted_apps' &&
      typeof value === 'string' &&
      value !== VERSION_LOG_KEY_VALUE_MAPTING.NOT_AVAILABLE
    ) {
      return renderBlacklistApps(value, changeIndex, key);
    }

    return (
      <li key={`${changeIndex}-${key}`}>
        <FormattedMessage {...generateChangeLogMessages(key)} />{' '}
        <FormattedMessage {...generateChangeLogMessages(value)} />
      </li>
    );
  };

  return (
    <ul className="text-sm text-gray-900 mt-5 leading-5 list-disc ml-4 font-roboto">
      {detail.changeLogs.map((change, changeIndex) =>
        Object.entries(change).map(([key, value]) => renderChangeLogItem(key, value, changeIndex)),
      )}
    </ul>
  );
};

export default ChangeLogList;
