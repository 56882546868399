import React, { useEffect, useMemo, useState } from 'react';

import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import _, { isEmpty, sortBy } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import EmptyMessagePage from '@components/Base/EmptyMessagePage';
import SearchInput from '@components/Base/Input';
import Pagination from '@components/Base/Pagination';
import { CustomColumnDef } from '@components/Base/Table/types';
import Tabs from '@components/Base/Tabs';
import VoucherModal from '@components/CreateVoucher';
import { User } from '@containers/app/types';
import { AppliedFilters } from '@containers/vouchers';
import { formatProperties } from '@containers/vouchers/helpers';
import messages from '@containers/vouchers/messages';
import { CreateVoucherPadload, CreateVoucherResponse } from '@containers/vouchers/saga';
import { RedeemedVoucherList, SELECTED_TAB, Voucher } from '@containers/vouchers/types';
import { getMixpanelProperties, getPageCount } from '@utils/helpers';
import { useAppSelector } from '@utils/hooks';
import { proviewConsoleVoucherFilterApplied, proviewConsoleVoucherSearched } from '@utils/mixpanelActions';
import ClipBoardIcon from 'app/images/clipboard.svg';
import NoResultsFound from 'app/images/svgs/noResultsFound.svg';

import VoucherFilters from './VoucherFilters';
import IssuedVoucherTable from './VoucherTable/IssuedTable';
import { createColumns } from './VoucherTable/IssuedTable/columns';
import RedeemedVoucherTable from './VoucherTable/RedeemedTable';
import { createRedeemColumns } from './VoucherTable/RedeemedTable/columns';

interface VoucherListProps {
  voucherList: Voucher[];
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  updateAppliedFilters: React.Dispatch<React.SetStateAction<object>>;
  vouchers: { label: string; value: string }[];
  appliedFilters: AppliedFilters;
  selectedTab: string;
  handleSelectedTab: (value: string) => void;
  skuList: { label: string; value: string }[];
  redeemedVouchersList: RedeemedVoucherList[];
  loadingRedeemedVouchers: boolean;
  handleCreateVoucher: (value: CreateVoucherPadload, voucherQuantity: string) => void;
  loadingCreatedVoucher: boolean;
  createdVoucher: CreateVoucherResponse | null;
  showVoucherSuccessModal: boolean;
  resetVoucherState: () => void;
  redeemedList: { label: string; value: string }[];
  resetFilters: string;
  totalCount: number;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  setPagination: React.Dispatch<React.SetStateAction<{ pageIndex: number; pageSize: number }>>;
  redeemedVouchersCount: number;
  paginationRedeemed: {
    pageIndex: number;
    pageSize: number;
  };
  setPaginationRedeemed: React.Dispatch<React.SetStateAction<{ pageIndex: number; pageSize: number }>>;
  createVoucherDownloaded: () => void;
  voucherDownloadListClicked: () => void;
  downloadCompleteList: () => void;
  loadingIssuedVouchersList: boolean;
  loadingRedeemedVoucherFullList: boolean;
  getTagslist: () => void;
  tagsList: { label: string; value: number }[];
  fetchLatestVouchersList: () => void;
  existingVoucherCodes: string[];
}

const VoucherList = (props: VoucherListProps) => {
  const {
    voucherList,
    searchTerm,
    isLoading,
    setSearchTerm,
    updateAppliedFilters,
    vouchers,
    appliedFilters,
    selectedTab,
    handleSelectedTab,
    skuList,
    redeemedVouchersList,
    loadingRedeemedVouchers,
    handleCreateVoucher,
    loadingCreatedVoucher,
    createdVoucher,
    showVoucherSuccessModal,
    resetVoucherState,
    redeemedList,
    resetFilters,
    totalCount,
    pagination,
    setPagination,
    redeemedVouchersCount,
    paginationRedeemed,
    setPaginationRedeemed,
    createVoucherDownloaded,
    voucherDownloadListClicked,
    downloadCompleteList,
    loadingIssuedVouchersList,
    loadingRedeemedVoucherFullList,
    getTagslist,
    tagsList,
    fetchLatestVouchersList,
    existingVoucherCodes,
  } = props;

  const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);
  const showEmptyPage = isEmpty(appliedFilters);
  const [openVoucherModal, setOpenVoucherModal] = useState<boolean>(false);
  const [isDownaloadDisabled, setIsDownloadDisabled] = useState(false);
  const pageCount = getPageCount(totalCount, pagination);
  const redeemedPageCount = getPageCount(redeemedVouchersCount, pagination);

  const downloadVoucher = () => {
    downloadCompleteList();
  };

  const renderVouchersEmptyPage =
    isEmpty(voucherList) && !isLoading && !searchTerm && showEmptyPage && selectedTab === SELECTED_TAB.ISSUED;

  const renderVouchersNoResultsFoundPage =
    isEmpty(voucherList) && (searchTerm || !showEmptyPage) && selectedTab === SELECTED_TAB.ISSUED;

  const sortedRedeemedData = sortBy(redeemedVouchersList, (order) => order.order_items?.[0]?.coupon?.code);

  const renderRedeemedVouchersEmptyPage =
    sortedRedeemedData.length === 0 && !searchTerm && !loadingRedeemedVouchers && selectedTab === SELECTED_TAB.REDEEMED;

  const renderRedeemedVouchersNoResultsFoundPage =
    sortedRedeemedData.length === 0 && searchTerm && selectedTab === SELECTED_TAB.REDEEMED;

  const columnsIssued = useMemo(() => {
    return createColumns().filter((column) => column !== null) as CustomColumnDef<Voucher>[];
  }, [selectedTab]);

  const columnsRedeemed = useMemo(() => {
    return createRedeemColumns().filter((column) => column !== null) as CustomColumnDef<RedeemedVoucherList>[];
  }, [selectedTab]);

  const handleSearch = (value: string) => {
    if (value) {
      triggerSearchMPEvent(value);
    }
    setSearchTerm(value.replace(/%/g, ''));
  };

  const triggerFilterMPEvent = (filters: object) => {
    if (isMixpanelInitialized && user) {
      const mixpanelProperties = getMixpanelProperties(user);
      const additionalProperties = {
        module: 'Vouchers',
        tab: selectedTab,
      };
      const combinedProperties = {
        ...additionalProperties,
        ...mixpanelProperties,
        filteredBy: { ...filters },
      };
      proviewConsoleVoucherFilterApplied(combinedProperties);
    }
  };

  const triggerSearchMPEvent = (searchParam: string) => {
    proviewConsoleVoucherSearchedEvent(isMixpanelInitialized, user, selectedTab, searchParam);
  };

  const applyFilters = (filters: object) => {
    if (!isEmpty(filters)) {
      triggerFilterMPEvent(filters);
    }
    updateAppliedFilters(filters);
  };

  const handleTabSelect = (value: string) => {
    handleSelectedTab(value);
  };

  const toggleModal = () => {
    setOpenVoucherModal((prev) => !prev);
    resetVoucherState();
    getTagslist();
    fetchLatestVouchersList();
  };

  const nextPage = () => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: prev.pageIndex + 1,
    }));
  };

  const prevPage = () => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: prev.pageIndex - 1,
    }));
  };

  const nextRedeemedPage = () => {
    setPaginationRedeemed((prev) => ({
      ...prev,
      pageIndex: prev.pageIndex + 1,
    }));
  };

  const prevRedeemedPage = () => {
    setPaginationRedeemed((prev) => ({
      ...prev,
      pageIndex: prev.pageIndex - 1,
    }));
  };

  useEffect(() => {
    const disabledCondition =
      renderVouchersNoResultsFoundPage ||
      (selectedTab === SELECTED_TAB.REDEEMED &&
        (renderRedeemedVouchersEmptyPage || renderRedeemedVouchersNoResultsFoundPage));

    setIsDownloadDisabled(Boolean(disabledCondition));
  }, [
    selectedTab,
    renderRedeemedVouchersEmptyPage,
    renderRedeemedVouchersNoResultsFoundPage,
    renderVouchersNoResultsFoundPage,
    voucherList,
    sortedRedeemedData,
  ]);

  return (
    <>
      {renderVouchersEmptyPage ? (
        <EmptyMessagePage
          icon={ClipBoardIcon}
          titleMessage={messages.empty_voucher}
          descriptionMessage={messages.empty_voucher_description}
          descriptionWidth="w-48"
        />
      ) : (
        <div className="flex w-full px-8 py-5">
          <div className="flex flex-col w-full">
            <div className="flex flex-row-reverse justify-between items-center mb-4">
              <div className="flex items-center gap-4">
                <SearchInput
                  initialValue={searchTerm}
                  handleChange={(value) => handleSearch(value)}
                  placeholderMessageId="app.containers.vouchers.search_vouchers"
                  resetSearch={selectedTab}
                />
                <VoucherFilters
                  updateAppliedFilters={applyFilters}
                  vouchers={vouchers}
                  appliedFilters={appliedFilters}
                  skuList={skuList}
                  selectedTab={selectedTab}
                  redeemedList={redeemedList}
                  resetFilters={resetFilters}
                />
                <div
                  className={`inline-flex items-center group cursor-pointer ${isDownaloadDisabled ? 'opacity-50 pointer-events-none' : ''}`}
                >
                  <ArrowDownTrayIcon className="w-5 h-5 text-blue-700 cursor-pointer mr-2 group-hover:text-blue-700" />
                  <Button
                    type="button"
                    variant="ghost"
                    data-testid="download-btn"
                    className="bg-transparent text-blue-700 font-medium text-sm p-0 m-0 
      group-hover:bg-transparent hover:bg-transparent"
                    onClick={downloadVoucher}
                    isLoading={
                      selectedTab === SELECTED_TAB.ISSUED ? loadingIssuedVouchersList : loadingRedeemedVoucherFullList
                    }
                  >
                    <span className="text-sm font-medium">
                      <FormattedMessage {...messages.voucher_download_list} />
                    </span>
                  </Button>
                </div>
                <div className="w-0 h-6 border border-gray-300" />
                <Pagination
                  total={selectedTab === SELECTED_TAB.ISSUED ? totalCount : redeemedVouchersCount}
                  pageSize={selectedTab === SELECTED_TAB.ISSUED ? pagination.pageSize : paginationRedeemed.pageSize}
                  currentPage={
                    selectedTab === SELECTED_TAB.ISSUED ? pagination.pageIndex : paginationRedeemed.pageIndex
                  }
                  summary
                  previousPage={selectedTab === SELECTED_TAB.ISSUED ? prevPage : prevRedeemedPage}
                  nextPage={selectedTab === SELECTED_TAB.ISSUED ? nextPage : nextRedeemedPage}
                  getCanPreviousPage={() => {
                    if (selectedTab === SELECTED_TAB.ISSUED) {
                      return pagination.pageIndex > 0;
                    } else {
                      return paginationRedeemed.pageIndex > 0;
                    }
                  }}
                  getCanNextPage={() => {
                    if (selectedTab === SELECTED_TAB.ISSUED) {
                      return pagination.pageIndex < pageCount - 1;
                    } else {
                      return paginationRedeemed.pageIndex < redeemedPageCount - 1;
                    }
                  }}
                />
              </div>
              <div className="flex items-center">
                <Tabs
                  tabData={[
                    {
                      label: <FormattedMessage {...messages.issued} />,
                      value: SELECTED_TAB.ISSUED,
                      isShow: true,
                    },
                    {
                      label: <FormattedMessage {...messages.redeemed} />,
                      value: SELECTED_TAB.REDEEMED,
                      isShow: true,
                    },
                  ]}
                  selectedValue={selectedTab}
                  onSelect={handleTabSelect}
                />
                <Button className="ml-2" variant="base" data-testid="create-voucher-btn" onClick={toggleModal}>
                  <FormattedMessage {...messages.create_voucher} />
                </Button>
              </div>
            </div>
            <>
              {selectedTab === SELECTED_TAB.ISSUED ? (
                renderVouchersNoResultsFoundPage ? (
                  <EmptyMessagePage
                    icon={NoResultsFound}
                    titleMessage={messages.empty_record_title}
                    descriptionMessage={messages.empty_record_description}
                    descriptionWidth="w-48"
                  />
                ) : (
                  <IssuedVoucherTable
                    data={voucherList}
                    columns={columnsIssued}
                    isLoading={isLoading}
                    pagination={pagination}
                    pageCount={pageCount}
                    setPagination={setPagination}
                  />
                )
              ) : renderRedeemedVouchersEmptyPage ? (
                <EmptyMessagePage
                  icon={ClipBoardIcon}
                  titleMessage={messages.empty_voucher}
                  descriptionMessage={messages.empty_redeemed_description}
                  descriptionWidth="w-48"
                />
              ) : renderRedeemedVouchersNoResultsFoundPage ? (
                <EmptyMessagePage
                  icon={NoResultsFound}
                  titleMessage={messages.empty_record_title}
                  descriptionMessage={messages.empty_record_description}
                  descriptionWidth="w-48"
                />
              ) : (
                <RedeemedVoucherTable
                  data={sortedRedeemedData}
                  columns={columnsRedeemed}
                  isLoading={loadingRedeemedVouchers}
                  pagination={paginationRedeemed}
                  pageCount={redeemedPageCount}
                  setPagination={setPaginationRedeemed}
                />
              )}
            </>
          </div>
        </div>
      )}
      <VoucherModal
        skuList={skuList}
        handleCreateVoucher={handleCreateVoucher}
        openVoucherModal={openVoucherModal}
        toggleModal={toggleModal}
        loadingCreatedVoucher={loadingCreatedVoucher}
        createdVoucher={createdVoucher}
        showVoucherSuccessModal={showVoucherSuccessModal}
        createVoucherDownloaded={createVoucherDownloaded}
        voucherDownloadListClicked={voucherDownloadListClicked}
        tagsList={tagsList}
        existingVoucherCodes={existingVoucherCodes}
      />
    </>
  );
};

export default VoucherList;

export const proviewConsoleVoucherSearchedEvent = (
  isMixpanelInitialized: boolean,
  user: User,
  selectedTab: string,
  searchParam: string,
) => {
  if (isMixpanelInitialized && user) {
    const combinedProperties = formatProperties(user, selectedTab, searchParam);
    proviewConsoleVoucherSearched(combinedProperties);
  }
};
