import React, { useState } from 'react';

import Edited from '@images/svgs/edited.svg';
import { RATING } from '@utils/data/enums';

import Flags from './Flags';
import RatingAtom from './RatingAtom';
import StatusHistory from './StatusHistory';

export interface SessionRatingHistory {
  status: RATING;
  updated_at: string;
  updated_by: string;
  description?: string;
}

interface Flags {
  critical: number;
  high: number;
  medium: number;
  low: number;
}

export interface RatingProps {
  rating?: RATING | undefined;
  statusHistory?: SessionRatingHistory[];
  flags?: Flags;
}

const Rating = ({ rating, statusHistory, flags }: RatingProps) => {
  const [showStatusHistory, setShowStatusHistory] = useState<boolean>(false);
  const toggleStatusHistory = () => {
    setShowStatusHistory(!showStatusHistory);
  };

  return (
    <div className="flex gap-2 relative pl-1 justify-center group cursor-default h-[20px]">
      <RatingAtom rating={rating} />
      {rating && flags ? (
        <Flags critical={flags.critical} high={flags.high} medium={flags.medium} low={flags.low} />
      ) : null}
      {statusHistory && statusHistory.length ? (
        <>
          <button onClick={toggleStatusHistory} onMouseLeave={showStatusHistory ? toggleStatusHistory : () => {}}>
            <img src={Edited} alt="Edited" className="h-4" />
          </button>
          {showStatusHistory && <StatusHistory statusHistory={statusHistory} />}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Rating;
