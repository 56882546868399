import { useEffect, useState } from 'react';

import { getFileUrlAction } from '@containers/app/slice';
import { FetchFileUrlResponse } from '@containers/app/types';
import { useAppDispatch } from '@utils/hooks';

const useFetchFileUrl = (profile_pic_id?: number) => {
  const dispatch = useAppDispatch();
  const [url, setUrl] = useState('');
  const [fileName, setFilelName] = useState('');

  useEffect(() => {
    if (profile_pic_id) {
      const payload = {
        queryVariables: {
          id: profile_pic_id,
        },
        callback: {
          onSuccess: (res: FetchFileUrlResponse) => {
            setUrl(() => res.resource_url);
            setFilelName(() => res.original_filename);
          },
        },
      };
      dispatch(getFileUrlAction(payload));
    }
  }, [profile_pic_id]);

  return { url, fileName };
};

export default useFetchFileUrl;
