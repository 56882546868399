import { defineMessages } from 'react-intl';

export const scope = 'app.component.Modal';

export default defineMessages({
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close',
  },
});
