import React, { useState } from 'react';

import { FilterButton } from '@components/Flows/Filters/FilterButton';
import Filters from '@components/ProviewConsoleSessions/Filters';
import { SESSION_TABS, USER_ROLE } from '@utils/data/enums';

export interface SessionFiltersProps {
  updateAppliedFilters: (filters: object) => void;
  userRole: string;
  selectedTab: string;
  examList: { label: string; value: number }[];
}

const SessionFilters = (props: SessionFiltersProps) => {
  const { updateAppliedFilters, userRole, selectedTab, examList } = props;

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const toogleFilterView = () => {
    setShowFilter((prev) => !prev);
  };

  const isShowSessionStatus = userRole === USER_ROLE.COORDINATOR && selectedTab === SESSION_TABS.ALL_SESSIONS;

  const isShowCompletedOnDate = userRole === USER_ROLE.COORDINATOR && selectedTab === SESSION_TABS.ALL_SESSIONS;

  const isShowRequestedOnDate = userRole === USER_ROLE.COORDINATOR && selectedTab === SESSION_TABS.REQUESTS;

  const showProvider = userRole === USER_ROLE.COORDINATOR && selectedTab === SESSION_TABS.REQUESTS;

  return (
    <>
      <FilterButton hasAppliedFilters={false} onClick={toogleFilterView} />
      <Filters
        updateAppliedFilters={updateAppliedFilters}
        showFilter={showFilter}
        toogleFilterView={toogleFilterView}
        isShowSessionStatus={isShowSessionStatus}
        isShowCompletedOnDate={isShowCompletedOnDate}
        isShowRequestedOnDate={isShowRequestedOnDate}
        showProvider={showProvider}
        examList={examList}
      />
    </>
  );
};

export default SessionFilters;
