import { get } from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { showWorkflowErrorToast } from '@containers/workflows/helpers';
import {
  getWtfWorkflowAction,
  getWtfWorkflowErrorAction,
  getWtfWorkflowSuccessAction,
} from '@containers/workflows/slice';
import { Workflow } from '@containers/workflows/types';
import fetchData from '@utils/fetchData';

import { GET_WTF_WORKFLOW_QUERY } from './queries';

interface GetWorkFlowListAction {
  type: string;
  payload: {
    searchConditions: object[] | object;
    limit: number;
    offset: number;
    conditions: object;
  };
}

export function* getWorkFlowListSaga(_action: GetWorkFlowListAction) {
  const { limit, offset, searchConditions, conditions } = _action.payload;
  const queryVariables = {
    limit: limit,
    offset: offset,
    searchExpression: searchConditions,
    conditions: conditions,
  };
  try {
    const response: Workflow[] = yield call(fetchData, {
      queryString: GET_WTF_WORKFLOW_QUERY,
      queryKey: 'wft_workflow',
      queryVariables: queryVariables,
      forceRefresh: true,
    });
    const aggregateResponse: {
      aggregate: { count: number };
    } = yield call(fetchData, {
      queryString: GET_WTF_WORKFLOW_QUERY,
      queryKey: 'wft_workflow_aggregate',
      queryVariables: queryVariables,
      forceRefresh: true,
    });
    const count = get(aggregateResponse, 'aggregate.count', 0);
    if (response) {
      yield put(getWtfWorkflowSuccessAction({ data: response, count: count }));
    }
  } catch (error) {
    showWorkflowErrorToast();
    yield put(getWtfWorkflowErrorAction(error as string | Error));
  }
}

export function* workFlowListSaga() {
  yield takeLatest(getWtfWorkflowAction.type, getWorkFlowListSaga);
}

export function* workFlowSaga() {
  yield all([workFlowListSaga()]);
}
