import React from 'react';

import { classNames } from '@utils/classNames';

export interface ToggleButtonProps {
  checked: boolean;
  disabled: boolean;
  name?: string;
  onClick: (checked: boolean) => void;
  focusVisible?: boolean;
}

const ToggleButton = ({ checked, disabled, name, onClick, focusVisible, ...rest }: ToggleButtonProps) => {
  const isChecked = checked;

  const handleClick = () => {
    const newChecked = !isChecked;
    if (typeof onClick === 'function') {
      onClick(newChecked);
    }
  };

  return (
    <button
      type="button"
      disabled={disabled}
      name={name}
      onClick={handleClick}
      className={classNames(
        isChecked ? 'bg-primary' : 'bg-gray-400',
        disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
        'flex items-center relative inline-flex h-4 w-7 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary',
        focusVisible && !disabled ? 'ring-2 ring-primary ring-offset-2' : '',
      )}
      role="switch"
      aria-checked={isChecked ? 'true' : 'false'}
      {...rest}
    >
      <span className="sr-only">{isChecked ? 'Toggle button checked' : 'Toggle button unchecked'}</span>
      <span
        aria-hidden="true"
        className={classNames(
          isChecked ? 'translate-x-3' : 'translate-x-0',
          'pointer-events-none inline-block h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
        )}
      ></span>
    </button>
  );
};

export default ToggleButton;
