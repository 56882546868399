import { defineMessages } from 'react-intl';

export const scope = 'app.components.flows.filters';

export default defineMessages({
  filters_button: {
    id: `${scope}.filters_button`,
    defaultMessage: 'Filters',
  },
  filter: {
    id: `${scope}.filter`,
    defaultMessage: 'Filter',
  },
  customize_view: {
    id: `${scope}.customize_view`,
    defaultMessage: 'Customize your view by selectively including or excluding table elements.',
  },
  select: {
    id: `${scope}.select`,
    defaultMessage: 'Select..',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  last_updated_by: {
    id: `${scope}.last_updated_by`,
    defaultMessage: 'Last Updated By',
  },
  last_updated_date: {
    id: `${scope}.last_updated_date`,
    defaultMessage: 'Last Updated Date',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Clear',
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Apply',
  },
});
