import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { Proctor } from '@containers/sessions/proctor_ng/types';

export type SessionState = {
  proctors: {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: Error | null;
    data: Proctor[];
  };
};

export const initialState: SessionState = {
  proctors: {
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
    data: [],
  },
};

export const sessionsSlice = createSlice({
  name: 'sessionsNg',
  initialState: initialState,
  reducers: {
    getProctorsAction: (state: SessionState) => {
      state.proctors.isLoading = true;
      state.proctors.isSuccess = false;
      state.proctors.isError = false;
      state.proctors.error = null;
    },
    getProctorsSuccessAction: (state: SessionState, { payload }: PayloadAction<Proctor[]>) => {
      state.proctors.data = payload;
      state.proctors.isLoading = false;
      state.proctors.isError = false;
      state.proctors.isSuccess = true;
    },
    getProctorsErrorAction: (state: SessionState, { payload: error }: PayloadAction<Error>) => {
      state.proctors.isLoading = false;
      state.proctors.isSuccess = false;
      state.proctors.isError = true;
      state.proctors.error = error;
    },
  },
});

export const { getProctorsAction, getProctorsSuccessAction, getProctorsErrorAction } = sessionsSlice.actions;

export default sessionsSlice.reducer;
