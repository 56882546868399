import { defineMessages } from 'react-intl';

export const scope = 'app.component.disable_flow';

export default defineMessages({
  disable_flow: {
    id: `${scope}.disable_flow`,
    defaultMessage: 'Disable flow?',
  },
  disable_flow_description: {
    id: `${scope}.disable_flow_description`,
    defaultMessage:
      'If disabled, this flow will become inactive in all exam sessions currently using it. Any ongoing sessions will switch to the default flow.',
  },
  disable_flow_note: {
    id: `${scope}.disable_flow_note`,
    defaultMessage: ' Please note: The default flow may have not certain requirements or configurations.',
  },
  save_changes: {
    id: `${scope}.save_changes`,
    defaultMessage: 'Save changes',
  },
  go_back: {
    id: `${scope}.go_back`,
    defaultMessage: 'Go back',
  },
});
