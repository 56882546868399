import React, { type KeyboardEvent, type ReactElement } from 'react';

import { FormattedMessage } from 'react-intl';

import type { CardDetailType } from '@components/Flows/FlowDetails/types';
import { classNames } from '@utils/classNames';
import { handleEnterKeyPress } from '@utils/helpers';

import messages from './messages';

export interface ConfigureCardProps {
  isSelected?: boolean;
  cardDetail: CardDetailType;
  openConfig: (type: string) => void;
  children?: ReactElement;
}
const ConfigureCard = (props: ConfigureCardProps) => {
  const { isSelected, cardDetail, children, openConfig } = props;

  const handleOpenConfigClick = () => openConfig(cardDetail.type);

  const handleOpenConfigKeyPress = (e: KeyboardEvent<HTMLDivElement>) =>
    handleEnterKeyPress(e, () => openConfig(cardDetail.type));

  return (
    <div
      data-testid="config_card"
      role="button"
      className={classNames(
        'rounded-lg shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)] w-full h-max overflow-hidden hover:border-2 hover:border-blue-700',
        isSelected ? 'border-2 border-blue-700' : 'border-2 border-transparent',
      )}
      tabIndex={0}
      onClick={handleOpenConfigClick}
      onKeyDown={handleOpenConfigKeyPress}
    >
      <div className="px-4 pt-4 pb-3 bg-gray-100 flex gap-5 h-[80px]">
        <div className="min-w-32 max-w-32 h-32">
          <img src={cardDetail.logo} alt="Configure Card icon" className="w-full h-full" />
        </div>
        <div className="flex justify-between items-start w-full pt-1">
          <div className="flex flex-col">
            <span className="text-lg text-gray-900">{cardDetail.label}</span>
            <span className="text-sm text-gray-600">{cardDetail.subText}</span>
          </div>
          <span className="text-sm text-blue-700 font-medium">
            <FormattedMessage {...messages.configure} />
          </span>
        </div>
      </div>
      <div className="min-h-[80px] pl-[164px] pt-3 bg-white">{children}</div>
    </div>
  );
};

export default ConfigureCard;
