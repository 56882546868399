import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  getProctorsAction,
  getProctorsErrorAction,
  getProctorsSuccessAction,
} from '@containers/sessions/proctor_ng/slice';
import type { Proctor } from '@containers/sessions/proctor_ng/types';
import fetchData from '@utils/fetchData';

import { GET_PROCTOR_LIST } from './queries';

export function* getProctorsList() {
  try {
    const response: Proctor[] = yield call(fetchData, {
      queryString: GET_PROCTOR_LIST,
      queryKey: 'auth_user',
      queryVariables: {},
      forceRefresh: true,
    });
    if (response) {
      yield put(getProctorsSuccessAction(response));
    }
  } catch (error) {
    yield put(getProctorsErrorAction(error as Error));
  }
}

export function* watchGetProctors() {
  yield takeLatest(getProctorsAction.type, getProctorsList);
}

export function* proctorsNgSessionsSaga() {
  yield all([watchGetProctors()]);
}
