import React, { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { useRouteError } from 'react-router-dom';

import SomethingWentWrong from '@components/Error/SomethingWentWrong';

const GlobalError = () => {
  const error = useRouteError();

  useEffect(() => {
    if (error) {
      Sentry.captureException(error);
    }
  }, [error]);

  return <SomethingWentWrong />;
};

export default GlobalError;
