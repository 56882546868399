import { PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { all, call, put, takeLatest } from 'redux-saga/effects';

import fetchData from '@utils/fetchData';
import postData from '@utils/postData';

import { showCreateVoucherErrorToast } from '.';
import { downloadRedeemedVoucherExcel, downloadVoucherExcel, formatBulkVoucherResponse, getFileName } from './helpers';
import {
  CREATE_VOUCHER_MUTATION,
  GET_REDEEMED_VOUCHER_LIST_QUERY,
  GET_SKU_LIST_QUERY,
  GET_TAG_LIST_QUERY,
  GET_VOUCHER_LIST_QUERY,
} from './queries';
import {
  createVoucherAction,
  createVoucherErrorAction,
  createVoucherSuccessAction,
  getFullRedeemedVoucherErrorAction,
  getFullRedeemedVoucherSuccessAction,
  getPayIssuedVouchersErrorAction,
  getPayIssuedVouchersSuccessAction,
  getPayTagAction,
  getPayTagErrorAction,
  getPayTagSuccessAction,
  getPayVouchersAction,
  getPayVouchersErrorAction,
  getPayVouchersSuccessAction,
  getRedeemedVoucherAction,
  getRedeemedVoucherErrorAction,
  getRedeemedVoucherSuccessAction,
  getSkuAction,
  getSkuErrorAction,
  getSkuSuccessAction,
} from './slice';
import { RedeemedVoucherList, SkuList, Voucher } from './types';

interface GetVoucherListAction {
  type: string;
  payload: {
    searchConditions: object[] | object;
    conditions: object;
    limit: number | null;
    offset: number;
    fetchCompleteList?: boolean;
    isDownloadList?: boolean;
  };
}

export type CreateVoucherPadload = {
  discount_percentage: number;
  sku_id: object[] | null;
  max_count: number;
  description: string | null;
  valid_till?: Date;
  code?: string;
};

export interface PayCreateCouponResponse {
  data: {
    pay_create_coupon: PayCouponObject;
  };
}

export interface PayCouponObject {
  valid_till: string | null;
  max_count: number;
  id: number;
  sku_coupons: {
    sku_id: number;
  }[];
  external_id: number | null;
  discount_percentage: number;
  description: string | null;
  code: string;
}

export type CreateVoucherResponse = PayCouponObject[];

export function* getVoucherListSaga(action: GetVoucherListAction) {
  const { searchConditions, conditions, limit, offset, fetchCompleteList, isDownloadList } = action.payload;
  const queryVariables = {
    ...(limit && {
      limit: limit,
    }),
    offset: offset,
    searchExpression: searchConditions,
    conditions: {
      ...conditions,
    },
  };

  try {
    const response: Voucher[] = yield call(fetchData, {
      queryString: GET_VOUCHER_LIST_QUERY,
      queryKey: 'pay_coupon',
      queryVariables: queryVariables,
      forceRefresh: true,
    });

    const aggregateResponse: {
      aggregate: { count: number };
    } = yield call(fetchData, {
      queryString: GET_VOUCHER_LIST_QUERY,
      queryKey: 'pay_coupon_aggregate',
      queryVariables: queryVariables,
      forceRefresh: true,
    });

    const count = get(aggregateResponse, 'aggregate.count', 0);
    if (response) {
      if (fetchCompleteList) {
        yield put(
          getPayIssuedVouchersSuccessAction({
            data: response,
            isDownloadList: !!isDownloadList,
          }),
        );
        if (isDownloadList) {
          const filename = getFileName('issued_coupons');
          downloadVoucherExcel(response, filename);
        }
      } else {
        yield put(getPayVouchersSuccessAction({ data: response, count: count }));
      }
    }
  } catch (error) {
    if (fetchCompleteList) {
      yield put(getPayIssuedVouchersErrorAction(error as string | Error));
    } else {
      yield put(getPayVouchersErrorAction(error as string | Error));
    }
  }
}

export function* getSkuListSaga() {
  try {
    const response: SkuList = yield call(fetchData, {
      queryString: GET_SKU_LIST_QUERY,
      queryKey: 'pay_sku',
      forceRefresh: true,
    });
    if (response) {
      yield put(getSkuSuccessAction(response));
    }
  } catch (error) {
    yield put(getSkuErrorAction(error as string | Error));
  }
}

export function* getTagListSaga() {
  try {
    const response: SkuList = yield call(fetchData, {
      queryString: GET_TAG_LIST_QUERY,
      queryKey: 'pay_tag',
      forceRefresh: true,
    });
    if (response) {
      yield put(getPayTagSuccessAction(response));
    }
  } catch (error) {
    yield put(getPayTagErrorAction(error as string | Error));
  }
}

export function* getRedeemedVoucherListSaga(action: GetVoucherListAction) {
  const { searchConditions, conditions, limit, offset, fetchCompleteList } = action.payload;
  const queryVariables = {
    limit: limit,
    offset: offset,
    searchExpression: searchConditions,
    conditions: {
      ...conditions,
    },
  };

  try {
    const response: RedeemedVoucherList[] = yield call(fetchData, {
      queryString: GET_REDEEMED_VOUCHER_LIST_QUERY,
      queryKey: 'pay_order',
      queryVariables: queryVariables,
      forceRefresh: true,
    });

    const aggregateResponse: {
      aggregate: { count: number };
    } = yield call(fetchData, {
      queryString: GET_REDEEMED_VOUCHER_LIST_QUERY,
      queryKey: 'pay_order_aggregate',
      queryVariables: queryVariables,
      forceRefresh: true,
    });

    const count = get(aggregateResponse, 'aggregate.count', 0);

    if (response) {
      if (fetchCompleteList) {
        yield put(getFullRedeemedVoucherSuccessAction({ data: response }));
        const filename = getFileName('redeemed_coupons');
        downloadRedeemedVoucherExcel(response, filename);
      } else {
        yield put(getRedeemedVoucherSuccessAction({ data: response, count: count }));
      }
    }
  } catch (error) {
    if (fetchCompleteList) {
      yield put(getFullRedeemedVoucherErrorAction(error as string | Error));
    } else {
      yield put(getRedeemedVoucherErrorAction(error as string | Error));
    }
  }
}

export function* createVouchersSaga({ payload }: PayloadAction<CreateVoucherPadload[]>) {
  try {
    const responses: PayCreateCouponResponse[] = yield all(
      payload.map((voucherPayload) =>
        call(postData, {
          queryString: CREATE_VOUCHER_MUTATION,
          payload: voucherPayload,
          spreadPayload: true,
        }),
      ),
    );

    const formatedResponse = formatBulkVoucherResponse(responses);
    if (formatedResponse) {
      yield put(createVoucherSuccessAction(formatedResponse));
    }
  } catch (error) {
    showCreateVoucherErrorToast();
    yield put(createVoucherErrorAction(error as string | Error));
  }
}

export function* redeemedVoucherListSaga() {
  yield takeLatest(getRedeemedVoucherAction.type, getRedeemedVoucherListSaga);
}

export function* voucherListSaga() {
  yield takeLatest(getPayVouchersAction.type, getVoucherListSaga);
}

export function* skuListSaga() {
  yield takeLatest(getSkuAction.type, getSkuListSaga);
}

export function* createVoucherSaga() {
  yield takeLatest(createVoucherAction.type, createVouchersSaga);
}

export function* payTagListSaga() {
  yield takeLatest(getPayTagAction.type, getTagListSaga);
}

export function* voucherSaga() {
  yield all([voucherListSaga(), skuListSaga(), redeemedVoucherListSaga(), createVoucherSaga(), payTagListSaga()]);
}
