import React, { useEffect, useMemo, useState } from 'react';

import { faCheck, faCopy, faPencil } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { Button } from '@components/Base/Button';
import { Option } from '@components/Base/GroupDropdown';
import Header from '@components/Base/Header';
import messages from '@components/Base/Header/messages';
import Tooltip from '@components/Base/Tooltip';
import BackWarningModal from '@components/Flows/FlowHeader/BackWarningModal';
import { InitialStateType } from '@containers/flowDetails/slice';
import type { Project, SelectedOption } from '@containers/projects/types';
import { STATUS } from '@utils/data/enums';

interface FlowHeaderProps {
  options: Option[];
  selectedFlow: {
    name: string;
    external_id?: string;
    status: string;
    is_default: boolean;
  };
  selectedProject?: Option;
  handleProjectChange: (project: SelectedOption) => void;
  openUpdateNameModal: () => void;
  setAsDefault: () => void;
  updateFlowState: InitialStateType['updateFlowStatus'];
  publishFlow: () => void;
  isPublishedLoading: boolean;
  isFlowChanged: boolean;
  currentProject: Project;
}

const FlowHeader = (props: FlowHeaderProps) => {
  const {
    selectedFlow,
    openUpdateNameModal,
    setAsDefault,
    updateFlowState,
    publishFlow,
    isPublishedLoading,
    isFlowChanged,
    currentProject,
  } = props;
  const [showWarning, setShowWarning] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const navigate = useNavigate();

  const handleBack = () => {
    closeModal();
    navigate(`/flows?project_uuid=${currentProject.token}`);
  };

  const handleWarningForClosing = () => {
    if (isFlowChanged) {
      setShowWarning(true);
    } else handleBack();
  };

  const closeModal = () => {
    setShowWarning(false);
  };

  const copyText = () => {
    if (selectedFlow.external_id) {
      navigator.clipboard.writeText(selectedFlow.external_id);
      setIsCopied(true);
    }
  };

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [isCopied]);

  // For creating header detail params
  const details = useMemo(
    () => ({
      label: selectedFlow.name,
      labelTooltip: selectedFlow.name,
      labelIcon: <FontAwesomeIcon icon={faPencil} className="w-4 h-4 cursor-pointer text-primary" />,
      onLabelIconClick: openUpdateNameModal,
      subText: selectedFlow.external_id,
      subTextIcon: isCopied ? (
        <Tooltip text={<FormattedMessage {...messages.copied} />} isVisible={isCopied} id={currentProject.token}>
          <FontAwesomeIcon icon={faCheck} className="w-4 h-4 text-primary" />
        </Tooltip>
      ) : (
        <FontAwesomeIcon icon={faCopy} className="w-4 h-4 cursor-pointer text-primary" />
      ),
      subTextTooltip: selectedFlow.external_id,
      onSubTextIconClick: copyText,
      handleBack: handleWarningForClosing,
      isCopied: isCopied,
    }),
    [selectedFlow, isCopied],
  );

  return (
    <>
      <Header isBackButton details={details}>
        <div className="flex items-center gap-3 grow justify-end">
          {selectedFlow.is_default ? (
            <span className="px-2.5 py-0.5 text-blue-700 bg-blue-50 text-xs font-medium">
              <FormattedMessage {...messages.default} />
            </span>
          ) : (
            <Button
              variant="ghost"
              isLoading={updateFlowState.loadingKey === 'is_default'}
              disabled={false}
              className="text-nowrap"
              onClick={setAsDefault}
            >
              <FormattedMessage {...messages.set_default} />
            </Button>
          )}
          {/* @TODO: Will be uncommented after preview implementation*/}
          {/*<Button*/}
          {/*  variant="outline"*/}
          {/*  isLoading={false}*/}
          {/*  disabled={false}*/}
          {/*  className="mr-3 ml-4"*/}
          {/*>*/}
          {/*  <div className="flex items-center text-nowrap">*/}
          {/*    <PlayCircleIcon className="w-6 h-6 mr-2" />{' '}*/}
          {/*    <FormattedMessage {...messages.preview} />*/}
          {/*  </div>*/}
          {/*</Button>*/}
          <Button
            variant="base"
            isLoading={isPublishedLoading}
            className="text-nowrap"
            onClick={publishFlow}
            disabled={selectedFlow.status === STATUS.PUBLISHED}
          >
            {selectedFlow.status === STATUS.PUBLISHED ? (
              <FormattedMessage {...messages.published} />
            ) : (
              <FormattedMessage {...messages.publish_flow} />
            )}
          </Button>
        </div>
      </Header>
      <BackWarningModal isModalOpen={showWarning} closeModal={closeModal} goBack={handleBack} />
    </>
  );
};
export default FlowHeader;
