import React from 'react';

import { FormattedMessage } from 'react-intl';

import Sparkles from '@images/svgs/sparkles.svg';
import { ALVY } from '@utils/data/enums';

import { SessionRatingHistory } from '.';
import messages from './messages';
import RatingAtom from './RatingAtom';

export type StatusHistoryProps = {
  statusHistory?: SessionRatingHistory[];
};

const StatusHistory = ({ statusHistory }: StatusHistoryProps) => {
  const formatDateHelper = (dateString: string) => {
    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    const formattedDate = date.toLocaleDateString('en-GB', options);

    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

    return `${formattedDate} at ${formattedTime}`;
  };
  return (
    <div className="z-1 -right-64 -top-4 absolute flex flex-col box-border rounded-lg border border-gray-200 shadow-[0_4px_32px_0_rgba(17,24,39,0.12)] bg-white px-3 py-2 text-wrap">
      <span className="text-[#3f3d3c] font-roboto font-medium text-sm">
        <FormattedMessage {...messages.statusHistory} />
      </span>
      <div className="flex flex-col items-center">
        {statusHistory?.map((status, index) => (
          <>
            <div key={index} className="flex justify-between py-2">
              <div className="flex w-[86px]">
                <RatingAtom rating={status.status} />
              </div>
              <div className="inline-flex flex-col font-roboto justify-end text-left w-[120px]">
                <div className="flex text-xs/[20px] text-slate-800 font-medium">
                  {status.updated_by}
                  {status.updated_by === ALVY ? (
                    <img src={Sparkles} alt="sparkles" className="w-3.5 m-0.5 ml-1" />
                  ) : null}
                </div>
                <div className="text-[10px]/[12px] text-slate-700 font-normal">
                  {formatDateHelper(status.updated_at)}
                </div>
                <div className="text-gray-500 text-[8px]">{status.description}</div>
              </div>
            </div>
            {index !== statusHistory.length - 1 && (
              <div className="border-t-[0.5px] border-[#e7e7e7] w-[180px] h-0 self-start"></div>
            )}
          </>
        ))}
      </div>
    </div>
  );
};

export default StatusHistory;
