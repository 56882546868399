import { DocumentNode, gql } from '@apollo/client';

export const processQuery = (queryString: string): DocumentNode | { errorMessage: string; error: unknown } => {
  try {
    return gql`
      ${queryString}
    `;
  } catch (error) {
    return { errorMessage: 'Invalid Query', error };
  }
};
