import React, { useState } from 'react';

import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@components/Base/Button';
import { Dropdown } from '@components/Base/Dropdown';
import Modal from '@components/Base/Modal';
import { Textarea } from '@components/Base/Textarea';
import DatePicker from '@components/Shared/DatePicker';

import messages from './messages';

interface AssignProviderProps {
  openAddFlowModal: boolean;
  handleCloseModal: () => void;
  data: { label: string; value: string }[];
  onRequestReview: (reviewRequest: { provider: string; dueDate: Date | null; requestReason?: string }) => void;
  selectedCount?: number;
  hasInProgressSession: boolean;
  headerText?: string;
  isEdit?: boolean;
}

interface Provider {
  label: string;
  value: string;
}

const AssignProvider: React.FC<AssignProviderProps> = ({
  openAddFlowModal,
  handleCloseModal,
  data,
  onRequestReview,
  selectedCount,
  hasInProgressSession,
  headerText,
  isEdit,
}) => {
  const [selectedProvider, setSelectedProvider] = useState<Provider | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [requestReason, setRequestReason] = useState<string>('');
  const intl = useIntl();

  const onDateSelect = (date: Date | null) => {
    setSelectedDate(date);
  };

  const requestReview = () => {
    if (selectedProvider && selectedDate) {
      onRequestReview({
        provider: selectedProvider.value,
        dueDate: selectedDate,
        requestReason: requestReason,
      });
      handleCloseModal();
    }
  };

  return (
    <Modal size="sm" show={openAddFlowModal} onCancel={handleCloseModal} closeOnOverlayClick={false}>
      {headerText && !selectedCount && <p className="font-semibold">{headerText}</p>}
      {selectedCount && <p className="font-semibold">{`${selectedCount} sessions selected`}</p>}
      <p className="pb-1.5 pt-3 text-sm font-normal">
        {' '}
        <FormattedMessage {...messages.assign_provider} />
      </p>
      <div className="w-full">
        <Dropdown
          id="provider"
          isMulti={false}
          value={selectedProvider}
          isSearchable={true}
          placeholder="Select provider..."
          onChange={(provider) => {
            setSelectedProvider(provider as Provider);
          }}
          options={data}
        />
        <div className="pt-4">
          <Textarea
            maxLength={100}
            value={requestReason}
            onChange={(e: { target: { value: string } }) => setRequestReason(e.target.value)}
            placeholder={intl.formatMessage(messages.reason_text_box_placeholder)}
            label={intl.formatMessage(messages.reason_text_box_header)}
            name="reasonDescription"
            className="resize-none"
          />
        </div>
        <p className="pt-4 pb-1.5 text-sm font-normal">
          <FormattedMessage {...messages.due_date} />
        </p>
        <DatePicker onChange={onDateSelect} />
      </div>
      {hasInProgressSession && (
        <div className="h-14 bg-yellow-50 w-full mt-4 rounded flex items-center">
          <FontAwesomeIcon icon={faTriangleExclamation} className="text-amber-400 pl-4" size="2xl" tabIndex={0} />
          <span className="font-normal text-sm pl-4">
            <FormattedMessage {...messages.sessions} />{' '}
            <span className="font-medium">
              <FormattedMessage {...messages.in_progress} />
            </span>
            <FormattedMessage {...messages.will_not_be_assigned} />
          </span>
        </div>
      )}
      <div className="flex justify-end pt-6">
        <Button className="ml-2 text-sm" onClick={handleCloseModal} variant="outline">
          <FormattedMessage {...messages.go_back} />
        </Button>
        <Button
          className="ml-2 text-sm"
          data-testid="request-review-button"
          onClick={requestReview}
          variant="base"
          disabled={!(selectedProvider && selectedDate)}
        >
          {isEdit ? <FormattedMessage {...messages.save_changes} /> : <FormattedMessage {...messages.request_review} />}
        </Button>
      </div>
    </Modal>
  );
};

export default AssignProvider;
