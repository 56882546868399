import { defineMessages } from 'react-intl';

export const scope = 'app.component.delete_flow';

export default defineMessages({
  modal_title: {
    id: `${scope}.modal_title`,
    defaultMessage: 'Delete Flow?',
  },
  modal_desciption: {
    id: `${scope}.modal_description`,
    defaultMessage:
      'Are you sure you want to delete this flow? Deleting the flow will remove all associated configurations and data.',
  },
  delete_button: {
    id: `${scope}.delete_button`,
    defaultMessage: 'Yes, delete',
  },
  back_button: {
    id: `${scope}.back_button`,
    defaultMessage: 'Go back',
  },
});
