import React, { useState } from 'react';

import { getDaysInMonth } from '@components/Shared/DatePicker/helper';
import { classNames } from '@utils/classNames';

export interface CalendarViewProps {
  date: Date;
  startDate: Date | null;
  endDate: Date | null;
  selectedDate: Date | null;
  onDateSelect: (date: Date) => void;
  disablePastDate?: boolean;
  isReadOnly?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disabledWeekend?: boolean;
  disabledDateFrom?: Date | null;
  isDateRangePicker?: boolean;
}

const daysOfWeek = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

const getCorrectFirstDayOfMonth = (year: number, month: number) => {
  const day = new Date(year, month, 1).getDay();
  return day === 0 ? 6 : day - 1;
};

const CalendarView: React.FC<CalendarViewProps> = ({
  date,
  startDate,
  endDate,
  onDateSelect,
  disablePastDate,
  isReadOnly,
  minDate,
  maxDate,
  disabledWeekend,
  disabledDateFrom,
  isDateRangePicker,
  selectedDate,
}) => {
  const [selectedDay, setSelectedDay] = useState<Date | null>(selectedDate);
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentMonth = currentDate.getMonth();
  const currentYear = currentDate.getFullYear();

  const isPastDate = (year: number, month: number, day: number) => {
    const date = new Date(year, month, day);
    return date < new Date(currentYear, currentMonth, currentDay);
  };

  const isBeforeMinDate = (date: Date): boolean => {
    return minDate ? date < minDate : false;
  };

  const isAfterMaxDate = (date: Date): boolean => {
    return maxDate ? date > maxDate : false;
  };

  const isWeekend = (year: number, month: number, day: number) => {
    const date = new Date(year, month, day);
    const dayOfWeek = date.getDay();
    return dayOfWeek === 6 || dayOfWeek === 0;
  };

  const handleDateClick = (day: number) => {
    const newSelectedDate = new Date(date.getFullYear(), date.getMonth(), day);
    if (
      (disablePastDate && isPastDate(date.getFullYear(), date.getMonth(), day)) ||
      (disabledDateFrom && isDateRangePicker && newSelectedDate < disabledDateFrom) ||
      isReadOnly ||
      isBeforeMinDate(newSelectedDate) ||
      isAfterMaxDate(newSelectedDate) ||
      (disabledWeekend && isWeekend(date.getFullYear(), date.getMonth(), day))
    ) {
      return;
    }
    if (!isDateRangePicker) {
      setSelectedDay(newSelectedDate);
    }

    onDateSelect(newSelectedDate);
  };

  const handleDateKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, day: number) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleDateClick(day);
    }
  };

  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDayOfMonth = getCorrectFirstDayOfMonth(year, month);
  const daysInMonth = getDaysInMonth(year, month);

  const isDateInRange = (presentDay: Date) => {
    if (startDate === null || endDate === null) {
      return false;
    }
    return startDate <= presentDay && presentDay <= endDate;
  };

  const renderDays = () => {
    const days = [];

    for (let i = 0; i < firstDayOfMonth; i++) {
      days.push(<div key={`empty-start-${i}`} className="flex items-center justify-center w-8 h-8"></div>);
    }

    for (let day = 1; day <= daysInMonth; day++) {
      const isCurrentDay = day === currentDay && month === currentMonth && year === currentYear;
      const isSelectedDay =
        selectedDay !== null &&
        day === selectedDay.getDate() &&
        month === selectedDay.getMonth() &&
        year === selectedDay.getFullYear();
      const isStartDate =
        startDate !== null &&
        day === startDate.getDate() &&
        month === startDate.getMonth() &&
        year === startDate.getFullYear();
      const isEndDate =
        endDate !== null && day === endDate.getDate() && month === endDate.getMonth() && year === endDate.getFullYear();
      const currentDate = new Date(year, month, day);
      const isDisabled =
        (disablePastDate && isPastDate(year, month, day)) ||
        (disabledDateFrom && currentDate < disabledDateFrom) ||
        isReadOnly ||
        isBeforeMinDate(currentDate) ||
        isAfterMaxDate(currentDate) ||
        (disabledWeekend && isWeekend(year, month, day));

      if (isDateRangePicker) {
        days.push(
          <div
            key={day}
            onClick={() => handleDateClick(day)}
            onKeyDown={(e) => handleDateKeyDown(e, day)}
            role={isReadOnly ? 'presentation' : 'button'}
            tabIndex={isDisabled ? -1 : 0}
            aria-label={`Day ${day}`}
            aria-disabled={isReadOnly || isDisabled}
            data-testid={isDisabled ? 'date-disabled' : 'date-enabled'}
            className={classNames(
              'flex items-center justify-center cursor-pointer text-sm w-8 h-8 rounded-full relative',
              {
                'bg-blue-700 ': isCurrentDay && isStartDate,
                'bg-blue-700/5': isCurrentDay,
                'bg-blue-700 text-white': isStartDate || isEndDate,
                'after:content-[""] after:absolute after:bottom-0 after:left-0 after:right-0 after:top-0 after:bg-blue-700 after:opacity-5 after:z-[-1]':
                  isDateInRange(currentDate),
                'hover:bg-gray-200': !isCurrentDay && !isStartDate && !isEndDate && !isReadOnly,
                'cursor-not-allowed opacity-50': isDisabled,
              },
            )}
          >
            {day}
          </div>,
        );
      } else {
        days.push(
          <div
            key={day}
            onClick={() => handleDateClick(day)}
            onKeyDown={(e) => handleDateKeyDown(e, day)}
            role={isReadOnly ? 'presentation' : 'button'}
            tabIndex={isDisabled ? -1 : 0}
            aria-label={`Day ${day}`}
            aria-disabled={isReadOnly || isDisabled}
            data-testid={isDisabled ? 'date-disabled' : 'date-enabled'}
            className={classNames('flex items-center justify-center cursor-pointer text-sm w-8 h-8 rounded-full', {
              'bg-blue-700 ': isCurrentDay && isSelectedDay,
              'bg-blue-700/5': isCurrentDay,
              'bg-blue-700 text-white': isSelectedDay,
              'hover:bg-gray-200': !isCurrentDay && !isSelectedDay && !isReadOnly,
              'cursor-not-allowed opacity-50': isDisabled,
            })}
          >
            {day}
          </div>,
        );
      }
    }

    return days;
  };

  return (
    <div className="flex flex-col w-full h-fit" data-testid="calendar-view">
      <div className="grid grid-cols-7 gap-1">
        {daysOfWeek.map((day) => (
          <div
            key={day}
            className="flex items-center justify-center text-center font-medium text-sm text-gray-500 w-8 h-8"
          >
            {day}
          </div>
        ))}
      </div>
      <div className="flex-1 mt-1">
        <div className="grid grid-cols-7 text-gray-900">{renderDays()}</div>
      </div>
    </div>
  );
};

export default CalendarView;
