import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { GetFileUrlActionPayload, UpdateUserActionPayload, UploadFileActionPayload, User } from '@containers/app/types';
import { FormattedTimeZone, TimeZone } from '@containers/user/types';
import { getDefaultLocale, getDefaultTimezone } from '@utils/helpers';

export type UsersSliceState = {
  loading: boolean;
  error: unknown | null;
  client: object;
  user: User;
  isMixpanelInitialized: boolean;
  language?: string;
  timezones: {
    selectedTimezone?: FormattedTimeZone;
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: string;
    data: TimeZone[];
  };
};

export const initialState: UsersSliceState = {
  loading: true,
  error: null,
  client: {},
  user: {
    id: null,
    currentRole: '',
    accountName: '',
    isLoggedIn: false,
    email: '',
    uid: '',
    name: '',
    username: '',
    tenant: {
      id: null,
      name: '',
      subdomain: '',
    },
    metadata: {
      creationTime: '',
      lastLoginAt: '',
      lastSignInTime: '',
      createdAt: null,
    },
    roles: [],
    url: '',
  },
  isMixpanelInitialized: false,
  language: getDefaultLocale(),
  timezones: {
    selectedTimezone: getDefaultTimezone(),
    isLoading: false,
    isSuccess: false,
    isError: false,
    data: [],
    error: '',
  },
};

export const usersSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    initClientAction: (state) => {
      state.loading = true;
    },
    initCompleteAction: (state, { payload }: PayloadAction<{ user: User }>) => {
      state.loading = false;
      // state.client = payload.client;
      state.user = payload.user;
    },
    setCurrentUserAction: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    signoutAction: (state) => {
      state.loading = true;
    },
    setGlobalErrorAction: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    setMixpanelInitialized: (state) => {
      state.isMixpanelInitialized = true;
    },
    getFileUrlAction: (state, _action: PayloadAction<GetFileUrlActionPayload>) => {
      return state;
    },
    uploadProfilePhotoAction: (state, _action: PayloadAction<UploadFileActionPayload>) => {
      return state;
    },
    updateUserAction: (state, _action: PayloadAction<UpdateUserActionPayload>) => {
      return state;
    },
    setTimezonesSuccessAction: (state, { payload }) => {
      state.timezones.isSuccess = true;
      state.timezones.data = payload;
    },
    setTimezoneAction: (state, { payload }) => {
      state.timezones.selectedTimezone = payload;
    },
  },
});

export const {
  initClientAction,
  initCompleteAction,
  setCurrentUserAction,
  signoutAction,
  setGlobalErrorAction,
  setMixpanelInitialized,
  getFileUrlAction,
  uploadProfilePhotoAction,
  updateUserAction,
  setTimezoneAction,
  setTimezonesSuccessAction,
} = usersSlice.actions;

export const selectCurrentUser = (state: UsersSliceState) => state.user;

export default usersSlice.reducer;
