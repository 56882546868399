// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.w-create-voucher-input-field {
  width: 49%;
}
`, "",{"version":3,"sources":["webpack://./app/components/CreateVoucher/styles.css"],"names":[],"mappings":"AAAA;EACE,UAAU;AACZ","sourcesContent":[".w-create-voucher-input-field {\n  width: 49%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
