import { defineMessages } from 'react-intl';

export const scope = 'app.component.add_flow';

export default defineMessages({
  add_flow: {
    id: `${scope}.add_flow`,
    defaultMessage: 'Add Flow',
  },
  create_flow: {
    id: `${scope}.create_flow`,
    defaultMessage: 'Create flow',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel',
  },
  new_flow: {
    id: `${scope}.new_flow`,
    defaultMessage: 'NEW FLOW',
  },
  use_a_template: {
    id: `${scope}.use_a_template`,
    defaultMessage: 'USE A TEMPLATE',
  },
  duplicate: {
    id: `${scope}.duplicate`,
    defaultMessage: 'DUPLICATE',
  },
  select_a_template: {
    id: `${scope}.select_a_template`,
    defaultMessage: 'Select a template',
  },
  select_template: {
    id: `${scope}.select_template`,
    defaultMessage: 'Select template',
  },
  select_flow_to_duplicate: {
    id: `${scope}.select_flow_to_duplicate`,
    defaultMessage: 'Select flow to duplicate',
  },
  name_of_flow: {
    id: `${scope}.name_of_flow`,
    defaultMessage: 'Name of flow',
  },
  select_from_existing: {
    id: `${scope}.select_from_existing`,
    defaultMessage: 'Select from existing',
  },
  unique_title: {
    id: `${scope}.unique_title`,
    defaultMessage: 'Write a unique title for your flow',
  },
  max_45_chars: {
    id: `${scope}.max_45_chars`,
    defaultMessage: 'Max 45 characters allowed.',
  },
  duplicate_title: {
    id: `${scope}.duplicate_title`,
    defaultMessage: 'Please provide a unique flow name.',
  },
});
