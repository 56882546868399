import { isEqual } from 'lodash';

import type { FlowConfig, VersionLogChange } from '@containers/flowDetails/types';
import {
  FEED_TYPE,
  KeysToAddNewKeyValues,
  VERIFICATION_TYPE,
  VERSION_LOG_KEY_VALUE_MAPTING,
  keyGroups,
} from '@utils/data/enums';

const nonRelevantKeys = [
  'updated_by',
  'updated_at',
  'created_at',
  'created_by',
  '__typename',
  'flow_id',
  'version',
  'status',
];

export const transformConfigToPayload = <T>(config: T) => {
  const payload = { ...(config as { [key: string]: string }) };
  nonRelevantKeys.forEach((key) => {
    delete payload[key];
  });
  return payload as T;
};

const excludedKeys = [
  'updated_at',
  'updated_by',
  'id',
  '__typename',
  'flow_id',
  'created_by',
  'created_at',
  'version',
  'branding_logo_file_id',
  'user_by_created_by',
  'photo_id_verification_type',
  'status',
];

const mapFlagResponse = (key: string, value: boolean | string | string[]) => {
  const conditionalMappings: Record<string, string> = {
    face_capture_verification:
      value === VERIFICATION_TYPE.VERIFY ? VERSION_LOG_KEY_VALUE_MAPTING.ENABLE : VERSION_LOG_KEY_VALUE_MAPTING.DISABLE,
    photo_id_verification:
      value === VERIFICATION_TYPE.VERIFY ? VERSION_LOG_KEY_VALUE_MAPTING.ENABLE : VERSION_LOG_KEY_VALUE_MAPTING.DISABLE,
    primary_camera_feed:
      value === FEED_TYPE.STREAM_AND_RECORD
        ? VERSION_LOG_KEY_VALUE_MAPTING.ENABLE
        : VERSION_LOG_KEY_VALUE_MAPTING.DISABLE,
    screen_share_recording:
      value === FEED_TYPE.STREAM_AND_RECORD
        ? VERSION_LOG_KEY_VALUE_MAPTING.ENABLE
        : VERSION_LOG_KEY_VALUE_MAPTING.DISABLE,
    secondary_camera_recording:
      value === FEED_TYPE.STREAM_AND_RECORD
        ? VERSION_LOG_KEY_VALUE_MAPTING.ENABLE
        : VERSION_LOG_KEY_VALUE_MAPTING.DISABLE,
    screen_feed:
      value === FEED_TYPE.DISABLE ? VERSION_LOG_KEY_VALUE_MAPTING.DISABLE : VERSION_LOG_KEY_VALUE_MAPTING.ENABLE,
    face: value === FEED_TYPE.DISABLE ? VERSION_LOG_KEY_VALUE_MAPTING.DISABLE : VERSION_LOG_KEY_VALUE_MAPTING.ENABLE,
    photo_id:
      value === FEED_TYPE.DISABLE ? VERSION_LOG_KEY_VALUE_MAPTING.DISABLE : VERSION_LOG_KEY_VALUE_MAPTING.ENABLE,
    secondary_camera_feed:
      value === FEED_TYPE.DISABLE ? VERSION_LOG_KEY_VALUE_MAPTING.DISABLE : VERSION_LOG_KEY_VALUE_MAPTING.ENABLE,
  };

  if (key in conditionalMappings) {
    return conditionalMappings[key];
  }

  if (typeof value === 'boolean') {
    return value ? VERSION_LOG_KEY_VALUE_MAPTING.ENABLE : VERSION_LOG_KEY_VALUE_MAPTING.DISABLE;
  }

  return value;
};

const processValue = (key: string, value: string | boolean | number | null) =>
  value === null || value === undefined
    ? VERSION_LOG_KEY_VALUE_MAPTING.NOT_AVAILABLE
    : mapFlagResponse(key as string, value as string | boolean | string[]);

const getKeyName = (key: string, value: string) => {
  const mapping: Record<string, Record<string, string>> = {
    screen_feed: {
      STREAM_AND_RECORD: VERSION_LOG_KEY_VALUE_MAPTING.SCREEN_SHARE_RECORDING,
      STREAM: VERSION_LOG_KEY_VALUE_MAPTING.SCREEN_SHARE_RECORDING,
    },
    photo_id: {
      VERIFY: VERSION_LOG_KEY_VALUE_MAPTING.PHOTO_ID_VERIFICATION,
      CAPTURE: VERSION_LOG_KEY_VALUE_MAPTING.PHOTO_ID_VERIFICATION,
    },
    face: {
      VERIFY: VERSION_LOG_KEY_VALUE_MAPTING.FACE_CAPTURE_VERIFICATION,
      CAPTURE: VERSION_LOG_KEY_VALUE_MAPTING.FACE_CAPTURE_VERIFICATION,
    },
    secondary_camera_feed: {
      STREAM_AND_RECORD: VERSION_LOG_KEY_VALUE_MAPTING.SECONDARY_CAMERA_RECORDING,
      STREAM: VERSION_LOG_KEY_VALUE_MAPTING.SECONDARY_CAMERA_RECORDING,
    },
  };

  return mapping[key]?.[value] ?? null;
};

const extractChanges = (config: FlowConfig) =>
  Object.entries(config).reduce(
    (changes, [key, value]) => {
      if (!excludedKeys.includes(key)) {
        const typedKey = key as keyof FlowConfig;
        changes[typedKey] = processValue(typedKey, value);
        if (KeysToAddNewKeyValues.includes(key)) {
          const newKeyName = getKeyName(key, value as string);
          newKeyName ? (changes[newKeyName] = processValue(newKeyName, value)) : null;
        }
      }
      return changes;
    },
    {} as { [key: string]: string | boolean | string[] },
  );

const findDifferences = (previous: FlowConfig, current: FlowConfig) =>
  Object.entries(current).reduce(
    (changes, [key, value]) => {
      const typedKey = key as keyof FlowConfig;
      if (!excludedKeys.includes(key) && !isEqual(value, previous[typedKey])) {
        if (key === keyGroups.photoVerification.photoIdKey || key === keyGroups.photoVerification.faceCaptureKey) {
          const isPrevVerificationDisable =
            previous[typedKey] === VERIFICATION_TYPE.DISABLE && value !== VERIFICATION_TYPE.DISABLE;
          const isPrevVerificationTypesValues =
            previous[typedKey] !== VERIFICATION_TYPE.DISABLE &&
            value !== VERIFICATION_TYPE.VERIFY &&
            value !== VERIFICATION_TYPE.CAPTURE;

          if (isPrevVerificationDisable || isPrevVerificationTypesValues) {
            changes[typedKey] = processValue(typedKey, value);
          }
        }

        if (key === keyGroups.cameraFeeds.screenFeedKey || key === keyGroups.cameraFeeds.secondaryCameraFeedKey) {
          const isPreviousFeedDisable = previous[typedKey] === FEED_TYPE.DISABLE && value !== FEED_TYPE.DISABLE;
          const isPreviousFeedValues =
            previous[typedKey] !== FEED_TYPE.DISABLE &&
            value !== FEED_TYPE.STREAM &&
            value !== FEED_TYPE.STREAM_AND_RECORD;

          if (isPreviousFeedDisable || isPreviousFeedValues) {
            changes[typedKey] = processValue(typedKey, value);
          }
        }

        if (
          key !== keyGroups.photoVerification.photoIdKey &&
          key !== keyGroups.photoVerification.faceCaptureKey &&
          key !== keyGroups.cameraFeeds.screenFeedKey &&
          key !== keyGroups.cameraFeeds.secondaryCameraFeedKey
        ) {
          changes[typedKey] = processValue(typedKey, value);
        }

        if (KeysToAddNewKeyValues.includes(typedKey)) {
          const newKeyName = getKeyName(typedKey, value as string);
          newKeyName ? (changes[newKeyName] = processValue(newKeyName, value)) : null;
        }
      }
      return changes;
    },
    {} as { [key: string]: string | boolean | string[] },
  );

const createLogEntry = (config: FlowConfig, changes: { [key: string]: string | boolean | string[] }) => ({
  version: `${VERSION_LOG_KEY_VALUE_MAPTING.VERSION} ${config.version}`,
  user: config.user_by_created_by,
  updatedAt: config.updated_at,
  id: config.id,
  status: config.status,
  changeLogs: [changes],
});

export const transformToVersionLog = (flowConfigs: FlowConfig[]): VersionLogChange[] =>
  flowConfigs.reduceRight((acc, current, index, array) => {
    const changes = index === 0 ? extractChanges(current) : findDifferences(array[index - 1], current);

    acc.push(createLogEntry(current, changes));
    return acc;
  }, [] as VersionLogChange[]);

export const validateHexColorCode = (code: string) => {
  const hexPattern = /^((?:[0-9a-fA-F]{3}){1,2})$/;
  return hexPattern.test(code);
};
