import React, { InputHTMLAttributes, ReactElement, forwardRef } from 'react';

import { classNames } from '@utils/classNames';

export interface TextboxProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | ReactElement;
  helper?: string;
  error?: string | ReactElement;
}

const Textbox = forwardRef<HTMLInputElement, TextboxProps>(
  ({ className, label, helper, error, name, disabled, required, ...props }, ref) => {
    return (
      <div className={classNames('text-gray-900', disabled && 'opacity-50 cursor-not-allowed')}>
        {label && (
          <label htmlFor={name} className="text-sm">
            {label}
            {required && <span className="pl-0.5">*</span>}
          </label>
        )}
        <input
          id={name}
          ref={ref}
          type="text"
          disabled={disabled}
          className={classNames(
            'my-1 block w-full rounded border-0 px-2 py-1.5 text-sm ring-1 ring-gray-200 placeholder:text-gray-400 focus:ring-primary sm:leading-6',
            error && 'ring-red-700 enabled:focus:ring-red-700',
            className,
          )}
          {...props}
        />
        {helper && <div className="text-xs">{helper}</div>}
        {error && (
          <div data-testid="input_error" className="text-xs text-red-700">
            {error}
          </div>
        )}
      </div>
    );
  },
);

Textbox.displayName = 'Textbox';

export { Textbox };
