import React, { useEffect, useMemo, useState } from 'react';

import uniqBy from 'lodash/uniqBy';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import Header from '@components/Base/Header';
import { triggerToast } from '@components/Base/Notification';
import SomethingWentWrong from '@components/Error/SomethingWentWrong';
import VoucherList from '@components/Vouchers';
import { User } from '@containers/app/types';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import {
  proviewConsoleVoucherCreated,
  proviewConsoleVoucherDownloadListClicked,
  proviewConsoleVoucherDownloaded,
  proviewConsoleVoucherPageViewedEvent,
} from '@utils/mixpanelActions';
import { RootState } from '@utils/store';

import {
  createVoucher,
  formatInitialFilters,
  getFormattedFilters,
  getFormattedRedeemedFilters,
  getFormattedSearchConditions,
} from './helpers';
import messages from './messages';
import { CreateVoucherPadload, CreateVoucherResponse } from './saga';
import {
  createVoucherAction,
  getPayTagAction,
  getPayVouchersAction,
  getRedeemedVoucherAction,
  getSkuAction,
  resetVoucherSuccessState,
} from './slice';
import { RedeemedVoucherList, SELECTED_TAB, SkuList, Tag, VOUCHER_CREATION_TYPE, Voucher } from './types';

export type AppliedFilters = {
  status?: string;
  selectedCreatedRange?: {
    startDate: Date;
    endDate: Date;
  };
  selectedVouchers?: { label: string; value: string }[];
  selectedExpiresDateRange?: {
    startDate: Date;
    endDate: Date;
  };
  selectedSku?: { label: string; value: string }[];
  selectedAttendee?: { label: string; value: string }[];
};

const Vouchers = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const parseInitialFilters = formatInitialFilters(searchParams);
  const filterDetails = parseInitialFilters();
  const { filters, search, tab } = filterDetails;
  const [searchTerm, setSearchTerm] = useState<string>(search);
  const [selectedTab, setSelectedTab] = useState<string>(tab);
  const [resetFilters, setResetFilters] = useState<string>('');
  const [appliedFilters, updateAppliedFilters] = useState<AppliedFilters>(filters);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [paginationRedeemed, setPaginationRedeemed] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const {
    data: vouchersData,
    isLoading,
    totalCount,
    error,
    skus: { skuList: skuData },
    issuedVouchersList,
    redeemedVouchers,
    redeemedVoucherFullList,
    newlyCreatedVouchers,
    tags: { data: tagsData },
  } = useAppSelector((state: RootState) => state.voucherList);

  const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);
  const {
    data: redeemedVouchersList,
    isLoading: loadingRedeemedVouchers,
    totalCount: redeemedVouchersCount,
  } = redeemedVouchers;
  const {
    data: createdVoucher,
    isLoading: loadingCreatedVoucher,
    isSuccess: showVoucherSuccessModal,
  } = newlyCreatedVouchers;

  const { isLoading: loadingIssuedVouchersList, voucherCodes } = issuedVouchersList;
  const { isLoading: loadingRedeemedVoucherFullList } = redeemedVoucherFullList;
  const { codes: existingVoucherCodes } = voucherCodes;

  const updateURLParams = (tab?: string) => {
    setSearchParams(
      (params) => {
        params.set('tab', tab || selectedTab);
        const filters = Object.entries(appliedFilters).reduce((acc: Record<string, string | object>, [key, value]) => {
          if (value) {
            if (
              Array.isArray(value) ? value.length > 0 : typeof value === 'object' ? Object.keys(value).length > 0 : true
            ) {
              acc[key] = value;
            }
          }
          return acc;
        }, {});

        if (Object.keys(filters).length > 0) {
          params.set('filters', JSON.stringify(filters));
        } else {
          params.delete('filters');
        }

        if (searchTerm) {
          params.set('search', searchTerm.replace(/%/g, ''));
        } else {
          params.delete('search');
        }
        return params;
      },
      { replace: true },
    );
  };

  const handleSelectedTab = (value: string) => {
    setSelectedTab(value);
    updateURLParams(value);
    setResetFilters(value);
    setSearchTerm('');
    resetAllPagination();
  };

  const vouchers = useMemo(() => {
    return vouchersData.map((voucher: Voucher) => ({
      label: voucher.code,
      value: voucher.code,
    }));
  }, [vouchersData]);

  const skusList = useMemo(() => {
    return skuData.map((sku: SkuList) => ({
      label: sku.name,
      value: sku.id,
    }));
  }, [skuData]);

  const redeemedList = useMemo(() => {
    const filteredOrders = redeemedVouchersList.filter(
      (order: RedeemedVoucherList) =>
        order.ordered_by_details &&
        (order.ordered_by_details.username || order.ordered_by_details.name || order.ordered_by_details.id),
    );

    const uniqueOrders = uniqBy(filteredOrders, (order: RedeemedVoucherList) => order.ordered_by_details?.id);

    return uniqueOrders
      .map((order) => {
        const { username, name, id } = order.ordered_by_details || {};
        return {
          label: username || name || '',
          value: id || '',
        };
      })
      .filter((order) => order.label && order.value);
  }, [redeemedVouchersList]);

  const resetVoucherState = () => {
    dispatch(resetVoucherSuccessState());
  };

  const downloadCompleteList = () => {
    if (selectedTab === SELECTED_TAB.ISSUED) {
      dispatch(
        getPayVouchersAction({
          searchConditions: searchTerm ? getFormattedSearchConditions(searchTerm, selectedTab) : {},
          conditions: getFormattedFilters(appliedFilters as AppliedFilters),
          limit: null,
          offset: 0,
          fetchCompleteList: true,
          isDownloadList: true,
        }),
      );
    } else {
      dispatch(
        getRedeemedVoucherAction({
          searchConditions: searchTerm ? getFormattedSearchConditions(searchTerm, selectedTab) : {},
          conditions: getFormattedRedeemedFilters(appliedFilters as AppliedFilters),
          limit: null,
          offset: 0,
          fetchCompleteList: true,
        }),
      );
    }
    voucherDownloadListClicked();
  };

  const fetchLatestVouchersList = () => {
    dispatch(
      getPayVouchersAction({
        searchConditions: searchTerm ? getFormattedSearchConditions(searchTerm, selectedTab) : {},
        conditions: getFormattedFilters(appliedFilters as AppliedFilters),
        limit: null,
        offset: 0,
        fetchCompleteList: true,
      }),
    );
  };

  const createVoucherDownloaded = () => {
    createVoucherDownloadedEvent(isMixpanelInitialized, user, createdVoucher);
  };

  const voucherDownloadListClicked = () => {
    voucherDownloadListClickedEvent(isMixpanelInitialized, user, selectedTab);
  };

  const handleCreateVoucher = async (createVoucherPayload: CreateVoucherPadload, voucherQuantity: string) => {
    const voucherCreationPayloads = await createVoucher(voucherQuantity, createVoucherPayload);
    dispatch(createVoucherAction(voucherCreationPayloads));
  };

  const getTagslist = () => {
    if (!tagsData.length) {
      dispatch(getPayTagAction());
    }
  };

  const resetAllPagination = () => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setPaginationRedeemed({ pageIndex: 0, pageSize: 10 });
  };

  const tagsList = useMemo(() => {
    return tagsData.map((tag: Tag) => ({
      label: tag.value,
      value: tag.id,
    }));
  }, [tagsData]);

  useEffect(() => {
    createVoucherEvent(createdVoucher, isMixpanelInitialized, user);
  }, [createdVoucher]);

  const handleVoucherDispatch = () => {
    const baseSearchConditions = getFormattedSearchConditions(searchTerm, selectedTab);
    const filters = getFormattedFilters(appliedFilters as AppliedFilters);
    if (selectedTab === SELECTED_TAB.ISSUED) {
      updateURLParams();
      dispatch(
        getPayVouchersAction({
          searchConditions: searchTerm ? baseSearchConditions : {},
          conditions: filters,
          limit: pagination.pageSize,
          offset: pagination.pageIndex * pagination.pageSize,
        }),
      );
    } else {
      const redeemedFilters = getFormattedRedeemedFilters(appliedFilters as AppliedFilters);
      updateURLParams();
      dispatch(
        getRedeemedVoucherAction({
          searchConditions: searchTerm ? baseSearchConditions : {},
          conditions: redeemedFilters,
          limit: paginationRedeemed.pageSize,
          offset: paginationRedeemed.pageIndex * paginationRedeemed.pageSize,
        }),
      );
    }
  };

  useEffect(() => {
    resetAllPagination();
    handleVoucherDispatch();
  }, [searchTerm, appliedFilters]);

  useEffect(() => {
    handleVoucherDispatch();
  }, [selectedTab, createdVoucher, pagination, paginationRedeemed]);

  useEffect(() => {
    if (!skuData.length) {
      dispatch(getSkuAction());
    }
  }, [skuData]);

  useEffect(() => {
    if (selectedTab === SELECTED_TAB.REDEEMED) {
      dispatch(
        getPayVouchersAction({
          searchConditions: {},
          conditions: {},
          limit: 10,
          offset: 0,
        }),
      );
    }
  }, [selectedTab]);

  useEffect(() => {
    if (isMixpanelInitialized && user) {
      const mixpanelProperties = getMixpanelProperties(user);
      proviewConsoleVoucherPageViewedEvent(mixpanelProperties);
    }
  }, [isMixpanelInitialized, user.tenant]);

  if (error) {
    triggerToast({
      variant: 'danger',
      setting: { position: 'top-right' },
      message: {
        title: <FormattedMessage {...messages.error_voucher_fetch_header} />,
        summary: <FormattedMessage {...messages.error_voucher_fetch_body} />,
      },
    });
    return <SomethingWentWrong />;
  }

  return (
    <>
      <Header details={{ label: <FormattedMessage {...messages.vouchers} /> }} />
      <VoucherList
        appliedFilters={appliedFilters}
        updateAppliedFilters={updateAppliedFilters}
        voucherList={vouchersData}
        isLoading={isLoading}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        vouchers={vouchers}
        selectedTab={selectedTab}
        handleSelectedTab={handleSelectedTab}
        skuList={skusList}
        redeemedVouchersList={redeemedVouchersList}
        loadingRedeemedVouchers={loadingRedeemedVouchers}
        handleCreateVoucher={handleCreateVoucher}
        loadingCreatedVoucher={loadingCreatedVoucher}
        createdVoucher={createdVoucher}
        showVoucherSuccessModal={showVoucherSuccessModal}
        resetVoucherState={resetVoucherState}
        redeemedList={redeemedList}
        resetFilters={resetFilters}
        totalCount={totalCount}
        pagination={pagination}
        setPagination={setPagination}
        redeemedVouchersCount={redeemedVouchersCount}
        paginationRedeemed={paginationRedeemed}
        setPaginationRedeemed={setPaginationRedeemed}
        createVoucherDownloaded={createVoucherDownloaded}
        voucherDownloadListClicked={voucherDownloadListClicked}
        downloadCompleteList={downloadCompleteList}
        loadingIssuedVouchersList={loadingIssuedVouchersList}
        loadingRedeemedVoucherFullList={loadingRedeemedVoucherFullList}
        getTagslist={getTagslist}
        tagsList={tagsList}
        fetchLatestVouchersList={fetchLatestVouchersList}
        existingVoucherCodes={existingVoucherCodes}
      />
    </>
  );
};

export default Vouchers;

export const showCreateVoucherErrorToast = () => {
  triggerToast({
    variant: 'danger',
    setting: { position: 'top-right' },
    message: {
      title: <FormattedMessage {...messages.voucher_creation_failed} />,
      summary: <FormattedMessage {...messages.voucher_creation_failed_description} />,
    },
  });
};

export const createVoucherMixpanelEvent = (
  isMixpanelInitialized: boolean,
  user: User,
  createdVoucher: CreateVoucherResponse | null,
) => {
  if (isMixpanelInitialized && user && createdVoucher?.length) {
    const mixpanelProperties = getMixpanelProperties(user);
    const additionalProperties = {
      module: 'Vouchers',
      'voucher type':
        createdVoucher.length > 1 ? VOUCHER_CREATION_TYPE.BULK_CODES : VOUCHER_CREATION_TYPE.STANDALONE_CODE,
      'Voucher quantity': createdVoucher.length ? createdVoucher.length : 0,
      'Redemption limit': createdVoucher[0]?.max_count,
    };
    const combinedProperties = {
      ...additionalProperties,
      ...mixpanelProperties,
      ...(createdVoucher.length === 1 && {
        'Voucher code': createdVoucher[0]?.code,
      }),
    };
    proviewConsoleVoucherCreated(combinedProperties);
  }
};

export const createVoucherDownloadedEvent = (
  isMixpanelInitialized: boolean,
  user: User,
  createdVoucher: CreateVoucherResponse | null,
) => {
  if (isMixpanelInitialized && user && createdVoucher?.length) {
    const mixpanelProperties = getMixpanelProperties(user);
    const additionalProperties = {
      module: 'Vouchers',
      'voucher type':
        createdVoucher.length > 1 ? VOUCHER_CREATION_TYPE.BULK_CODES : VOUCHER_CREATION_TYPE.STANDALONE_CODE,
      'Voucher quantity': createdVoucher.length,
      'Redemption limit': createdVoucher[0]?.max_count,
    };
    const combinedProperties = {
      ...additionalProperties,
      ...mixpanelProperties,
    };
    proviewConsoleVoucherDownloaded(combinedProperties);
  }
};

export const voucherDownloadListClickedEvent = (isMixpanelInitialized: boolean, user: User, selectedTab: string) => {
  if (isMixpanelInitialized && user) {
    const mixpanelProperties = getMixpanelProperties(user);
    const additionalProperties = {
      module: 'Vouchers',
      Tab: selectedTab,
    };
    const combinedProperties = {
      ...additionalProperties,
      ...mixpanelProperties,
    };
    proviewConsoleVoucherDownloadListClicked(combinedProperties);
  }
};

export const createVoucherEvent = (
  createdVoucher: CreateVoucherResponse | null,
  isMixpanelInitialized: boolean,
  user: User,
) => {
  if (createdVoucher?.length) {
    createVoucherMixpanelEvent(isMixpanelInitialized, user, createdVoucher);
  }
};
