import React from 'react';

import { FormattedMessage } from 'react-intl';

import Dropdown from '@components/Base/Dropdown';
import { Textbox } from '@components/Base/Textbox';
import type {
  AddFlowModalTabContent,
  SelectedOptions,
  SelectedTemplateOptions,
} from '@components/Flows/AddFlowModal/types';
import { DUPLICATE_FLOW_ERROR, MAX_45_CHARS } from '@utils/data/enums';

import duplicateFlow from '../../../../images/svgs/duplicateFlow.svg';
import newFlow from '../../../../images/svgs/newFlow.svg';
import template from '../../../../images/svgs/template.svg';
import messages from '../messages';

type GetErrorMessageProps = {
  createFlowError: string;
  isFlowNameExceedsMaxChar: boolean;
};

const getErrorMessage = (props: GetErrorMessageProps) => {
  if (props.createFlowError && props.createFlowError === DUPLICATE_FLOW_ERROR) {
    return <FormattedMessage {...messages.duplicate_title} />;
  }

  if (props.isFlowNameExceedsMaxChar) {
    return <FormattedMessage {...messages.max_45_chars} />;
  }

  return '';
};

export const flowTabs = [
  {
    id: 1,
    src: newFlow,
    name: <FormattedMessage {...messages.new_flow} />,
    content: (props: AddFlowModalTabContent) => (
      <div>
        <Textbox
          data-testid="newFlowName"
          label={<FormattedMessage {...messages.name_of_flow} />}
          required={true}
          placeholder={props.Intl.formatMessage(messages.unique_title)}
          value={props.flowName}
          onChange={(e) => props.handleflowNameChange(e)}
          maxLength={MAX_45_CHARS}
          error={getErrorMessage(props)}
        />
      </div>
    ),
  },
  {
    id: 2,
    src: template,
    name: <FormattedMessage {...messages.use_a_template} />,
    content: (props: AddFlowModalTabContent) => (
      <>
        <div className="flex-1 pb-6">
          <label className="text-gray-900 text-sm font-normal inline-block mb-2" htmlFor="templateSelect">
            <FormattedMessage {...messages.select_a_template} />
            <span className="pl-0.5">*</span>
          </label>
          <Dropdown
            id="templateSelect"
            value={props.selectedTemplate}
            isSearchable={false}
            placeholder={<FormattedMessage {...messages.select_template} />}
            onChange={(e) => {
              props.setSelectedTemplate(e as SelectedTemplateOptions);
            }}
            options={props.templateOptions}
          />
        </div>
        <Textbox
          data-testid="templateName"
          label={<FormattedMessage {...messages.name_of_flow} />}
          required={true}
          placeholder={props.Intl.formatMessage(messages.unique_title)}
          value={props.flowName}
          onChange={(e) => props.handleflowNameChange(e)}
          maxLength={MAX_45_CHARS}
          error={getErrorMessage(props)}
        />
      </>
    ),
  },
  {
    id: 3,
    src: duplicateFlow,
    name: <FormattedMessage {...messages.duplicate} />,
    content: (props: AddFlowModalTabContent) => (
      <>
        <div className="flex-1 pb-6">
          <label className="text-gray-900 text-sm font-normal inline-block mb-2" htmlFor="duplicateFlow">
            <FormattedMessage {...messages.select_flow_to_duplicate} />
            <span className="pl-0.5">*</span>
          </label>
          <Dropdown
            id="duplicateFlow"
            value={props.selectedDuplicateFlow}
            isSearchable={false}
            placeholder={<FormattedMessage {...messages.select_from_existing} />}
            onChange={(e) => {
              props.handleDuplicateChange(e as SelectedOptions);
            }}
            options={props.flowList}
          />
        </div>
        <Textbox
          data-testid="duplicateFlowName"
          label={<FormattedMessage {...messages.name_of_flow} />}
          required={true}
          placeholder={props.Intl.formatMessage(messages.unique_title)}
          value={props.flowName}
          onChange={(e) => props.handleflowNameChange(e)}
          maxLength={MAX_45_CHARS}
          error={getErrorMessage(props)}
        />
      </>
    ),
  },
];
