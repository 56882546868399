export const sessionType = {
  LIVE: 'Live',
  RECORDANDREVIEW: 'Record & review',
  RECORDED: 'Recorded',
};

export const sessionTypeList = [
  { label: 'live', value: sessionType.LIVE },
  { label: 'record&review', value: sessionType.RECORDANDREVIEW },
  { label: 'recorded', value: sessionType.RECORDED },
];

export const rating = {
  HIGH: 'High',
  MEDIUM: 'Medium',
  LOW: 'Low',
};

export const sessionRating = [
  { label: 'high', value: rating.HIGH },
  { label: 'medium', value: rating.MEDIUM },
  { label: 'low', value: rating.LOW },
];

export const status = {
  COMPLETED: 'Completed',
  IN_PROGRESS: 'In Progress',
  TERMINATED: 'Terminated',
  NO_SHOW: 'No Show',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
};

export const sessionStatus = [
  { label: status.COMPLETED, value: 'Completed' },
  { label: status.IN_PROGRESS, value: 'In Progress' },
  { label: status.TERMINATED, value: 'Terminated' },
  { label: status.NO_SHOW, value: 'No Show' },
];

export const reviewStatusList = [
  { label: 'Unassigned', value: 'Unassigned' },
  { label: 'Review Pending', value: 'Review Pending' },
  { label: 'Reviewed', value: 'Reviewed' },
];

export const tenantList = [
  {
    label: 'tenant1',
    value: 'tenant1',
  },
  {
    label: 'tenant2',
    value: 'tenant2',
  },
  {
    label: 'tenant3',
    value: 'tenant3',
  },
  {
    label: 'tenant4',
    value: 'tenant4',
  },
  {
    label: 'tenant5',
    value: 'tenant5',
  },
  {
    label: 'tenant6',
    value: 'tenant6',
  },
];

export const examList = [
  {
    label: 'exam1',
    value: 1,
  },
  {
    label: 'exam2',
    value: 2,
  },
];

export const providerList = [
  {
    label: 'provider1',
    value: 'provider1',
  },
  {
    label: 'provider2',
    value: 'provider2',
  },
  {
    label: 'provider3',
    value: 'provider3',
  },
  {
    label: 'provider4',
    value: 'provider4',
  },
  {
    label: 'provider5',
    value: 'provider5',
  },
  {
    label: 'provider6',
    value: 'provider6',
  },
];

export const proctorList = [
  {
    label: 'proctor1',
    value: 'proctor1',
  },
  {
    label: 'proctor2',
    value: 'proctor2',
  },
  {
    label: 'proctor3',
    value: 'proctor3',
  },
  {
    label: 'proctor4',
    value: 'proctor4',
  },
  {
    label: 'proctor5',
    value: 'proctor5',
  },
  {
    label: 'proctor6',
    value: 'proctor6',
  },
];

enum AccommodationType {
  EXTERNAL_HELP = 'External Help',
  REVIEWER = 'Reviewer',
  EXTRA_TIME = 'Extra Time',
  BREAKS = 'Breaks',
  REFERENCE_MATERIALS = 'Reference Materials',
  DISABILITIES = 'Disabilities',
  MEDICAL_EQUIPMENT = 'Medical Equipment',
  EXTERNAL_TOOLS = 'External Tools',
  EXAM_AREAS_ACCOMODATION = 'Exam Areas Accomodation',
}

export const accomodationList = Object.keys(AccommodationType).map((key) => ({
  label: AccommodationType[key as keyof typeof AccommodationType],
  value: key,
}));
