import React, { ChangeEvent, useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import { Checkbox } from '@components/Base/Checkbox';
import { Dropdown } from '@components/Base/Dropdown';
import messages from '@components/ProviewConsoleSessions/SessionFilters/messages';
import DateRangePicker from '@components/Shared/DateRangePicker';
import SidePanel from '@components/SidePanel';
import {
  accomodationList,
  providerList,
  sessionRating,
  sessionStatus,
  sessionTypeList,
} from '@containers/sessions/types';

export interface SessionFiltersProps {
  updateAppliedFilters: (filters: object) => void;
  isShowSessionStatus?: boolean;
  isShowCompletedOnDate?: boolean;
  isShowRequestedOnDate?: boolean;
  toogleFilterView: () => void;
  showFilter: boolean;
  showProvider?: boolean;
  examList: { label: string; value: number }[];
}

const Filters = (props: SessionFiltersProps) => {
  const {
    updateAppliedFilters,
    isShowSessionStatus,
    isShowCompletedOnDate,
    isShowRequestedOnDate,
    showFilter,
    showProvider,
    toogleFilterView,
    examList,
  } = props;

  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedSessionRating, setSelectedSessionRating] = useState<string[]>([]);

  const [sessionFilterStatus, setSessionFilterStatus] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(null);

  const [sessionExams, setSessionExams] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(null);

  const [selectedProvider, setSelectedProvider] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(null);

  const [selectTenants, setSelectTenants] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(null);

  const [reviewStatus, setReviewStatus] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(null);

  const [selectedAccomodations, setSelectedAccomodations] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(null);

  const [selectedDateRange, setSelectedDateRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  } | null>(null);

  const [selectedRequestedRange, setSelectedRequestedRange] = useState<{
    startDate: Date | null;
    endDate: Date | null;
  } | null>(null);

  const handleSessionRatingChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedSessionRating((prevState) => [
      ...new Set(e.target.checked ? [...prevState, value] : (prevState ?? []).filter((status) => status !== value)),
    ]);
  };

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedStatus((prevState) => [
      ...new Set(e.target.checked ? [...prevState, value] : (prevState ?? []).filter((status) => status !== value)),
    ]);
  };

  const handleClearFilters = () => {
    setSelectedStatus([]);
    setSessionFilterStatus(null);
    setSessionExams(null);
    setSelectTenants(null);
    setReviewStatus(null);
    updateAppliedFilters({});
    setSelectedAccomodations(null);
    setSelectedDateRange(null);
    setSelectedRequestedRange(null);
    setSelectedProvider(null);
    setSelectedSessionRating([]);
  };

  const handleApplyFilter = () => {
    toogleFilterView();
    updateAppliedFilters({
      rating: selectedSessionRating,
      status: selectedStatus,
      sessionFilterStatus: sessionFilterStatus,
      sessionExams: sessionExams,
      selectTenants: selectTenants,
      reviewStatus: reviewStatus,
      selectedAccomodations: selectedAccomodations,
      selectedDateRange: selectedDateRange,
      selectedRequestedRange: selectedRequestedRange,
      selectedProvider: selectedProvider,
    });
  };

  return (
    <>
      <SidePanel show={showFilter} containerPadding="p-0" onClose={toogleFilterView} allowCustomHeader={true}>
        <div className="flex flex-col flex-1 justify-between">
          <div>
            <div className="sticky top-0 border-b z-[1]">
              <div className="flex p-5 bg-white">
                <div className="w-[20.375rem]">
                  <div className="text-base font-semibold text-gray-900">
                    <FormattedMessage {...messages.filter} />
                  </div>
                  <div className="text-sm font-normal text-gray-600">
                    <FormattedMessage {...messages.customize_view} />
                  </div>
                </div>
                <div
                  data-testid="close_btn"
                  role="button"
                  tabIndex={-1}
                  className="px-2 text-gray-600 cursor-pointer"
                  onClick={toogleFilterView}
                  onKeyPress={toogleFilterView}
                >
                  <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                </div>
              </div>
            </div>
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.proctoring_type} />
              </div>
              <div className="flex pt-3">
                {sessionTypeList.map((list) => (
                  <div key={list.value} className="pr-4">
                    <Checkbox
                      id={list.value}
                      onChange={(e) => handleStatusChange(e, list.value)}
                      checked={!!selectedStatus.includes(list.value)}
                      disabled={false}
                      name={list.label}
                      value={list.label}
                      label={list.value}
                      size="sm"
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.exam} />
              </div>
              <div className="pt-3">
                <Dropdown
                  id="exams"
                  isMulti={true}
                  value={sessionExams}
                  isSearchable={true}
                  placeholder={<FormattedMessage {...messages.select_exam} />}
                  onChange={(exam) => setSessionExams(exam as { label: string; value: string }[])}
                  options={examList?.length ? examList : []}
                />
              </div>
            </div>
            {showProvider && (
              <div className="flex flex-col px-6 py-3 bg-white">
                <div className="text-base font-semibold text-gray-900">
                  <FormattedMessage {...messages.provider} />
                </div>
                <div className="pt-3">
                  <Dropdown
                    id="proctors"
                    isMulti={true}
                    value={selectedProvider}
                    isSearchable={true}
                    placeholder={<FormattedMessage {...messages.select_provider} />}
                    onChange={(provider) => setSelectedProvider(provider as { label: string; value: string }[])}
                    options={providerList}
                  />
                </div>
              </div>
            )}
            {isShowSessionStatus && (
              <div className="flex flex-col px-6 py-3 bg-white">
                <div className="text-base font-semibold text-gray-900">
                  <FormattedMessage {...messages.session_status} />
                </div>
                <div className="pt-3">
                  <Dropdown
                    id="session_status"
                    isMulti={true}
                    value={sessionFilterStatus}
                    isSearchable={false}
                    placeholder={<FormattedMessage {...messages.select_status} />}
                    onChange={(user) => setSessionFilterStatus(user as { label: string; value: string }[])}
                    options={sessionStatus}
                  />
                </div>
              </div>
            )}
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.accomodation} />
              </div>
              <div className="pt-3">
                <Dropdown
                  id="accomodation"
                  isMulti={true}
                  value={selectedAccomodations}
                  isSearchable={true}
                  placeholder={<FormattedMessage {...messages.select_accoummodations} />}
                  onChange={(accomodation) =>
                    setSelectedAccomodations(accomodation as { label: string; value: string }[])
                  }
                  options={accomodationList}
                />
              </div>
            </div>

            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.session_rating} />
              </div>
              <div className="flex pt-3">
                {sessionRating.map((list) => (
                  <div key={list.value} className="pr-4">
                    <Checkbox
                      id={list.value}
                      onChange={(e) => handleSessionRatingChange(e, list.value)}
                      checked={!!selectedSessionRating.includes(list.value)}
                      disabled={false}
                      name={list.label}
                      value={list.label}
                      label={list.value}
                      size="sm"
                    />
                  </div>
                ))}
              </div>
            </div>
            {isShowCompletedOnDate && (
              <div className="flex flex-col px-6 py-3 bg-white">
                <div className="text-base font-semibold text-gray-900">
                  <FormattedMessage {...messages.completed_on_date} />
                </div>
                <div className="flex pt-3">
                  <DateRangePicker onClickOnDonebtn={(range) => setSelectedDateRange(range)} />
                </div>
              </div>
            )}
            {isShowRequestedOnDate && (
              <div className="flex flex-col px-6 py-3 bg-white">
                <div className="text-base font-semibold text-gray-900">
                  <FormattedMessage {...messages.requested_on_date} />
                </div>
                <div className="flex pt-3">
                  <DateRangePicker onClickOnDonebtn={(range) => setSelectedRequestedRange(range)} />
                </div>
              </div>
            )}
          </div>

          <div className="sticky bottom-0">
            <div className="h-20 flex items-center justify-start px-6 bg-white shadow-[0px_3px_45px_0px_#00000024]">
              <Button variant="outline" onClick={handleClearFilters}>
                <FormattedMessage {...messages.clear_filters} />
              </Button>
              <div className="px-3">
                <Button onClick={handleApplyFilter}>
                  <FormattedMessage {...messages.apply} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SidePanel>
    </>
  );
};

export default Filters;
