import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { find } from 'lodash';

import { transformConfigToPayload, transformToVersionLog } from '@containers/flowDetails/helper';
import {
  type CreateFlowConfigActionPayload,
  FlowConfig,
  FlowDetailPayloadType,
  UpdateFlowConfigActionPayload,
  UpdateFlowConfigResponse,
  UpdateFlowOutputPayloadType,
  VersionLogChange,
} from '@containers/flowDetails/types';
import { STATUS, TOAST_STATE } from '@utils/data/enums';

export type InitialStateType = {
  isLoading: boolean;
  error: unknown;
  data: FlowDetailPayloadType | null;
  updateFlowStatus: { loadingKey: string; error: { message: string } | null };
  updateFlowConfigStatus: {
    isPublishedLoading: boolean;
    isConfigUpdateLoading: boolean;
    error: { message: string } | null;
  };
  versionLog: VersionLogChange[];
  createFlowConfigStatus: {
    isLoading: boolean;
    error: { message: string } | null;
    isSuccess: boolean;
  };
  triggerToast: string | null;
};

export const initialState: InitialStateType = {
  isLoading: false,
  error: null,
  data: null,
  updateFlowStatus: { loadingKey: '', error: null },
  updateFlowConfigStatus: {
    isPublishedLoading: false,
    isConfigUpdateLoading: false,
    error: null,
  },
  versionLog: [],
  createFlowConfigStatus: {
    isLoading: false,
    error: null,
    isSuccess: false,
  },
  triggerToast: '',
};

export const flowConfigSlice = createSlice({
  name: 'flowConfig',
  initialState: initialState,
  reducers: {
    getFlowConfigByIDAction: (state: InitialStateType, _: PayloadAction<{ uuid: string }>) => {
      state.isLoading = true;
      state.error = null;
    },
    getFlowConfigByIDSuccessAction: (state: InitialStateType, { payload }: PayloadAction<unknown>) => {
      const updatedFlow = payload as FlowDetailPayloadType;
      state.isLoading = false;
      state.data = {
        ...updatedFlow,
        flow_configs: [transformConfigToPayload(updatedFlow.flow_configs[updatedFlow.flow_configs.length - 1])],
      };
    },
    getFlowConfigByIDErrorAction: (state: InitialStateType, { payload: error }: PayloadAction<unknown>) => {
      state.isLoading = false;
      state.error = error;
    },
    updateFlowAction: (state: InitialStateType, _: PayloadAction<{ [key: string]: string | boolean | number }>) => {
      state.updateFlowStatus.loadingKey = Object.keys(_.payload)[0];
      state.updateFlowStatus.error = null;
    },
    updateFlowSuccessAction: (state: InitialStateType, { payload }: PayloadAction<UpdateFlowOutputPayloadType>) => {
      state.updateFlowStatus.loadingKey = '';
      state.data = { ...state.data, ...payload } as FlowDetailPayloadType;
      if (state.triggerToast === TOAST_STATE.UPDATE_FLOW_NAME_INITIATED) {
        state.triggerToast = TOAST_STATE.UPDATE_FLOW_NAME_SUCCESS;
      }
      if (state.triggerToast === TOAST_STATE.DEFAULT_FLOW_CHANGE_INITIATED) {
        state.triggerToast = TOAST_STATE.DEFAULT_FLOW_CHANGED;
      }
    },
    updateFlowErrorAction: (state: InitialStateType, { payload: error }: PayloadAction<{ message: string }>) => {
      state.updateFlowStatus.loadingKey = '';
      state.updateFlowStatus.error = error;
      if (state.triggerToast === TOAST_STATE.UPDATE_FLOW_NAME_INITIATED) {
        state.triggerToast = TOAST_STATE.UPDATE_FLOW_NAME_FAILED;
      }
      if (state.triggerToast === TOAST_STATE.DEFAULT_FLOW_CHANGE_INITIATED) {
        state.triggerToast = TOAST_STATE.DEFAULT_FLOW_UPDATE_FAILED;
      }
    },
    updateFlowConfigAction: (state: InitialStateType, action: PayloadAction<UpdateFlowConfigActionPayload>) => {
      const { payload } = action.payload;
      if (find(Object.keys(payload), (key: string) => key === 'status')) {
        state.updateFlowConfigStatus.isPublishedLoading = true;
      } else {
        state.updateFlowConfigStatus.isConfigUpdateLoading = true;
      }
      state.updateFlowStatus.error = null;
    },
    updateFlowConfigSuccessAction: (state: InitialStateType, { payload }: PayloadAction<UpdateFlowConfigResponse>) => {
      state.updateFlowConfigStatus.isPublishedLoading = false;
      state.updateFlowConfigStatus.isConfigUpdateLoading = false;
      state.data = {
        ...state.data,
        flow_configs: [transformConfigToPayload(payload)],
      } as FlowDetailPayloadType;
      if (payload.status === STATUS.PUBLISHED) {
        state.triggerToast = TOAST_STATE.PUBLISH_FLOW;
      } else {
        state.triggerToast = TOAST_STATE.FLOW_CONFIG_CHANGES_SAVED;
      }
    },
    updateFlowConfigErrorAction: (state: InitialStateType, { payload: error }: PayloadAction<{ message: string }>) => {
      state.updateFlowConfigStatus.isPublishedLoading = false;
      state.updateFlowConfigStatus.isConfigUpdateLoading = false;
      state.updateFlowConfigStatus.error = error;
      state.triggerToast = TOAST_STATE.FLOW_CONFIG_CHANGES_FAILED;
    },
    setVersionLogChanges: (state: InitialStateType, { payload }: PayloadAction<FlowConfig[]>) => {
      const versionLog = transformToVersionLog(payload as FlowConfig[]);
      state.versionLog = versionLog;
    },
    createFlowConfigAction: (state: InitialStateType, action: PayloadAction<CreateFlowConfigActionPayload>) => {
      const { payload, flowUuid } = action.payload;
      state.createFlowConfigStatus.isLoading = true;
      state.createFlowConfigStatus.isSuccess = false;
      state.createFlowConfigStatus.error = null;
      void payload;
      void flowUuid;
    },
    createFlowConfigSuccessAction: (state: InitialStateType) => {
      state.createFlowConfigStatus.isLoading = false;
      state.createFlowConfigStatus.isSuccess = true;
      state.createFlowConfigStatus.error = null;
      state.triggerToast = TOAST_STATE.FLOW_CONFIG_CHANGES_SAVED;
    },
    createFlowConfigErrorAction: (state: InitialStateType, { payload: error }: PayloadAction<{ message: string }>) => {
      state.createFlowConfigStatus.isLoading = false;
      state.createFlowConfigStatus.isSuccess = false;
      state.createFlowConfigStatus.error = error;
      state.triggerToast = TOAST_STATE.FLOW_CONFIG_CHANGES_FAILED;
    },
    resetTriggerToast: (state: InitialStateType, { payload }: PayloadAction<string | null>) => {
      state.triggerToast = payload;
    },
  },
});

export const {
  getFlowConfigByIDAction,
  getFlowConfigByIDSuccessAction,
  getFlowConfigByIDErrorAction,
  updateFlowAction,
  updateFlowSuccessAction,
  updateFlowErrorAction,
  updateFlowConfigAction,
  updateFlowConfigSuccessAction,
  updateFlowConfigErrorAction,
  setVersionLogChanges,
  createFlowConfigAction,
  createFlowConfigSuccessAction,
  createFlowConfigErrorAction,
  resetTriggerToast,
} = flowConfigSlice.actions;

export default flowConfigSlice.reducer;
