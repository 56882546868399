import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';

import messages from './messages';

type BackWarningModalType = {
  isModalOpen: boolean;
  closeModal: () => void;
  goBack: () => void;
};
const BackWarningModal = (props: BackWarningModalType) => {
  const { isModalOpen, closeModal, goBack } = props;

  return (
    <Modal size="md" show={isModalOpen} onCancel={closeModal}>
      <div className="text-xl font-semibold text-gray-900">
        <FormattedMessage {...messages.unsaved_changes} />
      </div>
      <div className="mt-3 mb-2 text-sm text-gray-900">
        <FormattedMessage {...messages.unsaved_changes_warning} />
      </div>

      <div className="flex items-center justify-end gap-3 w-full mt-6">
        <Button variant="destructive_outline" onClick={() => goBack()}>
          <FormattedMessage {...messages.discard} />
        </Button>

        <Button onClick={closeModal}>
          <FormattedMessage {...messages.keep_editing} />
        </Button>
      </div>
    </Modal>
  );
};

export default BackWarningModal;
