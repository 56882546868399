import React from 'react';

import Table from '@components/Base/Table';
import { CustomColumnDef } from '@components/Base/Table/types';
import { Workflow } from '@containers/workflows/types';

interface WorkFlowTableProps {
  wftWorkflow: Workflow[];
  columns: CustomColumnDef<Workflow>[];
  isLoading: boolean;
}

const WorkFlowTable: React.FC<WorkFlowTableProps> = (props) => {
  const { wftWorkflow, columns, isLoading } = props;
  return (
    <div className="h-full">
      <Table<Workflow>
        data={wftWorkflow}
        columns={columns}
        isMultiselect={false}
        isLoading={isLoading}
        pagination={{ pageIndex: 0, pageSize: 10 }}
        pageCount={0}
        setPagination={() => {}}
      />
    </div>
  );
};

export default WorkFlowTable;
