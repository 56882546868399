import { all, call, put, takeLatest } from 'redux-saga/effects';

import fetchData from '@utils/fetchData';

import { GET_USER_LIST_QUERY } from './queries';
import { getUserListAction, getUserListErrorAction, getUserListSuccessAction } from './slice';
import type { User } from './types';

export function* getUserListSaga() {
  try {
    const response: Array<User> = yield call(fetchData, {
      queryString: GET_USER_LIST_QUERY,
      queryKey: 'auth_user',
      forceRefresh: true,
    });
    if (response) {
      yield put(getUserListSuccessAction(response));
    }
  } catch (error) {
    yield put(getUserListErrorAction(error as string | Error));
  }
}

export function* watchGetUserList() {
  yield takeLatest(getUserListAction.type, getUserListSaga);
}

export function* userListSaga() {
  yield all([watchGetUserList()]);
}
