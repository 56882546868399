import { PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';

import { createEventChannel, init } from '@containers/app/api';
import {
  getFileUrlAction,
  initClientAction,
  initCompleteAction,
  selectCurrentUser,
  setCurrentUserAction,
  setGlobalErrorAction,
  setTimezonesSuccessAction,
  signoutAction,
  updateUserAction,
  uploadProfilePhotoAction,
} from '@containers/app/slice';
import type {
  FetchFileUrlResponse,
  GetFileUrlActionPayload,
  ROLE,
  UpdateUserActionPayload,
  UpdateUserResponse,
  UploadFileActionPayload,
  UploadFileResponse,
  User,
} from '@containers/app/types';
import { logout } from '@containers/auth/login/api';
import { redirectToAdminLogout } from '@containers/auth/login/helpers';
import Auth from '@utils/auth';
import { axiosPostData } from '@utils/axiosPostData';
import { getDisplayRole } from '@utils/data/roles';
import fetchData from '@utils/fetchData';
import { getHasuraHeaders } from '@utils/helpers';
import { getUserMasterPermissions } from '@utils/permissions/userPermission';
import postData from '@utils/postData';

import { FETCH_FILE_URL, GET_USER_PROFILE_DETAILS, UPDATE_USER, UPLOAD_FILE } from './queries';

type UserType = {
  displayName?: string;
  username?: string;
  isAnonymous?: boolean;
  id?: string;
  uid?: string;
  email?: string;
  tenantId?: string;
  getIdToken: () => Promise<string>;
};

type UserProps = {
  user: UserType;
};

// eslint-disable-next-line
export function* getUserDetailsSaga(): Generator<unknown, void, any> {
  try {
    const data = yield call(fetchData, {
      queryString: GET_USER_PROFILE_DETAILS,
      queryKey: 'auth_user_me',
      queryVariables: {},
      forceRefresh: true,
    });
    const timeZoneList = yield call(fetchData, {
      queryString: GET_USER_PROFILE_DETAILS,
      queryKey: 'md_timezone',
      queryVariables: {},
    });
    if (data && data.id) {
      yield put(setCurrentUserAction(data));
      yield call(updateStoredData, data);
      yield put(setTimezonesSuccessAction(timeZoneList));
    }
  } catch (error) {
    yield put(setGlobalErrorAction(error));
  }
}

export function updateStoredData(data: User) {
  const userDetails = {
    email: data.email,
    name: data.name,
    tenant: { id: data.tenant.id, name: data.tenant.name },
    username: data.username,
  };
  const storedData = JSON.parse(localStorage.getItem('tv.pc') || '{}');
  localStorage.setItem('tv.pc', JSON.stringify({ ...storedData, userDetails }));
}

export async function handleUser({ user }: UserProps) {
  const userPermission = getUserMasterPermissions();
  let client = {};
  let newUser = {};
  if (user) {
    const graphToken = await user.getIdToken();
    client = await Auth.createClient({
      graphToken,
      errorCallback: () => {},
      // role: get(th, '0'),
    });
    const parsedHeaders = getHasuraHeaders(graphToken);
    const parsedTenantId = get(parsedHeaders, 'X-Hasura-Tenant-Id');
    const parsedUserId = get(parsedHeaders, 'X-Hasura-User-Id');
    const roles: ROLE[] | undefined = get(parsedHeaders, 'X-Hasura-Allowed-Roles');
    newUser = {
      displayName: user.displayName,
      roles,
      currentRole: getDisplayRole(roles),
      accountName: '',
      userName: user.username,
      organization: user.tenantId || (parsedTenantId && Number(parsedTenantId)),
      isLoggedIn: !user.isAnonymous,
      email: user.email,
      uid: user.uid,
      id: user.id || (parsedUserId && Number(parsedUserId)),
      privileges: userPermission,
    };
  }
  return {
    client,
    user: newUser,
  };
}

export function* fetchFileUrlSaga({ payload }: PayloadAction<GetFileUrlActionPayload>) {
  const { queryVariables, callback } = payload;
  try {
    const response: FetchFileUrlResponse = yield call(fetchData, {
      queryString: FETCH_FILE_URL,
      queryVariables,
      queryKey: 'file_download_file',
    });
    if (response && response.resource_url && callback.onSuccess) {
      yield call(callback.onSuccess, response);
    }
  } catch (error) {
    yield put(setGlobalErrorAction(error));
  }
}

export function* updateUserSaga({ payload }: PayloadAction<UpdateUserActionPayload>) {
  const { body, callback } = payload;
  try {
    const response: UpdateUserResponse = yield call(postData, {
      queryString: UPDATE_USER,
      payload: body,
    });
    if (response && response.data) {
      yield call(getUserDetailsSaga);
      yield call(callback.onSuccess);
    }
  } catch (error) {
    yield call(callback.onError, error as { errorMessage: string });
    yield put(setGlobalErrorAction(error));
  }
}

export function* uploadFileSaga({ payload }: PayloadAction<UploadFileActionPayload>) {
  const { body, callback } = payload;
  try {
    const uploadFilePayload = {
      file_type_id: body.additionalParameter.file_type_id,
      original_name: body.file.name,
      owner_id: body.additionalParameter.owner_id,
      extension: body.file.name.split('.').pop(),
    };

    const response: UploadFileResponse = yield call(postData, {
      queryString: UPLOAD_FILE,
      payload: uploadFilePayload,
      spreadPayload: true,
    });

    if (response && response.data.file_upload_file) {
      const fileUploadData = response.data.file_upload_file.data;
      const URL = response.data.file_upload_file.data.url;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { url, ...newFileUploadData } = fileUploadData;

      const formData = new FormData();
      Object.entries(newFileUploadData).forEach(([key, value]) => value && formData.append(key, value));
      formData.append('file', body.file);

      yield call(axiosPostData, URL, formData);
      if (callback.onSuccess && response.data) {
        yield call(callback.onSuccess, response.data.file_upload_file.id);
      }
    }
  } catch (error) {
    yield call(callback.onError, error as { errorMessage: string });
    yield put(setGlobalErrorAction(error));
  }
}

// eslint-disable-next-line
export function* signoutSaga(): Generator<unknown, void, any> {
  try {
    const currentUser = yield select(selectCurrentUser);
    yield call(logout);
    redirectToAdminLogout(currentUser.tenant.id, currentUser.username, history, true);
  } catch (error) {
    yield put(setGlobalErrorAction(error));
  }
}

// eslint-disable-next-line
export function* initSaga(): Generator<unknown, void, any> {
  try {
    yield call(init);
    const channel = yield createEventChannel();
    while (true) {
      const data = yield take(channel);
      const { user } = yield call(handleUser, { user: data.user });
      yield put(
        initCompleteAction({
          user: {
            ...user,
            metadata: data?.user?.metadata,
          },
        }),
      );
      if (user && (user.email || user?.uid)) {
        yield call(getUserDetailsSaga);
      }
    }
  } catch (error) {
    yield put(setGlobalErrorAction(error));
  }
}

export function* watchGetApp() {
  yield takeLatest(initClientAction.type, initSaga);
}

export function* watchUpdateUser() {
  yield takeLatest(updateUserAction.type, updateUserSaga);
}

export function* watchSignout() {
  yield takeLatest(signoutAction.type, signoutSaga);
}

export function* watchUploadFile() {
  yield takeLatest(uploadProfilePhotoAction.type, uploadFileSaga);
}

export function* watchGetFileUrl() {
  yield takeLatest(getFileUrlAction.type, fetchFileUrlSaga);
}

export function* appSagas() {
  yield all([watchGetApp(), watchUpdateUser(), watchSignout(), watchUploadFile(), watchGetFileUrl()]);
}
