import { get, toNumber } from 'lodash';

import { FIREBASE_USER, HASURA_HEADERS } from '@containers/app/types';
import Apollo from '@utils/apollo';
import { getDisplayRole } from '@utils/data/roles';
import { parseJwt } from '@utils/helpers';

class Auth {
  expires: null | number;

  static instance: Auth;

  constructor() {
    this.expires = null;
    if (!Auth.instance) {
      Auth.instance = this;
    }
  }

  get isLoggedIn() {
    return !!this.graphToken;
  }

  get hasuraClaims(): HASURA_HEADERS | object {
    return get(this.user, 'https://hasura.io/jwt/claims', {});
  }

  get stored() {
    let st = {};
    try {
      st = JSON.parse(localStorage.getItem('tv.pc') || '{}');
    } catch (e) {
      // Sentry.captureException(e);
    }
    return st;
  }

  get graphToken(): string | null {
    return get(this.stored, 'access_token', null);
  }

  get user() {
    return get(this.stored, 'user', null);
  }

  get id() {
    const id = toNumber(get(this.stored, 'user.uid', null));
    return isNaN(id) || typeof id !== 'number' ? null : id;
  }

  get accountName() {
    return get(this.stored, 'user.organization', null);
  }

  get organization() {
    return (this.hasuraClaims as HASURA_HEADERS)['X-Hasura-Tenant-Id'] || 0;
  }

  get roles() {
    return get(this.stored, 'roles', null);
  }

  get role() {
    return get(this.stored, 'role', null);
  }

  async client() {
    return Apollo.createClient({
      // @ts-expect-error defined in processToken
      graphToken: this.graphToken,
      role: this.role,
    });
  }

  removeClient(): void {
    Apollo.removeClient();
  }

  async createClient({ errorCallback, graphToken }: { errorCallback: () => void; graphToken: string }) {
    await this.processToken(graphToken);
    const client = await Apollo.createClient({
      // @ts-expect-error defined in processToken
      graphToken: this.graphToken,
      errorCallback,
      role: get(this.roles, '0'),
    });
    return client;
  }

  async processToken(access_token: string) {
    const user = parseJwt(access_token) as FIREBASE_USER;
    const rest_token = user['rest-token'] as string | undefined;
    const roles = (user['https://hasura.io/jwt/claims'] as HASURA_HEADERS)['X-Hasura-Allowed-Roles'];
    const role = getDisplayRole(roles);
    localStorage.setItem('tv.pc', JSON.stringify({ access_token, user, rest_token, role, roles }));
    this.expires = user.exp;
    return user;
  }

  async logout() {
    localStorage.removeItem('tv.pc');
    await Apollo.clearCache();
    await Apollo.removeClient();
    return Promise.resolve();
  }
}

export const instance = new Auth();

export default instance;
