import React, { KeyboardEvent, ReactElement } from 'react';

import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { handleEnterKeyPress } from '@utils/helpers';

export interface HeaderDetailsProps {
  label?: ReactElement | string;
  labelTooltip?: string;
  labelIcon?: ReactElement;
  onLabelIconClick?: () => void;
  subText?: ReactElement | string;
  subTextIcon?: ReactElement;
  subTextTooltip?: string;
  onSubTextIconClick?: () => void;
  handleBack?: () => void;
}

export interface HeaderProps {
  isBackButton?: boolean;
  details: HeaderDetailsProps;
  children?: ReactElement;
}

const Header = (props: HeaderProps) => {
  const { isBackButton, details, children } = props;
  const {
    label,
    labelTooltip = '',
    labelIcon,
    onLabelIconClick,
    subText = '',
    subTextTooltip = '',
    subTextIcon,
    onSubTextIconClick,
    handleBack,
  } = details;

  const handleLabelIconKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>) =>
    onLabelIconClick && handleEnterKeyPress(e, onLabelIconClick);

  const handleSubtextIconKeyPress = (e: React.KeyboardEvent<HTMLButtonElement>) =>
    onSubTextIconClick && handleEnterKeyPress(e, onSubTextIconClick);

  return (
    <header className="flex items-center justify-between h-20 px-8 pt-4 pb-3 border-b border-gray-200">
      <div className="flex flex-col items-start flex-1 w-full max-w-96 ">
        <div className="flex items-center gap-3">
          {isBackButton && (
            <FontAwesomeIcon
              data-testid="back_btn"
              role="button"
              icon={faArrowLeft}
              className="w-6 h-6 cursor-pointer"
              onClick={handleBack}
              onKeyDown={(e: KeyboardEvent<SVGSVGElement>) => handleBack && handleEnterKeyPress(e, handleBack)}
              tabIndex={0}
            />
          )}
          {label && (
            <div className="flex">
              <span className="mr-2 text-xl font-semibold text-gray-900 truncate max-w-80" title={labelTooltip}>
                {label}
              </span>
              <button
                type="button"
                data-testid="label_icon"
                onClick={onLabelIconClick}
                tabIndex={0}
                onKeyDown={handleLabelIconKeyPress}
              >
                {labelIcon}
              </button>
            </div>
          )}
        </div>
        {subText && (
          <div className="flex ml-9">
            <span className="mr-2 text-sm font-normal text-gray-700 truncate max-w-80" title={subTextTooltip}>
              {subText}
            </span>
            <button
              type="button"
              data-testid="subText_icon"
              onClick={onSubTextIconClick}
              tabIndex={0}
              onKeyDown={handleSubtextIconKeyPress}
            >
              {subTextIcon}
            </button>
          </div>
        )}
      </div>
      {children}
    </header>
  );
};

export default Header;
