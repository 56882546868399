import React, { useMemo } from 'react';

import { PencilSquareIcon } from '@heroicons/react/24/outline';
import _, { isEmpty } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import EmptyMessagePage from '@components/Base/EmptyMessagePage';
import SearchInput from '@components/Base/Input';
import { CustomColumnDef } from '@components/Base/Table/types';
import { createColumns } from '@components/Exams/ProviewAssessmentTable/columns';
import { AppliedFilters } from '@containers/exams';
import { SessionData } from '@containers/exams/types';
import { classNames } from '@utils/classNames';
import ClipBoardIcon from 'app/images/clipboard.svg';
import NoResultsFound from 'app/images/svgs/noResultsFound.svg';

import ExamDetails from './ExamDetails';
import ExamFilters from './ExamsFilters';
import messages from './messages';
import ProviewAssessmentTable from './ProviewAssessmentTable';

interface AssessmentsProps {
  assessments: SessionData[];
  isLoading: boolean;
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  appliedFilters: AppliedFilters;
  updateAppliedFilters: React.Dispatch<React.SetStateAction<object>>;
}

const ProviewAssessmentList = (props: AssessmentsProps) => {
  const { assessments, isLoading, searchTerm, setSearchTerm, appliedFilters, updateAppliedFilters } = props;
  const [openPanel, setOpenPanel] = React.useState<boolean>(false);

  const isEmptyData = isEmpty(assessments) && !isLoading && !searchTerm;

  const isCustomDataAvailable = isEmpty(assessments) && searchTerm;

  const columnsIssued = useMemo(() => {
    return createColumns().filter((column) => column !== null) as CustomColumnDef<SessionData>[];
  }, []);

  const handleSearch = (value: string) => {
    setSearchTerm(value.replace(/%/g, ''));
  };

  const togglePanel = () => {
    setOpenPanel((prev) => !prev);
  };

  const applyFilters = (filters: object) => {
    updateAppliedFilters(filters);
  };

  return (
    <>
      {isEmptyData ? (
        <EmptyMessagePage
          icon={ClipBoardIcon}
          titleMessage={messages.no_assessments}
          descriptionMessage={messages.no_assessments_description}
          descriptionWidth="w-48"
        />
      ) : (
        <>
          <div className="flex w-full px-8 py-5">
            <div className="flex flex-col w-full">
              <div className="flex flex-row-reverse justify-between items-center">
                <div className="flex items-center gap-4">
                  <SearchInput
                    initialValue={searchTerm}
                    handleChange={(value) => handleSearch(value)}
                    placeholderMessageId="app.components.proview_assessment.exam_list.search_students"
                  />
                  <ExamFilters updateAppliedFilters={applyFilters} appliedFilters={appliedFilters} />
                </div>
                <div className="flex items-center gap-4">
                  <Button className="ml-2" variant="base" data-testid="create-voucher-btn">
                    <FormattedMessage {...messages.proview_assessment} />
                  </Button>
                  <PencilSquareIcon
                    onClick={togglePanel}
                    className={classNames('text-black h-5 w-5 focus:outline-none cursor-pointer')}
                    aria-hidden="true"
                    data-testid="edit-button"
                  />
                </div>
              </div>
              {isCustomDataAvailable ? (
                <EmptyMessagePage
                  icon={NoResultsFound}
                  titleMessage={messages.empty_record_title}
                  descriptionMessage={messages.empty_record_description}
                  descriptionWidth="w-48"
                />
              ) : (
                <ProviewAssessmentTable assessments={assessments} columns={columnsIssued} isLoading={isLoading} />
              )}
            </div>
          </div>
          <ExamDetails openPanel={openPanel} closePanel={togglePanel} />
        </>
      )}
    </>
  );
};

export default ProviewAssessmentList;
