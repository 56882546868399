import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';

import messages from './messages';

interface CancelProctorProps {
  openCancelProctor: boolean;
  toggleCancelProctorModal: () => void;
  handleCancelProctor: () => void;
}

const CancelProctor: React.FC<CancelProctorProps> = ({
  openCancelProctor,
  toggleCancelProctorModal,
  handleCancelProctor,
}) => {
  return (
    <Modal size="sm" show={openCancelProctor} onCancel={toggleCancelProctorModal} closeOnOverlayClick={false}>
      <p className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">
        <FormattedMessage {...messages.cancel_request} />
      </p>
      <div className="w-full">
        <p className="pt-4 pb-1.5 text-sm font-normal">
          <FormattedMessage {...messages.cancel_request_description} />
        </p>
      </div>
      <div className="flex justify-end pt-6">
        <Button className="ml-2 text-sm" variant="base" onClick={handleCancelProctor}>
          <FormattedMessage {...messages.cancel} />
        </Button>
      </div>
    </Modal>
  );
};

export default CancelProctor;
