import { defineMessages } from 'react-intl';

export const scope = 'app.component.flows.flow_list_table';

export default defineMessages({
  flow_name: {
    id: `${scope}.flow_name`,
    defaultMessage: 'Flow Name',
  },
  last_updated: {
    id: `${scope}.last_updated`,
    defaultMessage: 'Last Updated',
  },
  version: {
    id: `${scope}.version`,
    defaultMessage: 'Version',
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status',
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Active',
  },
  inactive: {
    id: `${scope}.inactive`,
    defaultMessage: 'Inactive',
  },
  default: {
    id: `${scope}.default`,
    defaultMessage: 'Default',
  },
});
