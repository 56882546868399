// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.attendee-position-left {
  left: 0 !important;
}

.z-index-one {
  z-index: 1;
}

.menu-list-height {
  height: 40px;
}

.menu-list-width {
  width : 214px;
}

.menu-list-max-height {
  max-height: 480px;
}
`, "",{"version":3,"sources":["webpack://./app/components/Exams/ProviewAssessmentTable/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".attendee-position-left {\n  left: 0 !important;\n}\n\n.z-index-one {\n  z-index: 1;\n}\n\n.menu-list-height {\n  height: 40px;\n}\n\n.menu-list-width {\n  width : 214px;\n}\n\n.menu-list-max-height {\n  max-height: 480px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
