import React, { useEffect, useMemo, useRef, useState } from 'react';

import { faDesktop, faImage, faUserGroup } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isEqual } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Accordion } from '@components/Base/Accordion';
import { Button } from '@components/Base/Button';
import ToggleButton from '@components/Base/ToggleButton';
import BrowserConfig from '@components/Flows/ConfigPanels/AttendeeOnboardingPanel/BrowserConfig';
import CameraRecordings from '@components/Flows/ConfigPanels/AttendeeOnboardingPanel/CameraRecordings';
import IdentityVerification from '@components/Flows/ConfigPanels/AttendeeOnboardingPanel/IdentityVerification';
import messages from '@components/Flows/ConfigPanels/messages';
import PanelHeader from '@components/Flows/ConfigPanels/PanelHeader';
import configCardMessages from '@components/Flows/ConfigureCard/messages';
import { InitialStateType } from '@containers/flowDetails/slice';
import type { AttendeeOnboardingConfigType, BlackListApps } from '@containers/flowDetails/types';
import { FEED_TYPE, VERIFICATION_TYPE } from '@utils/data/enums';

import LockIcon from '../../../../images/svgs/browserConfig.svg';

type AttendeeOnboardingProps = {
  config: AttendeeOnboardingConfigType;
  updatedConfig: AttendeeOnboardingConfigType;
  setDefaultPanelType: () => void;
  saveConfig: () => void;
  updateFeedbackChange: (obj: { [key: string]: boolean | string | null | BlackListApps }) => void;
  updateFlowConfigStatus: InitialStateType['updateFlowConfigStatus'];
  createFlowConfigStatus: InitialStateType['createFlowConfigStatus'];
};

type WrapperHeightsType = {
  browser_config: number;
  camera_and_recording: number;
  identity_verification: number;
};

const AttendeeOnboardingPanel = (props: AttendeeOnboardingProps) => {
  const {
    config,
    updatedConfig,
    saveConfig,
    setDefaultPanelType,
    updateFeedbackChange,
    updateFlowConfigStatus,
    createFlowConfigStatus,
  } = props;
  const [wrapperHeights, setWrapperHeights] = useState<WrapperHeightsType>({
    browser_config: 0,
    camera_and_recording: 0,
    identity_verification: 0,
  });

  const browserConfigRef = useRef<HTMLDivElement>(null);
  const cameraRecordingsRef = useRef<HTMLDivElement>(null);
  const identityVerificationRef = useRef<HTMLDivElement>(null);

  // for browser config dynamic height handling
  const handleBrowserConfigHeightUpdate = () => {
    if (updatedConfig.browser_sb_desktop && !config.browser_sb_desktop) {
      if (browserConfigRef && browserConfigRef.current) {
        setWrapperHeights({
          ...wrapperHeights,
          browser_config: browserConfigRef.current.clientHeight,
        });
      }
    } else {
      setWrapperHeights({
        ...wrapperHeights,
        browser_config: 0,
      });
    }
  };

  // for browser config dynamic height handling
  useEffect(() => {
    handleBrowserConfigHeightUpdate();
  }, [updatedConfig.browser_sb_desktop]);

  const handleCameraRecordingHeightUpdate = () => {
    if (
      (updatedConfig.screen_feed === FEED_TYPE.STREAM && config.screen_feed === FEED_TYPE.DISABLE) ||
      (updatedConfig.secondary_camera_feed === FEED_TYPE.STREAM && config.secondary_camera_feed === FEED_TYPE.DISABLE)
    ) {
      if (cameraRecordingsRef && cameraRecordingsRef.current) {
        setWrapperHeights({
          ...wrapperHeights,
          camera_and_recording: cameraRecordingsRef.current.clientHeight,
        });
      }
    } else {
      setWrapperHeights({
        ...wrapperHeights,
        camera_and_recording: 0,
      });
    }
  };

  // for camera and recording dynamic height handling
  useEffect(() => {
    handleCameraRecordingHeightUpdate();
  }, [updatedConfig.screen_feed, updatedConfig.secondary_camera_feed]);

  const handleIVHeightUpdate = () => {
    if (
      (updatedConfig.face === VERIFICATION_TYPE.CAPTURE && config.face === VERIFICATION_TYPE.DISABLE) ||
      (updatedConfig.photo_id === VERIFICATION_TYPE.CAPTURE && config.photo_id === VERIFICATION_TYPE.DISABLE)
    ) {
      if (identityVerificationRef && identityVerificationRef.current) {
        setWrapperHeights({
          ...wrapperHeights,
          identity_verification: identityVerificationRef.current.clientHeight,
        });
      }
    } else {
      setWrapperHeights({
        ...wrapperHeights,
        identity_verification: 0,
      });
    }
  };

  // for identity verification dynamic height handling
  useEffect(() => {
    handleIVHeightUpdate();
  }, [updatedConfig.face, updatedConfig.photo_id]);

  const browserConfig = useMemo(
    () => ({
      browser_chrome_desktop: updatedConfig.browser_chrome_desktop,
      browser_chrome_mobile: updatedConfig.browser_chrome_mobile,
      browser_edge_desktop: updatedConfig.browser_edge_desktop,
      browser_edge_mobile: updatedConfig.browser_edge_mobile,
      browser_firefox_desktop: updatedConfig.browser_firefox_desktop,
      browser_firefox_mobile: updatedConfig.browser_firefox_mobile,
      browser_safari_mobile: updatedConfig.browser_safari_mobile,
      browser_sb_desktop: updatedConfig.browser_sb_desktop,
      sb_blacklisted_apps: updatedConfig.sb_blacklisted_apps,
      sb_content_protection: updatedConfig.sb_content_protection,
      sb_kiosk_mode: updatedConfig.sb_kiosk_mode,
    }),
    [updatedConfig],
  );

  const cameraRecordingsConfig = useMemo(
    () => ({
      screen_feed: updatedConfig.screen_feed,
      primary_camera_feed: updatedConfig.primary_camera_feed,
      secondary_camera_feed: updatedConfig.secondary_camera_feed,
    }),
    [updatedConfig],
  );

  const identityVerificationConfig = useMemo(
    () => ({
      face: updatedConfig.face,
      photo_id: updatedConfig.photo_id,
    }),
    [updatedConfig],
  );

  return (
    <>
      <PanelHeader
        label={<FormattedMessage {...configCardMessages.ab_label} />}
        description={<FormattedMessage {...configCardMessages.ab_description_panel} />}
        setDefaultPanelType={setDefaultPanelType}
      />
      <div className="flex flex-col h-full gap-4 p-5 overflow-auto bg-white">
        <Accordion
          title={<FormattedMessage {...configCardMessages.browser_config} />}
          text={<FormattedMessage {...messages.browser_config_description} />}
          icon={<img src={LockIcon} alt="Browser config" className="w-6 h-6 text-blue-700" />}
          isOpened={false}
          parentRef={browserConfigRef}
          contentWrapperHeight={wrapperHeights.browser_config}
        >
          <BrowserConfig config={browserConfig} updateConfig={updateFeedbackChange} />
        </Accordion>
        <Accordion
          title={<FormattedMessage {...configCardMessages.camera_recordings} />}
          text={<FormattedMessage {...messages.camera_recordings_description} />}
          icon={<FontAwesomeIcon icon={faDesktop} className="w-6 h-6 text-blue-700" />}
          isOpened={false}
          parentRef={cameraRecordingsRef}
          contentWrapperHeight={wrapperHeights.camera_and_recording}
        >
          <CameraRecordings config={cameraRecordingsConfig} updateConfig={updateFeedbackChange} />
        </Accordion>
        <Accordion
          title={<FormattedMessage {...messages.identity_verification} />}
          text={<FormattedMessage {...messages.identity_verification_description} />}
          icon={<FontAwesomeIcon icon={faUserGroup} className="w-6 h-6 text-blue-700" />}
          parentRef={identityVerificationRef}
          contentWrapperHeight={wrapperHeights.identity_verification}
          isOpened={false}
        >
          <IdentityVerification config={identityVerificationConfig} updateConfig={updateFeedbackChange} />
        </Accordion>
        <div className="flex flex-col gap-4 px-4 py-3 mb-4 bg-white rounded-md shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)]">
          <div className="flex items-center">
            <div className="bg-gray-100 px-[5px] py-4 flex justify-center items-center rounded">
              <FontAwesomeIcon icon={faImage} className="w-6 h-6 text-blue-700" />
            </div>
            <div className="flex flex-col ml-2 mr-3">
              <span className="text-base font-semibold text-gray-900">
                <FormattedMessage {...configCardMessages.environment_test} />{' '}
              </span>
              <span className="text-xs text-gray-600">
                <FormattedMessage {...messages.environment_test_description} />
              </span>
            </div>
            <ToggleButton
              data-testid="environment_test_switch"
              checked={updatedConfig.environment_test as boolean}
              onClick={(checked: boolean) => {
                updateFeedbackChange({ environment_test: checked });
              }}
              disabled={false}
            />
          </div>
        </div>
      </div>
      <div className="w-full px-5 py-4 mt-auto border-t border-gray-200 ">
        <Button
          isLoading={updateFlowConfigStatus.isConfigUpdateLoading || createFlowConfigStatus.isLoading}
          data-testid="save_button"
          className="w-full text-sm"
          disabled={updateFlowConfigStatus.isConfigUpdateLoading || isEqual(config, updatedConfig)}
          tabIndex={0}
          onClick={saveConfig}
        >
          <FormattedMessage {...messages.save_changes} />
        </Button>
      </div>
    </>
  );
};

export default AttendeeOnboardingPanel;
