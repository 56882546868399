import React, { useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import Select, {
  CSSObjectWithLabel,
  GroupBase,
  PlaceholderProps,
  SingleValue,
  StylesConfig,
  ValueContainerProps,
  components,
} from 'react-select';

import { isTextTruncated } from '@utils/helpers';

export type Option = {
  label: string;
  options?: Array<{
    label: string;
    value: string;
    details: { id: string; location: string };
    disabled: boolean;
  }>;
  value?: string | number;
  clickOnly?: boolean;
  details?: { id: string; location: string };
};
const formatOptionLabels = ({
  label,
  disabled,
  details,
}: {
  label: string;
  disabled?: boolean;
  details?: { id: string; location: string };
}) => {
  const labelStyle = disabled ? 'text-gray-500' : 'text-gray-900';
  return (
    <div className="text-sm">
      <div className={`font-normal truncate ${labelStyle}`}>{label}</div>
      {details && (
        <div>
          <div className="text-xs text-gray-500">
            {details.id} | {details.location}
          </div>
        </div>
      )}
    </div>
  );
};

export interface ProjectDropdownProps {
  options: Array<Option>;
  onChange: (
    newValue: SingleValue<Option>,
    // actionMeta: ActionMeta<Option>
  ) => void;
  selectedValue?: Option;
}

export const reactSelectDefaultStyles: StylesConfig<Option, boolean, GroupBase<Option>> = {
  placeholder: (provided) => ({
    ...provided,
    paddingLeft: '6px',
    cursor: 'pointer',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 99,
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#F3F4F6' : state.isFocused ? '#F3F4F6' : 'white',
    color: '#111827',
    '&:hover': {
      backgroundColor: '#F3F4F6',
    },
  }),
  group: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
    cursor: 'pointer',
  }),
  control: (props: CSSObjectWithLabel) => ({
    ...props,
    boxShadow: 'none',
    fontSize: '0.875rem',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#6B7280',
    ':hover': {
      color: '#6B7280',
      cursor: 'pointer',
    },
  }),
  groupHeading: (provided) => ({
    ...provided,
    '&:before': {
      content: "''",
      display: 'block',
      paddingTop: '8px',
      borderTop: '1px solid #E5E7EB',
      cursor: 'pointer',
    },
  }),
};

function GroupDropdown({ options, onChange, selectedValue }: ProjectDropdownProps) {
  const [selectedOption, setSelectedOption] = useState<Option | undefined>();
  const [isTruncated, setIsTruncated] = useState(false);
  const labelRef = useRef<HTMLDivElement | null>(null);
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  useEffect(() => {
    if (labelRef.current) {
      setIsTruncated(isTextTruncated(labelRef.current));
    }
  }, [selectedOption]);

  useEffect(() => {
    selectedValue && setSelectedOption(selectedValue);
  }, [selectedValue]);

  const handleChange = (selectedOption: Option) => {
    onChange(selectedOption);
    !selectedOption.clickOnly && setSelectedOption(selectedOption);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setMenuIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const ValueContainer:
    | React.ComponentType<
        ValueContainerProps<
          {
            label: string;
            disabled?: boolean | undefined;
            details?:
              | {
                  id: string;
                  location: string;
                }
              | undefined;
          },
          false,
          GroupBase<{
            label: string;
            disabled?: boolean | undefined;
            details?:
              | {
                  id: string;
                  location: string;
                }
              | undefined;
          }>
        >
      >
    | undefined = ({ children, ...props }: { children: React.ReactNode; hasValue: boolean }) => {
    const { hasValue } = props as { hasValue: boolean };
    if (!hasValue) {
      return <>{children}</>;
    }

    return (
      <div className="flex-1 p-2 px-3">
        <div className="text-xs text-gray-500">CURRENT PROJECT</div>
        <div
          className="text-sm text-gray-900 truncate w-60"
          title={isTruncated && selectedOption ? selectedOption.label : undefined}
          ref={labelRef}
        >
          {selectedOption && selectedOption.label}
        </div>
      </div>
    );
  };

  const Placeholder = (props: PlaceholderProps<Option>) => {
    return (
      <components.Placeholder {...props}>
        <div className="p-1 pt-3 text-xs text-gray-500 ">CURRENT PROJECT</div>
        <div className="pb-1 pl-1 text-sm text-gray-900">-</div>
      </components.Placeholder>
    );
  };

  return (
    <div
      ref={dropdownRef}
      onClick={toggleDropdown}
      onKeyDown={toggleDropdown}
      tabIndex={0}
      className="cursor-pointer"
      role="button"
      aria-label="Toggle Dropdown"
    >
      <Select
        isSearchable={false}
        value={selectedOption}
        options={options}
        menuIsOpen={menuIsOpen}
        components={{
          ValueContainer,
          Placeholder,
        }}
        formatOptionLabel={formatOptionLabels}
        styles={{ ...reactSelectDefaultStyles }}
        placeholder="CURRENT PROJECT"
        classNames={{
          control: ({ isFocused }) => classNames('border rounded !shadow-0 !bg-white', isFocused && '!border-gray-200'),
        }}
        menuPlacement="auto"
        onChange={(value) => handleChange(value as Option)}
      />
    </div>
  );
}

export default GroupDropdown;
