import React, { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';
import { isEmpty } from 'lodash';
import mixpanel from 'mixpanel-browser';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { setMixpanelInitialized } from '@containers/app/slice';
import { ROLE } from '@containers/app/types';
import { getUser } from '@containers/auth/login/api';
import { loginRedirect } from '@containers/auth/login/helpers';
import { getProjectsAction, setCurrentProject } from '@containers/projects/slice';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import { userLoggedInEvent } from '@utils/mixpanelActions';

const Login = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const accessToken = queryParams.get('accessToken');

  const { data: projects } = useAppSelector((state) => state.projects);
  const { user: userData } = useAppSelector((state) => state.app);
  const [enableMixPanel, setEnableMixPanel] = useState(false);
  const user = getUser();

  const routeWithUuid = ['/projects', '/flows'];

  const isUuidRequired = () => {
    return routeWithUuid.includes(location.pathname);
  };

  const checkLogin = async () => {
    let path = '';
    if (!accessToken && user && !user.isAnonymous) {
      if (userData.currentRole === ROLE.PROCTOR_ADMIN || userData.currentRole === ROLE.PROCTOR) {
        path = '/projects';
      }
      if (userData.currentRole === ROLE.COORDINATOR || userData.currentRole === ROLE.TENANT_ADMIN) {
        if (isEmpty(params) && !searchParams.get('project_uuid') && isUuidRequired()) {
          path = projects[0] ? `/projects/${projects[0].token}` : '/projects';
          if (projects[0]) {
            dispatch(setCurrentProject(projects[0]));
          }
        } else {
          if (userData.currentRole != ROLE.TENANT_ADMIN) {
            let pathname;
            if (location.pathname === '/') {
              pathname = '/workFlows';
            }
            path = pathname || location.pathname;
          } else {
            path = projects[0] ? `/projects/${projects[0].token}` : '/projects';
          }
        }
      }
      setEnableMixPanel(true);
      navigate(path, { replace: true });
    } else if (!accessToken) {
      loginRedirect({ location });
    }
  };

  useEffect(() => {
    if (enableMixPanel && user && !user.isAnonymous) {
      const mixpanelToken = process.env.MIXPANEL_PROJECT_TOKEN;
      try {
        mixpanel.init(mixpanelToken as string, {
          ignore_dnt: true,
          debug: true,
          opt_out_tracking_by_default: !enableMixPanel,
        });
        mixpanel.identify(user.uid);
        if (userData.tenant) {
          dispatch(setMixpanelInitialized());
          const mixpanelProperties = getMixpanelProperties(userData);
          userLoggedInEvent(mixpanelProperties);
        }
      } catch (error) {
        Sentry.captureException(error);
      }
    }
  }, [enableMixPanel, user, userData.tenant]);

  useEffect(() => {
    checkLogin();
  }, [projects]);

  useEffect(() => {
    if (user && !user.isAnonymous && userData.currentRole) {
      (userData.currentRole === ROLE.COORDINATOR || userData.currentRole === ROLE.TENANT_ADMIN) &&
        dispatch(getProjectsAction());
    }
  }, [user, dispatch, userData]);

  return <Outlet />;
};

export default Login;
