import React from 'react';

interface ProgressBarProps {
  progress: number;
  barColor: string;
  color: string;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress, color, barColor }) => {
  return (
    <div id="progress-bar" className={`relative rounded-full w-full overflow-hidden h-full ${color}`}>
      <div
        className={`absolute top-0 left-0 h-full ${barColor} transition-width`}
        style={{ width: `${progress}%`, transition: 'width 0.5s ease-in-out' }}
      />
    </div>
  );
};

export default ProgressBar;
