import React, { FC, useEffect, useRef, useState } from 'react';

import { createPortal } from 'react-dom';

type TooltipProps = {
  refDiv: React.ReactNode;
  children: React.ReactNode;
  position?: 'top-middle' | 'top-left' | 'top-right' | 'bottom-middle' | 'bottom-left' | 'bottom-right';
  isVisible?: boolean;
};

const Tooltip: FC<TooltipProps> = ({ refDiv, children, position, isVisible }) => {
  const absoluteDivRef = useRef<HTMLDivElement>(null);
  const referenceDivRef = useRef<HTMLDivElement>(null);
  const [showToolTip, setShowTooltip] = useState(isVisible);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const [arrowPosition, setArrowPosition] = useState({});

  useEffect(() => {
    const element = referenceDivRef?.current;

    if (element) {
      element.addEventListener('mouseenter', onMouseEnter);
      element.addEventListener('mouseleave', onMouseLeave);
    }

    return () => {
      if (element) {
        element.removeEventListener('mouseenter', onMouseEnter);
        element.removeEventListener('mouseleave', onMouseLeave);
      }
    };
  }, [referenceDivRef]);

  const onMouseEnter = () => {
    setShowTooltip(true);
  };

  useEffect(() => {
    if (showToolTip) {
      const rect = referenceDivRef?.current?.getBoundingClientRect();
      const tooltipRect = absoluteDivRef?.current?.getBoundingClientRect();
      if (rect) {
        switch (position) {
          case 'bottom-left':
          case 'bottom-right':
          case 'bottom-middle':
            setArrowPosition({
              top: `${rect.top + rect.height}px`,
              left: `${rect.left + rect.width / 2 - 5.5}px`,
            });
            break;
          case 'top-left':
          case 'top-right':
          case 'top-middle':
          default:
            setArrowPosition({
              top: `${rect.top - 10}px`,
              left: `${rect.left + rect.width / 2 - 5.5}px`,
              rotate: '180deg',
            });
        }
      }
      if (rect && tooltipRect) {
        switch (position) {
          case 'top-left':
            setTooltipPosition({
              top: `${rect.top - tooltipRect.height - 10}px`,
              left: `${rect.left + rect.width / 2 - (tooltipRect.width * 1) / 3}px`,
            });
            break;
          case 'top-right':
            setTooltipPosition({
              top: `${rect.top - tooltipRect.height - 10}px`,
              left: `${rect.left + rect.width / 2 - (tooltipRect.width * 2) / 3}px`,
            });
            break;
          case 'bottom-left':
            setTooltipPosition({
              top: `${rect.top + rect.height + 5}px`,
              left: `${rect.left + rect.width / 2 - (tooltipRect.width * 1) / 3}px`,
            });
            break;
          case 'bottom-right':
            setTooltipPosition({
              top: `${rect.top + rect.height + 5}px`,
              left: `${rect.left + rect.width / 2 - (tooltipRect.width * 2) / 3}px`,
            });
            break;
          case 'bottom-middle':
            setTooltipPosition({
              top: `${rect.top + rect.height + 5}px`,
              left: `${rect.left + rect.width / 2 - tooltipRect.width / 2}px`,
            });
            break;
          case 'top-middle':
          default:
            setTooltipPosition({
              top: `${rect.top - tooltipRect.height - 10}px`,
              left: `${rect.left + rect.width / 2 - tooltipRect.width / 2}px`,
            });
        }
      }
    }
  }, [showToolTip]);

  const onMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <>
      <div ref={referenceDivRef} className="w-fit">
        {refDiv}
      </div>
      {showToolTip &&
        createPortal(
          <div data-testid="tooltip">
            <span
              ref={absoluteDivRef}
              className="fixed z-10 w-fit p-2.5 text-xs leading-none text-white whitespace-nowrap bg-[#152029]/[0.9] rounded shadow-lg"
              style={{ ...tooltipPosition }}
            >
              {children}
            </span>
            <div
              className="fixed z-10 w-[11px] h-[5px] bg-[#152029]/[0.9]"
              style={{
                ...arrowPosition,
                clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
              }}
            />
          </div>,
          document.body,
        )}
    </>
  );
};

export default Tooltip;
