import React, { useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import Header from '@components/Base/Header';
import ProviewAssessmentList from '@components/Exams';
import messages from '@containers/exams/messages';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import { RootState } from '@utils/store';

import { getExamsAction } from './slice';

export type AppliedFilters = {
  selectedDateRange?: { startDate: Date | null; endDate: Date | null } | null;
  selectedAttendee?: { label: string; value: string }[];
  selectedSessionRating?: string[];
  selectedAccomodations?: { label: string; value: string }[];
  selectedStatus?: { label: string; value: string }[];
};

const ProviewAssessment = () => {
  const dispatch = useAppDispatch();
  const { data: examsList, isLoading } = useAppSelector((state: RootState) => state.proviewExamsList);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [appliedFilters, updateAppliedFilters] = useState<AppliedFilters>({});

  useEffect(() => {
    if (!examsList.length) {
      dispatch(getExamsAction());
    }
  }, []);

  return (
    <>
      <Header details={{ label: <FormattedMessage {...messages.exams} /> }} />
      <ProviewAssessmentList
        appliedFilters={appliedFilters}
        updateAppliedFilters={updateAppliedFilters}
        assessments={examsList}
        isLoading={isLoading}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
    </>
  );
};

export default ProviewAssessment;
