import React from 'react';

import classNames from 'classnames';

import { shortMonthNames } from '@components/Shared/DatePicker/enums';

export interface MonthsViewProps {
  onMonthSelect: (monthIndex: number) => void;
  selectedMonth: number;
}

const MonthsView: React.FC<MonthsViewProps> = ({ onMonthSelect, selectedMonth }) => {
  const handleMonthClick = (monthIndex: number): void => {
    onMonthSelect(monthIndex);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, monthIndex: number): void => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onMonthSelect(monthIndex);
    }
  };

  return (
    <div className="pt-4 flex flex-wrap w-[225px] h-[184px] mb-2">
      {shortMonthNames.map((month, index) => {
        const isSelectedMonth = index === selectedMonth;

        return (
          <div
            key={month}
            role="button"
            tabIndex={0}
            className={classNames(
              'w-[calc(25%)] h-[61px] flex justify-center items-center cursor-pointer rounded-md font-roboto font-normal text-sm leading-[21px] text-gray-900',
              { 'bg-[#1D4ED80D]': isSelectedMonth },
            )}
            onClick={() => handleMonthClick(index)}
            onKeyDown={(event) => handleKeyDown(event, index)}
            style={{ color: '#111827' }}
          >
            {month}
          </div>
        );
      })}
    </div>
  );
};

export default MonthsView;
