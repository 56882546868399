import React, { useEffect, useRef, useState } from 'react';

import { MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { useIntl } from 'react-intl';

interface SearchInputProps {
  placeholderMessageId?: string;
  handleChange: (searchText: string) => void;
  initialValue?: string;
  resetSearch?: string;
}

const SearchInput: React.FC<SearchInputProps> = ({
  placeholderMessageId,
  handleChange,
  initialValue = '',
  resetSearch,
}) => {
  const intl = useIntl();
  const [value, setValue] = useState(initialValue || '');
  const isMounted = useRef<boolean>(false);

  const debounceCallback = useRef(
    debounce((searchText: string) => {
      if (handleChange) {
        handleChange(searchText);
      }
    }, 500),
  ).current;

  useEffect(() => {
    if (isMounted.current && resetSearch) {
      setValue('');
    } else {
      isMounted.current = true;
    }
  }, [resetSearch]);

  useEffect(() => {
    debounceCallback(value);
  }, [value, debounceCallback]);

  useEffect(() => {
    return () => {
      debounceCallback.cancel();
    };
  }, [debounceCallback]);

  return (
    <div className="relative flex mt-1 rounded-md">
      <div className={classNames('absolute inset-y-0 left-0 pl-3 flex items-center')}>
        <MagnifyingGlassIcon aria-hidden="true" className="w-5 h-5 text-gray-900" />
      </div>
      <input
        aria-label="Search"
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={intl.formatMessage({ id: placeholderMessageId })}
        className="w-full px-10 py-2 text-sm font-normal text-gray-500 border border-gray-200 rounded-md font-roboto focus:outline-none focus:ring-0 focus:border-gray-200"
      />
    </div>
  );
};

export default SearchInput;
