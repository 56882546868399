import React, { useState } from 'react';

import { faPen } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import User from '@components/Base/UserDetails';
import { ROLE } from '@containers/app/types';
import { classNames } from '@utils/classNames';

import ContactInformation from './ContactInformation';
import LastLogin from './LastLogin';
import ProfilePic from './ProfilePic';
import RoleOrganizations from './RoleOrganizations';

type ProfileProps = {
  user: User;
};

export function Profile(props: ProfileProps) {
  const { user } = props;
  const [editProfilePic, setEditProfilePic] = useState(false);

  return (
    <div className="p-6">
      {editProfilePic ? (
        <ProfilePic setEditProfilePic={setEditProfilePic} data={user} />
      ) : (
        <div>
          <div className="relative">
            <User data={user} size="md" additionalContent={user.external_id} avatarProps={{ size: 'xl' }} />
            <FontAwesomeIcon
              data-testid="pencil_icon"
              icon={faPen}
              className={classNames(
                'font-semibold absolute rounded-full bg-blue-700 text-white top-12 left-14 w-3 h-3 p-2 cursor-pointer',
              )}
              onClick={() => setEditProfilePic(true)}
            />
          </div>
          <div className="mt-10">
            <ContactInformation data={{ phoneNumber: user.phone_number, email: user.email }} />
          </div>
          <div className="mt-4">
            <RoleOrganizations
              data={{
                roles: user.roles as ROLE[],
                tenantName: user.tenant.name,
              }}
            />
          </div>
          <div className="mt-4">
            <LastLogin data={user.metadata.lastSignInTime} />
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
