import includes from 'lodash/includes';

import { Coordinator, TenantAdminAndCoordinator } from './coordinator';
import { ProctorPermission } from './proctor';
import { ProctorAdmin, TenantAdmin } from './tenantAdmin';

interface Permission {
  canView: boolean;
  canEdit: boolean;
  canCreate: boolean;
  canDelete: boolean;
}

export interface AppPermissions {
  Home: Permission;
  Events: Permission;
  Sessions: Permission;
  Proctors: Permission;
  Schedule: Permission;
  Rooms: Permission;
  Help: Permission;
  Notifications: Permission;
  Profile: Permission;
  Flows: Permission;
  Exams: Permission;
  Payments: Permission;
  Projects: Permission;
  Settings: Permission;
  Vouchers: Permission;
}

// Setting default permissions to true
const defaultPermissions: AppPermissions = {
  Home: { canView: true, canCreate: true, canDelete: true, canEdit: true },
  Projects: { canView: true, canCreate: true, canDelete: true, canEdit: true },
  Events: { canView: true, canCreate: true, canDelete: true, canEdit: true },
  Sessions: { canView: true, canCreate: true, canDelete: true, canEdit: true },
  Exams: {
    canView: true,
    canCreate: true,
    canDelete: true,
    canEdit: true,
  },
  Proctors: { canView: true, canCreate: true, canDelete: true, canEdit: true },
  Schedule: { canView: true, canCreate: true, canDelete: true, canEdit: true },
  Rooms: { canView: true, canCreate: true, canDelete: true, canEdit: true },
  Help: { canView: true, canCreate: true, canDelete: true, canEdit: true },
  Notifications: {
    canView: true,
    canCreate: true,
    canDelete: true,
    canEdit: true,
  },
  Profile: { canView: true, canCreate: true, canDelete: true, canEdit: true },
  Flows: { canView: true, canCreate: true, canDelete: true, canEdit: true },
  Payments: {
    canView: true,
    canCreate: true,
    canDelete: true,
    canEdit: true,
  },
  Settings: { canView: true, canCreate: true, canDelete: true, canEdit: true },
  Vouchers: { canView: true, canCreate: true, canDelete: true, canEdit: true },
};

const roleSpecificPermissions = {
  PROCTOR: ProctorPermission,
  TENANT_ADMIN: TenantAdmin,
  COORDINATOR: Coordinator,
  PROCTOR_ADMIN: ProctorAdmin,
  TENANT_ADMIN_AND_COORDINATOR: TenantAdminAndCoordinator,
};

function getUserMasterPermissions(): AppPermissions {
  const storageItem = localStorage.getItem('tv.pc');
  if (!storageItem) {
    return { ...defaultPermissions };
  }

  const parsedStorage = JSON.parse(storageItem);
  let roles: string[] = parsedStorage.roles ?? [];
  if (includes(roles, 'COORDINATOR') && includes(roles, 'TENANT_ADMIN')) {
    roles = ['TENANT_ADMIN_AND_COORDINATOR'];
  }
  return evaluateAccessPermissions(roles);
}

function evaluateAccessPermissions(roles: string[]): AppPermissions {
  const masterPermissions = { ...defaultPermissions };

  roles.forEach((role) => {
    const restrictions = roleSpecificPermissions[role as keyof typeof roleSpecificPermissions];
    if (restrictions) {
      Object.entries(restrictions).forEach(([key, permission]) => {
        masterPermissions[key as keyof AppPermissions].canView &&= permission.canView;
      });
    }
  });
  return masterPermissions;
}

export { getUserMasterPermissions };
