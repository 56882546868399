import { defineMessages } from 'react-intl';

export const scope = 'app.containers.vouchers';

export default defineMessages({
  vouchers: {
    id: `${scope}.vouchers`,
    defaultMessage: 'Vouchers',
  },
  search_vouchers: {
    id: `${scope}.search_vouchers`,
    defaultMessage: 'Search for vouchers...',
  },
  empty_voucher: {
    id: `${scope}.empty_voucher`,
    defaultMessage: 'No Vouchers Yet',
  },
  empty_voucher_description: {
    id: `${scope}.empty_voucher`,
    defaultMessage: 'No vouchers have been issued at the moment.',
  },
  empty_redeemed_description: {
    id: `${scope}.empty_voucher`,
    defaultMessage: 'No vouchers have been redeemed at the moment.',
  },
  error_voucher_fetch_header: {
    id: `${scope}.error_voucher_fetch`,
    defaultMessage: 'Data Fetch Failed',
  },
  error_voucher_fetch_body: {
    id: `${scope}.error_voucher_fetch_body`,
    defaultMessage: 'Unable to retrieve data. Retry.',
  },
  redeemed: {
    id: `${scope}.redeemed`,
    defaultMessage: 'Redeemed',
  },
  issued: {
    id: `${scope}.issued`,
    defaultMessage: 'Issued',
  },
  create_voucher: {
    id: `${scope}.create_voucher`,
    defaultMessage: 'Create Voucher',
  },
  empty_record_title: {
    id: `${scope}.empty_search_list`,
    defaultMessage: 'No Results Found',
  },
  empty_record_description: {
    id: `${scope}.empty_record_description`,
    defaultMessage: 'Sorry, we couldn’t find any matches. Please try again.',
  },
  voucher_creation_failed: {
    id: `${scope}.voucher_creation_success`,
    defaultMessage: 'Voucher Creation Failed',
  },
  voucher_creation_failed_description: {
    id: `${scope}.voucher_creation_failed_description`,
    defaultMessage: 'Unable to create voucher. Retry.',
  },

  voucher_download_success_status_header: {
    id: `${scope}.voucher_download_success_status_header`,
    defaultMessage: 'Download Complete',
  },
  voucher_download_success_status_summary: {
    id: `${scope}.voucher_download_success_status_summary`,
    defaultMessage: 'Vouchers downloaded successfully',
  },

  voucher_download_failed_status_header: {
    id: `${scope}.voucher_download_failed__status_header`,
    defaultMessage: 'Download Failed',
  },
  voucher_download_failed_status_summary: {
    id: `${scope}.voucher_download_failed_status_summary`,
    defaultMessage: 'Unable to download. Try again.',
  },
  voucher_download_list: {
    id: `${scope}.voucher_download_list`,
    defaultMessage: 'Download List',
  },
});
