import React, { useMemo, useState } from 'react';

import _, { isEmpty } from 'lodash';

import EmptyMessagePage from '@components/Base/EmptyMessagePage';
import SearchInput from '@components/Base/Input';
import Pagination from '@components/Base/Pagination';
import { CustomColumnDef } from '@components/Base/Table/types';
import messages from '@components/WorkFlow/messages';
import WorkFlowDetails from '@components/WorkFlow/WorkFlowDetails';
import WorkflowFilters from '@components/WorkFlow/WorkFlowFilters';
import WorkFlowTable from '@components/WorkFlow/WorkFlowTable';
import { createColumns } from '@components/WorkFlow/WorkFlowTable/columns';
import { User } from '@containers/app/types';
import { AppliedFilters } from '@containers/workflows';
import { formatProperties } from '@containers/workflows/helpers';
import { Workflow, wftJobStatusList } from '@containers/workflows/types';
import { getMixpanelProperties, getPageCount } from '@utils/helpers';
import { proviewConsoleExamWorkflowFilterApplied, proviewConsoleExamWorkflowSearched } from '@utils/mixpanelActions';
import ClipBoardIcon from 'app/images/clipboard.svg';
import NoResultsFound from 'app/images/svgs/noResultsFound.svg';

interface WorkFlowListProps {
  wftWorkflow: Workflow[];
  searchTerm: string;
  setSearchTerm: React.Dispatch<React.SetStateAction<string>>;
  isLoading: boolean;
  isMixpanelInitialized: boolean;
  user: User;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  setPagination: React.Dispatch<React.SetStateAction<{ pageIndex: number; pageSize: number }>>;
  totalCount: number;
  userList: { label: string; value: number }[];
  updateAppliedFilters: React.Dispatch<React.SetStateAction<object>>;
  appliedFilters: AppliedFilters;
}

const WorkFlowList: React.FC<WorkFlowListProps> = (props) => {
  const {
    wftWorkflow,
    searchTerm,
    setSearchTerm,
    isLoading,
    isMixpanelInitialized,
    user,
    pagination,
    setPagination,
    totalCount,
    userList,
    appliedFilters,
    updateAppliedFilters,
  } = props;
  const pageCount = getPageCount(totalCount, pagination);
  const showEmptyPage = isEmpty(appliedFilters);
  const [openWorkflowSidepanel, setOpenWorkflowSidepanel] = useState<boolean>(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(null);

  const renderEmptyPage = isEmpty(wftWorkflow) && !isLoading && !searchTerm && showEmptyPage;

  const renderNoResultsFoundPage = isEmpty(wftWorkflow) && (searchTerm || !showEmptyPage);

  const openWorkflowSidepanelHandler = (workflow: Workflow) => {
    setOpenWorkflowSidepanel(true);
    setSelectedWorkflow(workflow);
  };

  const closeWorkflowSidepanelHandler = () => {
    setOpenWorkflowSidepanel(false);
  };

  const triggerSearchMPEvent = (searchParam: string) => {
    proviewConsoleWorkflowSearchedEvent(isMixpanelInitialized, user, searchParam);
  };

  const nextPage = () => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: prev.pageIndex + 1,
    }));
  };

  const prevPage = () => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: prev.pageIndex - 1,
    }));
  };

  const handleSearch = (value: string) => {
    if (value) {
      triggerSearchMPEvent(value);
    }
    setSearchTerm(value.replace(/%/g, ''));
  };

  const status = wftJobStatusList.map((status) => ({
    label: status.label,
    value: status.key,
  }));

  const applyFilters = (filters: object) => {
    if (!isEmpty(filters)) {
      triggerFilterMPEvent(filters);
    }
    updateAppliedFilters(filters);
  };

  const triggerFilterMPEvent = (filters: object) => {
    if (isMixpanelInitialized && user) {
      const mixpanelProperties = getMixpanelProperties(user);
      const additionalProperties = {
        module: 'Exams',
      };
      const combinedProperties = {
        ...additionalProperties,
        ...mixpanelProperties,
        filteredBy: { ...filters },
      };
      proviewConsoleExamWorkflowFilterApplied(combinedProperties);
    }
  };

  const workflowsList = useMemo(() => {
    return wftWorkflow.map((workflow) => ({
      label: workflow.title,
      value: workflow.title,
    }));
  }, [wftWorkflow]);

  const columnsIssued = useMemo(() => {
    return createColumns(openWorkflowSidepanelHandler).filter(
      (column) => column !== null,
    ) as CustomColumnDef<Workflow>[];
  }, []);

  return (
    <>
      {renderEmptyPage ? (
        <EmptyMessagePage
          icon={ClipBoardIcon}
          titleMessage={messages.empty_workflow}
          descriptionMessage={messages.empty_workflow_description}
          descriptionWidth="w-48"
        />
      ) : (
        <div className="flex w-full px-8 py-5">
          <div className="flex flex-col w-full">
            <div className="flex flex-row-reverse justify-between items-center">
              <div className="flex items-center gap-4">
                <SearchInput
                  initialValue={searchTerm}
                  handleChange={(value) => handleSearch(value)}
                  placeholderMessageId="app.components.workflow.search_for_workflow"
                />
                <WorkflowFilters
                  workFlows={workflowsList}
                  status={status}
                  userList={userList}
                  updateAppliedFilters={applyFilters}
                  appliedFilters={appliedFilters}
                />
                <div className="w-0 h-6 border border-gray-300" />
                <Pagination
                  total={totalCount}
                  pageSize={pagination.pageSize}
                  currentPage={pagination.pageIndex}
                  summary
                  previousPage={prevPage}
                  nextPage={nextPage}
                  getCanPreviousPage={() => {
                    return pagination.pageIndex > 0;
                  }}
                  getCanNextPage={() => {
                    return pagination.pageIndex < pageCount - 1;
                  }}
                />
              </div>
            </div>
            {renderNoResultsFoundPage ? (
              <EmptyMessagePage
                icon={NoResultsFound}
                titleMessage={messages.empty_record_title}
                descriptionMessage={messages.empty_record_description}
                descriptionWidth="w-48"
              />
            ) : (
              <WorkFlowTable wftWorkflow={wftWorkflow} columns={columnsIssued} isLoading={isLoading} />
            )}
          </div>
        </div>
      )}
      <WorkFlowDetails
        openWorkflowSidepanel={openWorkflowSidepanel}
        closeWorkflowSidepanelHandler={closeWorkflowSidepanelHandler}
        selectedWorkflow={selectedWorkflow}
      />
    </>
  );
};

export default WorkFlowList;

export const proviewConsoleWorkflowSearchedEvent = (
  isMixpanelInitialized: boolean,
  user: User,
  searchParam: string,
) => {
  if (isMixpanelInitialized && user) {
    const combinedProperties = formatProperties(user, searchParam);
    proviewConsoleExamWorkflowSearched(combinedProperties);
  }
};
