import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SessionData } from './types';

export const initialState: {
  isLoading: boolean;
  isSuccess: boolean;
  error: string | Error | null;
  data: SessionData[];
  totalCount: number;
} = {
  isLoading: false,
  isSuccess: false,
  error: null,
  data: [],
  totalCount: -1,
};

export const proviewExamsList = createSlice({
  name: 'proviewExamsList',
  initialState: initialState,
  reducers: {
    getExamsAction: (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.error = null;
    },

    getExamsSuccessAction: (state, { payload }: PayloadAction<{ data: SessionData[] }>) => {
      state.data = payload.data;
      state.isSuccess = true;
      state.isLoading = false;
      state.error = null;
    },

    getExamsErrorAction: (state, { payload: error }) => {
      state.error = error;
      state.isLoading = false;
      state.isSuccess = false;
    },
  },
});

export const { getExamsAction, getExamsSuccessAction, getExamsErrorAction } = proviewExamsList.actions;

export default proviewExamsList.reducer;
