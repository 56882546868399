import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';

import './styles.css';

import messages from './messages';

interface Props {
  handleOpenModal: () => void;
}

const ProjectLanding = ({ handleOpenModal }: Props) => {
  return (
    <div className="bg-landing-overlay h-screen flex flex-col items-center w-full text-white">
      <div className="absolute top-1/3 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center gap-6 text-center">
        <h2 className="font-semibold text-3xl">
          <FormattedMessage {...messages.greeting} />
        </h2>
        <p className="w-[511px]">
          <FormattedMessage {...messages.greeting_text} />
        </p>
        <Button onClick={handleOpenModal} variant="ghost" className="bg-white text-primary">
          <FormattedMessage {...messages.create_new_project} />
        </Button>
      </div>
    </div>
  );
};

export default ProjectLanding;
