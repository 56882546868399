import React from 'react';

import { FormattedMessage } from 'react-intl';

import type { CardDetailType } from '@components/Flows/FlowDetails/types';
import { CONFIG_TYPE } from '@utils/data/enums';

import messages from './messages';
import offboarding from '../../../images/svgs/offboarding.svg';

export const cardDetail: CardDetailType = {
  label: <FormattedMessage {...messages.offboarding_label} />,
  subText: <FormattedMessage {...messages.offboarding_description} />,
  logo: offboarding,
  type: CONFIG_TYPE.OFFBOARDING,
};

export interface OffboardingProps {
  isFeedbackEnabled?: boolean;
}

const Offboarding = (props: OffboardingProps) => {
  const { isFeedbackEnabled } = props;

  return (
    <div>
      <div className="text-sm text-gray-900">
        <FormattedMessage {...messages.feedback} /> :{' '}
        {isFeedbackEnabled ? <FormattedMessage {...messages.enabled} /> : <FormattedMessage {...messages.disabled} />}
      </div>
    </div>
  );
};

export default Offboarding;
