import { defineMessages } from 'react-intl';

export const scope = 'app.containers.workflow';

export default defineMessages({
  workflow: {
    id: `${scope}.workflow`,
    defaultMessage: 'Workflows',
  },
  error_workflow_fetch_header: {
    id: `${scope}.error_workflow_fetch_header`,
    defaultMessage: 'Data Fetch Failed',
  },
  error_workflow_fetch_body: {
    id: `${scope}.error_workflow_fetch_body`,
    defaultMessage: 'Unable to retrieve data. Retry.',
  },
});
