import React from 'react';

import Badge from '@components/Base/Badge';

interface AccommodationTypes {
  accommodation_type: string;
  id: number;
}
interface AccommodationBadgesProps {
  accommodations?: AccommodationTypes[];
}

const AccommodationBadges = ({ accommodations }: AccommodationBadgesProps) => {
  if (!accommodations || accommodations.length === 0) {
    return null;
  }
  return (
    <>
      <span className="pr-2">
        <Badge type="secondary" rounded="md">
          {accommodations[0].accommodation_type}
        </Badge>
      </span>
      {accommodations.length > 2 ? (
        <span className="pr-2">
          <Badge type="secondary" rounded="md">
            +{accommodations.length - 1}
          </Badge>
        </span>
      ) : (
        accommodations.slice(1).map((acc, index) => (
          <span key={index} className="pr-2">
            <Badge type="secondary" rounded="md">
              {acc.accommodation_type}
            </Badge>
          </span>
        ))
      )}
    </>
  );
};

export default AccommodationBadges;
