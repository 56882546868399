import React, { useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import AssignProctor from '@components/AssignProctor';
import { Button } from '@components/Base/Button';
import EmptyMessagePage from '@components/Base/EmptyMessagePage';
import SearchInput from '@components/Base/Input';
import { CustomColumnDef } from '@components/Base/Table/types';
import CancelProctor from '@components/CancelProctor';
import SessionListTable from '@components/ProctorNgSessions/SessionTable';
import { SessionDetails, createColumns } from '@components/ProctorNgSessions/SessionTable/Columns';
import SessionTabs from '@components/ProctorNgSessions/SessionTabs';
import { SESSION_TABS, getCustomColumns } from '@components/ProctorNgSessions/types';
import type { Proctor } from '@containers/sessions/proctor_ng/types';
import ClipBoardIcon from '@images/clipboard.svg';
import { USER_ROLE } from '@utils/data/enums';

import messages from './messages';
import SessionFilters from '../SessionFilters';

interface SessionListProps {
  sessionData: SessionDetails[];
  userRole: string;
  searchString: string;
  updateSearchString: React.Dispatch<React.SetStateAction<string>>;
  handleCancelProctor: () => void;
  proctors: Proctor[];
}

const SessionList = ({
  sessionData,
  userRole,
  searchString,
  updateSearchString,
  handleCancelProctor,
  proctors,
}: SessionListProps) => {
  const [selectedTab, setSelectedTab] = useState<string>(
    userRole === USER_ROLE.PROCTOR_ADMIN ? SESSION_TABS.ALL_SESSIONS : SESSION_TABS.PENDING,
  );
  const [selectedCount, setSelectedCount] = useState(0);
  const [resetSelection, setResetSelection] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasInProgressSession] = useState(false);
  const [openCancelProctor, setOpenCancelProctor] = useState<boolean>(false);

  const data = useMemo(() => {
    return sessionData.filter((session) => {
      if (selectedTab === SESSION_TABS.PENDING) {
        return session.review === SESSION_TABS.PENDING;
      }
      if (selectedTab === SESSION_TABS.REVIEWED) {
        return session.review === SESSION_TABS.REVIEWED;
      }
      return session;
    });
  }, [selectedTab]);

  const handleRequestReview = (_details: { user: Proctor | null; date: Date | null }) => {};

  const toggleCancelProctorModal = () => {
    setOpenCancelProctor(!openCancelProctor);
  };

  const columns = useMemo(() => {
    const customColumns = getCustomColumns[userRole][selectedTab];
    return customColumns
      .map((key) => {
        const filteredColumns = createColumns({
          toggleCancelProctorModal,
          proctors,
        }).filter((column) => column.id === key);
        return filteredColumns.length > 0 ? filteredColumns[0] : null;
      })
      .filter((column) => column !== null) as CustomColumnDef<SessionDetails>[];
  }, [userRole, selectedTab]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleTabSelect = (value: string) => {
    setSelectedTab(value);
    setResetSelection(true);
  };

  const toogleRequestModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleTableCheck = (rowSelection: { [key: number]: boolean } | null) => {
    const selectionCount = rowSelection !== null ? Object.keys(rowSelection).length : 0;
    setSelectedCount(selectionCount);
    setResetSelection(false);
  };

  const isMultiselect = userRole === USER_ROLE.PROCTOR_ADMIN;

  return (
    <div className="flex w-full px-8 py-5">
      <div className="flex flex-col w-full">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <SessionTabs selectedTab={selectedTab} handleTabSelect={handleTabSelect} userRole={userRole} />
            {selectedCount > 0 && (
              <Button variant="base" onClick={toogleRequestModal} data-testid="request-review">
                <FormattedMessage {...(selectedTab === 'sessions' ? messages.request_review : messages.edit_request)} />
              </Button>
            )}
          </div>
          <div className="flex items-center gap-4">
            <SearchInput
              initialValue={searchString}
              handleChange={updateSearchString}
              placeholderMessageId="app.components.proctorng.sessions.sessions_list.search_sessions"
            />
            <SessionFilters
              proctors={proctors}
              userRole={userRole}
              selectedTab={selectedTab}
              updateAppliedFilters={() => {}}
            />
          </div>
        </div>
        {sessionData.length > 0 ? (
          <SessionListTable
            data={data}
            columns={columns}
            isMultiselect={isMultiselect}
            handleTableCheck={handleTableCheck}
            resetSelection={resetSelection}
          />
        ) : (
          <EmptyMessagePage
            icon={ClipBoardIcon}
            titleMessage={SESSION_TABS.ALL_SESSIONS === selectedTab ? messages.no_session : messages.no_review_pending}
            descriptionMessage={
              SESSION_TABS.ALL_SESSIONS === selectedTab
                ? messages.no_session_description
                : messages.no_review_pending_description
            }
            descriptionWidth="w-48"
          />
        )}
      </div>
      <AssignProctor
        data={proctors}
        openAddFlowModal={isModalOpen}
        handleCloseModal={handleCloseModal}
        onRequestReview={handleRequestReview}
        selectedCount={selectedCount}
        isEdit={!(selectedTab === SESSION_TABS.ALL_SESSIONS)}
        hasInProgressSession={hasInProgressSession}
      />
      <CancelProctor
        openCancelProctor={openCancelProctor}
        toggleCancelProctorModal={toggleCancelProctorModal}
        handleCancelProctor={handleCancelProctor}
      />
    </div>
  );
};

export default SessionList;
