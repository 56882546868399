import { defineMessages } from 'react-intl';

export const scope = 'app.components.Error.not_found';

export default defineMessages({
  page_not_found: {
    id: `${scope}.page_not_found`,
    defaultMessage: "We couldn't find this page",
  },
  page_not_found_sub_text: {
    id: `${scope}.page_not_found_sub_text`,
    defaultMessage: 'Sorry, looks like you may have mistyped the address or the page might have moved.',
  },
});
