import React, { ReactNode } from 'react';

import { ChevronRightIcon } from '@heroicons/react/24/outline';

import { classNames } from '@utils/classNames';
import { handleEnterKeyPress } from '@utils/helpers';

interface SideNavTabProps {
  label: string | React.ReactElement;
  icon: ReactNode;
  path: string;
  isExpanded: boolean;
  isArrow?: boolean;
  selected?: boolean;
  onClick: (path: string) => void;
}
const SideNavTab = (props: SideNavTabProps) => {
  const { label, icon, path, isExpanded, isArrow, selected, onClick } = props;

  return (
    <div
      role="button"
      className={classNames(
        'flex items-center cursor-pointer  transition-all duration-500 p-3  rounded-full',
        isExpanded ? 'w-full' : 'w-12',
        selected ? 'bg-blue-100 pointer-events-none' : 'hover:bg-gray-100',
      )}
      onClick={() => onClick(path)}
      onKeyDown={(event) => handleEnterKeyPress(event, () => onClick(path))}
      data-testid={`label-${label}`}
      tabIndex={0}
    >
      <div className="w-6 h-6"> {icon}</div>
      <div
        className={classNames('mr-auto ml-3 transition-all duration-1000', isExpanded ? 'opacity-100' : 'opacity-0')}
      >
        <div className="text-sm text-gray-900 text-nowrap">{label}</div>
      </div>
      {isArrow && <ChevronRightIcon height={20} width={20} className="ml-auto text-gray-400" />}
    </div>
  );
};
export default SideNavTab;
