import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import {
  type CreateProjectActionPayload,
  type EditProjectActionPayload,
  Project,
  ProjectError,
  type ProjectsState,
} from '@containers/projects/types';
import { currentProjectsInitialState, newProjectsInitialState } from '@mocks/data';
import { TOAST_STATE } from '@utils/data/enums';

export const initialState: ProjectsState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: null,
  data: [],
  currentProject: currentProjectsInitialState,
  projectForm: {
    isLoading: false,
    error: null,
    projectId: '',
    newProject: newProjectsInitialState,
    isSuccess: false,
    isComplete: false,
    isOpenModal: false,
  },
  triggerToast: null,
};

export const projectsSlice = createSlice({
  name: 'projects',
  initialState: initialState,
  reducers: {
    getProjectsAction: (state: ProjectsState) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.error = null;
    },
    getProjectSuccessAction: (state: ProjectsState, { payload }: PayloadAction<Project[]>) => {
      state.data = payload;
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = true;
    },
    getProjectErrorAction: (state: ProjectsState, { payload: error }: PayloadAction<ProjectError>) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.error = error;
    },
    setCurrentProject: (state: ProjectsState, { payload }: PayloadAction<Project>) => {
      state.currentProject = {
        ...payload,
      };
    },
    createProjectAction: (state: ProjectsState, { payload: _payload }: PayloadAction<CreateProjectActionPayload>) => {
      state.projectForm.isLoading = true;
      state.projectForm.isSuccess = false;
      state.projectForm.error = null;
    },
    editProjectAction: (state: ProjectsState, { payload: _payload }: PayloadAction<EditProjectActionPayload>) => {
      state.projectForm.isLoading = true;
      state.projectForm.isSuccess = false;
      state.projectForm.error = null;
    },
    projectFormSuccessAction: (state: ProjectsState, { payload }: PayloadAction<Project>) => {
      state.projectForm.isLoading = false;
      state.projectForm.isSuccess = true;
      state.projectForm.newProject = payload;
      state.triggerToast = TOAST_STATE.PROJECT_DETAILS_SAVED;
    },
    projectFormCompleteAction: (state: ProjectsState, _action: PayloadAction<undefined>) => {
      state.projectForm.isComplete = true;
      state.projectForm.isSuccess = false;
      state.projectForm.isLoading = false;
      state.data = state.data.map((project) => {
        if (project.id === state.projectForm.newProject.id) {
          return { ...project, ...state.projectForm.newProject };
        }
        return project;
      });
      state.projectForm.newProject = newProjectsInitialState;
    },
    projectFormErrorAction: (state: ProjectsState, { payload: error }: PayloadAction<unknown>) => {
      state.projectForm.isLoading = false;
      state.projectForm.error = error;
      state.triggerToast = TOAST_STATE.PROJECT_DETAILS_SAVE_FAILED;
    },
    setProjectFormModal: (
      state: ProjectsState,
      { payload }: PayloadAction<{ projectId?: string; isOpen: boolean }>,
    ) => {
      if (payload.isOpen) {
        state.projectForm.projectId = payload.projectId || '';
        state.projectForm.isOpenModal = payload.isOpen;
      } else {
        state.projectForm.projectId = '';
        state.projectForm.isOpenModal = false;
      }
    },
    resetTriggerToast: (state: ProjectsState) => {
      state.triggerToast = null;
    },
  },
});

export const {
  getProjectsAction,
  getProjectSuccessAction,
  getProjectErrorAction,
  setCurrentProject,
  createProjectAction,
  editProjectAction,
  projectFormSuccessAction,
  projectFormCompleteAction,
  projectFormErrorAction,
  setProjectFormModal,
  resetTriggerToast,
} = projectsSlice.actions;

export const selectProjects = (state: { projects: ProjectsState }) => state.projects.data;

export const selectProjectById = (state: { projects: ProjectsState }, id: string) =>
  state.projects.data.find((project) => project.id === id);

export default projectsSlice.reducer;
