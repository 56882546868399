import React, { useState } from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import { Dropdown } from '@components/Base/Dropdown';
import SidePanel from '@components/SidePanel';
import messages from '@components/WorkFlow/Filters/messages';
import './styles.css';
import { AppliedFilters } from '@containers/workflows';

export interface WorkflowFiltersProps {
  toogleFilterView: () => void;
  showFilter: boolean;
  workFlows: { label: string; value: string }[];
  status: { label: string; value: string }[];
  userList: { label: string; value: number }[];
  updateAppliedFilters: (filters: object) => void;
  appliedFilters: AppliedFilters;
}

const Filters = (props: WorkflowFiltersProps) => {
  const { showFilter, toogleFilterView, workFlows, status, userList, updateAppliedFilters, appliedFilters } = props;

  const [selectedStatus, setSelectedStatus] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(appliedFilters.selectedStatus ?? null);

  const [selectedWorkflow, setSelectedWorkflow] = useState<
    | {
        label: string;
        value: string;
      }[]
    | null
  >(appliedFilters.selectedWorkflow ?? null);

  const [selectedUser, setSelectedUser] = useState<
    | {
        label: string;
        value: number | string;
      }[]
    | null
  >(appliedFilters.selectedUser ?? null);

  const clearFilters = () => {
    setSelectedWorkflow(null);
    setSelectedStatus(null);
    setSelectedUser(null);
    updateAppliedFilters({});
  };

  const resetFilter = () => {
    clearFilters();
    toogleFilterView();
  };

  const handleApplyFilter = () => {
    const filters = {
      selectedStatus: selectedStatus,
      selectedWorkflow: selectedWorkflow,
      selectedUser: selectedUser,
    };
    toogleFilterView();
    updateAppliedFilters(filters);
  };

  return (
    <>
      <SidePanel show={showFilter} containerPadding="p-0" onClose={toogleFilterView} allowCustomHeader={true}>
        <div className="flex flex-col flex-1 justify-between">
          <div>
            <div className="sticky top-0 border-b z-[1]">
              <div className="flex p-5 bg-white">
                <div className="w-filter-header">
                  <div className="text-base font-semibold text-gray-900">
                    <FormattedMessage {...messages.filter} />
                  </div>
                  <div className="text-sm font-normal text-gray-600">
                    <FormattedMessage {...messages.customize_view} />
                  </div>
                </div>
                <div
                  data-testid="close_btn"
                  role="button"
                  tabIndex={-1}
                  className="px-2 text-gray-600 cursor-pointer"
                  onClick={toogleFilterView}
                  onKeyPress={toogleFilterView}
                >
                  <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                </div>
              </div>
            </div>
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.workflow} />
              </div>
              <div className="pt-3">
                <Dropdown
                  id="workflow-list"
                  isMulti={true}
                  value={selectedWorkflow}
                  isSearchable={true}
                  placeholder={<FormattedMessage {...messages.select_workflow} />}
                  onChange={(workflow) => setSelectedWorkflow(workflow as { label: string; value: string }[])}
                  options={workFlows.length ? workFlows : []}
                />
              </div>
            </div>
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.status} />
              </div>
              <div className="pt-3">
                <Dropdown
                  id="status-list"
                  isMulti={true}
                  value={selectedStatus}
                  isSearchable={true}
                  placeholder={<FormattedMessage {...messages.select_status} />}
                  onChange={(status) => {
                    setSelectedStatus(status as { label: string; value: string }[]);
                  }}
                  options={status.length ? status : []}
                />
              </div>
            </div>
            <div className="flex flex-col px-6 py-3 bg-white">
              <div className="text-base font-semibold text-gray-900">
                <FormattedMessage {...messages.modified_by} />
              </div>
              <div className="pt-3">
                <Dropdown
                  id="modified-user-list"
                  isMulti={true}
                  value={selectedUser}
                  isSearchable={true}
                  placeholder={<FormattedMessage {...messages.select_user} />}
                  onChange={(user) => setSelectedUser(user as { label: string; value: number }[])}
                  options={userList.length ? userList : []}
                />
              </div>
            </div>
          </div>
          <div className="sticky bottom-0">
            <div className="h-20 flex items-center justify-start px-6 bg-white shadow-[0px_3px_45px_0px_#00000024]">
              <Button variant="outline" onClick={resetFilter}>
                <FormattedMessage {...messages.clear} />
              </Button>
              <div className="px-3">
                <Button onClick={handleApplyFilter}>
                  <FormattedMessage {...messages.apply} />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </SidePanel>
    </>
  );
};

export default Filters;
