import { defineMessages } from 'react-intl';

import { commonScope } from '@utils/messages';

export const scope = 'app.components.project';

export default defineMessages({
  edit_project: {
    id: `${scope}.editProject`,
    defaultMessage: 'Edit Project',
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description',
  },
  geography: {
    id: `${scope}.geography`,
    defaultMessage: 'Geography',
  },
  project_overview: {
    id: `${scope}.projectOverview`,
    defaultMessage: 'Project Overview',
  },
  flows: {
    id: `${scope}.flows`,
    defaultMessage: 'Flows',
  },
  view_all: {
    id: `${scope}.view_all`,
    defaultMessage: 'View all',
  },
  details_updated: {
    id: `${scope}.details_updated`,
    defaultMessage: 'Details updated',
  },
  details_updated_description: {
    id: `${scope}.details_updated_description`,
    defaultMessage: 'Project details have been updated successfully.',
  },
  details_update_failed: {
    id: `${scope}.details_update_failed`,
    defaultMessage: 'Update failed',
  },
  details_update_failed_description: {
    id: `${scope}.details_update_failed_description`,
    defaultMessage: 'Failed to update project details.',
  },
  something_went_wrong: {
    id: `${commonScope}.something_went_wrong`,
    defaultMessage: 'Something went wrong',
  },
});
