import React, { useEffect, useState } from 'react';

import SessionList from '@components/ProctorNgSessions/SessionList';
import { sessionTableData } from '@components/ProctorNgSessions/SessionList/data';
import { getProctorsAction } from '@containers/sessions/proctor_ng/slice';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import { RootState } from '@utils/store';

interface ProctorNgSessionsProps {
  role: string;
  handleCancelProctor: () => void;
}

const ProctorNgSessions = (props: ProctorNgSessionsProps) => {
  const proctorData = useAppSelector((state: RootState) => state.sessionsNg.proctors);

  const { data, isSuccess: hasProctorListLoaded } = proctorData;

  const [searchString, setSearchString] = useState('');
  const { role, handleCancelProctor } = props;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!hasProctorListLoaded) {
      dispatch(getProctorsAction());
    }
  }, []);

  return (
    <SessionList
      sessionData={sessionTableData}
      userRole={role}
      searchString={searchString}
      updateSearchString={setSearchString}
      handleCancelProctor={handleCancelProctor}
      proctors={data}
    />
  );
};

export default ProctorNgSessions;
