import React from 'react';

import { FormattedMessage } from 'react-intl';

import ToggleButton from '@components/Base/ToggleButton';
import messages from '@components/Flows/ConfigPanels/messages';
import configCardMessages from '@components/Flows/ConfigureCard/messages';
import { FEED_TYPE } from '@utils/data/enums';

type CameraRecordingsProps = {
  config: {
    screen_feed: string;
    primary_camera_feed: string;
    secondary_camera_feed: string;
  };
  updateConfig: (obj: { [key: string]: string }) => void;
};

const CameraRecordings = (props: CameraRecordingsProps) => {
  const { config, updateConfig } = props;

  const handleUpdateConfig = (updatedConfig: { [key: string]: string }) => {
    updateConfig({ ...config, ...updatedConfig });
  };

  return (
    <>
      <div className="mx-4">
        <div className="flex flex-col gap-4 mt-1 pb-1">
          <div className="px-4 py-3 bg-white rounded-md shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)]">
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <span className="text-base font-semibold text-gray-900">
                  <FormattedMessage {...messages.screen_share} />
                </span>
                <span className="text-xs text-gray-600 font-roboto">
                  <FormattedMessage {...messages.screen_share_description} />
                </span>
              </div>
              <ToggleButton
                data-testid="screen_feed_switch"
                checked={config.screen_feed !== FEED_TYPE.DISABLE}
                onClick={(checked: boolean) => {
                  handleUpdateConfig({
                    screen_feed: checked ? FEED_TYPE.STREAM : FEED_TYPE.DISABLE,
                  });
                }}
                disabled={false}
              />
            </div>
            {config.screen_feed !== FEED_TYPE.DISABLE && (
              <div className="flex items-center gap-3 mt-2.5">
                <div className="flex flex-col">
                  <span className="text-base font-semibold text-gray-900">
                    <FormattedMessage {...messages.screen_recording} />
                  </span>
                  <span className="text-xs text-gray-600 font-roboto">
                    <FormattedMessage {...messages.screen_recording_description} />
                  </span>
                </div>
                <ToggleButton
                  data-testid="screen_recording_feed_switch"
                  checked={config.screen_feed === FEED_TYPE.STREAM_AND_RECORD}
                  onClick={(checked: boolean) => {
                    handleUpdateConfig({
                      screen_feed: checked ? FEED_TYPE.STREAM_AND_RECORD : FEED_TYPE.STREAM,
                    });
                  }}
                  disabled={false}
                />
              </div>
            )}
          </div>
          <div className="flex items-center gap-3 px-4 bg-white rounded-md">
            <div className="flex flex-col">
              <span className="text-base font-semibold text-gray-900">
                <FormattedMessage {...configCardMessages.primary_camera_recording} />
              </span>
              <span className="text-xs text-gray-600">
                <FormattedMessage {...messages.primary_camera_recording_description} />
              </span>
            </div>
            <ToggleButton
              data-testid="primary_camera_feed_switch"
              checked={config.primary_camera_feed === FEED_TYPE.STREAM_AND_RECORD}
              onClick={(checked: boolean) => {
                handleUpdateConfig({
                  primary_camera_feed: checked ? FEED_TYPE.STREAM_AND_RECORD : FEED_TYPE.STREAM,
                });
              }}
              disabled={false}
            />
          </div>
          <div className="px-4 py-3 mb-1 bg-white rounded-md shadow-[0px_1px_2px_-1px_rgba(0,_0,_0,_0.10),_0px_1px_3px_0px_rgba(0,_0,_0,_0.10)]">
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <span className="text-base font-semibold text-gray-900">
                  <FormattedMessage {...messages.secondary_camera} />
                </span>
                <span className="text-xs text-gray-600">
                  <FormattedMessage {...messages.secondary_camera_description} />
                </span>
              </div>
              <ToggleButton
                data-testid="secondary_camera_feed_switch"
                checked={config.secondary_camera_feed !== FEED_TYPE.DISABLE}
                onClick={(checked: boolean) => {
                  handleUpdateConfig({
                    secondary_camera_feed: checked ? FEED_TYPE.STREAM : FEED_TYPE.DISABLE,
                  });
                }}
                disabled={false}
              />
            </div>
            {config.secondary_camera_feed !== FEED_TYPE.DISABLE && (
              <div className="flex items-center gap-3 mt-2.5">
                <div className="flex flex-col">
                  <span className="text-base font-semibold text-gray-900">
                    <FormattedMessage {...messages.secondary_camera_recording} />
                  </span>
                  <span className="text-xs text-gray-600 font-roboto">
                    <FormattedMessage {...messages.secondary_camera_recording_description} />
                  </span>
                </div>
                <ToggleButton
                  data-testid="secondary_camera_recording_feed_switch"
                  checked={config.secondary_camera_feed === FEED_TYPE.STREAM_AND_RECORD}
                  onClick={(checked: boolean) => {
                    handleUpdateConfig({
                      secondary_camera_feed: checked ? FEED_TYPE.STREAM_AND_RECORD : FEED_TYPE.STREAM,
                    });
                  }}
                  disabled={false}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CameraRecordings;
