import React from 'react';

import ProgressBar from '@components/Base/ProgressBar';
import customBranding from 'app/images/svgs/customBranding.svg';

interface LogoUploadProgressBarProps {
  selectedFile: string | File;
  uploadProgress: number;
}
const UploadLogoProgressBar: React.FC<LogoUploadProgressBarProps> = (props) => {
  const { selectedFile, uploadProgress } = props;

  return (
    <div className="flex items-center h-10 gap-2 cursor-pointer mt-3">
      <div className="w-10 h-8">
        <img src={customBranding} alt="logo icon" className="max-w-full max-h-full" />
      </div>
      <div className="flex flex-col">
        <div className="text-xs text-gray-400 mb-1">
          {selectedFile && selectedFile instanceof File ? selectedFile.name : selectedFile}
        </div>
        <div className="w-44 h-1">
          <ProgressBar barColor="bg-blue-600" progress={uploadProgress} color="bg-gray-300" />
        </div>
      </div>
    </div>
  );
};

export default UploadLogoProgressBar;
