import React, { KeyboardEvent, useState } from 'react';

import { Bars3Icon } from '@heroicons/react/24/solid';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROLE, User } from '@containers/app/types';
import { FormattedTimeZone, TimeZone } from '@containers/user/types';
import { classNames } from '@utils/classNames';
import { handleEnterKeyPress } from '@utils/helpers';

import SideNavHelp from './SideNavHelp';
import SideNavNotification from './SideNavNotification';
import SideNavProfile from './SideNavProfile';
import { navSectionProps } from './SideNavTab/navItems';
import SideNavTab from './SideNavTab/SideNavTab';

interface SideNavigationProps {
  isNewUser: boolean;
  user: User;
  logo?: string;
  navItems: navSectionProps[];
  selectedLanguage: string;
  handleSetLocale: (language: string) => void;
  selectedTimezone: FormattedTimeZone;
  handleSetTimezone: (timezone: FormattedTimeZone) => void;
  timeZone: TimeZone[];
}

const SideNavigation = (props: SideNavigationProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const {
    isNewUser,
    user,
    logo,
    navItems,
    selectedLanguage,
    handleSetLocale,
    timeZone,
    selectedTimezone,
    handleSetTimezone,
  } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const handleExpandOnKeyPress = (e: KeyboardEvent<HTMLDivElement>) =>
    handleEnterKeyPress(e, () => setIsExpanded(!isExpanded));

  const handleExpandOnClick = () => setIsExpanded(!isExpanded);

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <div
      className={classNames(
        'flex flex-col min-h-screen transition-all duration-500 border-r border-gray-200',
        isExpanded ? 'w-52 shadow-[4px_0px_24px_0px_rgba(17,_24,_39,_0.06)] ' : 'w-20',
      )}
    >
      <div className="flex flex-col flex-grow px-4 pt-5">
        <div className="flex items-center gap-4">
          <div
            role="button"
            className={classNames(
              'p-3 cursor-pointer transition-all duration-500 rounded-full w-fit',
              isExpanded && 'bg-blue-100',
            )}
            onClick={handleExpandOnClick}
            onKeyDown={handleExpandOnKeyPress}
            data-testid="toggle_bar"
            tabIndex={0}
          >
            <Bars3Icon height={24} width={24} />
          </div>
          {logo && (
            <div>
              <img src={logo} alt="LOGO" className="w-full h-[38px] max-w-20 object-contain" />
            </div>
          )}
        </div>
        {!isNewUser || ROLE.PROCTOR_ADMIN === user.currentRole || ROLE.PROCTOR === user.currentRole ? (
          <div className="flex flex-col gap-3 mt-8 mb-3">
            {navItems.map(
              (element) =>
                element.showNavSection && (
                  <SideNavTab
                    key={element.id}
                    // for storybook testing
                    selected={element.path === '' ? false : location.pathname.includes(element.path)}
                    {...element}
                    isExpanded={isExpanded}
                    onClick={() => element.path && handleNavigate(element.path)}
                  />
                ),
            )}
          </div>
        ) : (
          <div className="flex flex-col gap-3 mt-8 mb-3">
            {navItems.map(
              (element) =>
                element.showNavSection &&
                element.path === '/vouchers' && (
                  <SideNavTab
                    key={element.id}
                    selected={location.pathname.includes(element.path)}
                    {...element}
                    isExpanded={isExpanded}
                    onClick={() => element.path && handleNavigate(element.path)}
                  />
                ),
            )}
          </div>
        )}
      </div>
      <div className="mt-auto px-3.5 pb-11">
        <div className={classNames('flex flex-col gap-5 border-t border-gray-200 pt-8')}>
          <SideNavHelp isExpanded={isExpanded} />
          <SideNavNotification isExpanded={isExpanded} />
          <SideNavProfile
            timeZone={timeZone}
            handleSetTimezone={handleSetTimezone}
            selectedTimezone={selectedTimezone}
            user={user}
            isExpanded={isExpanded}
            selectedLanguage={selectedLanguage}
            handleSetLocale={handleSetLocale}
          />
        </div>
      </div>
    </div>
  );
};

export default SideNavigation;
