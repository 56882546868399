function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const constructFullName = (firstName: string, lastName: string, middleName: string, isOnlyFirstName: boolean) => {
  if (firstName || lastName) {
    if (isOnlyFirstName) {
      return capitalizeFirstLetter(firstName);
    }
    return `${capitalizeFirstLetter(firstName)} ${
      middleName ? `${capitalizeFirstLetter(middleName)} ` : ''
    }${capitalizeFirstLetter(lastName)}`;
  }
  return '';
};

export default function getDisplayName(
  data?: {
    name?: string;
    first_name?: string;
    middle_name?: string;
    last_name?: string;
    email?: string;
  },
  isOnlyFirstName = false,
  isOnlyName = false,
): string {
  if (!data) return '';
  let fullName = '';
  const name: string = (data.name || '').trim();
  if (name) {
    fullName = name;
  } else {
    const firstName: string = (data.first_name || '').trim();
    const middleName: string = (data.middle_name || '').trim();
    const lastName: string = (data.last_name || '').trim();
    fullName = constructFullName(firstName, lastName, middleName, isOnlyFirstName);
  }
  return isOnlyName ? fullName : ((fullName || data.email) ?? '');
}
