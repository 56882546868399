import React, { useState } from 'react';

import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import PanelHeader from '@components/Flows/ConfigPanels/PanelHeader';
import configCardMessages from '@components/Flows/ConfigureCard/messages';
import { InitialStateType } from '@containers/flowDetails/slice';
import { proviewConsoleStackSet } from '@utils/mixpanelActions';

import StackList from './stackList';
import messages from '../messages';

interface OffboardingConfigProps {
  config: {
    stack: boolean;
  };
  isUpdated: boolean;
  setDefaultPanelType: () => void;
  saveConfig: () => void;
  updateStackSetting: (obj: { stack: string }) => void;
  updateFlowConfigStatus: InitialStateType['updateFlowConfigStatus'];
  createFlowConfigStatus: InitialStateType['createFlowConfigStatus'];
}

const StackSettingPanel: React.FC<OffboardingConfigProps> = (props) => {
  const {
    setDefaultPanelType,
    updateStackSetting,
    saveConfig,
    updateFlowConfigStatus,
    createFlowConfigStatus,
    isUpdated,
  } = props;

  const [selectedState, setSelectedState] = useState('');

  const handleStackSelect = (selectedId: string) => {
    setSelectedState(selectedId);
    updateStackSetting({ stack: selectedId });
  };

  const updateStack = () => {
    const stackProperty = {
      Stake: selectedState,
    };
    saveConfig();
    proviewConsoleStackSet(stackProperty);
  };

  return (
    <>
      <PanelHeader
        label={<FormattedMessage {...configCardMessages.stack_setting_label} />}
        description={<FormattedMessage {...configCardMessages.stack_setting_description} />}
        setDefaultPanelType={setDefaultPanelType}
      />
      <div className="pt-2">
        <StackList onSelect={handleStackSelect} />
      </div>
      <div className="w-full px-5 py-4 mt-auto border-t border-gray-200 ">
        <Button
          isLoading={updateFlowConfigStatus.isConfigUpdateLoading || createFlowConfigStatus.isLoading}
          data-testid="save_button"
          className="w-full text-sm"
          disabled={!isUpdated}
          onClick={updateStack}
          tabIndex={0}
        >
          <FormattedMessage {...messages.save_changes} />
        </Button>
      </div>
    </>
  );
};

export default StackSettingPanel;
