import React from 'react';

import { forEach, reduce } from 'lodash';
import { FormattedMessage } from 'react-intl';

import ToggleButton from '@components/Base/ToggleButton';
import messages from '@components/Flows/ConfigPanels/messages';

type SupportedBrowsersProps = {
  config: {
    browser_chrome_desktop: boolean;
    browser_chrome_mobile: boolean;
    browser_edge_desktop: boolean;
    browser_edge_mobile: boolean;
    browser_firefox_desktop: boolean;
    browser_firefox_mobile: boolean;
    browser_safari_mobile: boolean;
  };
  updateBrowserConfig: (config: { [key: string]: boolean }) => void;
};

type BrowserListType = {
  name: string;
  key: keyof SupportedBrowsersProps['config'];
};

const desktopBrowserList: BrowserListType[] = [
  {
    name: 'Google Chrome',
    key: 'browser_chrome_desktop',
  },
  {
    name: 'Mozilla Firefox',
    key: 'browser_firefox_desktop',
  },
  {
    name: 'Microsoft Edge',
    key: 'browser_edge_desktop',
  },
];

const mobileBrowserList: BrowserListType[] = [
  {
    name: 'Google Chrome',
    key: 'browser_chrome_mobile',
  },
  {
    name: 'Mozilla Firefox',
    key: 'browser_firefox_mobile',
  },
  {
    name: 'Microsoft Edge',
    key: 'browser_edge_mobile',
  },
  {
    name: 'Safari',
    key: 'browser_safari_mobile',
  },
];

const SupportedBrowsers = (props: SupportedBrowsersProps) => {
  const { config, updateBrowserConfig } = props;

  const getCheckedStatus = (arr: BrowserListType[]) => {
    const checked = reduce(
      arr,
      (isChecked: boolean, item: BrowserListType) => {
        return isChecked && config[item.key];
      },
      true,
    );
    return checked;
  };

  const handleUpdateConfig = (checked: boolean, browserList: BrowserListType[]) => {
    const updatedConfig = { ...config };
    forEach(browserList, (configItem: BrowserListType) => {
      updatedConfig[configItem.key] = checked;
    });
    updateBrowserConfig(updatedConfig);
  };

  return (
    <div>
      <div className="flex flex-col px-4 py-3">
        <span className="text-base font-semibold text-gray-900">
          <FormattedMessage {...messages.supported_browsers} />
        </span>
        <span className="text-xs text-gray-600">
          <FormattedMessage {...messages.supported_browsers_description} />
        </span>
      </div>
      <div className="flex items-center gap-2">
        <span className="h-[1px] rounded bg-gray-200 w-2" />
        <span className="text-gray-400 text-sm font-medium capitalize">
          <FormattedMessage {...messages.web} />
        </span>
        <span className="h-[1px] rounded bg-gray-200 w-[130px] capitalize" />
        <span className="text-gray-400 text-sm font-medium">
          <FormattedMessage {...messages.mobile} />
        </span>
        <span className="h-[1px] rounded bg-gray-200 w-24" />
      </div>
      <div className="flex">
        <div className="flex flex-col gap-2.5 px-4 py-3 basis-1/2">
          <div className="flex items-center gap-3">
            <ToggleButton
              data-testid="enable_switch_desktop"
              checked={getCheckedStatus(desktopBrowserList)}
              onClick={(checked: boolean) => handleUpdateConfig(checked, desktopBrowserList)}
              disabled={false}
            />
            <span className="text-sm font-semibold text-gray-900">
              <FormattedMessage {...messages.enable_all} />
            </span>
          </div>
          {desktopBrowserList.map((browser) => (
            <div className="flex items-center gap-3" key={browser.key}>
              <ToggleButton
                data-testid={`${browser.key}_switch`}
                checked={config[browser.key]}
                onClick={(checked: boolean) => handleUpdateConfig(checked, [browser])}
                disabled={false}
              />
              <span className="text-sm text-gray-900">{browser.name}</span>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-2.5 pl-4 pr-3 py-3 basis-1/2">
          <div className="flex items-center gap-3">
            <ToggleButton
              data-testid="enable_switch_mobile"
              checked={getCheckedStatus(mobileBrowserList)}
              onClick={(checked: boolean) => handleUpdateConfig(checked, mobileBrowserList)}
              disabled={false}
            />
            <span className="text-sm font-semibold text-gray-900">
              <FormattedMessage {...messages.enable_all} />
            </span>
          </div>
          {mobileBrowserList.map((browser) => (
            <div className="flex items-center gap-3" key={browser.key}>
              <ToggleButton
                data-testid={`${browser.key}_switch`}
                checked={config[browser.key]}
                onClick={(checked: boolean) => handleUpdateConfig(checked, [browser])}
                disabled={false}
              />
              <span className="text-sm text-gray-900">{browser.name}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SupportedBrowsers;
