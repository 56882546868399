import React, { useMemo } from 'react';

import ConfigureCard from '@components/Flows/ConfigureCard';
import AttendeeOnboarding, {
  cardDetail as AttendeeOnboardingCardDetails,
} from '@components/Flows/ConfigureCard/AttendeeOnboarding';
import CustomBranding, { cardDetail as cBCardDetails } from '@components/Flows/ConfigureCard/CustomBranding';
import Offboarding, { cardDetail as offboardingCardDetails } from '@components/Flows/ConfigureCard/Offboarding';
import { ConfigCardsComponentPropType } from '@components/Flows/FlowDetails/types';
import type { FlowConfigPayloadType } from '@containers/flowDetails/types';

import SettingStakes, { cardDetail as stackDetails } from '../ConfigureCard/SettingStakes';

interface ConfigCardListProps {
  config: FlowConfigPayloadType | null;
  openConfig: (type: string) => void;
}

const ConfigCardList = (props: ConfigCardListProps) => {
  const { config, openConfig } = props;

  const configCardsData = useMemo(
    () =>
      config
        ? [
            {
              cardDetail: cBCardDetails,
              component: CustomBranding,
              componentProps: {
                isLogoUploaded: !!config.branding_logo_file_id,
                branding_primary_color: config.branding_primary_color,
              },
            },
            {
              cardDetail: AttendeeOnboardingCardDetails,
              component: AttendeeOnboarding,
              componentProps: config,
            },
            {
              cardDetail: offboardingCardDetails,
              component: Offboarding,
              componentProps: {
                isFeedbackEnabled: config.feedback,
              },
            },

            {
              cardDetail: stackDetails,
              component: SettingStakes,
              componentProps: {
                isFeedbackEnabled: config.feedback,
              },
            },
          ]
        : [],
    [config],
  );

  return (
    <div className="p-5 pl-8 pr-7 flex flex-col gap-7">
      {configCardsData.map(({ cardDetail, component: Component, componentProps }) => (
        <ConfigureCard cardDetail={cardDetail} openConfig={openConfig} key={cardDetail.type}>
          {<Component {...(componentProps as ConfigCardsComponentPropType)} />}
        </ConfigureCard>
      ))}
    </div>
  );
};

export default ConfigCardList;
