import React from 'react';

import { FormattedMessage } from 'react-intl';

import messages from './messages';

const ComingSoon = () => {
  return (
    <div className="h-full flex items-center justify-center">
      <FormattedMessage {...messages.new_feature} />
    </div>
  );
};

export default ComingSoon;
