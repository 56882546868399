interface Step {
  name: string;
  id: number;
}

interface DriveSchedule {
  sku: {
    price: number;
    currency_code: string;
  };
}

export type Workflow = {
  id: number;
  title: string;
  status: string;
  steps: Step[];
  updated_by: number;
  updated_at: string;
  drive_schedule: DriveSchedule | null;
  updated_by_details: {
    email: string;
    name: string;
    id: number;
  };
};

export type InitialState = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: Error | null;
  data: Workflow[];
};

export const sessionType = {
  LIVE: 'Live',
  RECORDANDREVIEW: 'Record & review',
  RECORDED: 'Recorded',
};

export const sessionTypeList = [
  { label: 'live', value: sessionType.LIVE },
  { label: 'record&review', value: sessionType.RECORDANDREVIEW },
  { label: 'recorded', value: sessionType.RECORDED },
];

export const wftJobStatusTheme = {
  draft: { bg: 'bg-gray-100', text: 'text-gray-900' },
  closed: { bg: 'bg-orange-50', text: 'text-orange-600' },
  open: { bg: 'bg-blue-50', text: 'text-blue-900' },
  cancelled: { bg: 'bg-red-50', text: 'text-red-600' },
};

export const wftJobStatus = {
  DRAFT: 'DRAFT',
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  CANCELLED: 'CANCELLED',
};

export const wftJobStatusList = [
  {
    type: ['workflow'],
    key: wftJobStatus.DRAFT,
    label: 'Draft',
    theme: wftJobStatusTheme.draft,
  },
  {
    type: ['workflow'],
    key: wftJobStatus.OPEN,
    label: 'Open',
    theme: wftJobStatusTheme.open,
  },
  {
    type: ['workflow'],
    key: wftJobStatus.CLOSED,
    label: 'Closed',
    theme: wftJobStatusTheme.open,
  },
  {
    type: ['workflow'],
    key: wftJobStatus.CANCELLED,
    label: 'Cancelled',
    theme: wftJobStatusTheme.cancelled,
  },
];
