import React from 'react';

import NoResultsFound from '@images/svgs/noResultsFound.svg';

import messages from './messages';

type EmptyRecordProps = {
  colSpan: number;
};

const EmptyRecord = ({ colSpan }: EmptyRecordProps) => {
  const state = {
    imageUrl: NoResultsFound,
    title: messages.empty_record_title.defaultMessage,
    description: messages.empty_record_description.defaultMessage,
  };

  return (
    <td colSpan={colSpan}>
      <div className="flex justify-center w-full h-full">
        <div className="sticky left-0">
          <div className="flex flex-col items-center justify-center mt-24 text-center text-gray-900">
            <img src={state.imageUrl} alt="empty-table" className="mb-[1.5rem]" />
            <div className="text-lg font-semibold">{state.title}</div>
            <div className="w-64 text-sm font-normal">{state.description}</div>
          </div>
        </div>
      </div>
    </td>
  );
};

export default EmptyRecord;
