import React from 'react';

import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';

import LoaderAnimation from '@images/LoaderAnimation.json';

const Loader = () => {
  return (
    <div data-testid="lottie-loader" className="flex items-center justify-center min-h-screen">
      <LottiePlayer className="w-[513px] h-[513px]" autoplay loop src={LoaderAnimation} />
    </div>
  );
};

export default Loader;
