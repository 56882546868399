import React from 'react';

import classNames from 'classnames';
import _ from 'lodash';

export interface YearViewProps {
  startingDate?: Date;
  onYearSelect?: (year: number) => void;
  selectedYear?: number;
}

const YearView: React.FC<YearViewProps> = ({ startingDate, onYearSelect, selectedYear: styear }) => {
  // Determine the initial year range and selected year
  const startYear = startingDate ? startingDate.getFullYear() : new Date().getFullYear();
  const years = _.range(startYear - 5, startYear + 7);

  const handleYearClick = (year: number) => {
    if (onYearSelect) {
      onYearSelect(year);
    }
  };

  const handleYearKeyDown = (event: React.KeyboardEvent<HTMLDivElement>, year: number) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleYearClick(year);
    }
  };

  return (
    <div className="pt-4 flex flex-wrap w-[225px] h-[184px] mb-2">
      {years.map((year, index) => {
        return (
          <div
            key={index}
            role="button"
            tabIndex={0}
            onClick={() => handleYearClick(year)}
            onKeyDown={(event) => handleYearKeyDown(event, year)}
            className={classNames(
              'w-[calc(25%)] h-[61px] flex justify-center items-center cursor-pointer rounded-md font-roboto font-normal text-sm leading-[21px] text-gray-900',
              {
                'bg-[#1D4ED80D]': styear === year,
              },
            )}
          >
            {year}
          </div>
        );
      })}
    </div>
  );
};

export default YearView;
