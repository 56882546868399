import React from 'react';

import { ColumnDef } from '@tanstack/react-table';

import Table from '@components/Base/Table';

import { Flow } from './columns';

export interface FlowListTableProps {
  data: Flow[];
  columns: ColumnDef<Flow>[];
  setPagination?: React.Dispatch<React.SetStateAction<{ pageIndex: number; pageSize: number }>>;
  pagination?: { pageIndex: number; pageSize: number };
  searchText?: string;
  isLoading?: boolean;
}

const FlowListTable = ({ data, columns, setPagination, pagination, isLoading }: FlowListTableProps) => {
  return (
    <div className="h-full mt-5">
      <Table<Flow>
        data={data}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        setPagination={setPagination}
      />
    </div>
  );
};

export default FlowListTable;
