import React, { ChangeEvent, useState } from 'react';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TrashIcon } from '@heroicons/react/24/outline';
import { find } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import { Dropdown, DropdownValue } from '@components/Base/Dropdown';
import { Option } from '@components/Base/GroupDropdown';
import Modal from '@components/Base/Modal';
import { Textbox } from '@components/Base/Textbox';

import messages from './messages';

type BlackListModalProps = {
  blackListApps: {
    windows: string[];
    mac: string[];
  };
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (obj: BlackListModalProps['blackListApps']) => void;
};

type AppType = {
  value: string;
  operator: Option | null;
};

const appInitialValue = {
  value: '',
  operator: null,
};

const operatorOptions = [
  { value: 'windows', label: 'Windows' },
  { value: 'mac', label: 'Mac' },
];

const BlackListModal = (props: BlackListModalProps) => {
  const { blackListApps, isOpen, onClose, onSubmit } = props;
  const { windows, mac } = blackListApps;
  const [appList, setAppList] = useState<AppType[]>([]);
  const [currentApp, setCurrentApp] = useState<AppType>(appInitialValue);
  const [isError, setIsError] = useState(false);
  const updateCurrentApp = (val: ChangeEvent<HTMLInputElement> | Option) => {
    if (isError) setIsError(false);
    if ('target' in val) {
      setCurrentApp({
        value: val.target.value,
        operator: currentApp.operator,
      });
    } else if ('value' in val) {
      setCurrentApp({
        value: currentApp.value,
        operator: val as Option,
      });
    }
  };

  const handleAddApp = () => {
    if (find(appList, currentApp)) {
      setIsError(true);
    } else {
      const app = { ...currentApp };
      setAppList([...appList, app]);
      setCurrentApp(appInitialValue);
    }
  };

  const handleDelete = (app: AppType) => {
    const index = appList.indexOf(app);
    const updatedAppList = [...appList.slice(0, index), ...appList.slice(index + 1)];
    setAppList(updatedAppList);
  };

  const handleBlackListApp = () => {
    const finalList: BlackListModalProps['blackListApps'] = {
      windows: [],
      mac: [],
    };

    appList.forEach((app: AppType) => {
      if (app.operator?.value === 'windows') finalList.windows.push(app.value);
      else if (app.operator?.value === 'mac') finalList.mac.push(app.value);
    });

    onSubmit(finalList);
  };

  return (
    <Modal size="lg" show={isOpen} onCancel={onClose}>
      <div className="flex flex-col min-h-[510px]">
        <h3 className="text-xl font-semibold leading-6" id="modal-title">
          <FormattedMessage {...messages.blacklist_apps} />
        </h3>
        <div className="mt-3 mb-7">
          <span className="text-gray-900 text-sm">
            <FormattedMessage {...messages.current_blacklist_apps} />
          </span>
          {windows && (
            <div className="bg-gray-100 p-1 mt-2 mb-3 text-gray-600 text-xs">
              <span className="font-semibold">Windows :</span> {windows.join(', ')}
            </div>
          )}
          {mac && (
            <div className="bg-gray-100 p-1 mt-2 text-gray-600 text-xs">
              <span className="font-semibold">Mac :</span> {mac.join(', ')}
            </div>
          )}
        </div>
        <div>
          <span className="text-gray-900 text-sm">
            <FormattedMessage {...messages.add_more_apps} />
          </span>
          <div className="flex items-center gap-3 mt-2 ">
            <Textbox placeholder="Enter Name *" value={currentApp.value} onChange={updateCurrentApp} className="w-60" />
            <div className="min-w-48">
              <Dropdown
                id="1"
                placeholder="Operating system *"
                value={currentApp.operator as DropdownValue}
                options={operatorOptions}
                onChange={(option) => updateCurrentApp(option as Option)}
                isSearchable={false}
              />
            </div>
            <Button
              className="w-full"
              onClick={handleAddApp}
              disabled={!currentApp.value || !currentApp.operator?.value}
              data-testid="add-app-button"
            >
              <FontAwesomeIcon icon={faPlus} />
              <FormattedMessage {...messages.add_app} />
            </Button>
          </div>
          {isError && (
            <span className="text-sm text-red-700">
              <FormattedMessage {...messages.duplicate_entry} />
            </span>
          )}
          {appList.length > 0 && (
            <div className="border-gray-100/75 border-t border-x max-h-[168px] overflow-y-auto mt-4">
              {appList.map((app) => (
                <div
                  className="text-sm odd:bg-white border-b border-gray-100/75 bg-[#F9FAFB] py-3.5 px-4 flex items-center"
                  key={app.value}
                >
                  <span className="w-60 truncate text-nowrap " title={app.value}>
                    {app.value}
                  </span>
                  <span>{app.operator?.label}</span>
                  <TrashIcon
                    className="w-5 h-5 ml-auto"
                    onClick={() => handleDelete(app)}
                    data-testid={`delete-icon-${app.value}`}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="flex items-center justify-end w-full gap-3 mt-auto absolute bottom-0 left-0 right-0 p-6 rounded-b-lg bg-white">
          <Button variant="outline" data-testid="cancel_button" onClick={onClose}>
            <FormattedMessage {...messages.cancel} />
          </Button>

          <Button onClick={handleBlackListApp} disabled={appList.length === 0 || isError}>
            <FormattedMessage {...messages.blacklist_app} />
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BlackListModal;
