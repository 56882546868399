import React, { useState } from 'react';

import { faCheck, faCopy } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import { downloadBulkCreatedVoucher } from '@containers/vouchers/helpers';
import { CreateVoucherResponse } from '@containers/vouchers/saga';
import CheckAnimation from '@images/CheckAnimation.json';

import messages from './messages';

interface CreateVoucherSuccessProps {
  createdVoucher: CreateVoucherResponse | null;
  createVoucherDownloaded: () => void;
}

const CreateVoucherSuccess: React.FC<CreateVoucherSuccessProps> = ({ createdVoucher, createVoucherDownloaded }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (createdVoucher) {
      navigator.clipboard.writeText(createdVoucher[0].code).then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      });
    }
  };

  const downloadVoucher = () => {
    if (createdVoucher) {
      downloadBulkCreatedVoucher(createdVoucher, 'coupons.xlsx');
      createVoucherDownloaded();
    }
  };

  return (
    <div className="h-[580px]">
      <div className="text-center">
        <LottiePlayer className="relative max-w-[150px] w-full pt-24" autoplay loop src={CheckAnimation} />
        <p className="text-lg font-medium">
          <FormattedMessage {...messages.voucher_ready} />
        </p>
        <p className="mx-auto text-gray-500 text-sm mt-1.5">
          <FormattedMessage {...messages.voucher_ready_description} />
        </p>
        {createdVoucher && createdVoucher.length > 1 ? (
          <Button className="mx-auto mt-3" onClick={downloadVoucher}>
            <ArrowDownTrayIcon className="w-5 h-5 cursor-pointer mr-2" />
            <FormattedMessage {...messages.download_now} />
          </Button>
        ) : (
          <button
            tabIndex={0}
            className="flex items-center justify-center mx-auto mt-3"
            onClick={handleCopy}
            aria-label="Copy to clipboard"
          >
            <span className="mr-2 font-normal text-blue-700 cursor-pointer" aria-hidden="true">
              {copied ? <FormattedMessage {...messages.copied} /> : <FormattedMessage {...messages.copy_voucher} />}
            </span>
            {copied ? (
              <FontAwesomeIcon icon={faCheck} className="w-4 h-4 text-primary" />
            ) : (
              <FontAwesomeIcon icon={faCopy} className="w-4 h-4 cursor-pointer text-primary" />
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default CreateVoucherSuccess;
