import { ApolloClient, HttpLink, InMemoryCache, NormalizedCacheObject, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import { OperationDefinitionNode } from 'graphql';
import localforage from 'localforage';
import { get } from 'lodash';
import { SubscriptionClient } from 'subscriptions-transport-ws';

import type { ROLE } from '@containers/app/types';
import { getHasuraHeaders } from '@utils/helpers';

import { getCurrentRole } from './data/roles';

export class Client {
  private static instance: Client;

  private constructor() {
    if (!Client.instance) {
      Client.instance = this;
    }
  }

  private _client: ApolloClient<NormalizedCacheObject> | undefined;

  get client(): ApolloClient<NormalizedCacheObject> {
    if (!this._client) {
      throw new Error('Apollo Client not initialized. Call createClient first.');
    }
    return this._client;
  }

  private _persistor: CachePersistor<NormalizedCacheObject> | undefined;

  get persistor(): CachePersistor<NormalizedCacheObject> {
    if (!this._persistor) {
      throw new Error('Apollo Client persistor not initialized. Call createClient first.');
    }
    return this._persistor;
  }

  static getInstance(): Client {
    if (!Client.instance) {
      Client.instance = new Client();
    }
    return Client.instance;
  }

  async createClient({ graphToken, errorCallback }: { graphToken: Promise<string>; errorCallback?: () => void }) {
    if (this._client) return this._client;
    const token = await graphToken;
    const parsedHeaders = getHasuraHeaders(token);
    const roles: ROLE[] | undefined = get(parsedHeaders, 'X-Hasura-Allowed-Roles');
    const headers = {
      Authorization: `Bearer ${token}`,
      'x-hasura-role': getCurrentRole(roles),
    };
    const httplinkUrl = `${process.env.HASURA_HTTP}/v1/graphql`;
    const httpLink = new HttpLink({
      uri: httplinkUrl,
      headers,
    });

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        // eslint-disable-next-line no-restricted-syntax
        for (const err of graphQLErrors) {
          switch (err?.extensions?.code) {
            case 'invalid-jwt':
            case 'start-failed':
              if (errorCallback) {
                errorCallback();
              } else {
                window.location.href = '/logout';
              }
              break;
            default:
              if (console) {
                console.warn(`[GraphQL error]: Message: ${err.message}, Location: ${err?.extensions?.code}`);
              }
          }
        }
      }
      if (networkError && console) {
        console.error(`[Network error]: ${networkError}`);
      }
    });

    const wss = process.env.HASURA_WS;
    const wssClient = new SubscriptionClient(wss!, {
      reconnect: true,
      connectionParams: {
        headers,
      },
    });

    const wsLink = new WebSocketLink(wssClient);

    const link = split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query) as OperationDefinitionNode;
        return kind === 'OperationDefinition' && operation === 'subscription';
      },
      wsLink,
      httpLink,
    );

    const cache = new InMemoryCache();
    this._persistor = new CachePersistor({
      cache,
      maxSize: false,
      debounce: 500,
      storage: new LocalForageWrapper(localforage),
    });

    await localforage.setDriver([localforage.INDEXEDDB, localforage.LOCALSTORAGE]);

    this._client = new ApolloClient({
      link: errorLink.concat(link),
      cache,
      name: 'proview-console-client',
      version: process.env.VERSION || 'dev',
    });

    return this._client;
  }

  async clearCache() {
    if (this._persistor) {
      return this._persistor.purge();
    }
    return Promise.resolve();
  }

  removeClient() {
    if (this._client) {
      this._client.stop();
      return this.client.clearStore();
    }
    return true;
  }
}

const instance = Client.getInstance();

export default instance;
