import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { CreateVoucherPadload } from './saga';
import { RedeemedVoucherList, Voucher, VouchersState } from './types';

export const initialState: VouchersState = {
  isLoading: false,
  isSuccess: false,
  error: null,
  totalCount: -1,
  data: [],
  issuedVouchersList: {
    isLoading: false,
    isSuccess: false,
    error: null,
    data: [],
    voucherCodes: {
      isLoading: false,
      isSuccess: false,
      error: null,
      codes: [],
    },
  },
  searchConditions: null,
  skus: {
    isLoading: false,
    isSuccess: false,
    error: null,
    skuList: [],
  },
  redeemedVouchers: {
    isLoading: false,
    isSuccess: false,
    totalCount: -1,
    error: null,
    data: [],
  },
  redeemedVoucherFullList: {
    isLoading: false,
    isSuccess: false,
    error: null,
    data: [],
  },
  newlyCreatedVouchers: {
    isLoading: false,
    isSuccess: false,
    error: null,
    data: null,
  },
  tags: {
    isLoading: false,
    isSuccess: false,
    error: null,
    data: [],
  },
};

export const voucherList = createSlice({
  name: 'voucherList',
  initialState: initialState,
  reducers: {
    getPayVouchersAction: (
      state,
      action: PayloadAction<{
        searchConditions: object[] | object;
        conditions: object;
        limit: number | null;
        offset: number;
        fetchCompleteList?: boolean;
        isDownloadList?: boolean;
      }>,
    ) => {
      if (action.payload.fetchCompleteList && action.payload.isDownloadList) {
        state.issuedVouchersList.isLoading = true;
      } else if (!action.payload.fetchCompleteList) {
        state.isLoading = true;
        state.searchConditions = action.payload.searchConditions;
      }
    },

    getPayVouchersSuccessAction: (state, { payload }: PayloadAction<{ data: Voucher[]; count: number }>) => {
      state.data = payload.data;
      state.totalCount = payload.count;
      state.isSuccess = true;
      state.isLoading = false;
      state.error = null;
    },

    getPayIssuedVouchersSuccessAction: (
      state,
      { payload }: PayloadAction<{ data: Voucher[]; isDownloadList: boolean }>,
    ) => {
      if (payload.isDownloadList) {
        state.issuedVouchersList.data = payload.data;
        state.issuedVouchersList.isSuccess = true;
        state.issuedVouchersList.isLoading = false;
        state.issuedVouchersList.error = null;
      } else {
        const voucherCodes = payload.data.map((voucher) => voucher.code);
        state.issuedVouchersList.voucherCodes.isSuccess = true;
        state.issuedVouchersList.voucherCodes.isLoading = false;
        state.issuedVouchersList.voucherCodes.codes = voucherCodes;
        state.issuedVouchersList.voucherCodes.error = null;
      }
    },

    getPayVouchersErrorAction: (state, { payload: error }) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.error = error;
    },

    getPayIssuedVouchersErrorAction: (state, { payload: error }) => {
      state.issuedVouchersList.isLoading = false;
      state.issuedVouchersList.isSuccess = false;
      state.issuedVouchersList.error = error;
    },

    getSkuAction: (state) => {
      state.skus.isLoading = true;
    },

    getSkuSuccessAction: (state, { payload: payload }) => {
      state.skus.isSuccess = true;
      state.skus.skuList = payload;
      state.skus.isLoading = false;
      state.skus.error = null;
    },

    getSkuErrorAction: (state, { payload: error }) => {
      state.skus.isLoading = false;
      state.skus.isSuccess = false;
      state.skus.error = error;
    },

    getRedeemedVoucherAction: (
      state,
      action: PayloadAction<{
        searchConditions: object[] | object;
        conditions: object;
        limit: number | null;
        offset: number;
        fetchCompleteList?: boolean;
      }>,
    ) => {
      if (action.payload.fetchCompleteList) {
        state.redeemedVoucherFullList.isLoading = true;
      } else {
        state.redeemedVouchers.isLoading = true;
        state.searchConditions = action.payload.searchConditions;
      }
    },

    getRedeemedVoucherSuccessAction: (
      state,
      { payload }: PayloadAction<{ data: RedeemedVoucherList[]; count: number }>,
    ) => {
      state.redeemedVouchers.data = payload.data;
      state.redeemedVouchers.totalCount = payload.count;
      state.redeemedVouchers.isSuccess = true;
      state.redeemedVouchers.isLoading = false;
      state.redeemedVouchers.error = null;
    },

    getFullRedeemedVoucherSuccessAction: (state, { payload }: PayloadAction<{ data: RedeemedVoucherList[] }>) => {
      state.redeemedVoucherFullList.data = payload.data;
      state.redeemedVoucherFullList.isSuccess = true;
      state.redeemedVoucherFullList.isLoading = false;
      state.redeemedVoucherFullList.error = null;
    },

    getRedeemedVoucherErrorAction: (state, { payload: error }) => {
      state.redeemedVouchers.isLoading = false;
      state.redeemedVouchers.isSuccess = false;
      state.redeemedVouchers.error = error;
    },

    getFullRedeemedVoucherErrorAction: (state, { payload: error }) => {
      state.redeemedVoucherFullList.isLoading = false;
      state.redeemedVoucherFullList.isSuccess = false;
      state.redeemedVoucherFullList.error = error;
    },

    createVoucherAction: (state, { payload: _payload }: PayloadAction<CreateVoucherPadload[]>) => {
      state.newlyCreatedVouchers.isLoading = true;
      state.newlyCreatedVouchers.isSuccess = false;
      state.newlyCreatedVouchers.error = null;
    },

    createVoucherSuccessAction: (state, { payload: payload }) => {
      state.newlyCreatedVouchers.isSuccess = true;
      state.newlyCreatedVouchers.data = payload;
      state.newlyCreatedVouchers.isLoading = false;
      state.newlyCreatedVouchers.error = null;
    },

    createVoucherErrorAction: (state, { payload: error }) => {
      state.newlyCreatedVouchers.isLoading = false;
      state.newlyCreatedVouchers.isSuccess = false;
      state.newlyCreatedVouchers.error = error;
    },

    resetVoucherSuccessState: (state) => {
      state.newlyCreatedVouchers.isSuccess = false;
    },

    getPayTagAction: (state) => {
      state.tags.isLoading = true;
      state.tags.isSuccess = false;
      state.tags.error = null;
    },

    getPayTagSuccessAction: (state, { payload: payload }) => {
      state.tags.isLoading = false;
      state.tags.isSuccess = true;
      state.tags.data = payload;
    },

    getPayTagErrorAction: (state, { payload: error }) => {
      state.tags.isLoading = false;
      state.tags.isSuccess = false;
      state.tags.error = error;
    },
  },
});

export const {
  getPayVouchersAction,
  getPayVouchersSuccessAction,
  getPayVouchersErrorAction,
  getSkuAction,
  getSkuSuccessAction,
  getSkuErrorAction,
  getRedeemedVoucherAction,
  getRedeemedVoucherSuccessAction,
  getRedeemedVoucherErrorAction,
  createVoucherAction,
  createVoucherSuccessAction,
  createVoucherErrorAction,
  resetVoucherSuccessState,
  getPayIssuedVouchersSuccessAction,
  getFullRedeemedVoucherSuccessAction,
  getPayIssuedVouchersErrorAction,
  getFullRedeemedVoucherErrorAction,
  getPayTagAction,
  getPayTagSuccessAction,
  getPayTagErrorAction,
} = voucherList.actions;

export default voucherList.reducer;
