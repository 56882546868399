import React, { useCallback, useEffect, useRef, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import calendarIcon from '../../../../images/Calendar.svg';
import messages from '../messages';

export interface DateRangeInputBoxProps {
  onDateChange: (dateRange: { from: Date | null; to: Date | null }) => void;
  toDate?: Date | null;
  fromDate?: Date | null;
  onIconClick: () => void;
  onInputFocus: () => void;
  setSelectedRangeList: (option: null) => void;
}

const DateRangeInputBox = (props: DateRangeInputBoxProps) => {
  const { onDateChange, toDate, fromDate, onIconClick, onInputFocus, setSelectedRangeList } = props;

  const intl = useIntl();
  const [toDay, setToDay] = useState('');
  const [toMonth, setToMonth] = useState('');
  const [toYear, setToYear] = useState('');
  const [isValidDate, setIsValidDate] = useState(true);

  const [fromDay, setFromDay] = useState('');
  const [fromMonth, setFromMonth] = useState('');
  const [fromYear, setFromYear] = useState('');
  const [isValidFromDate, setIsValidFromDate] = useState(true);

  const [isValidDateRange, setIsValidDateRange] = useState(true);

  const containerRef = useRef<HTMLDivElement>(null);
  const fromDayInputRef = useRef<HTMLInputElement>(null);
  const fromMonthInputRef = useRef<HTMLInputElement>(null);
  const fromYearInputRef = useRef<HTMLInputElement>(null);
  const dayInputRef = useRef<HTMLInputElement>(null);
  const monthInputRef = useRef<HTMLInputElement>(null);
  const yearInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (toDate) {
      updateDateValues(toDate, setToDay, setToMonth, setToYear, setIsValidDate);
    }

    if (fromDate) {
      updateDateValues(fromDate, setFromDay, setFromMonth, setFromYear, setIsValidFromDate);
    }
  }, [toDate, fromDate]);

  useEffect(() => {
    validateDate(toDay, toMonth, toYear, setIsValidDate);
  }, [toDay, toMonth, toYear]);

  useEffect(() => {
    validateDate(fromDay, fromMonth, fromYear, setIsValidFromDate);
  }, [fromDay, fromMonth, fromYear]);

  const validateDate = useCallback(
    (day: string, month: string, year: string, setIsValid: React.Dispatch<React.SetStateAction<boolean>>) => {
      if (day && month && year) {
        const dayNum = parseInt(day, 10);
        const monthNum = parseInt(month, 10);
        const yearNum = parseInt(year, 10);

        if (!isNaN(dayNum) && !isNaN(monthNum) && !isNaN(yearNum)) {
          const daysInMonth = new Date(yearNum, monthNum, 0).getDate();
          if (dayNum > 0 && dayNum <= daysInMonth && monthNum > 0 && monthNum <= 12 && yearNum > 0) {
            setIsValid(true);
            return new Date(yearNum, monthNum - 1, dayNum);
          }
        }
        setIsValid(false);
        return null;
      } else {
        setIsValid(true);
        return null;
      }
    },
    [],
  );

  const updateDateValues = (
    date: Date,
    setDay: React.Dispatch<React.SetStateAction<string>>,
    setMonth: React.Dispatch<React.SetStateAction<string>>,
    setYear: React.Dispatch<React.SetStateAction<string>>,
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setDay(String(date.getDate()).padStart(2, '0'));
    setMonth(String(date.getMonth() + 1).padStart(2, '0'));
    setYear(String(date.getFullYear()));
    setIsValid(true);
  };

  const handleDayChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setDay: React.Dispatch<React.SetStateAction<string>>,
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>,
    nextInputRef: React.RefObject<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    setIsValid(true);
    if (/^\d*$/.test(value) && value.length <= 2) {
      setDay(value);
      if (value.length === 2) {
        nextInputRef.current?.focus();
      }
    }
  };

  const handleMonthChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setMonth: React.Dispatch<React.SetStateAction<string>>,
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>,
    nextInputRef: React.RefObject<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    setIsValid(true);
    if (/^\d*$/.test(value) && value.length <= 2) {
      setMonth(value);
      if (value.length === 2) {
        nextInputRef.current?.focus();
      }
    }
  };

  const handleYearChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setYear: React.Dispatch<React.SetStateAction<string>>,
    setIsValid: React.Dispatch<React.SetStateAction<boolean>>,
    nextInputRef?: React.RefObject<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    setIsValid(true);
    if (/^\d*$/.test(value) && value.length <= 4) {
      setYear(value);
      if (value.length === 4 && nextInputRef) {
        nextInputRef.current?.focus();
      }
    }
  };

  const validateAllDates = () => {
    const validFromDate = validateDate(fromDay, fromMonth, fromYear, setIsValidFromDate);
    const validToDate = validateDate(toDay, toMonth, toYear, setIsValidDate);

    if (validFromDate && validToDate) {
      if (validFromDate > validToDate) {
        setIsValidDateRange(false);
        return false;
      } else {
        onDateChange({
          from: validFromDate,
          to: validToDate,
        });
        setSelectedRangeList(null);
        setIsValidDateRange(true);
        return true;
      }
    } else {
      return true;
    }
  };

  const handleCalendarIconClick = () => {
    validateAllDates() && onIconClick();
  };

  const handleFocus = () => {
    onInputFocus();
  };

  return (
    <>
      <div
        className="flex flex-col relative w-full max-w-[432px]"
        ref={containerRef}
        tabIndex={-1}
        data-testid="date-input-box"
      >
        <div
          className={`border rounded-md flex items-center p-2 ${
            !isValidDate || !isValidFromDate || !isValidDateRange
              ? 'border-red-600'
              : 'focus-within:border-blue-700 border-gray-200'
          }`}
        >
          <div className="flex items-center w-fit">
            <input
              type="text"
              placeholder="DD"
              value={fromDay}
              onChange={(e) => handleDayChange(e, setFromDay, setIsValidFromDate, fromMonthInputRef)}
              onFocus={handleFocus}
              ref={fromDayInputRef}
              maxLength={2}
              className="text-center p-0 w-8 text-black placeholder-gray-500 border-none focus:outline-none focus:ring-0 focus:border-none"
            />
            <span className="text-base text-gray-900">/</span>
            <input
              type="text"
              placeholder="MM"
              value={fromMonth}
              onChange={(e) => handleMonthChange(e, setFromMonth, setIsValidFromDate, fromYearInputRef)}
              onFocus={handleFocus}
              ref={fromMonthInputRef}
              maxLength={2}
              className="text-center p-0 w-9 text-black placeholder-gray-500 border-none focus:outline-none focus:ring-0 focus:border-none"
            />
            <span className="text-base text-gray-900">/</span>
            <input
              type="text"
              placeholder="YYYY"
              value={fromYear}
              onChange={(e) => handleYearChange(e, setFromYear, setIsValidFromDate, dayInputRef)}
              onFocus={handleFocus}
              ref={fromYearInputRef}
              maxLength={4}
              className="text-center p-0 w-12 text-black placeholder-gray-500 border-none focus:outline-none focus:ring-0 focus:border-none"
            />
          </div>{' '}
          -{' '}
          <div className="flex items-center w-fit">
            <input
              type="text"
              placeholder="DD"
              value={toDay}
              onChange={(e) => handleDayChange(e, setToDay, setIsValidDate, monthInputRef)}
              onFocus={handleFocus}
              ref={dayInputRef}
              maxLength={2}
              className="text-center p-0 w-8 text-black placeholder-gray-500 border-none focus:outline-none focus:ring-0 focus:border-none"
            />
            <span className="text-base text-gray-900">/</span>
            <input
              type="text"
              placeholder="MM"
              value={toMonth}
              onChange={(e) => handleMonthChange(e, setToMonth, setIsValidDate, yearInputRef)}
              onFocus={handleFocus}
              ref={monthInputRef}
              maxLength={2}
              className="text-center p-0 w-9 text-black placeholder-gray-500 border-none focus:outline-none focus:ring-0 focus:border-none"
            />
            <span className="text-base text-gray-900">/</span>
            <input
              type="text"
              placeholder="YYYY"
              value={toYear}
              onChange={(e) => handleYearChange(e, setToYear, setIsValidDate)}
              onFocus={handleFocus}
              ref={yearInputRef}
              maxLength={4}
              className="text-center p-0 w-12 text-black placeholder-gray-500 border-none focus:outline-none focus:ring-0 focus:border-none"
            />
          </div>
          <div className="cursor-pointer w-[110px] h-6" onClick={handleCalendarIconClick} role="presentation"></div>
          <button
            className="absolute top-1/2 transform -translate-y-1/2 right-2 w-5 h-5 text-gray-500 cursor-pointer"
            aria-label={intl.formatMessage(messages.calendar_icon)}
            onClick={handleCalendarIconClick}
            tabIndex={0}
            data-testid="calendar-icon"
          >
            <img src={calendarIcon} alt={intl.formatMessage(messages.calendar_icon)} className="w-full h-full" />
          </button>
        </div>
      </div>
      {(!isValidFromDate || !isValidDate) && (
        <div className="text-red-600 text-sm mt-1">
          <FormattedMessage {...messages.invalid_date} />
        </div>
      )}
      {!isValidDateRange && (
        <div className="text-red-600 text-sm mt-1">
          <FormattedMessage {...messages.invalid_date_range} />
        </div>
      )}
    </>
  );
};

export default DateRangeInputBox;
