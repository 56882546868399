import { defineMessages } from 'react-intl';

export const scope = 'app.components.Shared.date_range_picker';

export default defineMessages({
  done: {
    id: `${scope}.done`,
    defaultMessage: 'Done',
  },
  clear: {
    id: `${scope}.clear`,
    defaultMessage: 'Clear',
  },
  invalid_date: {
    id: `${scope}.invalid_date`,
    defaultMessage: 'Enter valid date',
  },
  calendar_icon: {
    id: `${scope}.calendar_icon`,
    defaultMessage: 'Calendar Icon',
  },
  invalid_date_range: {
    id: `${scope}.invalid_date_range`,
    defaultMessage: 'End date should be greater than start date',
  },
});
