import React from 'react';

import { FormattedMessage } from 'react-intl';

import messages from './messages';

export type FlagProps = {
  critical: number;
  high: number;
  medium: number;
  low: number;
};

const Flags = ({ critical, high, medium, low }: FlagProps) => {
  return (
    <div className="-top-4 absolute -left-40 inline-flex p-4 flexgap-2.5 items-start rounded-lg shadow-[-4px_4px_100px_0_rgba(0,0,0,0.07)] bg-white text-gray-900 hidden group-hover:flex">
      <div className="font-roboto text-sm font-normal flex flex-row">
        <div className="shrink-0 w-28 flex flex-col">
          <span>
            <FormattedMessage {...messages.critical_alerts} />
          </span>
          <span>
            <FormattedMessage {...messages.high} />
          </span>
          <span>
            <FormattedMessage {...messages.medium} />
          </span>
          <span>
            <FormattedMessage {...messages.low} />
          </span>
        </div>
        <div className="flex flex-col">
          <span>{critical}</span>
          <span>{high}</span>
          <span>{medium}</span>
          <span>{low}</span>
        </div>
      </div>
    </div>
  );
};

export default Flags;
