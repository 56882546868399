import React from 'react';

import Table from '@components/Base/Table';
import { CustomColumnDef } from '@components/Base/Table/types';
import { RedeemedVoucherList } from '@containers/vouchers/types';

interface VouchersTableProps {
  data: RedeemedVoucherList[];
  columns: CustomColumnDef<RedeemedVoucherList>[];
  isLoading: boolean;
  pagination: {
    pageIndex: number;
    pageSize: number;
  };
  pageCount: number;
  setPagination: React.Dispatch<React.SetStateAction<{ pageIndex: number; pageSize: number }>>;
}

const RedeemedVoucherTable = (props: VouchersTableProps) => {
  const { data, columns, isLoading } = props;

  return (
    <div className="h-full mt-5">
      <Table<RedeemedVoucherList> data={data} columns={columns} isMultiselect={false} isLoading={isLoading} />
    </div>
  );
};

export default RedeemedVoucherTable;
