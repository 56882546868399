import React from 'react';

import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';
import messages from '@components/Flows/DeleteFlowModal/messages';

interface DeleteFlowProps {
  handleToggleDeleteFlowModal: () => void;
  openDeleteFlowModal: boolean;
  deleteSelectedFlow: () => void;
}

const DeleteFlowModal = ({ handleToggleDeleteFlowModal, openDeleteFlowModal, deleteSelectedFlow }: DeleteFlowProps) => {
  const handleCloseModal = () => {
    handleToggleDeleteFlowModal();
  };

  return (
    <Modal size="sm" show={openDeleteFlowModal} onCancel={handleCloseModal}>
      <>
        <div className="flex items-start">
          <div className="text-left">
            <p className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">
              <FormattedMessage {...messages.modal_title} />
            </p>
            <div className="mt-4">
              <p className="text-sm font-normal text-gray-900">
                <FormattedMessage {...messages.modal_desciption} />
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse mt-6">
          <Button variant="destructive" onClick={deleteSelectedFlow}>
            <FormattedMessage {...messages.delete_button} />
          </Button>
          <div className="mr-3">
            <Button variant="outline" onClick={handleCloseModal}>
              <FormattedMessage {...messages.back_button} />
            </Button>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default DeleteFlowModal;
