import { store } from '@utils/store';

import {
  createProjectAction,
  editProjectAction,
  projectFormCompleteAction,
  setCurrentProject,
  setProjectFormModal,
} from './slice';
import { CreateProjectActionPayload, EditProjectActionPayload, Project } from './types';

export const createNewProject = (payload: CreateProjectActionPayload) => {
  const filteredPayload = Object.fromEntries(
    Object.entries(payload).filter(([key, value]) => key && Boolean(value.length)),
  ) as CreateProjectActionPayload;
  store.dispatch(createProjectAction(filteredPayload));
};

export const editProject = (payload: EditProjectActionPayload) => {
  store.dispatch(editProjectAction(payload));
};

export const createProjectComplete = (newProject: Project) => {
  store.dispatch(setCurrentProject(newProject));
  store.dispatch(projectFormCompleteAction());
};

export const handleProjectFormOpen = (projectId?: string) => {
  store.dispatch(setProjectFormModal({ isOpen: true, projectId: projectId || '' }));
};

export const handleProjectFormClose = () => {
  store.dispatch(setProjectFormModal({ isOpen: false }));
};
