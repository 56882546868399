import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { User } from '@containers/user/types';

interface UserState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: string | Error;
  data: Array<User>;
}

export const initialState: UserState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: '',
  data: [],
};

const userSlice = createSlice({
  name: 'userList',
  initialState: initialState,
  reducers: {
    getUserListAction: (state: UserState) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.error = '';
    },
    getUserListSuccessAction: (state: UserState, { payload }: PayloadAction<User[]>) => {
      state.isLoading = false;
      state.data = payload;
      state.isSuccess = true;
      state.isError = false;
    },
    getUserListErrorAction: (state: UserState, { payload: error }: PayloadAction<string | Error>) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = true;
      state.error = error;
    },
  },
});

export const { getUserListAction, getUserListSuccessAction, getUserListErrorAction } = userSlice.actions;

export default userSlice.reducer;
