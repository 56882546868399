import React from 'react';

import { FormattedMessage } from 'react-intl';

import type { CardDetailType } from '@components/Flows/FlowDetails/types';
import { CONFIG_TYPE } from '@utils/data/enums';

import messages from './messages';
import settingStakes from '../../../images/svgs/settingStakes.svg';

export const cardDetail: CardDetailType = {
  label: <FormattedMessage {...messages.setting_stakes_label} />,
  subText: <FormattedMessage {...messages.setting_stakes_description} />,
  logo: settingStakes,
  type: CONFIG_TYPE.SETTING_STAKES,
};

export interface SettingStakesProps {
  stakes?: string;
}

const SettingStakes = (props: SettingStakesProps) => {
  const { stakes } = props;

  return <div className="text-sm text-gray-900">{stakes || <FormattedMessage {...messages.no_stakes} />}</div>;
};

export default SettingStakes;
