import React from 'react';

import {
  faCalendarCirclePlus,
  faCalendarPlus,
  faCircleDollar,
  faClipboardList,
  faFolder,
  faGraduationCap,
  faNetworkWired,
  faPersonShelter,
  faShapes,
  faUserGear,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { HomeIcon, TicketIcon, UsersIcon, VideoCameraIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

import { getUserMasterPermissions } from '@utils/permissions/userPermission';

import messages from './messages';
import SettingsNavItem from './settings';
import Flow from '../../../images/svgs/flow.svg';
const userPermission = getUserMasterPermissions();

export interface navSectionProps {
  id: string;
  icon: React.ReactElement;
  label: string | React.ReactElement;
  path: string;
  isArrow?: boolean;
  showNavSection: boolean;
  element?: React.ReactElement;
}

export const navSection: navSectionProps[] = [
  {
    id: 'navhome',
    icon: <HomeIcon className="w-6 h-6" />,
    label: <FormattedMessage {...messages.home} />,
    path: '/home',
    showNavSection: typeof userPermission.Home.canView === 'undefined' ? true : userPermission.Home.canView,
  },
  {
    id: 'navExams',
    icon: <FontAwesomeIcon icon={faGraduationCap} className="w-6 h-6" />,
    label: <FormattedMessage {...messages.exams} />,
    path: '/exams',
    showNavSection: false,
  },
  {
    id: 'navWorkFlows',
    icon: <FontAwesomeIcon icon={faNetworkWired} className="w-6 h-6" />,
    label: <FormattedMessage {...messages.workFlows} />,
    path: '/workFlows',
    showNavSection: typeof userPermission.Exams.canView === 'undefined' ? true : userPermission.Exams.canView,
  },
  {
    id: 'navProject',
    icon: (
      <div className="w-6 h-6">
        <FontAwesomeIcon icon={faFolder} className="w-5 h-5" />
      </div>
    ),
    label: <FormattedMessage {...messages.projects} />,
    path: '/projects',
    showNavSection: typeof userPermission.Projects.canView === 'undefined' ? true : userPermission.Projects.canView,
  },
  {
    id: 'navEvents',
    icon: <FontAwesomeIcon icon={faCalendarCirclePlus} className="w-6 h-6" />,
    label: <FormattedMessage {...messages.events} />,
    path: '',
    showNavSection: typeof userPermission.Events.canView === 'undefined' ? true : userPermission.Events.canView,
  },
  {
    id: 'navFlows',
    icon: <img src={Flow} alt="Flow" className="min-w-6 min-h-6" />,
    label: <FormattedMessage {...messages.flows} />,
    path: '/flows',
    showNavSection: false,
  },
  {
    id: 'navSessions',
    icon: <VideoCameraIcon className="w-6 h-6" />,
    label: <FormattedMessage {...messages.sessions} />,
    path: '/sessions',
    showNavSection: typeof userPermission.Sessions.canView === 'undefined' ? true : userPermission.Sessions.canView,
  },
  {
    id: 'navSetting',
    icon: <FontAwesomeIcon icon={faUserGear} className="w-6 h-6" />,
    label: <SettingsNavItem />,
    path: '',
    isArrow: true,
    showNavSection: typeof userPermission.Settings.canView === 'undefined' ? true : userPermission.Settings.canView,
  },
  {
    id: 'navPayment',
    icon: (
      <div className="w-6 h-6">
        <FontAwesomeIcon icon={faCircleDollar} className="w-5 h-5" />
      </div>
    ),
    label: <FormattedMessage {...messages.payments} />,
    path: '',
    showNavSection: typeof userPermission.Payments.canView === 'undefined' ? true : userPermission.Payments.canView,
  },
  {
    id: 'navProctors',
    icon: <UsersIcon className="w-6 h-6" />,
    label: <FormattedMessage {...messages.proctors} />,
    path: '',
    showNavSection: typeof userPermission.Proctors.canView === 'undefined' ? true : userPermission.Proctors.canView,
  },
  {
    id: 'navSchedule',
    icon: <FontAwesomeIcon icon={faCalendarPlus} className="w-6 h-6" />,
    label: <FormattedMessage {...messages.schedule} />,
    path: '',
    showNavSection: typeof userPermission.Schedule.canView === 'undefined' ? true : userPermission.Schedule.canView,
  },
  {
    id: 'navRooms',
    icon: <FontAwesomeIcon icon={faPersonShelter} className="w-6 h-6" />,
    label: <FormattedMessage {...messages.rooms} />,
    path: '',
    showNavSection: typeof userPermission.Rooms.canView === 'undefined' ? true : userPermission.Rooms.canView,
  },
  {
    id: 'navVouchers',
    icon: <TicketIcon className="w-6 h-6" />,
    label: <FormattedMessage {...messages.voucher} />,
    path: '/vouchers',
    showNavSection: typeof userPermission.Vouchers.canView === 'undefined' ? true : userPermission.Vouchers.canView,
  },
  {
    id: 'navAttendees',
    icon: <UsersIcon className="w-6 h-6" />,
    label: <FormattedMessage {...messages.attendees} />,
    path: '/attendees',
    showNavSection: true,
  },
  {
    id: 'navReport',
    icon: <FontAwesomeIcon icon={faClipboardList} className="w-6 h-6" />,
    label: <FormattedMessage {...messages.reports} />,
    path: '/reports',
    showNavSection: true,
  },
  {
    id: 'navMoreApps',
    icon: <FontAwesomeIcon icon={faShapes} className="w-6 h-6" />,
    label: <FormattedMessage {...messages.more_apps} />,
    path: '/more-apps',
    isArrow: true,
    showNavSection: true,
  },
];
