import React from 'react';

import { FormattedMessage } from 'react-intl';

import Tabs from '@components/Base/Tabs';
import { SESSION_TABS } from '@components/ProctorNgSessions/types';
import { USER_ROLE } from '@utils/data/enums';

import messages from './messages';

type SessionTabsProps = {
  selectedTab: string;
  handleTabSelect: (value: string) => void;
  userRole: string;
};

const SessionTabs = (props: SessionTabsProps) => {
  const { selectedTab, handleTabSelect, userRole } = props;
  return (
    <Tabs
      tabData={[
        {
          label: <FormattedMessage {...messages.all_sessions} />,
          value: SESSION_TABS.ALL_SESSIONS,
          isShow: userRole === USER_ROLE.PROCTOR_ADMIN,
        },
        {
          label: <FormattedMessage {...messages.review_pending} />,
          value: SESSION_TABS.PENDING,
          isShow: userRole === USER_ROLE.PROCTOR || userRole === USER_ROLE.PROCTOR_ADMIN,
        },
        {
          label: <FormattedMessage {...messages.reviewed} />,
          value: SESSION_TABS.REVIEWED,
          isShow: userRole === USER_ROLE.PROCTOR,
        },
      ]}
      selectedValue={selectedTab}
      onSelect={handleTabSelect}
    />
  );
};

export default SessionTabs;
