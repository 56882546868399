export type CreatedFlowType = {
  id: string;
  uuid: string;
  name: string;
};

export type CreateFlowResponseType = {
  pc_create_flow: CreatedFlowType;
};

export type CreateFlowState = {
  isLoading: boolean;
  error: unknown;
  isSuccess: boolean;
  data: CreatedFlowType;
};

export type FlowDetails = {
  name: string;
  is_template: boolean;
  selectedTemplate: {
    label: string;
    value: string;
    project_id: string;
    id: string;
  };
  selectedDuplicateFlow: {
    id: string;
    label: string;
    value: string;
  };
};

export type CreateFlowActionPayload = {
  name: string;
  is_template: boolean;
  project_id: string;
  reference_flow_id?: string;
};

export type DeleteFlowActionPayload = {
  id: string | number | undefined;
};

export type CreateFlowAPIResponse = {
  data: CreateFlowResponseType;
};

export type DeleteFlowType = {
  id: string;
};

export type DeleteFlowResponseType = {
  pc_delete_flow: DeleteFlowType;
};

export type DeleteFlowAPIResponse = {
  data: DeleteFlowResponseType;
};

export type Error = {
  message: string;
};

export type Template = {
  id: string;
  project_id: string;
  name: string;
  is_template: boolean;
};

export type FlowState = {
  uuid: string;
  isLoading: boolean;
  error: string;
};

export type createFlowPayload = {
  name: string;
  project_id: string;
  is_template: boolean;
  reference_flow_id?: string;
};

export const flowStatus = {
  ARCHIVED: 'ARCHIVED',
  DRAFT: 'DRAFT',
  PUBLISHED: 'PUBLISHED',
};

export const flowStatusList = [
  { label: 'Archived', value: flowStatus.ARCHIVED },
  { label: 'Draft', value: flowStatus.DRAFT },
  { label: 'Published', value: flowStatus.PUBLISHED },
];

export type AppliedFilters = {
  status?: string[];
  updated_by?: string | number;
};
