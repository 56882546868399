import React, { createContext, useContext, useState } from 'react';

import { getDefaultLocale } from '@utils/helpers';

export interface LanguageContextType {
  locale: string;
  switchLanguage: (language: string) => void;
}

const defaultValue: LanguageContextType = {
  locale: getDefaultLocale(),
  switchLanguage: () => {},
};

const LanguageContext = createContext(defaultValue);

export const LanguageProvider = ({ children }: { children: React.ReactNode }) => {
  const [locale, setLocale] = useState(getDefaultLocale());

  const switchLanguage = (language: string) => {
    setLocale(language);
  };

  return <LanguageContext.Provider value={{ locale, switchLanguage }}>{children}</LanguageContext.Provider>;
};

export const useLanguage = () => useContext(LanguageContext);
