import React from 'react';

import { classNames } from '@utils/classNames';

export interface RadioButtonProps {
  id: string;
  name: string;
  value: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  label: string;
  disabled: boolean;
  labelClassName?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({
  id,
  name,
  value,
  checked,
  onChange,
  label,
  disabled,
  labelClassName,
}) => {
  return (
    <div className="flex items-center">
      <input
        type="radio"
        id={`radio-${id}`}
        data-testid={`radio-${id}`}
        name={`radio-${name}`}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        className={classNames(
          disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer hover:border-primary',
          'border-gray-300 focus:outline-none focus:ring-0 hover:border-primary checked:text-primary focus:ring-transparent focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary',
        )}
        tabIndex={0}
      />
      <label
        className={classNames(
          'ml-1 font-normal text-gray-900',
          labelClassName,
          disabled && 'opacity-50',
          !disabled && 'cursor-pointer',
        )}
        htmlFor={`radio-${id}`}
      >
        {label}
      </label>
    </div>
  );
};

export default RadioButton;
