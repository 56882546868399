import React from 'react';

import { IntlProvider } from 'react-intl';

import { AppLocale, appMessages } from '@utils/messages';

type I18nProviderProps = {
  children: React.ReactNode;
  locale: string;
};
const I18nProvider = ({ children, locale }: I18nProviderProps) => {
  const { messages } = appMessages.find((localeMessage) => localeMessage.locale === locale) ?? { messages: {} };

  return (
    <IntlProvider defaultLocale={AppLocale.English} locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default I18nProvider;
