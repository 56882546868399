import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';

import { setGlobalErrorAction } from '@containers/app/slice';
import {
  CREATE_FLOW_CONFIG_MUTATION,
  GET_FLOW_BY_ID_QUERY,
  UPDATE_FLOW_CONFIG_MUTATION,
  UPDATE_FLOW_MUTATION,
} from '@containers/flowDetails/queries';
import {
  createFlowConfigAction,
  createFlowConfigErrorAction,
  createFlowConfigSuccessAction,
  getFlowConfigByIDAction,
  getFlowConfigByIDErrorAction,
  getFlowConfigByIDSuccessAction,
  setVersionLogChanges,
  updateFlowAction,
  updateFlowConfigAction,
  updateFlowConfigErrorAction,
  updateFlowConfigSuccessAction,
  updateFlowErrorAction,
  updateFlowSuccessAction,
} from '@containers/flowDetails/slice';
import {
  type CreateFlowConfigActionPayload,
  CreateFlowConfigOutputType,
  FlowConfig,
  type FlowDetailPayloadType,
  GetFlowConfigByIDAction,
  UpdateFlowAction,
  type UpdateFlowConfigActionPayload,
  UpdateFlowConfigOutputType,
  UpdateFlowOutputType,
} from '@containers/flowDetails/types';
import { setCurrentProject } from '@containers/projects/slice';
import fetchData from '@utils/fetchData';
import postData from '@utils/postData';

export function* getFlowConfigByIDSaga({ payload }: GetFlowConfigByIDAction): Generator {
  try {
    const data = yield call(fetchData, {
      queryString: GET_FLOW_BY_ID_QUERY,
      queryKey: 'pc_flow',
      queryVariables: payload,
      forceRefresh: true,
    });
    if (Array.isArray(data) && data.length === 1) {
      yield put(getFlowConfigByIDSuccessAction(data[0] as FlowDetailPayloadType));
      yield put(setVersionLogChanges(data[0].flow_configs as FlowConfig[]));
      yield put(setCurrentProject(data[0].project));
    }
  } catch (error) {
    yield put(getFlowConfigByIDErrorAction(error as { message: string }));
    yield put(setGlobalErrorAction(error));
  }
}

export function* updateFlowSaga({ payload }: UpdateFlowAction) {
  try {
    const response: UpdateFlowOutputType = yield call(postData, {
      queryString: UPDATE_FLOW_MUTATION,
      payload: payload,
    });
    if (response) {
      yield put(updateFlowSuccessAction(response.data.pc_update_flow));
    }
  } catch (error) {
    yield put(updateFlowErrorAction(error as { message: string }));
    yield put(setGlobalErrorAction(error));
  }
}

export function* fetchFlowConfigByID(uuid: string): Generator {
  try {
    const data = yield call(fetchData, {
      queryString: GET_FLOW_BY_ID_QUERY,
      queryKey: 'pc_flow',
      queryVariables: { uuid },
      forceRefresh: true,
    });

    return data;
  } catch (error) {
    yield put(setGlobalErrorAction(error));
    return null;
  }
}

export function* updateFlowConfigSaga(action: PayloadAction<UpdateFlowConfigActionPayload>) {
  try {
    const { payload, flowUuid } = action.payload;
    const response: UpdateFlowConfigOutputType = yield call(postData, {
      queryString: UPDATE_FLOW_CONFIG_MUTATION,
      payload: payload,
    });
    if (response) {
      yield put(updateFlowConfigSuccessAction(response.data.pc_update_flow_config));
      const flowData = yield* fetchFlowConfigByID(flowUuid);
      if (Array.isArray(flowData) && flowData.length === 1) {
        yield put(getFlowConfigByIDSuccessAction(flowData[0] as FlowDetailPayloadType));
        yield put(setVersionLogChanges(flowData[0].flow_configs as FlowConfig[]));
      }
    }
  } catch (error) {
    yield put(updateFlowConfigErrorAction(error as { message: string }));
    yield put(setGlobalErrorAction(error));
  }
}

export function* createFlowConfigSaga(action: PayloadAction<CreateFlowConfigActionPayload>) {
  try {
    const { payload, flowUuid } = action.payload;
    const response: CreateFlowConfigOutputType = yield call(postData, {
      queryString: CREATE_FLOW_CONFIG_MUTATION,
      payload: payload,
    });

    if (response && response.data && response.data.pc_create_flow_config) {
      yield put(createFlowConfigSuccessAction());
      const flowData = yield* fetchFlowConfigByID(flowUuid);
      if (Array.isArray(flowData) && flowData.length === 1) {
        yield put(getFlowConfigByIDSuccessAction(flowData[0] as FlowDetailPayloadType));
        yield put(setVersionLogChanges(flowData[0].flow_configs as FlowConfig[]));
      }
    }
  } catch (error) {
    yield put(createFlowConfigErrorAction(error as { message: string }));
    yield put(setGlobalErrorAction(error));
  }
}

export function* watchGetFlowConfigByID() {
  yield takeLatest(getFlowConfigByIDAction.type, getFlowConfigByIDSaga);
}

export function* watchUpdateFlow() {
  yield takeLatest(updateFlowAction.type, updateFlowSaga);
}

export function* watchUpdateFlowConfig() {
  yield takeLatest(updateFlowConfigAction.type, updateFlowConfigSaga);
}

export function* watchCreateFlowConfig() {
  yield takeLatest(createFlowConfigAction.type, createFlowConfigSaga);
}
export default function* flowDetailSaga() {
  yield all([
    fork(watchUpdateFlow),
    fork(watchGetFlowConfigByID),
    fork(watchUpdateFlowConfig),
    fork(watchCreateFlowConfig),
  ]);
}
