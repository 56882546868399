import React from 'react';

import Table from '@components/Base/Table';
import { CustomColumnDef } from '@components/Base/Table/types';
import { SessionData } from '@containers/sessions/proview_console/slice';
// import { SessionCoordinator } from './columns';

export interface SessionListTableProps {
  data: SessionData[];
  columns: CustomColumnDef<SessionData>[];
  setPagination?: React.Dispatch<React.SetStateAction<{ pageIndex: number; pageSize: number }>>;
  pagination?: { pageIndex: number; pageSize: number };
  searchText?: string;
  isLoading?: boolean;
  handleTableCheck?: (rowSelection: { [key: number]: boolean } | null) => void;
  resetSelection?: boolean;
}

const SessionListTable = ({
  data,
  columns,
  setPagination,
  pagination,
  isLoading,
  handleTableCheck,
  resetSelection,
}: SessionListTableProps) => {
  return (
    <div className="h-full mt-5">
      <Table<SessionData>
        data={data}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        setPagination={setPagination}
        isMultiselect={true}
        handleSelection={handleTableCheck}
        resetSelection={resetSelection}
      />
    </div>
  );
};

export default SessionListTable;
