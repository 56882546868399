import React, { useCallback, useMemo, useState } from 'react';

import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { fullMonthNames } from '@components/Shared/DatePicker/enums';
import MonthsView from '@components/Shared/DatePicker/MonthView';
import YearView from '@components/Shared/DatePicker/YearView';

export interface HeaderProps {
  onMonthSelect: (date: Date) => void;
  onYearSelect: (date: Date) => void;
  defaultDate?: Date | null;
  toggleMonth: (date: Date) => void;
  handleDateView: (isDisplay: boolean) => void;
  selectedMonth: number;
  selectedYear: number;
}

const Header: React.FC<HeaderProps> = ({
  onMonthSelect,
  onYearSelect,
  defaultDate,
  toggleMonth,
  handleDateView,
  selectedMonth,
  selectedYear,
}) => {
  const effectiveDate = useMemo(() => defaultDate ?? new Date(), [defaultDate]);
  const [yearViewYear, setYearViewYear] = useState(effectiveDate);

  const [isMonthViewVisible, setIsMonthViewVisible] = useState<boolean>(false);
  const [isYearViewVisible, setIsYearViewVisible] = useState<boolean>(false);

  const handleMonthSelect = useCallback(
    (monthIndex: number) => {
      handleDateView(true);
      const newDate = new Date(selectedYear, monthIndex, 1);
      onMonthSelect(newDate);
      setIsMonthViewVisible(false);
    },
    [selectedYear, onMonthSelect],
  );

  const handleYearSelect = useCallback(
    (year: number) => {
      const newDate = new Date(year, selectedMonth, 1);
      onYearSelect(newDate);
      setIsYearViewVisible(false);
      setIsMonthViewVisible(true); // Show month view again after selecting year
    },
    [onYearSelect],
  );

  const handleMonthChange = (index: number) => {
    const newDate = new Date(selectedYear, selectedMonth + index, 1);
    toggleMonth(newDate);
  };

  const handleYearRangeChange = (index: number) => {
    const a = new Date(yearViewYear);
    const b = a.setFullYear(a.getFullYear() + index);
    setYearViewYear(new Date(b));
  };

  const toggleMonthView = useCallback((): void => {
    handleDateView(false);
    setIsMonthViewVisible((prevVisible) => !prevVisible);
    if (!isMonthViewVisible) {
      setIsYearViewVisible(false);
    }
  }, [isMonthViewVisible]);

  const toggleYearView = useCallback((): void => {
    handleDateView(false);
    setIsYearViewVisible((prevVisible) => !prevVisible);
    if (!isYearViewVisible) {
      setIsMonthViewVisible(false);
    }
  }, [isYearViewVisible]);

  const onMonthKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        toggleMonthView();
      }
    },
    [toggleMonthView],
  );

  const onMonthChangeKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleMonthChange(index);
    }
  };

  const onYearRangeChangeKeyDown = (e: React.KeyboardEvent, index: number) => {
    if (e.key === 'Enter' || e.key === ' ') {
      handleYearRangeChange(index);
    }
  };

  const onYearKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter' || event.key === ' ') {
        event.preventDefault();
        toggleYearView();
      }
    },
    [toggleYearView],
  );

  return (
    <div className="relative w-[260px] rounded-t-lg">
      <div className="flex items-center justify-between py-1">
        {!isYearViewVisible && (
          <div className="flex items-center justify-between h-6 w-56 box-border px-1">
            <div className="flex items-center space-x-1 h-6">
              {!isMonthViewVisible && (
                <div
                  role="button"
                  tabIndex={0}
                  onClick={toggleMonthView}
                  onKeyDown={onMonthKeyDown}
                  data-testid="month-view-header"
                  className="cursor-pointer text-sm font-medium bg-blue-700/5 rounded h-6 flex items-center justify-center px-2"
                >
                  {fullMonthNames[selectedMonth]}
                </div>
              )}
              <div
                role="button"
                data-testid="year-view-header"
                tabIndex={0}
                onClick={toggleYearView}
                onKeyDown={onYearKeyDown}
                className="cursor-pointer text-sm font-medium bg-blue-700/5 rounded h-6 flex items-center justify-center px-2"
              >
                {selectedYear}
              </div>
            </div>
            {!isMonthViewVisible && (
              <div className="flex items-center justify-between w-12">
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="text-gray-900 cursor-pointer"
                  onClick={() => handleMonthChange(-1)}
                  onKeyDown={(e) => onMonthChangeKeyDown(e, -1)}
                  data-testid="previous-month"
                  size="sm"
                  tabIndex={0}
                />
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="text-gray-900 cursor-pointer"
                  onClick={() => handleMonthChange(1)}
                  onKeyDown={(e) => onMonthChangeKeyDown(e, 1)}
                  data-testid="next-month"
                  size="sm"
                  tabIndex={0}
                />
              </div>
            )}
          </div>
        )}

        {isYearViewVisible && (
          <div className="flex items-center justify-between space-x-4 h-6 w-56 box-border px-2">
            <div className="w-24 h-6 text-sm font-medium">
              {yearViewYear.getFullYear() - 5} - {yearViewYear.getFullYear() + 6}
            </div>
            <div className="flex items-center justify-between w-12">
              <FontAwesomeIcon
                icon={faChevronLeft}
                className="text-gray-900 cursor-pointer"
                onClick={() => handleYearRangeChange(-12)}
                onKeyDown={(e) => onYearRangeChangeKeyDown(e, -12)}
                size="sm"
                data-testid="previous-year-range"
                tabIndex={0}
              />
              <FontAwesomeIcon
                icon={faChevronRight}
                className="text-gray-900 cursor-pointer"
                onClick={() => handleYearRangeChange(12)}
                onKeyDown={(e) => onYearRangeChangeKeyDown(e, 12)}
                size="sm"
                data-testid="next-year-range"
                tabIndex={0}
              />
            </div>
          </div>
        )}
      </div>

      {isMonthViewVisible && (
        <div className="absolute left-0 bg-white z-50 h-[200px]">
          <MonthsView onMonthSelect={handleMonthSelect} selectedMonth={selectedMonth} />
        </div>
      )}

      {isYearViewVisible && (
        <div className="absolute left-0 bg-white z-50 h-[200px]" data-testid="year-view">
          <YearView startingDate={yearViewYear} selectedYear={selectedYear} onYearSelect={handleYearSelect} />
        </div>
      )}
    </div>
  );
};

export default Header;
