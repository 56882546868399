import React from 'react';

import { faBriefcase, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import { ROLE } from '@containers/app/types';
import { formatRolesArray } from '@utils/helpers';

import messages from './messages';

type RoleOrganizationsProps = {
  data: {
    roles: ROLE[];
    tenantName: string;
  };
};

function RoleOrganizations(props: RoleOrganizationsProps) {
  const { data } = props;
  return (
    <div className="bg-white border rounded-lg p-4">
      <div className="text-gray-900 text-sm font-semibold mb-2">
        <FormattedMessage {...messages.roles_organization} />
      </div>
      <div className="flex items-center mb-2">
        <FontAwesomeIcon icon={faUser} className="text-blue-700 w-4 p-2 bg-blue-50 rounded-full" />
        <div className="text-sm ml-2 text-gray-900">{formatRolesArray(data.roles) || '-'}</div>
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faBriefcase} className="text-blue-700 w-4 p-2 bg-blue-50 rounded-full" />
        <div className="ml-2 text-sm text-gray-900">{data.tenantName || '-'}</div>
      </div>
    </div>
  );
}

export default RoleOrganizations;
