import { defineMessages } from 'react-intl';

export const scope = 'app.components.session.sessions_tabs';

export default defineMessages({
  all_sessions: {
    id: `${scope}.all_sessions`,
    defaultMessage: 'All Sessions',
  },
  review_pending: {
    id: `${scope}.review_pending`,
    defaultMessage: 'Review Pending',
  },
  reviewed: {
    id: `${scope}.reviewed`,
    defaultMessage: 'Reviewed',
  },
  session_rating: {
    id: `${scope}.session_rating`,
    defaultMessage: 'Session Rating',
  },
});
