import React from 'react';

import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid';
import { FormattedMessage } from 'react-intl';

import type { CardProps } from '@containers/projects/types';
import { handleEnterKeyPress } from '@utils/helpers';

import messages from './messages';

const Card: React.FC<CardProps> = ({ count, label, imageProps: { alt, url: imageUrl }, description, onNavigate }) => {
  const onHandleClick = (e: React.MouseEvent | React.KeyboardEvent) => {
    e.stopPropagation();
    onNavigate();
  };

  const handleClick = (e: React.MouseEvent) => {
    onHandleClick(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    handleEnterKeyPress(e, () => onHandleClick(e));
  };

  return (
    <div
      data-testid={`card-${label}`}
      className="flex flex-col justify-between flex-shrink-0 p-5 bg-white shadow-md h-44 rounded-xl"
    >
      <div className="flex items-center justify-between">
        <div>
          <div className="pb-4 text-base font-medium text-gray-500">{label}</div>
          <div className="flex">
            <div className="text-3xl font-semibold tracking-wide text-black">{count}</div>
            <div className="mt-2 ml-3 text-base font-medium text-gray-500">{description}</div>
          </div>
        </div>
        <img src={imageUrl} alt={alt} className="w-16 h-16" />
      </div>
      <button
        type="button"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        className="flex items-center mt-6 text-base font-medium text-primary"
      >
        <ArrowTopRightOnSquareIcon className="w-5 h-5 mr-1" />
        <FormattedMessage {...messages.view_all} />
      </button>
    </div>
  );
};

export default Card;
