import React from 'react';

import { TrashIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import messages from '@components/Flows/ConfigPanels/messages';
import useFetchFileUrl from '@hooks/useFetchFileUrl';

import UploadLogoProgressBar from '../UploadLogoProgressBar';

interface LogoProps {
  selectedFile: string | File | null;
  handleLogoPick: () => void;
  logoId: number | null;
  uploadProgress: number;
  deleteLogo: () => void;
}

const UploadLogoStatus = (props: LogoProps) => {
  const { logoId, selectedFile, handleLogoPick, uploadProgress, deleteLogo } = props;
  const { url, fileName } = useFetchFileUrl(logoId ? logoId : 0);

  return (
    <>
      {url && logoId ? (
        <div className="flex items-center h-10 gap-2 cursor-pointer mt-3">
          <div className="w-10 h-8">
            <img src={url} alt="Selected" className="w-full h-full" loading="lazy" />
          </div>
          <div className="text-xs text-gray-900">{fileName}</div>
          <TrashIcon
            className="w-3.5 h-3.5 text-gray-800 cursor-pointer"
            data-testid="delete-logo"
            onClick={deleteLogo}
          />
        </div>
      ) : selectedFile ? (
        <UploadLogoProgressBar selectedFile={selectedFile} uploadProgress={uploadProgress} />
      ) : (
        <Button
          className="mt-3 text-sm w-fit"
          onClick={handleLogoPick}
          disabled={!!logoId || !!selectedFile}
          tabIndex={0}
        >
          <FormattedMessage {...messages.upload_logo} />
        </Button>
      )}
    </>
  );
};

export default UploadLogoStatus;
