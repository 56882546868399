import { defineMessages } from 'react-intl';

export const scope = 'app.components.tableHelpers.messages';

export default defineMessages({
  empty_record_title: {
    id: `${scope}.empty_search_list`,
    defaultMessage: 'No Results Found',
  },
  empty_record_description: {
    id: `${scope}.empty_record_description`,
    defaultMessage: 'Sorry, we couldn’t find any matches. Please try again.',
  },
});
