import React, { InputHTMLAttributes, ReactElement, forwardRef } from 'react';

import { classNames } from '@utils/classNames';

export interface TextareaProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string | ReactElement;
  helper?: string;
  error?: string;
}

const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, label, helper, error, name, disabled, ...props }, ref) => {
    return (
      <div className={classNames('text-gray-900', disabled && 'opacity-50 cursor-not-allowed')}>
        {label && (
          <label htmlFor={name} className="text-sm">
            {label}
          </label>
        )}
        <textarea
          id={name}
          ref={ref}
          rows={3}
          disabled={disabled}
          className={classNames(
            className,
            'my-1 block w-full rounded border-0 p-2 text-sm ring-1 ring-inset ring-gray-200 placeholder:text-gray-400  focus:ring-primary sm:leading-6',
            error && 'ring-red-700 enabled:focus:ring-red-700',
          )}
          {...props}
        ></textarea>
        {helper && <div className="text-xs">{helper}</div>}
        {error && <div className="text-xs text-red-700">{error}</div>}
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';

export { Textarea };
