import React from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';

import { Button } from '@components/Base/Button';
import SidePanel from '@components/SidePanel';

interface ExamDetailsProps {
  openPanel: boolean;
  closePanel: () => void;
}

const ExamDetails = (props: ExamDetailsProps) => {
  const { openPanel, closePanel } = props;

  const saveExamDetails = () => {};

  return (
    <SidePanel show={openPanel} onClose={closePanel} containerPadding="p-0" allowCustomHeader>
      <div className="flex flex-col flex-1">
        <div className="sticky top-0">
          <div className="flex p-5 bg-white border-b border-gray-300 w-full">
            <div className="w-fit flex flex-col">
              <div className="text-base font-semibold pt-4">Proview Assessment</div>
              <div className="text-base text-gray-600">
                <span>Project</span>:<span className="pl-1">Advanced Calculus Final</span>
              </div>
            </div>
            <div
              data-testid="close_btn"
              role="button"
              tabIndex={-1}
              className="px-2 text-gray-600 cursor-pointer"
              onClick={closePanel}
              onKeyPress={closePanel}
            >
              <XMarkIcon className="w-6 h-6" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
      <div className="sticky bottom-0">
        <div className="h-20 flex items-center justify-end px-6 bg-white shadow-[0px_3px_45px_0px_#00000024]">
          <Button onClick={saveExamDetails} className="w-20">
            Save
          </Button>
        </div>
      </div>
    </SidePanel>
  );
};

export default ExamDetails;
