import React from 'react';

import { v4 as uuidv4 } from 'uuid';

export interface StripLoaderProps {
  strip?: number;
}

const StripLoader = ({ strip = 1 }: StripLoaderProps) => {
  const uuids = Array.from({ length: strip }, () => uuidv4());

  return (
    <div className="animate-pulse">
      <div className="flex-1 space-y-1">
        {uuids.map((uuid) => (
          <div key={uuid} className="h-4 bg-gray-200 rounded" />
        ))}
      </div>
    </div>
  );
};

export default StripLoader;
