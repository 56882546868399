import React, { useMemo, useState } from 'react';

import Filters from '@components/Exams/Filters';
import { FilterButton } from '@components/Flows/Filters/FilterButton';
import { AppliedFilters } from '@containers/exams';
import { hasNonEmptyFilters } from '@containers/workflows/helpers';

export interface ExamFiltersProps {
  updateAppliedFilters: (filters: object) => void;
  appliedFilters: AppliedFilters;
}

const ExamFilters = (props: ExamFiltersProps) => {
  const { appliedFilters, updateAppliedFilters } = props;

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const toogleFilterView = () => {
    setShowFilter((prev) => !prev);
  };

  const hasAppliedFilters = useMemo(() => {
    return hasNonEmptyFilters(appliedFilters as AppliedFilters);
  }, [appliedFilters]);

  return (
    <>
      <FilterButton hasAppliedFilters={hasAppliedFilters} onClick={toogleFilterView} />
      <Filters
        updateAppliedFilters={updateAppliedFilters}
        showFilter={showFilter}
        toogleFilterView={toogleFilterView}
      />
    </>
  );
};

export default ExamFilters;
