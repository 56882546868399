import React from 'react';

import classNames from 'classnames';

export interface BadgeProps {
  type?: 'primary' | 'draft' | 'secondary' | 'red' | 'green' | 'wftdraft' | 'closed' | 'open' | 'cancelled';
  bgColor?: string;
  textColor?: string;
  rounded?: 'full' | 'lg' | 'md' | 'sm' | 'none'; // Add more if needed
  children: React.ReactNode;
}

const Badge: React.FC<BadgeProps> = ({ type, bgColor, textColor, rounded = 'full', children }) => {
  const typeStyles = {
    primary: {
      bgColor: 'bg-blue-100',
      textColor: 'text-blue-700',
    },
    secondary: {
      bgColor: 'bg-blue-50',
      textColor: 'text-blue-700',
    },
    draft: {
      bgColor: 'bg-yellow-100',
      textColor: 'text-amber-800',
    },
    wftdraft: {
      bgColor: 'bg-gray-100',
      textColor: 'text-gray-900',
    },
    closed: {
      bgColor: 'bg-orange-50',
      textColor: 'text-orange-600',
    },
    open: {
      bgColor: 'bg-blue-50',
      textColor: 'text-blue-900',
    },
    cancelled: {
      bgColor: 'bg-red-50',
      textColor: 'text-red-600',
    },
    red: {
      bgColor: 'bg-red-500',
      textColor: 'text-white',
    },
    green: {
      bgColor: 'bg-green-700',
      textColor: 'text-white',
    },
  };

  const roundedClasses = {
    full: 'rounded-full',
    lg: 'rounded-lg',
    md: 'rounded-md',
    sm: 'rounded-sm',
    none: 'rounded-none',
  };

  const styles: { bgColor?: string; textColor?: string } = type ? typeStyles[type] : {};

  return (
    <span
      className={classNames(
        'inline-flex text-xs font-medium justify-center items-center py-1 px-3',
        bgColor || styles.bgColor,
        textColor || styles.textColor,
        roundedClasses[rounded],
      )}
    >
      {children}
    </span>
  );
};

export default Badge;
