import { TypedStartListening, createListenerMiddleware } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/react';
import { get } from 'lodash';

import { AppDispatch, RootState } from '@utils/store';

import { setGlobalErrorAction } from './slice';
type AppStartListening = TypedStartListening<RootState, AppDispatch>;

export const globalErrorListener = createListenerMiddleware();

const startListeningGlobalError = globalErrorListener.startListening as AppStartListening;

startListeningGlobalError({
  actionCreator: setGlobalErrorAction,
  effect: ({ payload: error }) => {
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      window.location.pathname = '/logout';
      return;
    }
    if (get(error, 'graphQLErrors.0.extensions.code') === 'start-failed') {
      return;
    }
    Sentry.captureException(error);
  },
});

export default globalErrorListener.middleware;
