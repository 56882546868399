import React, { useMemo, useState } from 'react';

import { FilterButton } from '@components/Flows/Filters/FilterButton';
import Filters from '@components/Vouchers/Filters/index';
import { AppliedFilters } from '@containers/vouchers';
import { hasNonEmptyFilters } from '@containers/vouchers/helpers';

export interface SessionFiltersProps {
  updateAppliedFilters: (filters: object) => void;
  vouchers: { label: string; value: string }[];
  appliedFilters: AppliedFilters;
  skuList: { label: string; value: string }[];
  selectedTab: string;
  redeemedList: { label: string; value: string }[];
  resetFilters: string;
}

const VoucherFilters = (props: SessionFiltersProps) => {
  const { updateAppliedFilters, vouchers, appliedFilters, skuList, selectedTab, redeemedList, resetFilters } = props;

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const toogleFilterView = () => {
    setShowFilter((prev) => !prev);
  };

  const hasAppliedFilters = useMemo(() => {
    return hasNonEmptyFilters(appliedFilters as AppliedFilters);
  }, [appliedFilters]);

  return (
    <>
      <FilterButton hasAppliedFilters={hasAppliedFilters} onClick={toogleFilterView} />
      <Filters
        updateAppliedFilters={updateAppliedFilters}
        showFilter={showFilter}
        toogleFilterView={toogleFilterView}
        vouchers={vouchers}
        appliedFilters={appliedFilters}
        skuList={skuList}
        selectedTab={selectedTab}
        redeemedList={redeemedList}
        resetFilters={resetFilters}
      />
    </>
  );
};

export default VoucherFilters;
