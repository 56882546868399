import { defineMessages } from 'react-intl';

export const scope = 'app.components.proview_console_session.sessions_list';

export default defineMessages({
  all_sessions: {
    id: `${scope}.all_sessions`,
    defaultMessage: 'All Sessions',
  },
  requests: {
    id: `${scope}.requests`,
    defaultMessage: 'Requests',
  },
  no_session: {
    id: `${scope}.no_session`,
    defaultMessage: 'No Sessions Yet',
  },
  no_session_description: {
    id: `${scope}.no_session_description`,
    defaultMessage: 'There are no sessions to view at the moment.',
  },
  request_review: {
    id: `${scope}.request_review`,
    defaultMessage: 'Request Review',
  },
  edit_request: {
    id: `${scope}.edit_request`,
    defaultMessage: 'Edit Request',
  },
  no_request_pending: {
    id: `${scope}.no_review_pending`,
    defaultMessage: 'No Review Requests Yet',
  },
  no_request_pending_description: {
    id: `${scope}.no_review_pending_description`,
    defaultMessage: 'There are no review requests at the moment.',
  },
});
