import React from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';

import SidePanel from '@components/SidePanel';
import { Workflow } from '@containers/workflows/types';

interface WorkFlowDetailsProps {
  openWorkflowSidepanel: boolean;
  closeWorkflowSidepanelHandler: () => void;
  selectedWorkflow: Workflow | null;
}

const WorkFlowDetails = (props: WorkFlowDetailsProps) => {
  const { openWorkflowSidepanel, closeWorkflowSidepanelHandler, selectedWorkflow } = props;
  return (
    <SidePanel
      show={openWorkflowSidepanel}
      onClose={closeWorkflowSidepanelHandler}
      containerPadding="p-0"
      allowCustomHeader
    >
      <div className="flex flex-col flex-1">
        <div className="sticky top-0">
          <div className="flex p-5 bg-white">
            <div className="w-[20.375rem]">
              <div className="text-lg font-semibold text-gray-900">{selectedWorkflow?.title}</div>
              <div className="text-base font-semibold pt-4">Quiz Attributes</div>
              <div className="text-sm pt-3">
                <span>Course Id:</span>
                <span className="pl-1">3</span>
              </div>
              <div className="text-sm">
                <span>Course Module ID:</span>
                <span className="pl-1">21</span>
              </div>
              <div className="text-sm">
                <span>Course Module Item Id:</span>
                <span className="pl-1">Not availlable</span>
              </div>
              <div className="text-base font-semibold pt-5">Candidate Instrcutions</div>
              <div className="text-sm pt-1">
                <span>
                  Ensure a stable internet connection and a quiet environment. Enable your webcam and microphone
                  throughout the quiz. Keep your ID ready for verification. No external devices or assistance allowed.
                  Contact support immediately if you face technical issues.
                </span>
              </div>
              <div className="text-base font-semibold pt-5">Proctor Instrcutions</div>
              <div className="text-sm pt-1">
                <span>
                  Monitor candidates via webcam and microphone for the quiz duration. Verify IDs and ensure compliance
                  with rules. Flag suspicious behavior promptly. Maintain professionalism and report technical issues or
                  irregularities to support immediately.
                </span>
              </div>
              <div className="text-base font-semibold pt-5">Reference Material</div>
              <div className="text-sm pt-1">
                <span>
                  Monitor candidates via webcam and microphone for the quiz duration. Verify IDs and ensure compliance
                  with rules. Flag suspicious behavior promptly. Maintain professionalism and report technical issues or
                  irregularities to support immediately.
                </span>
              </div>
            </div>
            <div
              data-testid="close_btn"
              role="button"
              tabIndex={-1}
              className="px-2 text-gray-600 cursor-pointer"
              onClick={closeWorkflowSidepanelHandler}
              onKeyPress={closeWorkflowSidepanelHandler}
            >
              <XMarkIcon className="w-6 h-6" aria-hidden="true" />
            </div>
          </div>
        </div>
      </div>
    </SidePanel>
  );
};

export default WorkFlowDetails;
