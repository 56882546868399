import React from 'react';

import { faBell, faGlobe, faKey, faUser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import { AccountSettingsTab } from './constants';
import messages from './messages';
import EnglishUS from '../../images/Languages/EnglishUS.png';

export type AccountSettingsOption = {
  label: React.ReactNode;
  value: AccountSettingsTab;
  icon: React.ReactNode;
};

export const options: AccountSettingsOption[] = [
  {
    label: <FormattedMessage {...messages.profile} />,
    value: AccountSettingsTab.PROFILE,
    icon: <FontAwesomeIcon icon={faUser} className="h-4 w-4" />,
  },
  {
    label: <FormattedMessage {...messages.notification_preferences} />,
    value: AccountSettingsTab.NOTIFICATION,
    icon: <FontAwesomeIcon icon={faBell} className="h-4 w-4" />,
  },
  {
    label: <FormattedMessage {...messages.change_password} />,
    value: AccountSettingsTab.PASSWORD,
    icon: <FontAwesomeIcon icon={faKey} className="h-4 w-4" />,
  },
  {
    label: <FormattedMessage {...messages.language_settings} />,
    value: AccountSettingsTab.LANGUAGE,
    icon: <img src={EnglishUS} alt="US flag" className="h-4 w-4" />,
  },
  {
    label: <FormattedMessage {...messages.timezone} />,
    value: AccountSettingsTab.TIMEZONE,
    icon: <FontAwesomeIcon icon={faGlobe} className="h-4 w-4" />,
  },
];
