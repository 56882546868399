import React from 'react';

import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import {
  ActionMeta,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  GroupBase,
  MultiValue,
  SingleValue,
  StylesConfig,
  components,
} from 'react-select';
import CreatableSelect from 'react-select/creatable';

import messages from './messages';

type Option =
  | {
      label: string;
      value: string | number;
    }
  | number
  | string;

export const reactSelectDefaultStyles: StylesConfig<Option, boolean, GroupBase<Option>> = {
  input: (props: CSSObjectWithLabel) => ({
    ...props,
    input: {
      boxShadow: 'none !important',
      border: 'none !important',
    },
  }),
  placeholder: (props: CSSObjectWithLabel) => ({
    ...props,
    color: 'gray-400',
  }),
  control: (props: CSSObjectWithLabel) => ({
    ...props,
    boxShadow: 'none',
    fontSize: '0.875rem',
  }),
  option: (props: CSSObjectWithLabel, state: { isSelected: boolean; isFocused: boolean }) => ({
    ...props,
    fontSize: '0.875rem',
    backgroundColor: state.isSelected || state.isFocused ? 'gray-100' : 'white',
    color: 'gray-900',
  }),
  multiValue: (props: CSSObjectWithLabel) => ({
    ...props,
    backgroundColor: '#F3F4F6',
    borderRadius: '12px',
    margin: '2px',
    color: '#56595C',
  }),
  multiValueLabel: () => ({
    color: '#56595C',
    paddingLeft: '10px',
  }),
  multiValueRemove: (props: CSSObjectWithLabel) => ({
    ...props,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    ':hover': {
      backgroundColor: 'transparent',
      color: '#56595C',
    },
  }),
};

export type DropdownValue = MultiValue<Option> | SingleValue<Option>;
export interface CustomDropdownProps {
  id: string;
  placeholder: string;
  isMulti: boolean;
  isClearable: boolean;
  options: Option[];
  onChange: (newValue: MultiValue<Option> | SingleValue<Option>, actionMeta: ActionMeta<Option>) => void;
  isSearchable: boolean;
  value: DropdownValue;
  name?: string;
  customStyles?: object;
  isDisabled?: boolean;
  isSearchIcon?: boolean;
}

const NoOptionsMessage = () => (
  <div className="text-sm font-normal leading-[16px] mx-0 text-gray-900 p-2">No results found</div>
);

const DropdownIndicator: React.FC<DropdownIndicatorProps<Option, boolean, GroupBase<Option>>> = (props) => {
  const color = 'text-gray-900';
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className={`h-6 w-6 ${color}`} />
    </components.DropdownIndicator>
  );
};

const DropdownSearchIndicator: React.FC<DropdownIndicatorProps<Option, boolean, GroupBase<Option>>> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <MagnifyingGlassIcon className="w-5 h-5 text-gray-900" />
    </components.DropdownIndicator>
  );
};

const CustomDropdown = (props: CustomDropdownProps) => {
  const {
    id,
    placeholder,
    isMulti,
    isClearable,
    options,
    onChange,
    isSearchable,
    value,
    customStyles = {},
    isDisabled,
    isSearchIcon,
    name = 'creatable-dropdown',
  } = props;

  return (
    <CreatableSelect
      id={`select-${id}`}
      value={value}
      components={{
        ...CreatableSelect,
        DropdownIndicator: isSearchIcon ? DropdownSearchIndicator : DropdownIndicator,
        NoOptionsMessage: NoOptionsMessage,
      }}
      classNames={{
        control: () => classNames(`border rounded !shadow-0 !bg-white !border-gray-200 w-full`),
        placeholder: () => 'text-sm font-normal leading-[16px] mx-0 text-gray-400',
        input: () => 'm-0 p-0',
        indicatorSeparator: () => 'hidden',
        indicatorsContainer: () => 'h-8',
        singleValue: () => 'text-sm font-normal leading-[16px] mx-0 text-gray-900',
        valueContainer: () => 'p-0',
        menuList: () => 'flex flex-col',
        option: ({ isSelected }) =>
          classNames('px-1.5 py-2 text-sm', isSelected ? 'bg-gray-100' : 'hover:bg-gray-100 bg-white'),
      }}
      isSearchable={isSearchable}
      placeholder={placeholder}
      styles={{
        ...reactSelectDefaultStyles,
        ...customStyles,
      }}
      name={name}
      onChange={onChange}
      isMulti={isMulti}
      isClearable={isClearable}
      options={options}
      isDisabled={isDisabled}
      formatCreateLabel={(inputValue) => (
        <>
          <FormattedMessage {...messages.add} />
          <span className="font-bold ml-1">{inputValue}</span>
        </>
      )}
    />
  );
};

export default CustomDropdown;
