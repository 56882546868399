import React from 'react';

import Table from '@components/Base/Table';
import { CustomColumnDef } from '@components/Base/Table/types';
import { SessionDetails } from '@components/ProctorNgSessions/SessionTable/Columns';

export interface SessionListTableProps {
  data: SessionDetails[];
  columns: CustomColumnDef<SessionDetails>[];
  setPagination?: React.Dispatch<React.SetStateAction<{ pageIndex: number; pageSize: number }>>;
  pagination?: { pageIndex: number; pageSize: number };
  searchText?: string;
  isLoading?: boolean;
  isMultiselect: boolean;
  handleTableCheck?: (rowSelection: { [key: number]: boolean } | null) => void;
  resetSelection?: boolean;
}

const SessionListTable = ({
  data,
  columns,
  setPagination,
  pagination,
  isLoading,
  isMultiselect,
  handleTableCheck,
  resetSelection,
}: SessionListTableProps) => {
  return (
    <div className="h-full mt-5">
      <Table<SessionDetails>
        data={data}
        columns={columns}
        isLoading={isLoading}
        pagination={pagination}
        setPagination={setPagination}
        isMultiselect={isMultiselect}
        handleSelection={handleTableCheck}
        resetSelection={resetSelection}
      />
    </div>
  );
};

export default SessionListTable;
