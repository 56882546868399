import { defineMessages } from 'react-intl';

import { commonScope } from '@utils/messages';

export const scope = 'app.component.blacklist_modal';

export default defineMessages({
  blacklist_apps: {
    id: `${scope}.blacklist_apps`,
    defaultMessage: 'Blacklist Apps',
  },
  current_blacklist_apps: {
    id: `${scope}.current_blacklist_apps`,
    defaultMessage: 'Currently blacklisted apps :',
  },
  add_more_apps: {
    id: `${scope}.add_more_apps`,
    defaultMessage: 'Add more apps :',
  },
  blacklist_app: {
    id: `${scope}.blacklist_app`,
    defaultMessage: 'Blacklist app',
  },
  cancel: {
    id: `${commonScope}.cancel`,
    defaultMessage: 'Cancel',
  },
  duplicate_entry: {
    id: `${scope}.duplicate_entry`,
    defaultMessage: 'Duplicate entry',
  },
  add_app: {
    id: `${scope}.add_app`,
    defaultMessage: 'Add app',
  },
});
