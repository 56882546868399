import { defineMessages } from 'react-intl';

export const scope = 'app.components.assign_provider';

export default defineMessages({
  assign_provider: {
    id: `${scope}.assign_provider`,
    defaultMessage: 'Assign Provider',
  },
  due_date: {
    id: `${scope}.due_date`,
    defaultMessage: 'Due Date',
  },
  sessions: {
    id: `${scope}.sessions`,
    defaultMessage: 'Sessions',
  },
  in_progress: {
    id: `${scope}.in_progress`,
    defaultMessage: 'In Progress',
  },
  will_not_be_assigned: {
    id: `${scope}.will_not_be_assigned`,
    defaultMessage: 'will not be assigned.',
  },
  go_back: {
    id: `${scope}.go_back`,
    defaultMessage: 'Go Back',
  },
  save_changes: {
    id: `${scope}.save_changes`,
    defaultMessage: 'Save Changes',
  },
  request_review: {
    id: `${scope}.request_review`,
    defaultMessage: 'Request Review',
  },
  reason_text_box_placeholder: {
    id: `${scope}.reason_text_box_placeholder`,
    defaultMessage: 'Provide a brief reason for your review request to give the proctor some context.',
  },
  reason_text_box_header: {
    id: `${scope}.reason_text_box_header`,
    defaultMessage: 'Reason (optional)',
  },
});
