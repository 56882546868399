import React, { useMemo, useState } from 'react';

import { FormattedMessage } from 'react-intl';

import AssignProvider from '@components/AssignProvider';
import { Button } from '@components/Base/Button';
import EmptyMessagePage from '@components/Base/EmptyMessagePage';
import SearchInput from '@components/Base/Input';
import { CustomColumnDef } from '@components/Base/Table/types';
import Tabs from '@components/Base/Tabs';
import CancelProvider from '@components/CancelProvider';
import { SESSION_TABS } from '@components/ProctorNgSessions/types';
import { SessionData } from '@containers/sessions/proview_console/slice';
import { providerList } from '@containers/sessions/types';
import ClipBoardIcon from '@images/clipboard.svg';
import { USER_ROLE } from '@utils/data/enums';

import messages from './messages';
import RequestsList from '../RequestsList';
import SessionFilters from '../SessionFilters';
import SessionListTable from '../SessionsTable';
import { createColumns, getCustomColumns } from '../SessionsTable/columns';

interface SessionListProps {
  sessionData: SessionData[];
  role: string;
  examList: { label: string; value: number }[];
  selectedTab: string;
  handleSelectedTab: (value: string) => void;
  updateAppliedFilters: React.Dispatch<React.SetStateAction<object>>;
}

interface ReviewRequest {
  provider: string;
  dueDate: Date | null;
  requestReason?: string;
}

const SessionList = ({
  sessionData,
  role,
  examList,
  selectedTab,
  handleSelectedTab,
  updateAppliedFilters,
}: SessionListProps) => {
  const [selectedCount, setSelectedCount] = useState(0);
  const [resetSelection, setResetSelection] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hasInProgressSession] = useState(false);
  const [openCancelProvider, setOpenCancelProvider] = useState<boolean>(false);

  const handleTabSelect = (value: string) => {
    handleSelectedTab(value);
    setResetSelection(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleRequestReview = (_reviewRequest: ReviewRequest) => {
    // Need to handle the request review funtionality
  };

  const toggleCancelProviderModal = () => {
    setOpenCancelProvider(!openCancelProvider);
  };

  const applyFilters = (filters: object) => {
    updateAppliedFilters(filters);
  };

  const columns = useMemo(() => {
    const customColumns = getCustomColumns[USER_ROLE.COORDINATOR][selectedTab];
    return customColumns
      .map((key) => {
        const filteredColumns = createColumns({
          toggleCancelProviderModal,
        }).filter((column) => column.id === key);
        return filteredColumns.length > 0 ? filteredColumns[0] : null;
      })
      .filter((column) => column !== null) as CustomColumnDef<SessionData>[];
  }, [selectedTab]);

  const handleTableCheck = (rowSelection: { [key: number]: boolean } | null) => {
    const selectionCount = rowSelection !== null ? Object.keys(rowSelection).length : 0;
    setSelectedCount(selectionCount);
  };

  return (
    <div className="flex w-full px-8 py-5">
      <div className="flex flex-col w-full">
        <div className="flex justify-between items-center">
          <div className="flex items-center">
            <Tabs
              tabData={[
                {
                  label: <FormattedMessage {...messages.all_sessions} />,
                  value: 'sessions',
                  isShow: true,
                },
                {
                  label: <FormattedMessage {...messages.requests} />,
                  value: 'requests',
                  isShow: true,
                },
              ]}
              selectedValue={selectedTab}
              onSelect={handleTabSelect}
            />
            {selectedCount > 0 && (
              <Button
                className="ml-2"
                variant="base"
                data-testid="request-review-btn"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                <FormattedMessage
                  {...(selectedTab === SESSION_TABS.ALL_SESSIONS ? messages.request_review : messages.edit_request)}
                />
              </Button>
            )}
          </div>
          <div className="flex items-center gap-4">
            <SearchInput
              initialValue={''}
              handleChange={() => {}}
              placeholderMessageId="app.components.proctorng.sessions.sessions_list.search_sessions"
            />
            <SessionFilters
              userRole={role}
              selectedTab={selectedTab}
              updateAppliedFilters={applyFilters}
              examList={examList}
            />
          </div>
        </div>
        {sessionData.length > 0 ? (
          <>
            {selectedTab === 'sessions' ? (
              <SessionListTable
                data={sessionData}
                columns={columns}
                handleTableCheck={handleTableCheck}
                resetSelection={resetSelection}
              />
            ) : (
              <RequestsList
                data={sessionData}
                columns={columns}
                handleTableCheck={handleTableCheck}
                resetSelection={resetSelection}
              />
            )}
          </>
        ) : (
          <EmptyMessagePage
            icon={ClipBoardIcon}
            titleMessage={SESSION_TABS.ALL_SESSIONS === selectedTab ? messages.no_session : messages.no_request_pending}
            descriptionMessage={
              SESSION_TABS.ALL_SESSIONS === selectedTab
                ? messages.no_session_description
                : messages.no_request_pending_description
            }
            descriptionWidth="w-48"
          />
        )}
        <AssignProvider
          data={providerList}
          openAddFlowModal={isModalOpen}
          handleCloseModal={handleCloseModal}
          onRequestReview={handleRequestReview}
          selectedCount={selectedCount}
          isEdit={!(selectedTab === SESSION_TABS.ALL_SESSIONS)}
          hasInProgressSession={hasInProgressSession}
        />
        <CancelProvider
          openCancelProvider={openCancelProvider}
          toggleCancelProviderModal={toggleCancelProviderModal}
          handleCancelProvider={() => {}}
        />
      </div>
    </div>
  );
};

export default SessionList;
