import React from 'react';

import { FormattedMessage } from 'react-intl';

import type { CardDetailType } from '@components/Flows/FlowDetails/types';
import { CONFIG_TYPE } from '@utils/data/enums';

import messages from './messages';
import customBranding from '../../../images/svgs/customBranding.svg';

export const cardDetail: CardDetailType = {
  label: <FormattedMessage {...messages.custom_branding_label} />,
  subText: <FormattedMessage {...messages.custom_branding_description} />,
  logo: customBranding,
  type: CONFIG_TYPE.CUSTOM_BRANDING,
};

export interface CustomBrandingProps {
  isLogoUploaded?: boolean;
  branding_primary_color: string | null;
}

const CustomBranding = (props: CustomBrandingProps) => {
  const { isLogoUploaded, branding_primary_color } = props;

  return (
    <div className="flex flex-col gap-2">
      <div className="text-sm text-gray-900">
        <FormattedMessage {...messages.logo} /> :{' '}
        {isLogoUploaded ? <FormattedMessage {...messages.uploaded} /> : <FormattedMessage {...messages.not_uploaded} />}
      </div>
      <div className="text-sm text-gray-900 flex items-center">
        <FormattedMessage {...messages.color} /> :{' '}
        {branding_primary_color ? (
          <>
            <div
              style={{ backgroundColor: branding_primary_color }}
              className="w-4 h-4 border border-gray-200 rounded-sm mx-1"
            />
            {branding_primary_color}
          </>
        ) : (
          <FormattedMessage {...messages.not_uploaded} />
        )}
      </div>
    </div>
  );
};

export default CustomBranding;
