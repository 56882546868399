import React from 'react';

import { Bars3BottomLeftIcon } from '@heroicons/react/20/solid';
import { MapPinIcon } from '@heroicons/react/24/outline';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import type { Project } from '@containers/projects/types';

import messages from './messages';

type Props = Project & {
  handleEditProjectModal: (id: string) => void;
};

const SectionDetails = (props: Props) => {
  const { id, name, region, description, handleEditProjectModal } = props;

  return (
    <div className="w-full px-4 box-border" role="region" aria-label={`Project details for ${name}`}>
      <div className="border-b border-gray-200 p-6 box-border">
        <div className="flex">
          <div className="w-4/5">
            <p className="text-lg font-medium text-gray-900">{name}</p>
            <span className="text-sm font-normal text-gray-500">{id}</span>
          </div>
          <div className="flex justify-end w-1/5">
            <Button onClick={() => handleEditProjectModal(id)} variant="ghost" aria-label={`Edit project ${name}`}>
              <FormattedMessage {...messages.edit_project} />
            </Button>
          </div>
        </div>

        <div className="mt-6 flex">
          <div className="w-5/12 flex items-start">
            <div className="flex items-center mr-2">
              <Bars3BottomLeftIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              <span className="text-sm font-normal text-gray-500 ml-2">
                <FormattedMessage {...messages.description} />
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-sm text-gray-900 font-normal break-all whitespace-pre-wrap">{description}</span>
            </div>
          </div>

          <div className="w-7/12 flex text-sm text-gray-500 pl-2">
            <div className="flex mr-8">
              <MapPinIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
              <span className="text-sm font-normal text-gray-500 ml-2">
                <FormattedMessage {...messages.geography} />
              </span>
            </div>
            <div className="flex flex-col">
              <span className="text-sm text-gray-900 font-normal">{region}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionDetails;
