export const AddNewProject = 'addNewProject';

export type OptionDetail = {
  id: string;
  location: string;
};

export type SelectedOption = {
  value: string;
  label: string;
  details: OptionDetail;
  description: string | null;
};

export type Flows = {
  name: string;
  id: string;
};

export type Project = {
  id: string;
  name: string;
  region: string;
  tags?: ProjectTag[];
  description?: string | null;
  flows: Flows[];
  token: string;
};

export type ProjectProps = {
  currentProject: Project;
};

export type ProjectError = {
  message?: string;
};

export type ProjectFormState = {
  isLoading: boolean;
  error: unknown;
  isSuccess: boolean;
  isComplete: boolean;
  projectId: string;
  newProject: Project;
  isOpenModal: boolean;
};

export type ProjectsState = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: ProjectError | null;
  data: Project[];
  currentProject: Project;
  projectForm: ProjectFormState;
  triggerToast: null | string;
};

export type CreateProjectActionPayload = {
  name: string;
  region: string;
  description?: string;
  tags?: {
    key: string;
    value: string;
  }[];
};

export type EditProjectActionPayload = {
  id: string;
  name?: string;
  description?: string;
  tags?: {
    key: string;
    value: string;
  }[];
};

export type ProjectTag = {
  id: number;
  key: string;
  value: string;
};

export type ImageProps = {
  alt: string;
  url: string;
};

export type CardProps = {
  count: number;
  label: React.ReactElement;
  imageProps: ImageProps;
  description?: string;
  onNavigate: () => void;
};
