import { defineMessages } from 'react-intl';

export const scope = 'app.components.projects.project_landing';

export default defineMessages({
  greeting: {
    id: `${scope}.greeting`,
    defaultMessage: 'Hey, Welcome!',
  },
  greeting_text: {
    id: `${scope}.greeting_text`,
    defaultMessage: `Get started by creating your first project. You can manage flows, track attendees, generate reports, and oversee progress and outcomes.`,
  },
  create_new_project: {
    id: `${scope}.create_new_project`,
    defaultMessage: 'Create New Project',
  },
});
