import type { MessageFormatElement } from 'react-intl';

import English from '@translations/en.json';
import Spanish from '@translations/es.json';
import French from '@translations/fr.json';
import Japanese from '@translations/ja.json';

type AppMessages = {
  messages: Record<string, string> | Record<string, MessageFormatElement[]>;
  locale: AppLocale;
}[];

export enum AppLocale {
  English = 'en',
  French = 'fr',
  Spanish = 'es',
  Japanese = 'ja',
}

export const appMessages: AppMessages = [
  {
    messages: English as unknown as Record<string, string>,
    locale: AppLocale.English,
  },
  {
    messages: French as unknown as Record<string, string>,
    locale: AppLocale.French,
  },
  {
    messages: Spanish as unknown as Record<string, string>,
    locale: AppLocale.Spanish,
  },
  {
    messages: Japanese as unknown as Record<string, string>,
    locale: AppLocale.Japanese,
  },
];

export const commonScope = 'app.common';
