import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const appName = `pc-${process.env.BUILD_ENV}`;

const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_API_DOMAIN,
};
const app = initializeApp(config, { name: appName });
const auth = getAuth(app);

export default app;
export { app, auth };
