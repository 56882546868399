import { defineMessages } from 'react-intl';

export const scope = 'app.containers.sessions';

export default defineMessages({
  sessions: {
    id: `${scope}.sessions`,
    defaultMessage: 'Sessions',
  },
});
