import React, { ReactElement, useEffect, useState } from 'react';

import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import CustomDropdown from '@components/Base/CustomDropdown';
import Dropdown from '@components/Base/Dropdown';
import Modal from '@components/Base/Modal';
import RadioButton from '@components/Base/Radio';
import { Textarea } from '@components/Base/Textarea';
import { Textbox } from '@components/Base/Textbox';
import CreateVoucherSuccess from '@components/CreateVoucherSuccess';
import DatePicker from '@components/Shared/DatePicker';
import { formateVoucherPayload, validateNumericInput, validateVoucherCode } from '@containers/vouchers/helpers';
import { CreateVoucherPadload, CreateVoucherResponse } from '@containers/vouchers/saga';
import { VOUCHER_CREATION_TYPE, VOUCHER_ERROR, voucherCreationType } from '@containers/vouchers/types';

import messages from './messages';
import './styles.css';

interface VoucherModalProps {
  handleCreateVoucher: (value: CreateVoucherPadload, voucherQuantity: string) => void;
  openVoucherModal: boolean;
  toggleModal: () => void;
  skuList: { label: string; value: string }[];
  loadingCreatedVoucher: boolean;
  createdVoucher: CreateVoucherResponse | null;
  showVoucherSuccessModal: boolean;
  createVoucherDownloaded: () => void;
  voucherDownloadListClicked: () => void;
  tagsList: { label: string; value: number }[];
  existingVoucherCodes: string[];
}

const VoucherModal = (props: VoucherModalProps) => {
  const {
    handleCreateVoucher,
    openVoucherModal,
    toggleModal,
    skuList,
    loadingCreatedVoucher,
    createdVoucher,
    showVoucherSuccessModal,
    createVoucherDownloaded,
    tagsList,
    existingVoucherCodes,
  } = props;

  const [selectedVoucherType, setSelectedVoucherType] = useState<string>(VOUCHER_CREATION_TYPE.STANDALONE_CODE);
  const [voucherName, setVoucherName] = useState<string>('');
  const [voucherQuantity, setVoucherQuantity] = useState<string>('1');
  const [maxUse, setMaxUse] = useState<string>('1');
  const [voucherDescription, setVoucherDescription] = useState<string>('');
  const [voucherDiscount, setVoucherDiscount] = useState<string>('');
  const [expiryDate, setExpiryDate] = useState<Date | null>(null);
  const [discountError, setDiscountError] = useState<ReactElement | string>('');
  const [voucherError, setVoucherError] = useState<string>('');

  const [selectedSku, setSelectedSku] = useState<{
    label: string;
    value: string;
  } | null>(null);

  const [selectedTags, setSelectedTags] = useState<
    | {
        label: string;
        value: number;
      }[]
    | null
  >(null);

  const handleSelectedVoucherType = (value: string) => {
    setSelectedVoucherType(value);
  };

  const disabledCreateButton = () => {
    if (selectedVoucherType === VOUCHER_CREATION_TYPE.BULK_CODES) {
      return voucherQuantity && voucherDiscount && maxUse && !discountError;
    }
    return (
      voucherName &&
      voucherError === '' &&
      voucherDiscount &&
      maxUse &&
      !discountError &&
      Number(voucherQuantity) > 0 &&
      Number(maxUse) > 0
    );
  };

  const resetState = () => {
    setVoucherName('');
    setVoucherQuantity('1');
    setMaxUse('1');
    setVoucherDescription('');
    setVoucherDiscount('');
    setExpiryDate(null);
    setDiscountError('');
    setSelectedSku(null);
    setSelectedTags(null);
    setVoucherError('');
  };

  const createVoucher = () => {
    const voucherData: {
      max_count: number;
      discount_percentage: number;
      description: string | null;
      sku_id: object[] | null;
      valid_till?: Date;
      code?: string;
      tag_coupons?: string[];
    } = formateVoucherPayload(
      maxUse,
      voucherDiscount,
      voucherDescription,
      selectedSku,
      expiryDate,
      selectedTags,
      voucherName,
    );
    handleCreateVoucher(voucherData, voucherQuantity);
  };

  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (validateNumericInput(value)) {
      setVoucherDiscount(value);
      if (parseInt(value) > 100) {
        setDiscountError(<FormattedMessage {...messages.discount_validation_message} />);
      } else {
        setDiscountError('');
      }
    }
  };

  const handleChange = (code: string) => {
    setVoucherName(code);
    if (!validateVoucherCode(code)) {
      setVoucherError(VOUCHER_ERROR.INVALID_VOUCHER);
    } else {
      const upperCaseCode = code.toUpperCase();
      const upperCaseExistingCodes = existingVoucherCodes.map((voucher) => voucher.toUpperCase());
      if (upperCaseExistingCodes.includes(upperCaseCode)) {
        setVoucherError(VOUCHER_ERROR.DUPLICATE_VOUCHER);
        return;
      }
      setVoucherError('');
    }
  };

  const getVoucherError = () => {
    return voucherError === VOUCHER_ERROR.INVALID_VOUCHER ? (
      <FormattedMessage {...messages.voucher_code_error} />
    ) : voucherError === VOUCHER_ERROR.DUPLICATE_VOUCHER ? (
      <FormattedMessage {...messages.voucher_unique_error} />
    ) : (
      ''
    );
  };

  const closeModal = () => {
    resetState();
    setSelectedVoucherType(VOUCHER_CREATION_TYPE.STANDALONE_CODE);
    toggleModal();
  };

  useEffect(() => {
    if (selectedVoucherType) {
      resetState();
    }
  }, [selectedVoucherType]);

  return (
    <Modal size="md" show={openVoucherModal} onCancel={closeModal}>
      {showVoucherSuccessModal && createdVoucher && createdVoucher.length ? (
        <CreateVoucherSuccess createdVoucher={createdVoucher} createVoucherDownloaded={createVoucherDownloaded} />
      ) : (
        <>
          <p className="text-xl font-semibold leading-6 text-gray-900" id="modal-title">
            <FormattedMessage {...messages.new_voucher} />
          </p>
          <div className="flex text-sm align-middle text-gray-900 mt-8 bg-white">
            <div className="mr-6">
              <FormattedMessage {...messages.type} />:
            </div>
            <div className="flex">
              {voucherCreationType.map((list) => (
                <div key={list.value} className="pr-4 ">
                  <RadioButton
                    data-testid={list.value}
                    id={list.value}
                    onChange={() => handleSelectedVoucherType(list.value)}
                    checked={!!selectedVoucherType.includes(list.value)}
                    name={VOUCHER_CREATION_TYPE.VOUCHER_CREATION}
                    value={list.label}
                    label={list.value}
                    disabled={false}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex align-middle pt-4 gap-3 bg-white">
            <div className="flex-1">
              <Textbox
                data-testid="voucherName"
                name="voucherName"
                maxLength={5}
                label={<FormattedMessage {...messages.voucher_code} />}
                required
                error={getVoucherError()}
                className="inline-block flex-1"
                placeholder={'Enter unique alphanumeric code'}
                value={voucherName}
                onChange={(e) => {
                  const value = e.target.value;
                  handleChange(value);
                }}
                disabled={selectedVoucherType === VOUCHER_CREATION_TYPE.BULK_CODES}
              />
            </div>
            <div className="flex flex-1 gap-3">
              <div className="flex-auto w-1/3">
                <Textbox
                  name="voucheQuantity"
                  data-testid="voucherQuantity"
                  maxLength={30}
                  label={<FormattedMessage {...messages.quantity} />}
                  required
                  error={Number(voucherQuantity) === 0 ? <FormattedMessage {...messages.min_quantity} /> : ''}
                  className="inline-block flex-1"
                  placeholder={'Enter a quantity'}
                  value={voucherQuantity}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (validateNumericInput(value)) {
                      setVoucherQuantity(value);
                    }
                  }}
                  disabled={selectedVoucherType === VOUCHER_CREATION_TYPE.STANDALONE_CODE}
                />
              </div>
              <div className="flex-auto w-2/3">
                <Textbox
                  data-testid="voucherRedemptionLimit"
                  name="voucherRedemptionLimit"
                  maxLength={30}
                  label={<FormattedMessage {...messages.redemption_limit} />}
                  required
                  error={Number(maxUse) === 0 ? <FormattedMessage {...messages.min_max_use} /> : ''}
                  className="inline-block flex-1"
                  placeholder={'Enter a redemption limit'}
                  value={maxUse}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (validateNumericInput(value)) {
                      setMaxUse(value);
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div className="pt-4 bg-white flex-1">
            <Textarea
              maxLength={100}
              value={voucherDescription}
              onChange={(e) => setVoucherDescription(e.target.value)}
              placeholder="Describe the voucher"
              label={<FormattedMessage {...messages.description} />}
              name="voucherDescription"
              className="resize-none"
            />
          </div>
          <div className="flex w-full align-middle justify-between pt-4 bg-white">
            <div className="w-create-voucher-input-field">
              <label htmlFor="voucher-discount" className="text-sm">
                <FormattedMessage {...messages.discount} />
                <span className="pl-0.5">*</span>
              </label>
              <div className="mt-1">
                <div className="flex align-middle gap-3 bg-white" id="voucher-discount">
                  <div className="flex-1">
                    <div className="flex flex-col relative w-full" tabIndex={-1} data-testid="date-input-box">
                      <div
                        className={classNames(
                          'border rounded flex items-center p-1.5 focus-within:border-blue-700 border-gray-200',
                          discountError && 'focus-within:border-red-700',
                        )}
                      >
                        <div className="flex items-center w-fit">
                          <input
                            type="text"
                            placeholder="00"
                            value={voucherDiscount}
                            onChange={(e) => {
                              handleDiscountChange(e);
                            }}
                            maxLength={3}
                            className="text-center p-0 w-8 text-black placeholder-gray-500 border-none focus:outline-none focus:ring-0 focus:border-none"
                          />
                          <span className="text-base text-gray-900">%</span>
                          <input
                            type="text"
                            onFocus={(e) => e.target.blur()}
                            className="text-center p-0 w-12 text-black placeholder-gray-500 border-none focus:outline-none focus:ring-0 focus:border-none"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {discountError && (
                <div data-testid="input_error" className="text-xs text-red-700">
                  {discountError}
                </div>
              )}
            </div>
            <div className="w-create-voucher-input-field">
              <label htmlFor="voucher-skus" className="text-sm">
                <FormattedMessage {...messages.sku} />
              </label>
              <div className="mt-1">
                <Dropdown
                  id="voucher-skus"
                  isMulti={false}
                  value={selectedSku}
                  isSearchable={true}
                  placeholder="Select"
                  onChange={(sku) => {
                    setSelectedSku(sku as { label: string; value: string });
                  }}
                  options={skuList.length ? skuList : []}
                />
              </div>
            </div>
          </div>
          <div className="flex w-full align-middle justify-between mt-4 bg-white ">
            <div className="w-create-voucher-input-field">
              <label htmlFor="voucher-tags" className="text-sm">
                <FormattedMessage {...messages.tags} />
              </label>
              <div className="mt-1">
                <CustomDropdown
                  id="voucher-tags"
                  isMulti={true}
                  isClearable={false}
                  value={selectedTags}
                  isSearchable={true}
                  placeholder="Select"
                  onChange={(tag) => {
                    setSelectedTags(tag as { label: string; value: number }[]);
                  }}
                  options={tagsList.length ? tagsList : []}
                />
              </div>
            </div>
            <div className="w-create-voucher-input-field">
              <label htmlFor="voucher-datepicker" className="text-sm">
                <FormattedMessage {...messages.expiry} />
              </label>
              <div id="voucher-datepicker" className="mt-1">
                <DatePicker
                  onChange={(date) => {
                    setExpiryDate(date);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-row mt-8">
            <Button
              data-testid="create-voucher"
              onClick={createVoucher}
              isLoading={loadingCreatedVoucher}
              disabled={!disabledCreateButton()}
            >
              <FormattedMessage {...messages.create_Voucher} />
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default VoucherModal;
