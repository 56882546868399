import { defineMessages } from 'react-intl';

export const scope = 'app.components.flows.flow_list';

export default defineMessages({
  add_flow: {
    id: `${scope}.add_flow`,
    defaultMessage: 'Add Flow',
  },
  search_flows: {
    id: `${scope}.search_flows`,
    defaultMessage: 'Search flows...',
  },
  no_flow_created: {
    id: `${scope}.no_flow_created`,
    defaultMessage: 'No Flows Created Yet',
  },
  no_flow_created_description: {
    id: `${scope}.no_flow_created_description`,
    defaultMessage: 'There are no flow configurations created at the moment. You can create one now.',
  },
});
