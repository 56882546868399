import React, { useCallback, useMemo, useState } from 'react';

import { get } from 'lodash';

import AttendeeOnboardingPanel from '@components/Flows/ConfigPanels/AttendeeOnboardingPanel';
import CustomBrandingPanel from '@components/Flows/ConfigPanels/CustomBranding/CustomBrandingPanel/CustomBrandingPanel';
import OffboardingPanel from '@components/Flows/ConfigPanels/OffboardingPanel';
import VersionLog from '@components/Flows/ConfigPanels/VersionLog';
import ConfigCardList from '@components/Flows/FlowDetails/ConfigCardList';
import { getAttendeeOnboardingConfig } from '@components/Flows/FlowDetails/helper';
import { InitialStateType } from '@containers/flowDetails/slice';
import type {
  AttendeeOnboardingConfigType,
  BlackListApps,
  FlowConfigPayloadType,
  VersionLogChange,
} from '@containers/flowDetails/types';
import { CONFIG_TYPE } from '@utils/data/enums';

import StackSettingPanel from '../ConfigPanels/StackSetting/stackSettingPanel';

interface FlowDetailsProps {
  config: FlowConfigPayloadType | null;
  updatedConfig: FlowConfigPayloadType | null;
  updateConfig: (obj: { [key: string]: string | number | boolean | null | BlackListApps }) => void;
  saveConfig: () => void;
  updateFlowConfigStatus: InitialStateType['updateFlowConfigStatus'];
  versionLog: VersionLogChange[];
  createFlowConfigStatus: InitialStateType['createFlowConfigStatus'];
}

const FlowDetails = (props: FlowDetailsProps) => {
  const {
    config,
    saveConfig,
    updatedConfig,
    updateConfig,
    updateFlowConfigStatus,
    versionLog,
    createFlowConfigStatus,
  } = props;
  const [openedConfigType, setOpenedConfigType] = useState<string>(CONFIG_TYPE.VERSION);
  const [deleteLogo, setDeleteLogo] = useState<boolean>(false);
  const setDefaultPanelType = () => setOpenedConfigType(CONFIG_TYPE.VERSION);

  const attendeeOnboardingConfig = useMemo(() => getAttendeeOnboardingConfig(config), [config]);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const updateDeleteLogo = (value: boolean) => {
    setDeleteLogo(value);
  };

  const updateUploadProgress = (progress: number) => {
    setUploadProgress(progress);
  };

  const updatedAttendeeOnboardingConfig = useMemo(() => {
    return getAttendeeOnboardingConfig(updatedConfig);
  }, [updatedConfig]);

  const getPanelType = useCallback(
    (type: string) => {
      switch (type) {
        case CONFIG_TYPE.CUSTOM_BRANDING:
          return (
            <CustomBrandingPanel
              config={{
                logo: {
                  id: `${config && config.branding_logo_file_id}`,
                },
                branding_primary_color: config && config.branding_primary_color,
              }}
              updatedConfig={{
                logo: {
                  id: `${updatedConfig && updatedConfig.branding_logo_file_id}`,
                },
                branding_primary_color: updatedConfig && updatedConfig.branding_primary_color,
              }}
              setDefaultPanelType={setDefaultPanelType}
              updateFeedbackChange={updateConfig}
              saveConfig={saveConfig}
              updateFlowConfigStatus={updateFlowConfigStatus}
              createFlowConfigStatus={createFlowConfigStatus}
              updateDeleteLogo={updateDeleteLogo}
              deleteLogo={deleteLogo}
              uploadProgress={uploadProgress}
              updateUploadProgress={updateUploadProgress}
            />
          );
        case CONFIG_TYPE.OFFBOARDING:
          return (
            <OffboardingPanel
              config={{
                feedback: !!(updatedConfig && updatedConfig.feedback),
              }}
              isUpdated={get(updatedConfig, 'feedback') !== get(config, 'feedback')}
              setDefaultPanelType={setDefaultPanelType}
              updateFeedbackChange={updateConfig}
              saveConfig={saveConfig}
              updateFlowConfigStatus={updateFlowConfigStatus}
              createFlowConfigStatus={createFlowConfigStatus}
            />
          );
        case CONFIG_TYPE.ATTENDEE_ONBOARDING:
          return (
            <AttendeeOnboardingPanel
              config={attendeeOnboardingConfig as AttendeeOnboardingConfigType}
              updatedConfig={updatedAttendeeOnboardingConfig as AttendeeOnboardingConfigType}
              setDefaultPanelType={setDefaultPanelType}
              updateFeedbackChange={updateConfig}
              saveConfig={saveConfig}
              updateFlowConfigStatus={updateFlowConfigStatus}
              createFlowConfigStatus={createFlowConfigStatus}
            />
          );
        case CONFIG_TYPE.SETTING_STAKES:
          return (
            <StackSettingPanel
              config={{
                stack: !!(updatedConfig && updatedConfig.stack),
              }}
              isUpdated={get(updatedConfig, 'stack') !== get(config, 'stack')}
              setDefaultPanelType={setDefaultPanelType}
              updateStackSetting={updateConfig}
              saveConfig={saveConfig}
              updateFlowConfigStatus={updateFlowConfigStatus}
              createFlowConfigStatus={createFlowConfigStatus}
            />
          );
        default:
          return <VersionLog versionLog={versionLog} />;
      }
    },
    [
      openedConfigType,
      updatedConfig,
      updateFlowConfigStatus.isConfigUpdateLoading,
      createFlowConfigStatus,
      versionLog,
      uploadProgress,
    ],
  );

  return (
    <div className="flex w-full bg-white">
      <div className="flex-1 h-[calc(100vh-81px)] overflow-auto">
        <ConfigCardList openConfig={setOpenedConfigType} config={config} />
      </div>
      <div className="min-w-96 flex flex-col max-w-[408px] w-full shadow-[0px_4px_4px_0px_rgba(0,_0,_0,_0.25)] h-[calc(100vh-81px)] overflow-auto">
        {getPanelType(openedConfigType)}
      </div>
    </div>
  );
};

export default FlowDetails;
