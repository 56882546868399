import { defineMessages } from 'react-intl';

export const scope = 'app.containers.exams';

export default defineMessages({
  exams: {
    id: `${scope}.exams`,
    defaultMessage: 'Exams',
  },
});
