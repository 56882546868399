import React, { useMemo, useState } from 'react';

import { FilterButton } from '@components/Flows/Filters/FilterButton';
import Filters from '@components/WorkFlow/Filters';
import { AppliedFilters } from '@containers/workflows';
import { hasNonEmptyFilters } from '@containers/workflows/helpers';

export interface WorkflowFiltersProps {
  workFlows: { label: string; value: string }[];
  status: { label: string; value: string }[];
  userList: { label: string; value: number }[];
  updateAppliedFilters: (filters: object) => void;
  appliedFilters: AppliedFilters;
}

const WorkflowFilters = (props: WorkflowFiltersProps) => {
  const { workFlows, status, userList, appliedFilters, updateAppliedFilters } = props;

  const [showFilter, setShowFilter] = useState<boolean>(false);

  const toogleFilterView = () => {
    setShowFilter((prev) => !prev);
  };

  const hasAppliedFilters = useMemo(() => {
    return hasNonEmptyFilters(appliedFilters as AppliedFilters);
  }, [appliedFilters]);

  return (
    <>
      <FilterButton hasAppliedFilters={hasAppliedFilters} onClick={toogleFilterView} />
      <Filters
        updateAppliedFilters={updateAppliedFilters}
        appliedFilters={appliedFilters}
        showFilter={showFilter}
        status={status}
        toogleFilterView={toogleFilterView}
        workFlows={workFlows}
        userList={userList}
      />
    </>
  );
};

export default WorkflowFilters;
