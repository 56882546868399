import React, { Fragment } from 'react';

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/outline';

import Avatar from '@components/Base/Avatar';
import { ROLE, User } from '@containers/app/types';
import { FormattedTimeZone, TimeZone } from '@containers/user/types';
import { classNames } from '@utils/classNames';
import { formatRoles } from '@utils/helpers';

import SideNavProfileContent from './SideNavProfileContent';

interface SideNavProfileProps {
  user: User;
  isExpanded: boolean;
  selectedLanguage: string;
  handleSetLocale: (language: string) => void;
  selectedTimezone: FormattedTimeZone;
  handleSetTimezone: (timezone: FormattedTimeZone) => void;
  timeZone: TimeZone[];
}

const SideNavProfile = (props: SideNavProfileProps) => {
  const { user, isExpanded, selectedLanguage, handleSetLocale, handleSetTimezone, selectedTimezone, timeZone } = props;
  const { name, currentRole } = user;

  return (
    <Popover className="relative">
      <PopoverButton className="w-full outline-none">
        <div
          className={classNames(
            'flex items-center cursor-pointer overflow-hidden transition-all duration-500 text-left',
            isExpanded ? 'w-full pl-3.5 pr-2' : 'px-3.5',
          )}
        >
          <div className="fit-content">
            <Avatar data={user} />
          </div>
          <div
            className={classNames(
              'truncate mr-auto ml-3 transition-all duration-1000 w-20',
              isExpanded ? 'opacity-100' : 'opacity-0',
            )}
          >
            <div className="text-sm text-gray-900 truncate">{name}</div>
            {currentRole && <div className="text-xs text-gray-600 truncate">{formatRoles(currentRole as ROLE)}</div>}
          </div>
          <ChevronRightIcon height={20} width={20} className="ml-auto text-gray-400" />
        </div>
      </PopoverButton>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-100"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <PopoverPanel className={classNames('absolute bg-white bottom-0 z-10', isExpanded ? 'left-48' : 'left-20')}>
          <SideNavProfileContent
            timeZone={timeZone}
            handleSetTimezone={handleSetTimezone}
            selectedTimezone={selectedTimezone}
            user={user}
            selectedLanguage={selectedLanguage}
            handleSetLocale={handleSetLocale}
          />
        </PopoverPanel>
      </Transition>
    </Popover>
  );
};

export default SideNavProfile;
