import { SessionDetails } from '@components/ProctorNgSessions/SessionTable/Columns';
import { RATING } from '@utils/data/enums';
import '../styles.css';

export const sessionTableData: SessionDetails[] = [
  {
    id: '1',
    session_candidate: {
      first_name: 'Parvathy',
      last_name: 'Raj',
      id: 'CNBFSD-20',
      email: 'parvathy@parvathy.com',
    },
    review: 'reviewed',
    exam_details: {
      name: 'Exam CAT1',
      id: 'SDBFSD-15',
      link: 'www.google.com',
    },
    session_rating_details: {
      rating: RATING.HIGH,
      status_history: [
        {
          status: RATING.HIGH,
          updated_at: new Date().toISOString(),
          updated_by: 'Talview',
        },
        {
          status: RATING.MEDIUM,
          updated_at: new Date().toISOString(),
          updated_by: 'Talview',
        },
        {
          status: RATING.LOW,
          updated_at: new Date().toISOString(),
          updated_by: 'Alvy',
        },
      ],
      flags: {
        critical: 0,
        high: 1,
        medium: 1,
        low: 1,
      },
    },
    proctoring_type: 'Recorded',
    accomodations: [
      {
        id: 1,
        accommodation_type: 'Extra time',
      },
      {
        id: 2,
        accommodation_type: 'Hearing aid usage',
      },
    ],
    attempt: 2,
    status: 'Completed',
    reason: 'Needs investigating',
    summary: 'Candidate confirmed that they were not using a smartphone or a smartwatch',
    session_duration: 120,
    external_id: '#CNBFSD90',
    completed_on: '2024-09-26T05:55:26.996Z',
    requested_on: '2024-09-26T05:55:26.996Z',
    due_date: '2024-09-26T05:55:26.996Z',
    tenant: 'PWC',
    proctor: {
      first_name: 'Parvathy',
      last_name: 'Raj',
    },
    reason_for_request: '',
  },
  {
    id: '2',
    session_candidate: {
      id: 'CNBFSD-26',
      email: 'johndoe@gmail.com',
    },
    review: 'reviewed',
    exam_details: {
      name: 'Exam Test -1',
      id: 'SDBFSD-20',
      link: 'www.google.com',
    },
    session_rating_details: {
      rating: RATING.MEDIUM,
      status_history: [
        {
          status: RATING.HIGH,
          updated_at: new Date().toISOString(),
          updated_by: 'Talview',
        },
        {
          status: RATING.MEDIUM,
          updated_at: new Date().toISOString(),
          updated_by: 'Talview',
        },
        {
          status: RATING.LOW,
          updated_at: new Date().toISOString(),
          updated_by: 'Alvy',
        },
      ],
      flags: {
        critical: 0,
        high: 1,
        medium: 1,
        low: 1,
      },
    },
    proctoring_type: 'Recorded',
    accomodations: [
      {
        id: 1,
        accommodation_type: 'Extra time',
      },
    ],
    attempt: 2,
    status: 'Completed',
    reason: 'Needs investigating',
    summary: 'Candidate confirmed that they were not using a smartphone or a smartwatch',
    session_duration: 45,
    external_id: '#CNBFSD90',
    completed_on: '2024-09-25T05:55:26.996Z',
    requested_on: '2024-09-26T05:55:26.996Z',
    due_date: '2024-09-25T05:55:26.996Z',
    tenant: 'PWC',
    proctor: {
      first_name: 'Parvathy',
      last_name: 'Raj',
    },
    reason_for_request: 'Suspicious behavior detected during the session.',
  },
  {
    id: '3',
    review: 'pending',
    session_candidate: {
      id: 'CNBFSD-24',
    },
    exam_details: {
      name: 'Exam 1',
      id: 'SDBFSD-25',
      link: 'www.google.com',
    },
    session_rating_details: {
      rating: RATING.LOW,
      status_history: [
        {
          status: RATING.HIGH,
          updated_at: new Date().toISOString(),
          updated_by: 'Talview',
        },
        {
          status: RATING.MEDIUM,
          updated_at: new Date().toISOString(),
          updated_by: 'Talview',
        },
        {
          status: RATING.LOW,
          updated_at: new Date().toISOString(),
          updated_by: 'Alvy',
        },
      ],
      flags: {
        critical: 0,
        high: 1,
        medium: 1,
        low: 1,
      },
    },
    proctoring_type: 'Recorded',
    accomodations: [
      {
        id: 1,
        accommodation_type: 'Extra time',
      },
      {
        id: 2,
        accommodation_type: 'Hearing aid usage',
      },
    ],
    attempt: 2,
    status: 'Completed',
    reason: 'Needs investigating',
    summary: 'Candidate confirmed that they were not using a smartphone or a smartwatch',
    session_duration: 130,
    external_id: '#CNBFSD90',
    completed_on: '2024-09-27T05:55:26.996Z',
    requested_on: '2024-09-26T05:55:26.996Z',
    due_date: '2024-09-27T05:55:26.996Z',
    tenant: 'PWC',
    proctor: {
      first_name: 'Parvathy',
      last_name: 'Raj',
    },
    reason_for_request: 'Suspicious behavior detected during the session.',
  },
];
