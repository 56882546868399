import React, { useState } from 'react';

import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Select, { OptionProps, SingleValue, StylesConfig, components } from 'react-select';

import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';
import User from '@components/Base/UserDetails';
import DatePicker from '@components/Shared/DatePicker';
import type { Proctor } from '@containers/sessions/proctor_ng/types';

interface AssignProctorProps {
  openAddFlowModal: boolean;
  handleCloseModal: () => void;
  data: Proctor[];
  onRequestReview: (details: { user: Proctor | null; date: Date | null }) => void;
  selectedCount?: number;
  hasInProgressSession: boolean;
  headerText?: string;
  isEdit?: boolean;
}

interface OptionType {
  value: number;
  label: string;
}

const AssignProctor: React.FC<AssignProctorProps> = ({
  openAddFlowModal,
  handleCloseModal,
  data,
  onRequestReview,
  selectedCount,
  hasInProgressSession,
  headerText,
  isEdit,
}) => {
  const [selectedUser, setSelectedUser] = useState<Proctor | null>(null);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const options: OptionType[] = data?.map((user) => ({
    value: user.id || 0,
    label: `${user.name} (${user.email})`,
  }));

  const handleChange = (selectedOption: SingleValue<OptionType>) => {
    const selectedId = selectedOption?.value;
    const foundUser = data.find(({ id }) => id === selectedId);
    setSelectedUser(foundUser || null);
  };

  const onDateSelect = (date: Date | null) => {
    setSelectedDate(date);
  };

  const CustomOption = (props: OptionProps<OptionType, false>) => {
    const user = data.find(({ id }) => id === props.data.value);
    return (
      <components.Option {...props}>
        {user ? <User data={user} additionalContent={user.email} /> : <span>No User Found</span>}
      </components.Option>
    );
  };

  const customStyles: StylesConfig<OptionType, false> = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#e0e0e0' : state.isFocused ? '#f0f0f0' : '#fff',
      color: state.isSelected ? '#000' : '#333',
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '#333',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '0',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
  };

  const requestReview = () => {
    onRequestReview({ user: selectedUser, date: selectedDate });
    handleCloseModal();
  };

  return (
    <Modal size="sm" show={openAddFlowModal} onCancel={handleCloseModal} closeOnOverlayClick={false}>
      {headerText && !selectedCount && <p className="font-semibold">{headerText}</p>}
      {selectedCount && <p className="font-semibold">{`${selectedCount} sessions selected`}</p>}
      <p className="pb-1.5 pt-3 text-sm font-normal">Assign Proctor</p>
      <div className="w-full">
        <Select
          options={options}
          onChange={handleChange}
          placeholder="Select a proctor..."
          components={{ Option: CustomOption }}
          styles={customStyles}
          isSearchable={false}
        />
        <p className="pt-4 pb-1.5 text-sm font-normal">Due Date</p>
        <DatePicker onChange={onDateSelect} />
      </div>
      {hasInProgressSession && (
        <div className="h-14 bg-yellow-50 w-full mt-4 rounded flex items-center">
          <FontAwesomeIcon icon={faTriangleExclamation} className="text-amber-400 pl-4" size="2xl" tabIndex={0} />
          <span data-testid="in-progress-message" className="font-normal text-sm pl-4">
            Sessions <span className="font-medium">In Progress</span> will not be assigned.
          </span>
        </div>
      )}
      <div className="flex justify-end pt-6">
        <Button className="ml-2 text-sm" onClick={handleCloseModal} variant="outline">
          Go Back
        </Button>
        <Button className="ml-2 text-sm" onClick={requestReview} variant="base">
          {isEdit ? 'Save Changes' : 'Request Review'}
        </Button>
      </div>
    </Modal>
  );
};

export default AssignProctor;
