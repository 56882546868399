import React from 'react';

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

import { Checkbox } from '@components/Base/Checkbox';
import { CustomColumnDef } from '@components/Base/Table/types';
import TextDescription from '@components/Base/TextDescription';
import User from '@components/Base/UserDetails';
import { convertToTitleCase } from '@components/ProctorNgSessions/SessionTable/helper';
import { Voucher } from '@containers/vouchers/types';
import gripHorizontalIcon from '@images/grip-horizontal.svg';
import { classNames } from '@utils/classNames';
import { ValidVoucher, formatDate } from '@utils/dateFormat';

export const createColumns = (): CustomColumnDef<Voucher>[] => [
  {
    id: 'voucher code',
    header: () => 'Voucher Code',
    accessorKey: 'code',
    enableSorting: false,
    hidden: false,
    cell: ({ row }) => <div>{row.original.code || '-'}</div>,
    size: 200,
    className:
      'text-left text-sm font-medium font-roboto text-gray-900 left-[0px] sticky attendee-column shadow-[inset_-1px_0px_0px_0px] shadow-gray-400/20',
    cellClassName:
      'text-left sticky left-[0px] attendee-column shadow-[inset_-1px_0px_0px_0px] z-[1] shadow-gray-400/20',
  },
  {
    id: 'Discount',
    header: () => 'Discount',
    accessorKey: 'discount_percentage',
    enableSorting: false,
    cell: ({ row }) => (row.original.discount_percentage ? `${row.original.discount_percentage}%` : '-'),
    size: 100,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'description',
    header: 'Description',
    accessorKey: 'description',
    enableSorting: false,
    cell: ({ row }) => {
      return <div>{row.original.description || '-'}</div>;
    },
    size: 250,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
  },
  {
    id: 'SKU',
    header: () => 'SKU',
    accessorKey: 'sku',
    enableSorting: false,
    size: 250,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left fit-content',
    hidden: false,
    cell: ({ row }) => {
      const skuCoupons = row?.original?.sku_coupons;
      let combinedNames;
      if (skuCoupons?.length) {
        combinedNames = skuCoupons.map((coupon) => coupon?.sku?.name).join(', ');
      } else {
        combinedNames = '-';
      }

      return (
        <TextDescription text={combinedNames ?? '-'} showToolTip={true} maxNumberOfLines={2} textClass="w-[200px]" />
      );
    },
  },
  {
    id: 'Tags',
    header: () => 'Tags',
    accessorKey: 'tags',
    enableSorting: false,
    cell: ({ row }) => {
      const tagCoupons = row?.original?.tag_coupons;
      let combinedNames;
      if (tagCoupons?.length) {
        combinedNames = tagCoupons.map((coupon) => coupon?.tag?.value).join(', ');
      } else {
        combinedNames = '-';
      }

      if (combinedNames != '-') {
        return (
          <TextDescription text={combinedNames ?? '-'} showToolTip={true} maxNumberOfLines={2} textClass="w-[200px]" />
        );
      } else {
        return <span>-</span>;
      }
    },
    size: 220,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'Max Use',
    header: () => 'Max Use',
    accessorKey: 'max_count',
    enableSorting: false,
    cell: ({ row }) => {
      return <div>{row.original.max_count || '-'}</div>;
    },
    size: 80,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'Expires On',
    header: () => 'Expires On',
    accessorKey: 'valid_till',
    enableSorting: false,
    cell: ({ row }) => (row.original.valid_till ? ValidVoucher(new Date(row.original.valid_till)) : '-'),
    size: 203,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left text-sm/[19px] font-normal',
    hidden: false,
  },
  {
    id: 'Created by',
    header: 'Created by',
    accessorKey: 'created_by_details',
    enableSorting: false,
    cell: ({ row }) => {
      const email = row.original.created_by_details?.email
        ? row.original.created_by_details.email
        : row.original.created_by_details?.name
          ? row.original.created_by_details.name
          : '';
      return (
        <User
          data={{
            name: email,
          }}
          size="sm"
          hideImage={false}
          additionalContent={row.original.created_at ? formatDate(new Date(row.original.created_at), 'withTime') : ''}
        />
      );
    },
    size: 250,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
  },
  {
    id: 'menu',
    enableSorting: false,
    accessorKey: 'menu',
    size: 48,
    className: 'text-left right-0 sticky shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
    cellClassName: 'text-center right-0 sticky z-[1] shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
    header: ({ table }) => {
      return (
        <div>
          <Popover className="flex justify-end items-center pr-3">
            <PopoverButton className={classNames('focus:outline-none')}>
              <PlusCircleIcon
                className={classNames('text-black h-5 w-5 focus:outline-none')}
                aria-hidden="true"
                data-testid="hide-column"
              />
            </PopoverButton>
            <Transition>
              <PopoverPanel>
                <div
                  className="bg-white border-solid border-[1px] w-[214px] font-normal shadow-sm max-h-[480px] overflow-y-auto rounded-md px-4 absolute right-0 top-12"
                  data-testid="column-model"
                >
                  {table.getAllLeafColumns().map((column) => {
                    if (column.id === 'menu') {
                      return null;
                    }
                    return (
                      <div key={column.id} className="h-[40px] w-full flex items-center">
                        <span className="pr-2 w-6 h-6">
                          <img src={gripHorizontalIcon} alt="edit" className="w-full h-full" />
                        </span>
                        <Checkbox
                          label={
                            typeof column.columnDef.header === 'string'
                              ? column.columnDef.header
                              : convertToTitleCase(column.id)
                          }
                          id={column.id}
                          size="md"
                          checked={column.getIsVisible()}
                          onChange={column.getToggleVisibilityHandler()}
                          disabled={false}
                        />
                      </div>
                    );
                  })}
                </div>
              </PopoverPanel>
            </Transition>
          </Popover>
        </div>
      );
    },
  },
];
