import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import App from '@containers/app/App';
import { LanguageContextType, LanguageProvider, useLanguage } from '@contexts/LanguageContext';
import { store } from '@utils/store';

import './index.css';

import I18nProvider from './i18n';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    environment: process.env.ENVIRONMENT || 'dev',
    tracesSampleRate: process.env.ENVIRONMENT === 'local' ? 1.0 : 0.1,
    release: process.env.SENTRY_RELEASE || 'proview-console-client@dev',
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const AppWrapper = () => {
  const { locale } = useLanguage() as LanguageContextType;

  return (
    <I18nProvider locale={locale}>
      <App />
    </I18nProvider>
  );
};

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <Provider store={store}>
      <LanguageProvider>
        <AppWrapper />
      </LanguageProvider>
    </Provider>,
  );
} else {
  console.error('Root element with id "root" not found in the document.');
}
