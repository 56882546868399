import React from 'react';

import { FormattedMessage } from 'react-intl';

import type { CardDetailType } from '@components/Flows/FlowDetails/types';
import { FlowConfigPayloadType } from '@containers/flowDetails/types';
import { CONFIG_TYPE, FEED_TYPE, VERIFICATION_TYPE } from '@utils/data/enums';

import messages from './messages';
import attendeeOnboarding from '../../../images/svgs/attendeeOnboarding.svg';

export const cardDetail: CardDetailType = {
  label: <FormattedMessage {...messages.ab_label} />,
  subText: <FormattedMessage {...messages.ab_description} />,
  logo: attendeeOnboarding,
  type: CONFIG_TYPE.ATTENDEE_ONBOARDING,
};

const getFlagResponse = (flag: boolean) => {
  if (flag) return <FormattedMessage {...messages.enabled} />;
  else return <FormattedMessage {...messages.disabled} />;
};

const AttendeeOnboarding = (props: FlowConfigPayloadType) => {
  const {
    browser_sb_desktop,
    sb_kiosk_mode,
    browser_firefox_desktop,
    browser_firefox_mobile,
    browser_chrome_desktop,
    browser_chrome_mobile,
    browser_edge_desktop,
    browser_edge_mobile,
    browser_safari_mobile,
    environment_test,
    screen_feed,
    primary_camera_feed,
    face,
    photo_id,
  } = props;

  return (
    <div className="flex flex-wrap pb-5">
      <div className="mb-5 text-sm text-gray-900 basis-1/2">
        <span className="font-medium">
          <FormattedMessage {...messages.browser_config} />
        </span>

        <ul className="ml-5 leading-5 list-disc">
          <li>Secure Browser (SB): {getFlagResponse(browser_sb_desktop)}</li>
          <li>Kiosk Mode: {getFlagResponse(sb_kiosk_mode)}</li>
          <li>Mozilla Firefox Mobile: {getFlagResponse(browser_firefox_mobile)}</li>
          <li>Mozilla Firefox Desktop: {getFlagResponse(browser_firefox_desktop)}</li>
          <li>Google Chrome Mobile: {getFlagResponse(browser_chrome_mobile)}</li>
          <li>Google Chrome Desktop: {getFlagResponse(browser_chrome_desktop)}</li>
          <li>Microsoft Edge Mobile: {getFlagResponse(browser_edge_mobile)}</li>
          <li>Microsoft Edge Desktop: {getFlagResponse(browser_edge_desktop)}</li>
          <li>Safari Mobile: {getFlagResponse(browser_safari_mobile)}</li>
        </ul>
      </div>
      <div className="text-sm text-gray-900 basis-1/2">
        <span className="font-medium">
          <FormattedMessage {...messages.camera_recordings} />
        </span>
        <ul className="ml-5 leading-5 list-disc">
          {/* TODO: Uncomment when fullscreen is available from BE
            <li>Full Screen Mode: {getFlagResponse(browser_firefox_desktop)}</li>
          */}
          <li>
            <FormattedMessage {...messages.screen_sharing} />:{' '}
            {getFlagResponse(!!screen_feed && screen_feed !== FEED_TYPE.DISABLE)}
          </li>
          <li>
            <FormattedMessage {...messages.primary_camera_recording} />:{' '}
            {getFlagResponse(!!primary_camera_feed && primary_camera_feed === FEED_TYPE.STREAM_AND_RECORD)}
          </li>
          <li>
            <FormattedMessage {...messages.screen_sharing_recording} />:{' '}
            {getFlagResponse(!!screen_feed && screen_feed === FEED_TYPE.STREAM_AND_RECORD)}
          </li>
        </ul>
      </div>
      <div className="text-sm text-gray-900 basis-1/2">
        <span className="font-medium">
          <FormattedMessage {...messages.identity_permission} />
        </span>
        <ul className="ml-5 leading-5 list-disc">
          <li>
            <FormattedMessage {...messages.photo_id_capture} />:{' '}
            {getFlagResponse(!!photo_id && photo_id === VERIFICATION_TYPE.CAPTURE)}
          </li>
          <li>
            <FormattedMessage {...messages.face_capture} />:{' '}
            {getFlagResponse(!!face && face === VERIFICATION_TYPE.CAPTURE)}
          </li>
        </ul>
      </div>
      <div className="text-sm font-medium text-gray-900 basis-1/2">
        <FormattedMessage {...messages.environment_test} />: {getFlagResponse(environment_test)}
      </div>
    </div>
  );
};

export default AttendeeOnboarding;
