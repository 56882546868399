import React from 'react';

import { FormattedMessage } from 'react-intl';

import ProfileAvatar from '@components/Base/Avatar';
import Badge from '@components/Base/Badge';
import messages from '@components/Flows/ConfigPanels/messages';
import PanelHeader from '@components/Flows/ConfigPanels/PanelHeader';
import ChangeLogList from '@components/Flows/ConfigPanels/VersionLog/ChangeLogList';
import { VersionLogChange } from '@containers/flowDetails/types';
import { STATUS } from '@utils/data/enums';
import { formatDate } from '@utils/dateFormat';

export interface VersionLogProps {
  versionLog: VersionLogChange[];
}

const VersionLog: React.FC<VersionLogProps> = ({ versionLog }) => {
  return (
    <>
      <PanelHeader
        label={<FormattedMessage {...messages.version_history} />}
        description={<FormattedMessage {...messages.version_history_description} />}
        isCloseIcon={false}
      />
      <div className="px-6 py-[18px] flex flex-col gap-7">
        {versionLog.map((detail: VersionLogChange) => (
          <div key={detail.version}>
            <div className="text-base font-medium">
              {detail.version}
              {detail.status === STATUS.DRAFT && (
                <>
                  <span className="ml-1">
                    (
                    <FormattedMessage {...messages.version_history_unpublished} />)
                  </span>
                  <span className="ml-1">
                    <Badge type="secondary">
                      <FormattedMessage {...messages.version_history_current} />
                    </Badge>
                  </span>
                </>
              )}
            </div>
            <div className="text-sm text-gray-600 my-1">
              {detail.status === STATUS.PUBLISHED ? (
                <FormattedMessage {...messages.published_on} />
              ) : (
                <FormattedMessage {...messages.updated_on} />
              )}
              {formatDate(new Date(detail.updatedAt), 'withTime')}
            </div>
            <div className="flex">
              <ProfileAvatar data={detail.user} size="sm" bgColor="bg-blue-100" />
              <div className="text-sm text-gray-600 ml-1">{detail.user.name}</div>
            </div>
            <ChangeLogList detail={detail} />
          </div>
        ))}
      </div>
    </>
  );
};

export default VersionLog;
