import React, { useMemo } from 'react';

import { faClock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import { formatDate } from '@utils/dateFormat';

import messages from './messages';

type LastLoginProps = {
  data: string;
};

function LastLogin(props: LastLoginProps) {
  const { data: lastSignInDate } = props;

  const lastLogInTime = useMemo(() => {
    const parsedDate = lastSignInDate ? formatDate(new Date(lastSignInDate), 'withTime') : null;
    return parsedDate;
  }, [lastSignInDate]);

  return (
    <div className="border rounded-md p-2 bg-gray-100">
      <div className="flex items-center text-gray-500 ">
        <FontAwesomeIcon icon={faClock} className="w-4 rounded-full pr-1" />
        <span className="text-base ml-1">
          <FormattedMessage {...messages.last_login} />
          <span className="ml-0.5"> {lastLogInTime || '-'}</span>
        </span>
      </div>
    </div>
  );
}

export default LastLogin;
