import React, { useEffect, useState } from 'react';

import { faEnvelope, faPenToSquare, faPhone } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';

import PhoneNumberUpdate from '@components/PhoneNumberUpdate';
import { classNames } from '@utils/classNames';

import messages from './messages';

type ContactInformationProps = {
  data: {
    phoneNumber?: string;
    email?: string;
  };
};

function ContactInformation(props: ContactInformationProps) {
  const { data } = props;
  const [editPhoneNumber, toggleEditPhoneNumber] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!data.phoneNumber) {
      toggleEditPhoneNumber(true);
    }
  }, [data.phoneNumber]);

  return (
    <div className="bg-white border rounded-lg p-4">
      <div className="text-gray-900 text-sm font-semibold mb-2">
        <FormattedMessage {...messages.contact_information} />
      </div>
      <div className={classNames('flex mb-2', editPhoneNumber ? 'items-start' : 'items-center')}>
        <FontAwesomeIcon icon={faPhone} className="text-blue-700 w-4 p-2 bg-blue-50 rounded-full" />
        {editPhoneNumber ? (
          <div className="ml-2">
            <PhoneNumberUpdate
              toggleEditPhoneNumber={toggleEditPhoneNumber}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              data={{ phoneNumber: data.phoneNumber || '' }}
            />
          </div>
        ) : (
          <>
            <div className="text-sm ml-2 text-gray-900">{data.phoneNumber || '-'}</div>
            <FontAwesomeIcon
              icon={faPenToSquare}
              className="w-4 ml-4 cursor-pointer"
              onClick={() => toggleEditPhoneNumber(true)}
              data-testid="edit_icon"
            />
          </>
        )}
      </div>
      <div className="flex items-center">
        <FontAwesomeIcon icon={faEnvelope} className="text-blue-700 w-4 p-2 bg-blue-50 rounded-full" />
        <div className="ml-2 text-sm text-gray-900">{data.email || '-'}</div>
      </div>
    </div>
  );
}

export default ContactInformation;
