import React, { useEffect, useState } from 'react';

import 'react-phone-number-input/style.css';
import './styles.css';
import { faCheck, faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Player as LottiePlayer } from '@lottiefiles/react-lottie-player';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import EllipsisAnimation from '@images/EllipsisAnimation.json';

type PhoneNumberUpdateProps = {
  value?: string;
  toggleEditPhoneNumber: (value: boolean) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  data: {
    phoneNumber: string;
  };
};

export function PhoneNumberUpdate(props: PhoneNumberUpdateProps) {
  const { value = '', toggleEditPhoneNumber, isLoading, setIsLoading, data } = props;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isError, setIsError] = useState(true);

  useEffect(() => {
    if (phoneNumber) {
      setIsError(() => !isValidPhoneNumber(phoneNumber));
    }
    if (!phoneNumber) {
      setPhoneNumber(value);
    }
  }, [phoneNumber]);

  const handlePhoneUpdate = () => {
    setIsLoading(true);
    if (!isError) {
      setIsLoading(false);
    }
    // @TODO: Implement API call phone update
  };

  return (
    <div>
      <div className="flex items-center">
        <div data-testid="phone_input">
          <PhoneInput
            disabled={isLoading}
            international
            value={data.phoneNumber}
            onChange={(value) => setPhoneNumber(value as string)}
            defaultCountry="IN"
          />
        </div>
        {isLoading ? (
          <div className="w-4 ml-1 h-2" data-chromatic="ignore" data-testid="ellipsis_loading">
            <LottiePlayer autoplay loop src={EllipsisAnimation} />
          </div>
        ) : (
          <>
            <div className="ml-3 text-lime-700 w-4 cursor-pointer">
              <FontAwesomeIcon icon={faCheck} onClick={handlePhoneUpdate} data-testid="edit_confirm_btn" />
            </div>
            <div className="ml-3 text-rose-600 w-4 cursor-pointer">
              <FontAwesomeIcon
                icon={faXmark}
                onClick={() => toggleEditPhoneNumber(false)}
                data-testid="edit_cancel_btn"
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default PhoneNumberUpdate;
