import { User, browserLocalPersistence, setPersistence, signInWithCustomToken, signOut } from 'firebase/auth';
import { trim } from 'lodash';

import Auth from '@utils/auth';
import { auth } from '@utils/firebase';

export const getUser = (): User | null => {
  return auth.currentUser;
};

export async function authenticateToken({
  access_token,
  errorCallback,
}: {
  access_token: string;
  errorCallback?: () => void;
}) {
  if (!trim(access_token)) throw new Error('Invalid Access Token');
  await setPersistence(auth, browserLocalPersistence);
  const UC = await signInWithCustomToken(auth, access_token);
  if (UC.user) {
    const token = await UC.user.getIdToken();
    return Auth.createClient({
      graphToken: token,
      errorCallback: errorCallback ? errorCallback : () => {},
    });
  }

  return false;
}

export async function logout() {
  try {
    if (auth.currentUser) {
      await auth.currentUser.getIdToken(true);
    }
    await signOut(auth);
    await Auth.logout();
    return Promise.resolve();
  } catch (err) {
    return Promise.resolve();
  }
}
