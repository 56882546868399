import React, { ChangeEvent, useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@components/Base/Button';
import Modal from '@components/Base/Modal';
import { Textbox } from '@components/Base/Textbox';
import flowDetailsMessages from '@components/Flows/ConfigPanels/messages';
import messages from '@components/Flows/EditFlowNameModal/messages';
import type { InitialStateType } from '@containers/flowDetails/slice';

type EditFlowNameModalType = {
  name: string;
  isModalOpen: boolean;
  closeModal: () => void;
  updateFlowName: (val: { name: string }) => void;
  updateFlowStatus: InitialStateType['updateFlowStatus'];
};
const EditFlowNameModal = (props: EditFlowNameModalType) => {
  const { name, isModalOpen, closeModal, updateFlowName, updateFlowStatus } = props;
  const [flowName, setFlowName] = useState('');
  const Intl = useIntl();

  useEffect(() => {
    if (name === flowName) {
      closeModal();
    }
    setFlowName(name);
  }, [name]);

  const handleCloseModal = () => {
    setFlowName(name);
    closeModal();
  };

  return (
    <Modal size="sm" show={isModalOpen} onCancel={handleCloseModal}>
      <div className="text-xl font-semibold text-gray-900">
        <FormattedMessage {...messages.edit_name} />
      </div>
      <div className="mt-3 mb-2 text-sm text-gray-900">
        <FormattedMessage {...messages.name_label} />
      </div>
      <Textbox
        placeholder={Intl.formatMessage(messages.placeholder)}
        value={flowName}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setFlowName(e.target.value)}
        data-testid="edit_flow_name_input"
      />
      <div className="mt-1 text-xs text-red-700">
        {updateFlowStatus.error && (updateFlowStatus.error.message as string)}
      </div>
      <div className="flex items-center justify-end w-full gap-3 mt-6">
        <Button variant="outline" data-testid="create_flow_button" onClick={handleCloseModal}>
          <FormattedMessage {...messages.go_back} />
        </Button>

        <Button
          isLoading={updateFlowStatus.loadingKey === 'name'}
          onClick={() => updateFlowName({ name: flowName })}
          disabled={flowName === name}
        >
          <FormattedMessage {...flowDetailsMessages.save_changes} />
        </Button>
      </div>
    </Modal>
  );
};

export default EditFlowNameModal;
