import React, { FC, Fragment, ReactNode, memo, useEffect, useState } from 'react';

import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';

export interface SidePanelProps {
  children: ReactNode;
  title?: ReactNode;
  show: boolean;
  onClose?: () => void;
  allowCustomHeader?: boolean;
  disabledAutoClose?: boolean;
  initialFocus?: React.MutableRefObject<HTMLElement | null>;
  containerPadding?: string;
}

const SidePanel: FC<SidePanelProps> = ({
  children,
  title,
  show,
  onClose,
  allowCustomHeader = false,
  disabledAutoClose = false,
  initialFocus,
  containerPadding = 'p-6',
}) => {
  const [open, setOpen] = useState(show);
  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleOnClose = () => {
    if (disabledAutoClose) return;
    onClose ? onClose() : setOpen(false);
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50"
        data-testid="side-panel"
        onClose={handleOnClose}
        initialFocus={initialFocus}
      >
        <div className="absolute inset-0">
          <div
            data-testid="action-panel"
            className="fixed bottom-0 right-0 flex max-w-full pointer-events-none h-[calc(100vh-5rem)]"
          >
            <TransitionChild
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto">
                <div className={classNames('flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl')}>
                  <div className="flex flex-col flex-1 min-h-0">
                    {!allowCustomHeader && (
                      <div
                        className={classNames(
                          'flex items-start justify-between top-0 pt-3 sticky w-full pr-10 mb-3 shadow-xs border-b-2 border-gray-100',
                        )}
                      >
                        <div className="flex items-center justify-between w-full mb-2 ml-3 align-middle h-7">
                          {title && (
                            <DialogTitle className="text-base font-normal text-gray-900 lg:text-lg lg:font-medium">
                              {title}
                            </DialogTitle>
                          )}
                          <button
                            data-testid="button-close"
                            type="button"
                            className="text-gray-400 bg-white rounded-md hover:text-gray-500"
                            onClick={handleOnClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    )}
                    <div className={classNames('flex flex-1 flex-col overflow-y-auto', containerPadding)}>
                      {children}
                    </div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default memo(SidePanel);
