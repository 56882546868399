import React, { useMemo } from 'react';

import classNames from 'classnames';
import { get } from 'lodash';

import Avatar from '@components/Base/Avatar';
import type { User } from '@containers/app/types';
import getDisplayName from '@utils/getDisplayName';

interface AvatarProps {
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  shape?: 'rounded-full' | 'rounded-md';
  background?: string;
  isInitials?: boolean;
  textColor?: string;
}

export interface UserDetailsProps {
  data?: Partial<User>;
  size?: 'xs' | 'sm' | 'md' | 'lg';
  border?: 'border-none' | 'default' | 'border-round';
  hideImage?: boolean;
  showEmail?: boolean | string;
  avatarProps?: AvatarProps;
  additionalContent?: React.ReactNode;
}

const sizes = {
  xs: {
    wrapper: 'space-x-2',
    title: 'text-xs',
    description: 'text-xs',
  },
  sm: {
    wrapper: 'space-x-3',
    title: 'text-sm',
    description: 'text-xs',
  },
  md: {
    wrapper: 'space-x-4',
    title: 'text-base',
    description: 'text-sm',
  },
  lg: {
    wrapper: 'space-x-5',
    title: 'text-lg',
    description: 'text-base',
  },
};

const User: React.FC<UserDetailsProps> = (props) => {
  const { data: userData, showEmail, size = 'sm', hideImage, avatarProps, additionalContent } = props;
  const fullName = useMemo(() => getDisplayName(userData), [userData]);

  if (!userData) {
    return <div>-</div>;
  }

  return (
    <div className={classNames('flex flex-1 items-center', sizes[size].wrapper)}>
      {!hideImage && <Avatar size="lg" data={userData} {...avatarProps} />}
      <div className={classNames('flex flex-1 flex-col max-w-max')}>
        <span className={classNames(get(sizes[size], 'title'), 'font-normal text-sm text-gray-900')}>{fullName}</span>
        {additionalContent && (
          <div className={classNames(`text-gray-700`, sizes[size].description)}>{additionalContent}</div>
        )}
        {showEmail && (
          <div className={classNames(typeof showEmail === 'string' && 'mt-1', sizes[size].description)}>
            {userData.email || userData.username}
          </div>
        )}
      </div>
    </div>
  );
};

export default User;
