import React from 'react';

import { FormattedMessage } from 'react-intl';

import messages from './messages';
import somethingWentWrong from '../../../images/svgs/somethingWentWrong.svg';

const SomethingWentWrong = () => {
  return (
    <div className="flex flex-col min-h-screen items-center justify-center z-10">
      <div className="w-[576px] text-center text-gray-900 text-[32px] font-semibold">
        <FormattedMessage {...messages.something_went_wrong} />
      </div>
      <div className="w-[388px] text-center text-gray-700 text-base font-normal leading-[27px]">
        <FormattedMessage {...messages.sub_text} />
      </div>
      <div className="w-[413px] h-[369px] my-4">
        <img src={somethingWentWrong} alt="Something went wrong" className="w-full h-full" />
      </div>
      <div className="w-[605px] text-center">
        <span className="text-gray-700 text-base font-normal">
          <FormattedMessage {...messages.contact} />{' '}
        </span>
        <span className="text-blue-700 text-base font-medium">
          <FormattedMessage {...messages.support} />
        </span>
        <span className="text-gray-700 text-base font-normal">
          {' '}
          <FormattedMessage {...messages.support_team} />
        </span>
      </div>
    </div>
  );
};

export default SomethingWentWrong;
