import React from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { FormattedMessage } from 'react-intl';

import messages from './messages';

export interface PaginationProps {
  total: number;
  pageSize: number;
  currentPage: number;
  summary?: boolean;
  previousPage: () => void;
  getCanPreviousPage: () => boolean;
  nextPage: () => void;
  getCanNextPage: () => boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  total,
  pageSize,
  currentPage,
  summary,
  previousPage,
  getCanPreviousPage,
  nextPage,
  getCanNextPage,
}) => {
  const startRow = currentPage * pageSize + 1;
  const endRow = Math.min(startRow + pageSize - 1, total);

  return (
    <div className="flex items-center float-right" data-testid="pagination">
      {summary && (
        <p className="pr-2 text-sm font-normal text-gray-600 w-55 h-21">
          <span>{startRow}</span> - <span>{endRow}</span> of <span>{total}</span>
        </p>
      )}
      <div>
        <button
          data-testid="prev-page"
          type="button"
          tabIndex={0}
          onClick={() => previousPage()}
          disabled={!getCanPreviousPage()}
          className="items-center px-2 py-2 text-sm font-medium text-gray-500 rounded-md hover:bg-neutral-100"
        >
          <span className="sr-only">
            <FormattedMessage {...messages.previous} />
          </span>
          <ChevronLeftIcon className={`h-7 w-13 ${!getCanPreviousPage() ? 'fill-gray-300' : ''}`} aria-hidden="true" />
        </button>
        <button
          data-testid="next-page"
          type="button"
          tabIndex={0}
          onClick={() => nextPage()}
          disabled={!getCanNextPage() || total <= 0}
          className="items-center px-2 py-2 text-sm font-medium text-gray-500 rounded-md hover:bg-neutral-100"
        >
          <span className="sr-only">
            <FormattedMessage {...messages.next} />
          </span>
          <ChevronRightIcon
            className={`h-7 w-13 ${!getCanNextPage() || total <= 0 ? 'fill-gray-300' : ''}`}
            aria-hidden="true"
          />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
