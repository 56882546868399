export const GET_PRO_SESSION_QUERY = `query getProSession {
    pro_session {
      uuid
      title
      candidate_id
      candidate_details {
        email
        first_name
        last_name
        id
      }
      session_status
      session_type
      summary
      system_rating
      duration
      tenant_id
      updated_at
      project_id
      session_reviews(order_by: {assigned_at: desc}) {
        assigned_at
        rating
        review_status
        review_request_reason
        rating_reason {
          text
        }
      }
      attempt_count
      completed_at
      created_at
      external_id
      id
      flow_config_id
      accommodation {
        accommodation_type
        id
      }
      flags {
        id
        flag_type {
          flag_type_classification {
            text
            id
          }
          flag_type_classification_id
          id
        }
        flag_type_id
      }
    }
  }`;
