import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { Flow, type StatusPayload } from '@components/Flows/FlowListTable/columns';
import { FLOW_DELETION_ERROR } from '@utils/data/enums';

import { CreateFlowActionPayload, CreatedFlowType, DeleteFlowActionPayload, FlowState, Template } from './types';

export interface TableStateProps {
  pageIndex: number;
  pageSize: number;
  searchText: string;
  filter: object;
}

interface FlowListState {
  isLoading: boolean;
  error: unknown;
  data: Array<Flow>;
  totalCount: number;
  limit: number;
  offset: number;
  flow: FlowState;
  conditions?: object;
  searchExpression?: object;
  projectId?: string | null;
  templateList: {
    isLoading: boolean;
    error: string;
    data: Array<Template>;
  };
  openAddFlowModal: boolean;
  tableState: TableStateProps;
  triggerFlowCreationToast: boolean;
  triggerFlowDeletionToast: boolean;
}

export const initialState: FlowListState = {
  isLoading: false,
  error: null,
  data: [],
  totalCount: -1,
  limit: 10,
  offset: 0,
  flow: {
    isLoading: false,
    error: '',
    uuid: '',
  },
  conditions: {},
  searchExpression: {},
  projectId: null,
  templateList: {
    isLoading: false,
    error: '',
    data: [],
  },
  openAddFlowModal: false,
  tableState: {} as TableStateProps,
  triggerFlowCreationToast: false,
  triggerFlowDeletionToast: false,
};

export const flowListSlice = createSlice({
  name: 'flowList',
  initialState: initialState,
  reducers: {
    createFlowAction: (state: FlowListState, _action: PayloadAction<CreateFlowActionPayload>) => {
      state.flow.isLoading = true;
      state.flow.error = '';
    },
    createFlowSuccessAction: (state: FlowListState, { payload }: PayloadAction<CreatedFlowType>) => {
      state.flow.isLoading = false;
      state.flow.error = '';
      state.flow.uuid = payload.uuid;
      state.triggerFlowCreationToast = true;
    },
    createFlowErrorAction: (state: FlowListState, { payload: error }) => {
      state.flow.isLoading = false;
      state.flow.error = error;
      state.flow.uuid = '';
    },
    deleteFlowAction: (state: FlowListState, _action: PayloadAction<DeleteFlowActionPayload>) => {
      state.isLoading = true;
      state.flow.error = '';
    },
    deleteFlowSuccessAction: (state: FlowListState, { payload }: PayloadAction<DeleteFlowActionPayload>) => {
      state.isLoading = false;
      state.flow.error = '';
      state.triggerFlowDeletionToast = true;
      state.data = state.data.filter((flow) => flow.id !== payload.id);
    },
    deleteFlowErrorAction: (state: FlowListState) => {
      state.isLoading = false;
      state.flow.error = FLOW_DELETION_ERROR;
    },
    getFlowListAction: (
      state: FlowListState,
      action: PayloadAction<{
        limit: number;
        offset: number;
        searchExpression?: object;
        projectId?: string;
        conditions?: object;
      }>,
    ) => {
      state.isLoading = true;
      state.error = null;
      state.limit = action.payload.limit;
      state.offset = action.payload.offset;
      state.searchExpression = action.payload.searchExpression;
      state.projectId = action.payload.projectId;
      state.conditions = action.payload.conditions;
    },
    updateFlowAction: (_state: FlowListState, _payload: PayloadAction<StatusPayload>) => {},
    getFlowListSuccessAction: (
      state: FlowListState,
      { payload }: PayloadAction<{ data: Flow[]; totalCount: number }>,
    ) => {
      state.isLoading = false;
      state.data = payload.data;
      state.totalCount = payload.totalCount;
    },
    getFlowListErrorAction: (state: FlowListState, { payload: error }: PayloadAction<unknown>) => {
      state.isLoading = false;
      state.error = error;
    },
    setListTableState: (
      state: FlowListState,
      action: PayloadAction<{ tableState: TableStateProps; projectId: string }>,
    ) => {
      state.tableState = action.payload.tableState;
      state.projectId = action.payload.projectId;
    },
    getTemplateListAction: (state: FlowListState) => {
      state.templateList.isLoading = true;
      state.templateList.error = '';
    },
    getTemplateListSuccessAction: (state: FlowListState, { payload }: PayloadAction<Template[]>) => {
      state.templateList.isLoading = false;
      state.templateList.data = payload;
      state.templateList.error = '';
    },
    getTemplateListErrorAction: (state: FlowListState, { payload: error }) => {
      state.templateList.isLoading = false;
      state.templateList.error = error as string;
    },
    setUpdateAddFlowModal: (state: FlowListState, action: PayloadAction<boolean>) => {
      state.openAddFlowModal = action.payload;
    },
    resetFlowState: (state: FlowListState) => {
      state.flow.isLoading = false;
      state.flow.error = '';
      state.flow.uuid = '';
    },
    resetTriggerFlowCreationToast: (state: FlowListState) => {
      state.triggerFlowCreationToast = false;
    },
    resetTriggerFlowDeletionToast: (state: FlowListState) => {
      state.triggerFlowDeletionToast = false;
    },
  },
});

export const {
  getFlowListAction,
  getFlowListSuccessAction,
  getFlowListErrorAction,
  createFlowAction,
  createFlowSuccessAction,
  createFlowErrorAction,
  deleteFlowAction,
  deleteFlowSuccessAction,
  deleteFlowErrorAction,
  setListTableState,
  getTemplateListAction,
  getTemplateListSuccessAction,
  getTemplateListErrorAction,
  setUpdateAddFlowModal,
  resetFlowState,
  resetTriggerFlowCreationToast,
  resetTriggerFlowDeletionToast,
  updateFlowAction,
} = flowListSlice.actions;

export default flowListSlice.reducer;
