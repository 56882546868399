import React, { useContext, useEffect } from 'react';

import { get } from 'lodash';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Card from '@components/Base/Card';
import GroupDropdown, { Option, ProjectDropdownProps } from '@components/Base/GroupDropdown';
import Header from '@components/Base/Header';
import ProjectLoading from '@components/Base/Loader';
import { triggerToast } from '@components/Base/Notification';
import SomethingWentWrong from '@components/Error/SomethingWentWrong';
import ProjectLanding from '@components/Projects/ProjectLanding';
import SectionDetails from '@components/Projects/SectionDetails';
import messages from '@components/Projects/SectionDetails/messages';
import { resetTriggerToast } from '@containers/projects/slice';
import { HeaderContext, HeaderContextType } from '@contexts/HeaderContext';
import { TOAST_STATE, TOAST_VARIANT } from '@utils/data/enums';
import { getMixpanelProperties } from '@utils/helpers';
import { useAppDispatch, useAppSelector } from '@utils/hooks';
import { proviewUserNewProjectCreatedEvent, proviewUserProjectPageOpenedEvent } from '@utils/mixpanelActions';
import type { RootState } from '@utils/store';

import { handleProjectFormOpen } from './helpers';
import FlowIcon from '../../images/flows-icon.png';

const Projects = () => {
  const navigate = useNavigate();

  const {
    data: projects,
    isLoading,
    currentProject,
    error,
    triggerToast: triggerProjectToast,
  } = useAppSelector((state: RootState) => state.projects);
  const { user, isMixpanelInitialized } = useAppSelector((state) => state.app);

  const { isComplete: isCreateComplete, isSuccess: isNewProjectCreated } = useAppSelector(
    (state: RootState) => state.projects.projectForm,
  );
  const dispatch = useAppDispatch();

  const { handleProjectChange, options } = useContext<HeaderContextType>(HeaderContext);

  const handleNavigate = () => {
    navigate(`/flows?project_uuid=${currentProject.token}`);
  };

  useEffect(() => {
    if (triggerProjectToast) {
      handleTriggerToast(triggerProjectToast);
      dispatch(resetTriggerToast());
    }
  }, [triggerProjectToast]);

  useEffect(() => {
    if (isNewProjectCreated && isMixpanelInitialized) {
      const mixpanelProperties = getMixpanelProperties(user);
      proviewUserNewProjectCreatedEvent(mixpanelProperties);
    }
  }, [isNewProjectCreated, isMixpanelInitialized]);

  useEffect(() => {
    if (currentProject.token && isMixpanelInitialized) {
      const mixpanelProperties = getMixpanelProperties(user, currentProject.name);
      proviewUserProjectPageOpenedEvent(mixpanelProperties);
      navigate(`/projects/${currentProject.token}`);
    }
  }, [currentProject.token, isMixpanelInitialized]);

  const handleTriggerToast = (triggerFlowConfigToast: string) => {
    switch (triggerFlowConfigToast) {
      case TOAST_STATE.PROJECT_DETAILS_SAVED:
        showToast(TOAST_VARIANT.SUCCESS, messages.details_updated_description, messages.details_updated);
        break;

      case TOAST_STATE.PROJECT_DETAILS_SAVE_FAILED:
        showToast(TOAST_VARIANT.DANGER, messages.details_update_failed_description, messages.details_update_failed);
        break;
      default:
        showToast(TOAST_VARIANT.DANGER, messages.something_went_wrong);
    }
  };

  const showToast = (variant: string, summary: MessageDescriptor, title?: MessageDescriptor) => {
    triggerToast({
      variant,
      message: {
        title: <FormattedMessage {...title} />,
        summary: <FormattedMessage {...summary} />,
      },
    });
  };

  if (isLoading) {
    return !projects.length && <ProjectLoading />;
  }

  if (error) {
    return <SomethingWentWrong />;
  }

  return projects.length > 0 || isCreateComplete ? (
    <>
      <Header
        details={{
          label: <FormattedMessage {...messages.project_overview} />,
          labelTooltip: 'Project overview',
        }}
      >
        <div className="w-[308px]">
          <GroupDropdown
            selectedValue={{ label: currentProject.name }}
            options={options as Option[]}
            onChange={handleProjectChange as ProjectDropdownProps['onChange']}
          />
        </div>
      </Header>
      {currentProject && <SectionDetails {...currentProject} handleEditProjectModal={handleProjectFormOpen} />}
      <div className="box-border grid grid-cols-1 gap-4 px-4 mt-8 md:grid-cols-2 lg:grid-cols-4">
        <Card
          count={get(currentProject, 'flows.length', 0)}
          label={<FormattedMessage {...messages.flows} />}
          imageProps={{
            alt: 'Flows card',
            url: FlowIcon,
          }}
          onNavigate={handleNavigate}
        />
      </div>
    </>
  ) : (
    <ProjectLanding handleOpenModal={handleProjectFormOpen} />
  );
};

export default Projects;
