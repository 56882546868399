import React from 'react';

import { type ToastOptions, type ToastPosition, toast } from 'react-toastify';

import Content from './NotificationContent/Content';
import type { NotificationProps, ToastProps } from './types';

export const Notification = (props: NotificationProps) => {
  const { children, variant, message, actions, closeToast } = props;
  return (
    <>
      {children || (
        <Content
          title={message.title}
          summary={message.summary}
          onClose={closeToast}
          actions={actions}
          variant={variant}
        />
      )}
    </>
  );
};

export const triggerToast = (arg: ToastProps) => {
  const { setting = {}, ...props } = arg;
  const defaultSettings: ToastOptions = {
    theme: 'light',
    icon: false,
    hideProgressBar: true,
    autoClose: 5000,
    position: 'bottom-right' as ToastPosition,
    ...setting,
  };

  toast(<Notification {...props} />, defaultSettings);
};
