import React, { useEffect, useMemo, useState } from 'react';

import SessionList from '@components/ProviewConsoleSessions/SessionsList';
import { getProSessionsAction } from '@containers/sessions/proview_console/slice';
import { useAppDispatch, useAppSelector } from '@utils/hooks';

interface ProviewConsoleSessionsProps {
  role: string;
}

export type AppliedFilters = {
  sessionFilterStatus?: { label: string; value: string }[];
  sessionExams?: { label: string; value: string }[];
  rating?: string[];
  selectedAccomodations?: { label: string; value: string }[];
  status?: string[];
  selectedDateRange?: {
    startDate: Date;
    endDate: Date;
  };
  selectedRequestedRange?: {
    startDate: Date;
    endDate: Date;
  };
};

const ProviewConsoleSessions = (props: ProviewConsoleSessionsProps) => {
  const { role } = props;
  const { data: consoleSessions } = useAppSelector((state) => state.consoleSessions);
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState('sessions');
  const [appliedFilters, updateAppliedFilters] = useState<AppliedFilters>({});

  const sessionList = useMemo(() => {
    return consoleSessions.filter((session) => {
      return selectedTab === 'sessions' ? session : session.session_reviews[0]?.review_status === 'PENDING';
    });
  }, [consoleSessions, selectedTab]);

  const examList = useMemo(() => {
    return consoleSessions
      .filter((session) => {
        return (
          (selectedTab === 'sessions' ? session : session.session_reviews[0]?.review_status === 'PENDING') &&
          session.title !== null
        );
      })
      .map((session) => ({
        label: session.title,
        value: session.id,
      }));
  }, [consoleSessions, selectedTab]);

  const handleSelectedTab = (value: string) => {
    setSelectedTab(value);
  };

  const parseFilterValue = (filter: { label: string; value: string }[]) =>
    filter.map(({ value }) => value.toUpperCase());

  useEffect(() => {
    const {
      sessionFilterStatus,
      sessionExams,
      rating,
      selectedAccomodations,
      status,
      selectedDateRange,
      selectedRequestedRange,
    } = appliedFilters;

    const filters = {
      ...(sessionExams?.length && {
        title: { _in: sessionExams.map(({ label }) => label) },
      }),
      ...(sessionFilterStatus?.length && {
        session_status: { _in: parseFilterValue(sessionFilterStatus) },
      }),
      ...(rating?.length && {
        system_rating: { _in: rating.map((value) => value.toLowerCase()) },
      }),
      ...(status?.length && {
        session_type: { _in: status.map((value) => value.toUpperCase()) },
      }),
      ...(selectedAccomodations?.length && {
        accommodation: {
          accommodation_type: {
            _in: selectedAccomodations.map(({ value }) => value.toUpperCase()),
          },
        },
      }),
      ...(selectedDateRange &&
        selectedDateRange.startDate &&
        selectedDateRange.endDate && {
          completed_at: {
            _gte: selectedDateRange.startDate,
            _lte: selectedDateRange.endDate,
          },
        }),
      ...(selectedRequestedRange &&
        selectedRequestedRange.startDate &&
        selectedRequestedRange.endDate && {
          session_reviews: {
            assigned_at: {
              _gte: selectedRequestedRange.startDate,
              _lte: selectedRequestedRange.endDate,
            },
          },
        }),
    };

    dispatch(getProSessionsAction({ conditions: filters }));
  }, [appliedFilters]);

  useEffect(() => {
    if (!consoleSessions.length) {
      dispatch(
        getProSessionsAction({
          conditions: {},
        }),
      );
    }
  }, []);

  return (
    <SessionList
      updateAppliedFilters={updateAppliedFilters}
      sessionData={sessionList}
      role={role}
      examList={examList}
      selectedTab={selectedTab}
      handleSelectedTab={handleSelectedTab}
    />
  );
};

export default ProviewConsoleSessions;
