import type { DocumentNode } from '@apollo/client';

import Auth from '@utils/auth';

import { processQuery } from './processQuery';

export const makeRequest = async ({
  variables,
  queryString,
  key,
  forceRefresh = false,
  // context = {},
}: {
  variables: object;
  queryString: DocumentNode;
  key: string;
  forceRefresh?: boolean;
  // context?: object;
}) => {
  const apolloClient = await Auth.client();
  if (!apolloClient) {
    throw new Error('Apollo client is undefined.');
  }
  // const defaultContext = getDefaultContext(Auth.graphToken!);
  const { data } = await apolloClient.query({
    query: queryString,
    variables,
    fetchPolicy: forceRefresh ? 'network-only' : 'cache-first',
    // context: {
    //   ...defaultContext,
    //   ...context,
    // },
  });
  return key ? data[key] : data;
};

const fetchData = async ({
  queryString,
  queryKey,
  queryVariables = {},
  forceRefresh,
  // context,
}: {
  queryString: string;
  queryKey: string;
  queryVariables?: object;
  forceRefresh?: boolean;
  // context?: object;
}) => {
  if (!queryString || typeof queryString !== 'string' || !queryKey) {
    return [];
  }

  const graphQuery = processQuery(queryString);

  if ('errorMessage' in graphQuery && graphQuery?.errorMessage === 'Invalid Query') {
    throw graphQuery;
  }

  const response = await makeRequest({
    variables: {
      ...queryVariables,
    },
    key: queryKey,
    queryString: graphQuery as DocumentNode,
    forceRefresh,
    // context,
  });
  return response;
};

export default fetchData;
