import React from 'react';

import { XMarkIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { get } from 'lodash';

import { handleEnterKeyPress } from '@utils/helpers';

import { dataMap } from './notificationData';
import type { ActionProps, ContentProps } from '../types';

const Content = ({ title, summary, onClose, actions, variant }: ContentProps) => {
  const currentVariant = variant || 'default';
  const icon = get(dataMap, `${currentVariant}.icon`);
  const altText = get(dataMap, `${currentVariant}.altText`);
  const borderColor = get(dataMap, `${currentVariant}.borderColor`);
  const color = get(dataMap, `${currentVariant}.color`);

  const handleActionKeyPress = (action: ActionProps): React.KeyboardEventHandler<HTMLSpanElement> | undefined => {
    return (e) => handleEnterKeyPress(e, () => action.onClick());
  };

  return (
    <div
      className={classNames(
        'flex items-start border-l-8 bg-white py-4 px-2 rounded shadow-toast w-[420px]',
        borderColor,
      )}
    >
      <div className="flex items-start justify-center mt-1 w-11" data-chromatic="ignore">
        <img src={icon} alt={altText} />
      </div>
      <div className="flex flex-col items-center justify-center flex-1">
        {title ? (
          <div className="flex justify-between w-full">
            <div>
              <span className="self-stretch text-sm font-semibold leading-5 text-gray-900">{title}</span>
              {summary && <p className="pt-1 text-sm font-normal text-gray-900">{summary}</p>}
            </div>
          </div>
        ) : (
          <div className="flex items-center justify-between w-full h-full">
            {summary && <p className="pt-1 text-sm font-normal text-gray-900">{summary}</p>}
          </div>
        )}
        <div className="flex items-center justify-start w-full space-x-4">
          {actions &&
            actions.map((action: ActionProps) => (
              <span
                data-testid={`toast-action-${action.label}`}
                key={action.label}
                role="button"
                tabIndex={0}
                className={classNames('cursor-pointer text-sm font-semibold mt-2', color)}
                onClick={action.onClick}
                onKeyDown={handleActionKeyPress(action)}
              >
                {action.label}
              </span>
            ))}
        </div>
      </div>
      <div className="pr-4 pl-4 pt-[2px] cursor-pointer">
        <XMarkIcon
          role="presentation"
          className="w-6 h-6 text-gray-900"
          onClick={onClose}
          data-testid="close-toast-notification"
        />
      </div>
    </div>
  );
};

export default Content;
