import { defineMessages } from 'react-intl';

export const scope = 'app.base.component.custom_dropdown';

export default defineMessages({
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add',
  },
});
