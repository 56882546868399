import React, { useMemo } from 'react';

import { FormattedMessage } from 'react-intl';

import High from '@images/svgs/high-signal.svg';
import Low from '@images/svgs/low-signal.svg';
import Medium from '@images/svgs/medium-signal.svg';
import { RATING } from '@utils/data/enums';

import messages from './messages';

export interface RatingAtomProps {
  rating?: RATING;
}

const RatingAtom = ({ rating }: RatingAtomProps) => {
  const integrity_rating = useMemo(() => {
    switch (rating) {
      case RATING.LOW:
        return (
          <>
            <img src={Low} alt="Low" className="mt-0.5 h-4" />
            <div className="text-sm font-medium font-roboto text-red-600">
              <FormattedMessage {...messages.low} />
            </div>
          </>
        );
      case RATING.MEDIUM:
        return (
          <>
            <img src={Medium} alt="Medium" className="mt-0.5 h-4" />
            <div className="text-sm font-medium font-roboto text-amber-400">
              <FormattedMessage {...messages.medium} />
            </div>
          </>
        );
      case RATING.HIGH:
        return (
          <>
            <img src={High} alt="High" className="mt-0.5 h-4" />
            <div className="text-sm font-medium font-roboto text-green-600">
              <FormattedMessage {...messages.high} />
            </div>
          </>
        );
      default:
        return <>-</>;
    }
  }, [rating]);
  return <div className="flex gap-1">{integrity_rating}</div>;
};

export default RatingAtom;
