import React from 'react';

import { FormattedMessage } from 'react-intl';

import LanguageSelectContent from './LanguageSelectContent';
import messages from './messages';

interface LanguageSelectProps {
  selectedLanguage: string;
  handleSetLocale: (language: string) => void;
}

const LanguageSelect = ({ selectedLanguage, handleSetLocale }: LanguageSelectProps) => {
  return (
    <div className="p-6">
      <div className="pb-6 text-base font-medium leading-5 text-gray-900">
        <FormattedMessage {...messages.header} />
      </div>
      <LanguageSelectContent selectedLanguage={selectedLanguage} handleSetLocale={handleSetLocale} />
    </div>
  );
};

export default LanguageSelect;
