export const GET_VOUCHER_LIST_QUERY = `
query getVoucherList ($limit: Int, $offset: Int, $searchExpression: [pay_coupon_bool_exp!], $conditions: pay_coupon_bool_exp = {}) {
 pay_coupon(limit: $limit, offset: $offset,where: { _and: [{_or: $searchExpression}, $conditions]}, order_by: {created_at: desc}) {
      code
      created_at
      created_by
      description
      discount_percentage
      external_id
      created_by_details {
        email
        name
        id
      }
      id
      is_active
      max_count
      valid_till
      updated_by
      updated_at
      sku_coupons {
        sku {
          name
          id
        }
      }
      tag_coupons {
        tag {
          value
          id
        }
      }
    }
    pay_coupon_aggregate (where: { _and: [{_or: $searchExpression}, $conditions]}) {
      aggregate {
        count
      }
    }
}`;

export const GET_SKU_LIST_QUERY = `
query getSkuList {
  pay_sku {
    name
    id
  }
}`;

export const GET_TAG_LIST_QUERY = `
query getPayTagList {
  pay_tag {
    value
    id
  }
}`;

export const GET_REDEEMED_VOUCHER_LIST_QUERY = `query getRedeemedVoucherList($limit: Int, $offset: Int, $searchExpression: [pay_order_bool_exp!], $conditions: pay_order_bool_exp = {}) {
  pay_order(limit: $limit, offset: $offset, where: {status: {_in: [COMPLETED]}, order_items: {coupon_id: {_is_null: false}}, _and:[{ _or: $searchExpression}, $conditions]}, order_by: {created_at: desc}) {
    created_at
    created_by
    external_id
    id
    ordered_by
    status
    ordered_by_details {
      id
      name
      username
    }
    updated_at
    updated_by
    order_items {
      coupon {
        code
        discount_percentage
        created_by_details {
          email
          name
        }
        description
        max_count
        valid_till
      }
      coupon_id
      item {
        id
        sku {
          name
        }
      }
    }
    transactions {
      transaction_status_histories(where: {status_to: {_eq: COMPLETED}}) {
        updated_at
        status_to
      }
    }
  }
  pay_order_aggregate (where: {status: {_in: [COMPLETED]}, order_items: {coupon_id: {_is_null: false}}, _and:[{ _or: $searchExpression}, $conditions]})  {
    aggregate {
      count
    }
    nodes {
      transactions {
        transaction_status_histories(where: {status_to: {_eq: COMPLETED}}) {
          status_to
        }
      }
    }
  }
}`;

export const CREATE_VOUCHER_MUTATION = `mutation createVoucher($code: String, $tag_coupons: [String!], $discount_percentage: Int!,$sku_id: [pay_sku_coupon_input]!, $max_count: Int!, $description: String, $valid_till: timestamptz) {
  pay_create_coupon(
      code: $code
      discount_percentage: $discount_percentage
      sku_coupons: $sku_id
      description: $description
      max_count: $max_count
      valid_till: $valid_till
      tag_coupons: $tag_coupons
    ) {
      valid_till
      max_count
      id
      discount_percentage
      sku_coupons {
        sku_id
      }
      description
      code
    }
  }`;
