import { CreateVoucherResponse } from './saga';

interface VoucherItemDetails {
  id: number;
  email?: string;
  external_id?: string | null;
}

interface VoucherOrderItem {
  coupon_id: number;
  item: {
    id: number;
  };
  item_id: number;
  total_price: number;
  unit_price: number;
  quantity: number;
  created_by_details: VoucherItemDetails;
}

interface Sku {
  name: string;
  id: number;
}

export interface Tag {
  value: string;
  id: number;
}

interface SkuCoupon {
  sku: Sku;
}

interface TagCoupon {
  tag: Tag;
}

export interface RedeemedVoucher {
  code: string;
  created_at: string;
  created_by: number;
  description: string | null;
  discount_percentage: number;
  external_id: string | null;
  id: number;
  is_active: boolean;
  is_deleted: boolean;
  max_count: number;
  valid_till: string | null;
  updated_by: number;
  updated_at: string;
  order_items: VoucherOrderItem[];
  created_by_details: {
    email: string;
    name: string;
    id: number;
  };
  redeemed_by_details: {
    email: string;
    name: string;
    id: number;
  };
  sku_coupons: SkuCoupon[];
  tag_coupons: TagCoupon[];
}

export interface Voucher {
  code: string;
  created_at: string;
  created_by: number;
  description: string | null;
  discount_percentage: number | null;
  external_id: string | null;
  redeem_count: number | null;
  id: number;
  is_active: boolean;
  is_deleted: boolean;
  max_count: number | null;
  valid_till: string | null;
  updated_by: number | null;
  updated_at: string;
  order_items: VoucherOrderItem[];
  created_by_details: {
    email: string;
    name: string;
    id: number;
  } | null;
  sku_coupons: SkuCoupon[];
  tag_coupons: TagCoupon[];
}

interface VoucherData {
  pay_coupon: Voucher[];
}

export interface VoucherListEntry {
  data: VoucherData;
}

export interface SkuList {
  id: string;
  name: string;
}
export interface VouchersState {
  isLoading: boolean;
  isSuccess: boolean;
  error: string | null;
  data: Voucher[];
  issuedVouchersList: {
    isLoading: boolean;
    isSuccess: boolean;
    error: string | null;
    data: Voucher[];
    voucherCodes: {
      isLoading: boolean;
      isSuccess: boolean;
      error: string | null;
      codes: string[];
    };
  };
  totalCount: number;
  searchConditions: object[] | object | null;
  skus: {
    isLoading: boolean;
    isSuccess: boolean;
    error: string | null;
    skuList: SkuList[];
  };
  redeemedVouchers: {
    isLoading: boolean;
    isSuccess: boolean;
    error: string | null;
    totalCount: number;
    data: RedeemedVoucherList[];
  };
  redeemedVoucherFullList: {
    isLoading: boolean;
    isSuccess: boolean;
    error: string | null;
    data: RedeemedVoucherList[];
  };
  newlyCreatedVouchers: {
    isLoading: boolean;
    isSuccess: boolean;
    error: string | null;
    data: CreateVoucherResponse | null;
  };
  tags: {
    isLoading: boolean;
    isSuccess: boolean;
    error: string | null;
    data: Tag[];
  };
}

export const VOUCHER_STATUS = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  EXPIRED: 'Expired',
  NO_EXPIRY: 'No Expiry',
  NONE: 'None',
  TIMES: 'times',
  NA: 'N/A',
  STATUS: 'status',
};

export const voucherStatus = [
  { label: VOUCHER_STATUS.ACTIVE, value: VOUCHER_STATUS.ACTIVE },
  { label: VOUCHER_STATUS.INACTIVE, value: VOUCHER_STATUS.INACTIVE },
];

export const VOUCHER_CREATION_TYPE = {
  BULK_CODES: 'Bulk Codes',
  STANDALONE_CODE: 'Standalone Code',
  VOUCHER_CREATION: 'voucherCreation',
};

export const voucherCreationType = [
  {
    label: VOUCHER_CREATION_TYPE.STANDALONE_CODE,
    value: VOUCHER_CREATION_TYPE.STANDALONE_CODE,
  },
  {
    label: VOUCHER_CREATION_TYPE.BULK_CODES,
    value: VOUCHER_CREATION_TYPE.BULK_CODES,
  },
];

interface CreatedByDetails {
  email: string | null;
  name: string | null;
}

interface Coupon {
  code: string | null;
  discount_percentage: number | null;
  created_by_details: CreatedByDetails | null;
  description: string | null;
  max_count: number | null;
  valid_till: string | null;
}

interface SKU {
  name: string;
}

interface Item {
  id: number;
  sku: SKU;
}

export interface OrderItem {
  coupon: Coupon | null;
  coupon_id: number;
  item: Item;
}

interface TransactionStatusHistory {
  updated_at: string;
}

interface Transaction {
  transaction_status_histories: TransactionStatusHistory[];
}

interface OrderedByDetails {
  id: string;
  name: string | null;
  username: string | null;
}

export interface RedeemedVoucherList {
  created_at: string;
  created_by: number;
  external_id: number | null;
  id: number;
  ordered_by: number;
  status: string;
  ordered_by_details: OrderedByDetails | null;
  updated_at: string;
  updated_by: number;
  order_items: OrderItem[] | null;
  transactions: Transaction[];
}

export const SELECTED_TAB = {
  ISSUED: 'issued',
  REDEEMED: 'redeemed',
};

export const VOUCHER_ERROR = {
  INVALID_VOUCHER: 'Invalid voucher',
  DUPLICATE_VOUCHER: 'Duplicate voucher',
};
