import { all, call, put, takeLatest } from 'redux-saga/effects';

import fetchData from '@utils/fetchData';

import { GET_PRO_SESSION_QUERY } from './queries';
import { getExamsAction, getExamsErrorAction, getExamsSuccessAction } from './slice';
import { SessionData } from './types';

export function* getExamsListSaga() {
  const queryVariables = {};
  try {
    const response: SessionData[] = yield call(fetchData, {
      queryString: GET_PRO_SESSION_QUERY,
      queryKey: 'pro_session',
      queryVariables: queryVariables,
      forceRefresh: true,
    });
    if (response) {
      yield put(getExamsSuccessAction({ data: response }));
    }
  } catch (error) {
    yield put(getExamsErrorAction(error as string | Error));
  }
}

export function* examsListSaga() {
  yield takeLatest(getExamsAction.type, getExamsListSaga);
}

export function* proviewExamsSaga() {
  yield all([examsListSaga()]);
}
