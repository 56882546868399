import { defineMessages } from 'react-intl';

export const scope = 'app.components.proctorng.cancel_provider';

export default defineMessages({
  cancel_request: {
    id: `${scope}.cancel_request`,
    defaultMessage: 'Cancel request?',
  },
  cancel_request_description: {
    id: `${scope}.requests`,
    defaultMessage: 'Once cancelled, you will have to request again for this session to be reviewed.',
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Yes, Cancel',
  },
});
