import React from 'react';

import { Popover, PopoverButton, PopoverPanel, Transition } from '@headlessui/react';
import { PlusCircleIcon } from '@heroicons/react/24/outline';

import Badge from '@components/Base/Badge';
import { Checkbox } from '@components/Base/Checkbox';
import { CustomColumnDef } from '@components/Base/Table/types';
import User from '@components/Base/UserDetails';
import { convertToTitleCase } from '@components/ProctorNgSessions/SessionTable/helper';
import { Workflow, wftJobStatus } from '@containers/workflows/types';
import { classNames } from '@utils/classNames';
import { formatDate } from '@utils/dateFormat';
import { getCurrencySymbol } from '@utils/helpers';
import gripHorizontalIcon from 'app/images/grip-horizontal.svg';

export const StatusBadge = (status: string) => {
  switch (status) {
    case wftJobStatus.OPEN:
      return <Badge type="open">{status}</Badge>;
    case wftJobStatus.CLOSED:
      return <Badge type="closed">{status}</Badge>;
    case wftJobStatus.DRAFT:
      return <Badge type="wftdraft">{status}</Badge>;
    case wftJobStatus.CANCELLED:
      return <Badge type="cancelled">{status}</Badge>;
    default:
      return <Badge type="green">{status}</Badge>;
  }
};

export const createColumns = (
  openWorkflowSidepanelHandler: (workflow: Workflow) => void,
): CustomColumnDef<Workflow>[] => [
  {
    id: 'Workflow',
    header: () => 'Workflow',
    accessorKey: 'Workflow',
    enableSorting: false,
    hidden: false,
    cell: ({ row }) => (
      <button
        aria-label="Workflow-title"
        type="button"
        onClick={() => openWorkflowSidepanelHandler(row.original)}
        className="cursor-pointer"
      >
        {row.original.title}
      </button>
    ),
    size: 200,
    className:
      'text-left text-sm font-medium font-roboto text-gray-900 left-[0px] sticky attendee-column shadow-[inset_-1px_0px_0px_0px] shadow-gray-400/20',
    cellClassName:
      'text-left sticky left-[0px] attendee-column shadow-[inset_-1px_0px_0px_0px] z-[1] shadow-gray-400/20',
  },
  {
    id: 'Status',
    header: () => 'Status',
    accessorKey: 'Status',
    enableSorting: false,
    cell: ({ row }) => StatusBadge(row.original.status || ''),
    size: 100,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'Steps',
    header: 'Steps',
    accessorKey: 'Steps',
    enableSorting: false,
    cell: ({ row }) => <div>{row.original.steps[0]?.name || '-'}</div>,
    size: 150,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
  },
  {
    id: 'Payment',
    header: () => 'Payment',
    accessorKey: 'max_count',
    enableSorting: false,
    cell: ({ row }) => {
      return (
        <div>
          {row.original.drive_schedule ? (
            <div>
              {row.original.drive_schedule?.sku?.currency_code &&
                getCurrencySymbol(row.original.drive_schedule?.sku?.currency_code)}
              {row.original.drive_schedule?.sku?.price}
            </div>
          ) : (
            '-'
          )}
        </div>
      );
    },
    size: 100,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'Last Modified By',
    header: () => 'Last Modified By',
    accessorKey: 'Last_Modified_By',
    enableSorting: false,
    cell: ({ row }) => {
      const email = row.original.updated_by_details?.email
        ? row.original.updated_by_details?.email
        : row.original.updated_by_details?.name
          ? row.original.updated_by_details?.name
          : '';
      return (
        <User
          data={{
            name: email || 'NA',
          }}
          size="sm"
          hideImage={false}
          additionalContent={row.original.updated_at ? formatDate(new Date(row.original.updated_at), 'withTime') : ''}
        />
      );
    },
    size: 150,
    className: 'text-left text-sm font-medium font-roboto text-gray-900',
    cellClassName: 'text-left',
    hidden: false,
  },
  {
    id: 'menu',
    enableSorting: false,
    accessorKey: 'menu',
    size: 48,
    className: 'text-left right-0 sticky shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
    cellClassName: 'text-center right-0 sticky z-[1] shadow-[inset_0px_0px_0px_-1px] shadow-gray-400/20',
    header: ({ table }) => {
      return (
        <div>
          <Popover className="flex justify-end items-center pr-3">
            <PopoverButton className={classNames('focus:outline-none')}>
              <PlusCircleIcon
                className={classNames('text-black h-5 w-5 focus:outline-none')}
                aria-hidden="true"
                data-testid="hide-column"
              />
            </PopoverButton>
            <Transition>
              <PopoverPanel>
                <div
                  className="bg-white border-solid border-[1px] w-[214px] font-normal shadow-sm max-h-[480px] overflow-y-auto rounded-md px-4 absolute right-0 top-12"
                  data-testid="column-model"
                >
                  {table.getAllLeafColumns().map((column) => {
                    if (column.id === 'menu') {
                      return null;
                    }
                    return (
                      <div key={column.id} className="h-[40px] w-full flex items-center">
                        <span className="pr-2 w-6 h-6">
                          <img src={gripHorizontalIcon} alt="edit" className="w-full h-full" />
                        </span>
                        <Checkbox
                          label={
                            typeof column.columnDef.header === 'string'
                              ? column.columnDef.header
                              : convertToTitleCase(column.id)
                          }
                          id={column.id}
                          size="md"
                          checked={column.getIsVisible()}
                          onChange={column.getToggleVisibilityHandler()}
                          disabled={false}
                        />
                      </div>
                    );
                  })}
                </div>
              </PopoverPanel>
            </Transition>
          </Popover>
        </div>
      );
    },
  },
];
