import React, { useEffect, useState } from 'react';

import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TrashIcon } from '@heroicons/react/24/outline';
import { filter, isEqual, map, some } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';

import { Button } from '@components/Base/Button';
import { Dropdown } from '@components/Base/Dropdown';
import Modal from '@components/Base/Modal';
import { Textarea } from '@components/Base/Textarea';
import { Textbox } from '@components/Base/Textbox';
import ConfirmMessage from '@components/Projects/ConfirmMessage';
import { geographyOptions } from '@containers/projects/constants';
import type {
  CreateProjectActionPayload,
  EditProjectActionPayload,
  Project,
  SelectedOption,
} from '@containers/projects/types';
import { classNames } from '@utils/classNames';

import { parseProjectTags } from './helper';
import messages from './messages';

interface Props {
  selectedProject?: Project;
  isOpen: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  projects: Project[];
  newProject: Project;
  onCreateProject: (payload: CreateProjectActionPayload) => void;
  onEditProject: (payload: EditProjectActionPayload) => void;
  onComplete: (newProject: Project) => void;
  onClose: () => void;
}

const ProjectForm = ({
  isOpen,
  isLoading,
  isSuccess,
  projects,
  newProject,
  selectedProject,
  onCreateProject,
  onEditProject,
  onComplete,
  onClose,
}: Props) => {
  const Intl = useIntl();

  const [projectName, setProjectName] = useState('');
  const [geography, setGeography] = useState<SelectedOption | string>('');
  const [projectDescription, setProjectDescription] = useState('');
  const [tagList, setTagList] = useState([
    {
      id: 1,
      key: '',
      value: '',
      isAdded: false,
      isKeyDuplicate: false,
      isValueDuplicate: false,
      isPrefilled: false,
    },
  ]);
  const [error, setError] = useState({
    isProjectNameEmpty: false,
    isProjectNameDuplicate: false,
    isGeographyEmpty: false,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);

  const currentProjectDetails = {
    name: selectedProject?.name,
    description: selectedProject?.description,
    tags: parseProjectTags(selectedProject?.tags || []),
  };

  const updatedProjectDetails = {
    name: projectName,
    description: projectDescription,
    tags: parseProjectTags(tagList),
  };

  const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const editedProjectName = e.target.value;
    const isDuplicate = some(projects, ({ name }) => name === editedProjectName);
    setProjectName(editedProjectName);
    setError({
      ...error,
      isProjectNameEmpty: !editedProjectName,
      isProjectNameDuplicate: isDuplicate,
    });
  };

  const handleGeographyChange = (e: SelectedOption) => {
    setGeography(e);
    setError({ ...error, isGeographyEmpty: !e.value });
  };

  const handleAddTag = (id: number) => {
    const updatedTagList = map(tagList, (tag) => {
      if (tag.id === id) {
        tag.isAdded = true;
      }
      return tag;
    });
    const newTagPayload = {
      id: tagList[tagList.length - 1].id + 1,
      key: '',
      value: '',
      isAdded: false,
      isKeyDuplicate: false,
      isValueDuplicate: false,
      isPrefilled: false,
    };
    setTagList([...updatedTagList, newTagPayload]);
  };

  const handleUpdateTagName = (id: number, newKey: string) => {
    const isDuplicate = some(tagList, (existingTag) => existingTag.key === newKey);

    const updatedTags = tagList.map((tag) => {
      tag.isKeyDuplicate = false;
      if (tag.id === id) {
        tag.key = newKey;
        tag.isKeyDuplicate = isDuplicate;
      }
      return tag;
    });

    setTagList(updatedTags);
  };

  const handleUpdateTagValue = (id: number, newValue: string) => {
    const isDuplicate = some(tagList, (existingTag) => existingTag.value === newValue);

    const updatedTags = tagList.map((tag) => {
      tag.isValueDuplicate = false;
      if (tag.id === id) {
        tag.value = newValue;
        tag.isValueDuplicate = isDuplicate;
      }
      return tag;
    });
    setTagList(updatedTags);
  };

  const handleDeleteTag = (id: number) => {
    const filteredTags = filter(tagList, (tag) => tag.id !== id);
    setTagList(filteredTags);
  };

  const handleSubmit = () => {
    const updatedTags = map(
      filter(tagList, ({ key }) => Boolean(key)),
      ({ id, key, value, isPrefilled }) => (isPrefilled ? { id, key, value } : { key, value }),
    );
    if (!projectName || !geography) {
      setError({
        ...error,
        isProjectNameEmpty: !projectName,
        isGeographyEmpty: !geography,
      });
      return;
    }
    selectedProject
      ? onEditProject({
          name: projectName,
          description: projectDescription,
          tags: updatedTags,
          id: selectedProject.id,
        })
      : onCreateProject({
          name: projectName,
          region: (geography as SelectedOption).value,
          description: projectDescription,
          tags: updatedTags,
        });
  };

  const handleComplete = () => {
    const payload = selectedProject ? { ...selectedProject, ...newProject } : newProject;
    onComplete(payload);
    onClose();
  };

  useEffect(() => {
    if (isEqual(currentProjectDetails, updatedProjectDetails)) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  }, [updatedProjectDetails]);

  useEffect(() => {
    if (!selectedProject) {
      return;
    }

    const { name, region, description, tags } = selectedProject;

    name && setProjectName(name);

    const selectedGeography = geographyOptions.find((geography) => geography.value === region);
    selectedGeography && setGeography(selectedGeography as SelectedOption);

    description && setProjectDescription(description);

    tags &&
      tags.length &&
      setTagList(
        map(tags, ({ id, key, value }, index) => ({
          id,
          key,
          value,
          isAdded: tags.length - 1 !== index,
          isKeyDuplicate: false,
          isValueDuplicate: false,
          isPrefilled: true,
        })),
      );
  }, [selectedProject]);

  return isLoading || isSuccess ? (
    <ConfirmMessage isSuccess={isSuccess} onSuccess={handleComplete} isEditProject={!!selectedProject} />
  ) : (
    <Modal size="md" show={isOpen} onCancel={onClose}>
      <div className="relative text-gray-900 w-full h-full overflow-auto px-1 pb-10">
        <h3 className="text-xl font-semibold leading-6" id="modal-title">
          {selectedProject ? (
            <FormattedMessage {...messages.edit_project} />
          ) : (
            <FormattedMessage {...messages.new_project} />
          )}
        </h3>
        <div className="flex flex-col gap-6 mt-10 w-full h-[60vh] overflow-auto px-[1px]">
          <div className="w-full flex gap-3">
            <div className="flex-1">
              <Textbox
                name="projectName"
                maxLength={30}
                label={Intl.formatMessage(messages.project_name)}
                required
                error={
                  error.isProjectNameDuplicate
                    ? Intl.formatMessage(messages.project_name_error)
                    : error.isProjectNameEmpty
                      ? Intl.formatMessage(messages.mandatory_field_error)
                      : ''
                }
                className="inline-block flex-1"
                placeholder={Intl.formatMessage(messages.project_name_placeholder)}
                value={projectName}
                onChange={handleProjectNameChange}
              />
            </div>
            <div className="flex-1">
              <label htmlFor="geography" className="text-black text-sm font-normal inline-block mb-1">
                <FormattedMessage {...messages.geography} />
                <span className="pl-0.5">*</span>
              </label>
              <Dropdown
                id="geography"
                value={geography}
                isDisabled={!!(selectedProject && selectedProject.region)}
                isSearchable={false}
                onChange={(e) => handleGeographyChange(e as SelectedOption)}
                options={geographyOptions}
              />
              {error.isGeographyEmpty && (
                <span className="mt-0 text-xs text-red-700">{Intl.formatMessage(messages.mandatory_field_error)}</span>
              )}
            </div>
          </div>
          <Textarea
            maxLength={100}
            value={projectDescription}
            onChange={(e) => setProjectDescription(e.target.value)}
            placeholder="Describe your project"
            label={Intl.formatMessage(messages.description_placeholder)}
            name="projectDescription"
            className="resize-none"
          />
          <div className="flex items-center text-black gap-2">
            <p className="text-sm">
              <FormattedMessage {...messages.tags} />
            </p>
            <div className="h-[1px] w-full bg-gray-200"></div>
          </div>
          <div className="pb-4 flex flex-col gap-3">
            {map(tagList, ({ id, key, value, isAdded, isKeyDuplicate, isValueDuplicate, isPrefilled }, index) => (
              <div className="flex items-start gap-3" key={id}>
                <div className="flex-1">
                  <Textbox
                    data-testid={`tag-name-input${index}`}
                    error={isKeyDuplicate && !selectedProject ? Intl.formatMessage(messages.project_tag_error) : ''}
                    label={!index ? Intl.formatMessage(messages.tag_name) : ''}
                    onChange={(e) => handleUpdateTagName(id, e.target.value)}
                    placeholder="Case insensitive"
                    value={key}
                    className={classNames('block', isPrefilled && 'pointer-events-none')}
                  />
                </div>
                <div className="flex-1 flex items-center gap-3">
                  <div className="flex-1">
                    <Textbox
                      data-testid={`tag-value-input${index}`}
                      error={isValueDuplicate && selectedProject ? Intl.formatMessage(messages.project_tag_error) : ''}
                      label={!index ? Intl.formatMessage(messages.tag_value) : ''}
                      onChange={(e) => handleUpdateTagValue(id, e.target.value)}
                      placeholder="Case sensitive"
                      className="block"
                      value={value}
                    />
                  </div>
                  {isAdded ? (
                    <Button
                      data-testid="remove-tag-btn"
                      onClick={() => handleDeleteTag(id)}
                      variant="destructive_ghost"
                      className="self-end mb-1"
                    >
                      <TrashIcon className="w-4 h-4" />
                    </Button>
                  ) : (
                    <Button
                      data-testid="add-tag-btn"
                      onClick={() => handleAddTag(id)}
                      variant="ghost"
                      disabled={!key || !value || isKeyDuplicate || isValueDuplicate}
                      className={classNames('mb-1', isKeyDuplicate || isValueDuplicate ? 'self-start' : 'self-end')}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <Button
          data-testid="submit-btn"
          className="absolute bottom-0 left-0"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          {selectedProject ? (
            <FormattedMessage {...messages.save_changes} />
          ) : (
            <FormattedMessage {...messages.create_new_project} />
          )}
        </Button>
      </div>
    </Modal>
  );
};

export default ProjectForm;
