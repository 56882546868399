import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export type Accommodation = {
  accommodation_type: string;
  id: number;
};

export type Reviews = {
  rating: string;
  created_at: string;
  assigned_at: null | string;
};

export type SessionReviews = {
  assigned_at: null | string;
  rating: string;
  review_status: string;
  review_request_reason: string | null;
  rating_reason: {
    session_reviews: Reviews[];
    text: string;
  };
};

interface FlagTypeClassification {
  text: string;
  id: number;
}

interface FlagType {
  flag_type_classification: FlagTypeClassification;
  flag_type_classification_id: number;
  id: number;
}

export type Flag = {
  id: string;
  flag_type: FlagType;
  flag_type_id: number;
};
export interface SessionData {
  uuid: string;
  title: string;
  candidate_id: number;
  session_status: string;
  session_type: string;
  summary: string;
  system_rating: null;
  duration: number | null;
  tenant_id: number;
  updated_at: null | string;
  project_id: number;
  session_reviews: SessionReviews[];
  attempt_count: null | number;
  completed_at: null | string;
  created_at: string;
  external_id: string;
  id: number;
  flow_config_id: number;
  accommodation: Accommodation[];
  flags: Flag[];
}

export type SessionsState = {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: Error | null;
  data: SessionData[];
};

export const initialState: SessionsState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: null,
  data: [],
};

export const consoleSessions = createSlice({
  name: 'consoleSessions',
  initialState: initialState,
  reducers: {
    getProSessionsAction: (
      state,
      _action: PayloadAction<{
        conditions: object;
      }>,
    ) => {
      state.isLoading = true;
    },

    getProSessionsSuccessAction: (state, { payload: payload }) => {
      state.data = payload;
      state.isSuccess = true;
      state.isLoading = false;
    },

    getProSessionsErrorAction: (state, { payload: error }) => {
      state.isLoading = false;
      state.isError = true;
      state.error = error;
    },
  },
});

export const { getProSessionsAction, getProSessionsSuccessAction, getProSessionsErrorAction } = consoleSessions.actions;

export default consoleSessions.reducer;
