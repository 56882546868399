import React from 'react';

import { signoutAction, updateUserAction, uploadProfilePhotoAction } from '@containers/app/slice';
import { UpdateUserActionPayload, UploadFileActionPayload } from '@containers/app/types';
import { useAppDispatch } from '@utils/hooks';

const withUserActions = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const ComponentWithUserActions: React.FC<P> = (props) => {
    const dispatch = useAppDispatch();

    const actions = {
      onSignout: () => dispatch(signoutAction()),
      onUpdateProfilePhoto: (payload: UploadFileActionPayload) => dispatch(uploadProfilePhotoAction(payload)),
      onUpdateUserAction: (payload: UpdateUserActionPayload) => dispatch(updateUserAction(payload)),
    };

    return <WrappedComponent {...props} {...actions} />;
  };

  return ComponentWithUserActions;
};

export default withUserActions;
