import { defineMessages } from 'react-intl';

export const scope = 'app.components.account_settings.language_select';

export default defineMessages({
  header: {
    id: `${scope}.header`,
    defaultMessage: 'Language Settings',
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Save Changes',
  },
  change_lang: {
    id: `${scope}.change_lang`,
    defaultMessage: 'Change Language*',
  },
});
