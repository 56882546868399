import React, { useState } from 'react';

import { find, get } from 'lodash';
import { FormattedMessage } from 'react-intl';

import { Button } from '@components/Base/Button';
import { Dropdown } from '@components/Base/Dropdown';
import { LOCAL_STORAGE_KEYS } from '@utils/data/enums';
import { setItemInLocalStorage } from '@utils/helpers';

import { LanguageOption, languages } from './languages';
import messages from './messages';

interface LanguageSelectProps {
  selectedLanguage?: string;
  handleSetLocale: (language: string) => void;
}
const LanguageSelectContent = ({ selectedLanguage, handleSetLocale }: LanguageSelectProps) => {
  const [language, setLanguage] = useState(() => find(languages, ['value', selectedLanguage]));

  const save = () => {
    setItemInLocalStorage(LOCAL_STORAGE_KEYS.LANGUAGE, JSON.stringify(language));
    if (get(language, 'value')) {
      handleSetLocale(get(language, 'value', ''));
    }
  };

  const formatOptionLabel = (option: string | number | { label: string; value: string | number }) => (
    <div className="flex items-center">
      <span>
        <img src={get(option, 'flag')} alt="flag" className="mr-2 h-7 w-7" />
      </span>
      <span>{get(option, 'label')}</span>
    </div>
  );

  return (
    <div className="w-full">
      <div className="mb-4 text-sm font-normal leading-4 text-gray-900">
        <FormattedMessage {...messages.change_lang} />
      </div>
      <div className="w-[470px]">
        <Dropdown
          value={language as LanguageOption}
          name="provider-select"
          options={languages}
          onChange={(value) => setLanguage(value as LanguageOption)}
          placeholder="Change Language"
          id="topics"
          isSearchable={false}
          formatOptionLabel={formatOptionLabel}
        />
      </div>
      <div className="flex justify-end gap-3 pt-10 pr-2">
        <Button disabled={language?.value === selectedLanguage} onClick={save}>
          <FormattedMessage {...messages.submit} />
        </Button>
      </div>
    </div>
  );
};

export default LanguageSelectContent;
