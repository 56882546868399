import React from 'react';

import Table from '@components/Base/Table';
import { CustomColumnDef } from '@components/Base/Table/types';
import { SessionData } from '@containers/exams/types';

interface ProviewAssessmentTableProps {
  assessments: SessionData[];
  columns: CustomColumnDef<SessionData>[];
  isLoading: boolean;
}

const ProviewAssessmentTable: React.FC<ProviewAssessmentTableProps> = (props) => {
  const { assessments, columns, isLoading } = props;
  return (
    <div className="h-full">
      <Table<SessionData>
        data={assessments}
        columns={columns}
        isMultiselect={false}
        isLoading={isLoading}
        pagination={{ pageIndex: 0, pageSize: 10 }}
        pageCount={0}
        setPagination={() => {}}
      />
    </div>
  );
};

export default ProviewAssessmentTable;
